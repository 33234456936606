import Decimal from "decimal.js";
import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { toValue, toNumber, toBoolean, toDatetime } from "./behavior-commons";

export function createConversionFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "valueof":
      return new valueOfFunctionBehaviorNode(token, mode);
    case "tostring":
      return new toStringFunctionBehaviorNode(token, mode);
    case "fromstring":
      return new fromStringFunctionBehaviorNode(token, mode);
    case "tonumber":
      return new toNumberFunctionBehaviorNode(token, mode);
    case "tointeger":
      return new toIntegerFunctionBehaviorNode(token, mode);
    case "tofloat":
      return new toFloatFunctionBehaviorNode(token, mode);
    case "toboolean":
      return new toBooleanFunctionBehaviorNode(token, mode);
    case "todatetime":
      return new toDatetimeFunctionBehaviorNode(token, mode);
    case "tosheetsdatetime":
      return new toSheetsDatetimeFunctionBehaviorNode(token, mode);
    case "fromsheetsdatetime":
      return new fromSheetsDatetimeFunctionBehaviorNode(token, mode);
    case "encodebase64":
      return new encodeBase64FunctionBehaviorNode(token, mode);
    case "decodebase64":
      return new decodeBase64FunctionBehaviorNode(token, mode);
  }
}

/**
 * Replaces references with their values. If value is an array, it returns an array of values.
 * @name valueOf() function
 * @param value A reference or array of references.
 * @returns The value or an array of values.
 */
class valueOfFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): Value | Value[] {
    const result = this.arguments[0].evaluate(scope, calledFrom);

    // on a single item return the value of the item
    if (!Array.isArray(result) || result.length < 2) return toValue(result);

    // on an array result return an array of values
    const array: Value[] = [];
    for (const v of result) {
      array.push(toValue(v));
    }
    return array;
  }
}

/**
 * Converts a value to its string representation.
 * @name toString() function
 * @param value A value or array of values.
 * @returns The value converted to a string. For datetime values it is an ISO
 *    8601 datetime string
 */
class toStringFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const value = toValue(this.arguments[0].evaluate(scope, calledFrom));
    return value instanceof Date
      ? value.toISOString()
      : value?.toString() ?? "";
  }
}

// regular expressions used by the fromString() function
const numberRE =
  /^\s*[+-]?(?:[0-9]+(?:\.[0-9]+)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?\s*$/;
const trueRE = /^\s*(true)\s*$/i;
const falseRE = /^\s*(false)\s*$/i;
const nullRE = /^\s*(null)\s*$/i;

/**
 * Converts an arbitrary value to a type that fits the value best. If value is
 * not a string, then the type of value is kept. Does not convert date or time
 * strings to a datetime value. Use toDatetime instead.
 * @name fromString() function
 * @param value A value or array of values.
 * @returns The converted value.
 * @example 123.4 -> 123.4
 * @example "123.4" -> 123.4
 * @example "true", "TRUE", "True" -> true
 * @example "false", "FALSE", "False" -> false
 * @example "null", "NULL", "Null" -> null
 * @example "Hyperseed" -> "Hyperseed"
 */
class fromStringFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    const value = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (typeof value === "string") {
      if (numberRE.test(value)) return toNumber(value);
      if (trueRE.test(value)) return true;
      if (falseRE.test(value)) return false;
      if (nullRE.test(value)) return null;
    }
    return value;
  }
}

/**
 * Converts a value to its number representation.
 * @name toNumber() function
 * @param value A value or array of values.
 * @returns The value converted to a number.
 */
class toNumberFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toNumber(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Converts a value to its integer representation. Truncates all decimal digits (thus it is not rounding the value).
 * @name toInteger() function
 * @param value A value or array of values.
 * @returns The value converted to an integer.
 */
class toIntegerFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return Number(
      Decimal.trunc(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Converts a value to its floating point number representation. Identical to toNumber().
 * @name toFloat() function
 * @param value A value or array of values.
 * @returns The value converted to a floating point number.
 */
class toFloatFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toNumber(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Converts a value to its boolean representation.
 * @name toBoolean() function
 * @param value A value or array of values.
 * @returns The value converted to a boolean.
 */
class toBooleanFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return toBoolean(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Converts a value to a its datetime representation.
 * @name toDatetime() function
 * @param value A value or array of values.
 * @returns The value converted to DateTime.
 */
class toDatetimeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    return toDatetime(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Converts a datetime value to a spreadsheet date-time serial number.
 * @name toSheetsDatetime() function
 * @param value A datetime value.
 * @returns A spreadsheet date-time serial number.
 */
class toSheetsDatetimeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    const datetime = toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).valueOf();
    return Number(Decimal.div(datetime, 86400000).add(25569));
  }
}

/**
 * Converts a spreadsheet date-time serial number to a datetime value.
 * @name fromSheetsDatetime() function
 * @param value A spreadsheet date-time serial number.
 * @returns A datetime value.
 */
class fromSheetsDatetimeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const dateNumber = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    return new Date(Number(Decimal.sub(dateNumber, 25569).mul(86400000)));
  }
}

/**
 * Encodes a string into a Base64 string.
 * @name encodeBase64() function
 * @param value A binary string.
 * @returns The encoded Base64 string.
 */
class encodeBase64FunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const str = this.arguments[0].evaluate(scope, calledFrom)?.toString() ?? "";
    if (typeof Buffer === "function") {
      // the nodejs version
      return Buffer.from(str).toString("base64");
    }
    // otherwise execute the web browser javascript version

    // convert a Unicode string to a string in which each 16-bit unit occupies
    // only one byte
    // see also https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
    const codeUnits = Uint16Array.from(
      { length: str.length },
      (element, index) => str.charCodeAt(index)
    );
    const charCodes = new Uint8Array(codeUnits.buffer);

    let bin = "";
    charCodes.forEach((char) => {
      bin += String.fromCharCode(char);
    });

    return btoa(bin);
  }
}

/**
 * Decodes a Base64 string into a string.
 * @name decodeBase64() function
 * @param value A binary string.
 * @returns The encoded Base64 string.
 */
class decodeBase64FunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const base64 =
      this.arguments[0].evaluate(scope, calledFrom)?.toString() ?? "";
    if (typeof Buffer === "function") {
      // the nodejs version
      return Buffer.from(base64, "base64").toString();
    }

    // otherwise execute the web browser javascript version
    try {
      const bin = atob(base64);

      // reverse the conversion done in encodeBase64
      // see also https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
      const bytes = Uint8Array.from({ length: bin.length }, (element, index) =>
        bin.charCodeAt(index)
      );
      const charCodes = new Uint16Array(bytes.buffer);

      let str = "";
      charCodes.forEach((char) => {
        str += String.fromCharCode(char);
      });

      return str;
    } catch (e) {
      // TODO: return as an error object as soon as we support error objects as
      // values
      return "Error invalid character";
    }
  }
}
