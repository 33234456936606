import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import { AppContext } from "@shared/src/classes/app-context";
import queueFrame from "@shared/src/classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { OrgRoles } from "@shared/src/model";
import { Schema } from "@shared/src/schema/schema-global";

enum Status {
  INITIALIZING = "initializing",
  SIGNIN_PENDING = "signin-pending",
  SIGNIN_FAILED = "signin-failed",
  SIGNOUT_PENDING = "signout-pending",
  SIGNOUT_FAILED = "signout-failed",
  AUTHENTICATED = "authenticated",
  NOT_AUTHENTICATED = "not-authenticated",
}

/**
 * Signs the user in using Firebase Authentication with email and
 * password.
 */
export default class AuthSignInComponent implements InitializableComponent {
  private _invokedBy: ComponentInvoker;

  /** Triggers the signin call when true */
  private _signin = false;
  /** Triggers the signout call when true */
  private _signout = false;
  /** User's unique username (email) */
  private _username: string | null = null;
  /** User's password */
  private _password: string | null = null;

  /** The user id after successful authentication */
  private _id: string | null = null;
  /** The current org id after successful authentication */
  private _orgId: string | null = null;
  /** the authenticated user's username (email) */
  private _authenticatedUsername: string | null = null;
  /** The authentication token after successful authentication */
  private _token: string | null = null;
  /** The roles the authenticated user is assigned to */
  private _roles: string[] | null = null;
  /** The org roles the authenticated user is assigned to */
  private _orgRoles: ValueGraph | null = null;

  /** The status after signin or signout has been triggered */
  private _status: Status | null = null;
  /** The error message if an error occured during signin or signout */
  private _error: string | null = null;
  /**
   * `null` while the component is initializing, `true` when the user
   * is authenticated, otherwise `false`
   */
  private _isAuthenticated: boolean | null = null;
  /** Flag to indicate whether user has explicitly signed in or out  */
  private _isInitialized = false;

  private _context?: AppContext;
  private _contextSubscription?: Unsubscribable;

  private _authUnsubscribe?: firebase.Unsubscribe;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      signin: { _input: false },
      signout: { _input: false },
      username: { _input: null },
      password: { _input: null },

      id: { _output: null },
      orgId: { _output: null },
      authenticatedUsername: { _output: null },
      token: { _output: null },
      roles: { _iscollection: true, _output: null },
      orgRoles: { _iscollection: true, _output: null },
      error: { _output: null },
      status: { _output: null },
      isAuthenticated: { _output: null },
    };
  }

  init(): void {
    // Register observer to update outputs whenever a sign-in or
    // sign-out event occurs.
    this._authUnsubscribe = firebase.auth().onAuthStateChanged(async (u) => {
      this._isInitialized = true;
      this._orgId = null;
      await this.updateOutputs(u);
    });
    // Immediately update outputs. Needed in particular when a
    // subsequent instance of the component is created e.g., to read
    // orgId.
    this.updateOutputs(null).catch(console.error);
    this._context = this._invokedBy.getAppContext();
    this._contextSubscription = this._context?.subscribe({
      next: (context: AppContext) => {
        this._context = context;
        // Process changes to orgId.
        if (context.orgId ?? null !== this._orgId) {
          this._orgId = context.orgId ?? null;
          this.updateOutputs(null).catch(console.error);
        }
      },
    });
  }

  destroy(): void {
    this._authUnsubscribe?.();
    this._contextSubscription?.unsubscribe();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "signin":
          this._signin = !!value;
          break;
        case "signout":
          this._signout = !!value;
          break;
        case "username":
          this._username = value?.toString() ?? null;
          break;
        case "password":
          this._password = value?.toString() ?? null;
          break;
      }
    });

    // TODO: call signin and signout only when the value turns true
    if (this._signin) queueFrame(() => this.callSignIn());
    if (this._signout) queueFrame(() => this.callSignOut());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      id: this._id,
      orgId: this._orgId,
      authenticatedUsername: this._authenticatedUsername,
      token: this._token,
      roles: this._roles,
      orgRoles: this._orgRoles,
      status: this._status,
      error: this._error,
      isAuthenticated: this._isAuthenticated,
    };
  }

  private updateContext() {
    if (!this._context) return;
    this._context.orgId = this._orgId ?? undefined;
    this._context.userId = this._id ?? undefined;
  }

  /**
   * Calls the asynchronuous signIn method and sets the output properties of the
   * component accordingly.
   */
  private callSignIn() {
    this._status = Status.SIGNIN_PENDING;
    this._error = null;
    this.pushOutputs();
    this.signIn(
      this._username?.toString() ?? "",
      this._password?.toString() ?? ""
    )
      .then(async (user) => {
        if (!user)
          throw new Error(
            "Authentication component must receive a user object on successful authentication"
          );
        await this.updateOutputs(user);
      })
      .catch((err) => {
        this._error = (err as { code: string }).code ?? "signin failed";
        this._status = Status.SIGNIN_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Calls the asynchronuous signOut method and sets the output properties of the
   * component accordingly.
   */
  private callSignOut() {
    this._status = Status.SIGNOUT_PENDING;
    this._error = null;
    this.pushOutputs();
    this.signOut()
      .then(() => this.updateOutputs(null))
      .catch((err) => {
        this._error = (err as { code: string }).code ?? "signout failed";
        this._status = Status.SIGNOUT_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Signs the user in.
   *
   * @param username user's unique username
   * @param password user's password
   * @returns the user object or null
   * @throws Error if signing in failed
   */
  private async signIn(
    username: string,
    password: string
  ): Promise<firebase.User | null> {
    try {
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(username, password);
      if (userCredential.user === null) return null;
      const user = userCredential.user;
      localStorage.setItem("username", username);
      localStorage.setItem("userId", user.uid);
      this._orgId = null;
      this._isInitialized = true;
      return user;
    } catch (err) {
      console.error("sign in failed:", err);
      throw err;
    }
  }

  /**
   * Signs the user out.
   *
   * @throws Error if signing out failed
   */
  private async signOut(): Promise<void> {
    try {
      await firebase.auth().signOut();
      localStorage.removeItem("username");
      localStorage.removeItem("userId");
      localStorage.removeItem("orgId");
      this._isInitialized = true;
    } catch (err) {
      console.error("Firebase auth signOut() failed:", err);
      throw err;
    }
  }

  /**
   * Updates component outputs according to the currently signed-in
   * user or signed-out state.
   *
   * @param user the currently signed-in user or 'null' if the user is
   *   not currently signed in
   */
  private async updateOutputs(user: firebase.User | null): Promise<void> {
    if (user === null) user = firebase.auth().currentUser;
    if (user === null) {
      this._id = null;
      this._orgId = null;
      this._authenticatedUsername = null;
      this._token = null;
      this._roles = null;
      this._orgRoles = null;
      this._error = null;
      this._status = this._isInitialized
        ? Status.NOT_AUTHENTICATED
        : Status.INITIALIZING;
      this._isAuthenticated = this._isInitialized ? false : null;
      this.updateContext();
      this.pushOutputs();
      return;
    }
    const tokenResult = await user.getIdTokenResult();
    const orgRoles = tokenResult.claims.orgs as OrgRoles;
    const orgIds = orgRoles ? Object.keys(orgRoles) : [];
    if (orgIds.length !== 1) {
      const email = user.email ?? "unknown";
      console.warn(
        `User ${email}'s auth token has ${orgIds.length} orgs (expected 1):`,
        orgIds
      );
    }
    if (!this._orgId && orgIds.length > 0) {
      this._orgId = orgIds[0];
    }
    if (this._orgId && !orgIds.includes(this._orgId)) {
      const email = user.email ?? "unknown";
      console.warn(
        `User ${email}'s auth token does not include orgId ${this._orgId}`
      );
    }
    if (this._orgId) localStorage.setItem("orgId", this._orgId);
    this._id = user.uid;
    this._authenticatedUsername = user.email;
    this._token = tokenResult.token;
    this._roles = tokenResult.claims.roles as string[];
    this._orgRoles = orgRoles;
    this._error = null;
    this._status = Status.AUTHENTICATED;
    this._isAuthenticated = true;
    this.updateContext();
    this.pushOutputs();
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
