import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIElementComponent from "./ui-element-component";

/**
 * Represents an SVG circle element in the DOM tree. Details about the circle
 * element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/circle
 */
export default class SVGCircleComponent extends UIElementComponent {
  protected _DOMNode: SVGCircleElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle"
    );
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.cx = { _input: null };
    schema.cy = { _input: null };
    schema.r = { _input: null };
    return schema;
  }

  get DOMNode(): SVGCircleElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "cx":
      case "cy":
      case "r":
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
    }
  }
}
