import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIElementComponent from "./ui-element-component";

/**
 * The SVG text component represents an svg text element in the DOM tree.
 * Details about the text element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/text
 */
export default class SVGTextComponent extends UIElementComponent {
  protected _DOMNode: SVGTextElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.textContent = { _input: null };
    schema.x = { _input: null };
    schema.y = { _input: null };
    schema.dx = { _input: null };
    schema.dy = { _input: null };
    schema.rotate = { _input: null };
    return schema;
  }

  get DOMNode(): SVGTextElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "textcontent":
        // set the text content to null just if value is null
        this.DOMNode.textContent = value?.toString() ?? null;
        break;
      case "x":
      case "y":
      case "dx":
      case "dy":
      case "rotate":
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
    }
  }
}
