import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * UIContextMenuComponent implements a container for the context menu items as
 * well as capturing events to open and close the contextmenu container.
 */
export default class UIContextMenuComponent extends UIHTMLElementComponent {
  private _DOMNode: HTMLElement;
  private _visible = false;
  private _leftButton = false;
  private _rightButton = true;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);
    this._DOMNode = document.createElement("div");
    this.DOMNode.style.visibility = "hidden";
    this.DOMNode.style.position = "fixed";
  }

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.leftButton = { _input: {} };
    schema.rightButton = { _input: {} };
    schema.visible = { _output: {} };

    return schema;
  }

  get DOMNode(): HTMLElement {
    return this._DOMNode;
  }

  setInputValueGraph(graph: ValueGraph): void {
    const leftButton = this._leftButton;
    const rightButton = this._rightButton;

    super.setInputValueGraph(graph);

    this.updateEventListeners(leftButton, rightButton);
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "leftbutton":
        this._leftButton = Boolean(value);
        break;
      case "rightbutton":
        this._rightButton = Boolean(value);
        break;
    }
  }

  updateEventListeners(prevLeft: boolean, prevRight: boolean) {
    if (prevRight !== this._rightButton) {
      if (this._rightButton)
        this.DOMNode.parentElement?.addEventListener("contextmenu", this.show);
      else
        this.DOMNode.parentElement?.removeEventListener(
          "contextmenu",
          this.show
        );
    }
    if (prevLeft !== this._leftButton) {
      if (this._leftButton)
        this.DOMNode.parentElement?.addEventListener("mousedown", this.show);
      else
        this.DOMNode.parentElement?.removeEventListener("mousedown", this.show);
    }
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();
    result.visible = this._visible;
    return result;
  }

  private show = (event: Event) => {
    this.DOMNode.style.visibility = "visible";
    this.DOMNode.style.position = "fixed";
    this.DOMNode.style.left = `${(event as MouseEvent).clientX}px`;
    this.DOMNode.style.top = `${(event as MouseEvent).clientY}px`;
    this.DOMNode.style.zIndex = "10000";
    event.preventDefault();
    event.stopPropagation();

    this._visible = true;
    this.pushOutputs();
  };

  private hide = (event: Event) => {
    this.DOMNode.style.visibility = "hidden";
    this.DOMNode.style.position = "fixed";
    event.stopPropagation();

    this._visible = false;
    this.pushOutputs();
  };

  private onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === "Escape") {
      this.DOMNode.style.visibility = "hidden";
      this.DOMNode.style.position = "fixed";

      this._visible = false;
      this.pushOutputs();
    }
  };

  attachDOM(parentDOMNode: Element): void {
    super.attachDOM(parentDOMNode);

    if (this._rightButton)
      this.DOMNode.parentElement?.addEventListener("contextmenu", this.show);
    if (this._leftButton)
      this.DOMNode.parentElement?.addEventListener("mousedown", this.show);

    // prevent repositioning the contextmenu when the user triggers the context
    // menu on the context menu itself
    this.DOMNode.addEventListener("contextmenu", (event) =>
      event.stopPropagation()
    );
    // prevent hiding the contextmenu when an item within has been clicked
    this.DOMNode.addEventListener("mousedown", (event) =>
      event.stopPropagation()
    );
    // hide the context menu when any item within it has been clicked
    this.DOMNode.addEventListener("click", this.hide);

    // hide the context menu when some interaction happens outside the context
    // menu
    window.addEventListener("blur", this.hide);
    document.addEventListener("mousedown", this.hide);
    document.addEventListener("mousewheel", this.hide);
    document.addEventListener("keydown", this.onKeyDown);
  }

  detachDOM(): void {
    if (this._rightButton)
      this.DOMNode.parentElement?.removeEventListener("contextmenu", this.show);
    if (this._leftButton)
      this.DOMNode.parentElement?.removeEventListener("mousedown", this.show);

    this.DOMNode.removeEventListener("contextmenu", (event) =>
      event.stopPropagation()
    );
    this.DOMNode.removeEventListener("mousedown", (event) =>
      event.stopPropagation()
    );
    this.DOMNode.removeEventListener("click", this.hide);

    window.removeEventListener("blur", this.hide);
    document.removeEventListener("mousedown", this.hide);
    document.removeEventListener("mousewheel", this.hide);
    document.removeEventListener("keydown", this.onKeyDown);

    super.detachDOM();

    this._visible = false;

    queueFrame(() => this.pushOutputs());
  }
}
