import { backOff } from "exponential-backoff";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

import { AppContext } from "@shared/src/classes/app-context";
import queueFrame from "@shared/src/classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { OrgRoles } from "@shared/src/model";
import * as rpc from "@shared/src/rpc";
import { Schema } from "@shared/src/schema/schema-global";

import { getDefaultBackoffOptions } from "../callable";

enum Status {
  INITIALIZING = "initializing",
  SIGNUP_PENDING = "signup-pending",
  SIGNUP_FAILED = "signup-failed",
  /** Signed up and authenticated */
  AUTHENTICATED = "authenticated",
  NOT_AUTHENTICATED = "not-authenticated",
}

/**
 * Signs the user up using Firebase Authentication with email and
 * password.
 */
export default class AuthSignUpComponent implements InitializableComponent {
  private _invokedBy: ComponentInvoker;

  /** Triggers the signup call when true */
  private _signup = false;
  /** User's unique username (email) */
  private _username: string | null = null;
  /** User's password */
  private _password: string | null = null;

  /** The user id after successful authentication */
  private _id: string | null = null;
  /** The current org id after successful authentication */
  private _orgId: string | null = null;
  /** the authenticated user's username (email) */
  private _authenticatedUsername: string | null = null;
  /** The authentication token after successful authentication */
  private _token: string | null = null;

  /** The status after signup has been triggered */
  private _status: Status | null = null;
  /** The error message if an error occured during signup */
  private _error: string | null = null;
  /**
   * `null` while the component is initializing, `true` when the user
   * is authenticated, otherwise `false`
   */
  private _isAuthenticated: boolean | null = null;
  /** Flag to indicate whether user has explicitly signed in or out  */
  private _isInitialized = false;

  private _context?: AppContext;
  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      signup: { _input: false },
      username: { _input: null },
      password: { _input: null },

      id: { _output: null },
      orgId: { _output: null },
      authenticatedUsername: { _output: null },
      token: { _output: null },
      error: { _output: null },
      status: { _output: null },
      isAuthenticated: { _output: null },
    };
  }

  init(): void {
    this._context = this._invokedBy.getAppContext();
    this._contextSubscription = this._context?.subscribe({
      next: (context: AppContext) => {
        this._context = context;
        // Process changes to orgId.
        if (context.orgId ?? null !== this._orgId) {
          this._orgId = context.orgId ?? null;
          this.updateOutputs(null).catch(console.error);
        }
      },
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "signup":
          this._signup = Boolean(value);
          break;
        case "username":
          this._username = value?.toString() ?? null;
          break;
        case "password":
          this._password = value?.toString() ?? null;
          break;
      }
    });

    // TODO: call signup only when the value turns true
    if (this._signup) queueFrame(() => this.callSignUp());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      id: this._id,
      orgId: this._orgId,
      authenticatedUsername: this._authenticatedUsername,
      token: this._token,
      status: this._status,
      error: this._error,
      isAuthenticated: this._isAuthenticated,
    };
  }

  private updateContext() {
    if (!this._context) return;
    this._context.orgId = this._orgId ?? undefined;
    this._context.userId = this._id ?? undefined;
  }

  /**
   * Calls the asynchronuous signUp method and sets the output properties of the
   * component accordingly.
   */
  private callSignUp() {
    this._status = Status.SIGNUP_PENDING;
    this._error = null;
    this.pushOutputs();

    this.signUp(
      this._username?.toString() ?? "",
      this._password?.toString() ?? ""
    )
      .then(async (user) => {
        if (!user)
          throw new Error(
            "Authentication component must receive a user object on successful authentication"
          );
        await this.updateOutputs(user);
      })
      .catch((err) => {
        this._error = (err as { code: string }).code ?? "signup failed";
        this._status = Status.SIGNUP_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Signs a new user up.
   *
   * @param username user's unique username
   * @param password user's password
   * @returns the user object or null
   * @throws Error if signing in failed
   */
  private async signUp(
    username: string,
    password: string
  ): Promise<firebase.User | null> {
    try {
      const userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(username, password);
      if (userCredential.user === null) return null;
      this._isInitialized = true;
      // Call backend function to create a personal org for the user.
      const createOrgFn = firebase
        .functions()
        .httpsCallable("org-createForNewUser");
      const backoffOptions = getDefaultBackoffOptions();
      const result = await backOff(() => createOrgFn({}), backoffOptions);
      const response = result.data as rpc.OrgCreateResponse;
      this._orgId = response.id;
      localStorage.setItem("username", username);
      localStorage.setItem("userId", userCredential.user.uid);
      localStorage.setItem("orgId", this._orgId);
      await userCredential.user.sendEmailVerification();
      return userCredential.user;
    } catch (err) {
      console.error("sign up failed:", err);
      throw err;
    }
  }

  /**
   * Updates component outputs according to the currently signed-in
   * user or signed-out state.
   *
   * @param user the currently signed-in user or 'null' if the user is
   *   not currently signed in
   */
  private async updateOutputs(user: firebase.User | null): Promise<void> {
    if (user === null) user = firebase.auth().currentUser;
    if (user === null) {
      this._id = null;
      this._authenticatedUsername = null;
      this._token = null;
      this._error = null;
      this._status = this._isInitialized
        ? Status.NOT_AUTHENTICATED
        : Status.INITIALIZING;
      this._isAuthenticated = this._isInitialized ? false : null;
      this.updateContext();
      this.pushOutputs();
      return;
    }
    // Force token refresh to get the claims added by
    // org-createForNewUser.
    const tokenResult = await user.getIdTokenResult(true);
    const orgRoles = tokenResult.claims.orgs as OrgRoles;
    const orgIds = orgRoles ? Object.keys(orgRoles) : [];
    if (orgIds.length !== 1) {
      const email = user.email ?? "unknown";
      console.warn(
        `User ${email}'s auth token has ${orgIds.length} orgs (expected 1):`,
        orgIds
      );
    }
    if (this._orgId && !orgIds.includes(this._orgId)) {
      const email = user.email ?? "unknown";
      console.warn(
        `User ${email}'s auth token does not include orgId ${this._orgId}`
      );
    }
    if (this._orgId) localStorage.setItem("orgId", this._orgId);
    this._id = user.uid;
    this._authenticatedUsername = user.email;
    this._token = tokenResult.token;
    this._error = null;
    this._status = Status.AUTHENTICATED;
    this._isAuthenticated = true;
    this.updateContext();
    this.pushOutputs();
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
