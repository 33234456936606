import { AppContext } from "../classes/app-context";
import CellType from "../classes/cell-type";
import { ComponentLibrary } from "../classes/component-library";
import CustomComponent from "../classes/custom-component";
import queueFrame from "../classes/frame-queue";
import { ConsolePerfCounter } from "../classes/perf-counter";
import {
  ComponentInvoker,
  Component,
  ValueGraph,
  FrameObserver,
} from "../interfaces/global-interfaces";
import { Schema, Value } from "../schema/schema-global";
import { StringYAMLSchemaLoader } from "../schema/schema-loader";
import { getEntryPoint } from "../schema/schema-utils";

// a performance counter class writing to console.log
export class EmbeddedPerfCounter extends ConsolePerfCounter {
  valueSet(
    path: string,
    value: Value | Value[],
    before: Value | Value[],
    after: Value | Value[]
  ): void {
    // log the values (for now :)
    if (this.trace)
      console.log("[embedded]", path, {
        before: before,
        after: after,
        result: value,
      });
  }
}

/**
 * Launches a Hyperseed application with a given schema and mounts it to the
 * DOM.
 */
export default class LocalSchemaComponent
  implements Component, ComponentInvoker, FrameObserver
{
  private _invokedBy: ComponentInvoker;
  private _library?: ComponentLibrary;

  /** The schema contents as a YAML string */
  private _schema: string | null = null;
  private _lastSchema: string | null = null;

  /** The name of the component in the Hyperseed application schema that should
   * be executed. The default value used if not specified is `main`.  */
  private _componentName: string | null = null;
  private _lastComponentName: string | null = null;

  private _input: ValueGraph | null = null;
  private _output: ValueGraph | null = null;

  /** The id of a DOM element the UI of the application shall attach to */
  private _attachToId: string | null = null;
  private _lastAttachToId: string | null = null;

  private _perfCounter = new EmbeddedPerfCounter();

  /** When `true` then the state of the application is traced and exposed as an
   * output */
  private _traceState = false;

  /** The error message if an error occured during publishing */
  private _error: string | null = null;

  private _component: CustomComponent | null = null;
  private _parentDOMNode: Element | null = null;

  constructor(invokedBy: ComponentInvoker, library?: ComponentLibrary) {
    this._invokedBy = invokedBy;
    this._library = library;
  }

  static getSchema(): Schema {
    return {
      /** the schema to load */
      schema: { _input: null },
      /** the name of the component to invoke from the loaded schema */
      component: { _input: null },
      /** an object that is forwarded to the inputs of the invoked component */
      input: { _input: null },
      /** attaches the visual output to a DOM node with this Id */
      attachToId: { _input: null },
      /** if `true` it logs all value updates in the console */
      trace: { _input: false },
      /** if `true` it exposes the value graph from within the invoked component
      to the state output */
      traceState: { _input: false },

      /** the output object being returned from the inviked component */
      output: { _output: null },
      /** exposes errors when loading the schema or invoking component, not from
      within the invoked component itself */
      error: { _output: null },
      /** exposes the value graph of the invoked coomponent as path/value pairs
      when `traceState` is set to `true` */
      state: {
        _output: null,
        _iscollection: true,
        path: { _output: null },
        value: { _output: null },
      },
    };
  }

  get DOMNode(): Element | null {
    return null;
  }

  destroy(): void {
    this._component?.destroy();
    this._component = null;
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "schema":
          this._schema = value?.toString() ?? null;
          break;
        case "component":
          this._componentName = value?.toString() ?? null;
          break;
        case "input":
          this._input = value as unknown as ValueGraph;
          break;
        case "attachtoid":
          this._attachToId = value?.toString() ?? null;
          break;
        case "trace":
          this._perfCounter.trace = value ? "*" : "";
          break;
        case "tracestate":
          this._traceState = Boolean(value);
          break;
      }
    });

    if (
      this._schema !== this._lastSchema ||
      this._componentName !== this._lastComponentName
    ) {
      // if the schema or the component from the schema changes, we do an entire
      // (re)initialization
      this.setBusy(true);
      queueFrame(() => this.update());
      return;
    }

    // attaching or detaching from a UI element does not affect the busy state
    // or any other property of the embedded component
    if (this._attachToId !== this._lastAttachToId) {
      this._lastAttachToId = this._attachToId;
      this.attachDOM();
    }

    // inputs are simply propagated to the embedded component
    if (this._component && this._input)
      this._component.setInputValueGraph(this._input);
  }

  getOutputValueGraph(): ValueGraph {
    const result: ValueGraph = {
      error: this._error,
      output: this._output,
    };
    if (this._traceState) {
      result.state = this._component?.rootInstance?.getValueGraphAsArray();
    }
    return result;
  }

  attachDOM(): void {
    this._component?.detachDOM();
    this._parentDOMNode = null;

    if (this._attachToId) {
      if (!document)
        throw new Error(
          "An application must run in a web-browser to be attached to the DOM"
        );

      this._parentDOMNode = document.getElementById(this._attachToId);
      // for the time being we ignore that that the element to be bound to has
      // not been found as subsequent calls to `attachDOM` fix the issue anyhow.
      // TODO: expose an error on the error output when the id cannot be found
      if (this._parentDOMNode) this._component?.attachDOM(this._parentDOMNode);
    }
  }

  setOutputValueGraph(graph: ValueGraph): void {
    this._output = graph;
    this.pushOutputs();
  }

  setBusy(busy: boolean): void {
    this._invokedBy.setBusy(busy);
  }

  fixOrderOfDOMNodes(): void {
    // do nothing (for now)
  }

  getAppContext(): AppContext | undefined {
    return this._invokedBy?.getAppContext();
  }

  behaviorFrameFinished(): void {
    // if a value has been updated, the state is propagated at the end of the
    // behavior frame of the embeeded component
    if (this._traceState) this.pushOutputs();
  }

  private update() {
    // delete a previously existing component
    this._component?.destroy();
    this._component = null;

    this._lastSchema = this._schema;
    this._lastComponentName = this._componentName;
    this._lastAttachToId = this._attachToId;

    if (!this._schema) {
      this._output = null;
      this._error = null;
      this.pushOutputs();
      this.setBusy(false);
      return;
    }

    new StringYAMLSchemaLoader(this._schema)
      .getSchema()
      .then((schema) => {
        // schema use cases to cover:
        // - just a single component not using any library, component name is undefined
        // - a single component using the library of the invoker, name is undefined
        // - a main component with its own library
        // - a library where one of its components should be invoked
        if (!this._library || getEntryPoint(schema, "main")) {
          // create a local library if none has been provided or if the schema
          // itself contains a library
          this._component = new CustomComponent(
            new ComponentLibrary(schema),
            this,
            this._perfCounter
          );
          this._component.init(this._componentName ?? "main");
        } else {
          // otherwise use the library that has been provided on creation and
          // create a CellType from the component schema
          const cellType = new CellType(
            schema,
            "main",
            null,
            this._library.schema
          );
          this._component = new CustomComponent(
            this._library,
            this,
            this._perfCounter
          );
          this._component.init(cellType);
        }

        // init always sets a component to be initially busy, so no need to
        // explicitly set busy

        this.attachDOM();
        this._error = null;
        this.pushOutputs();
        if (this._input) this._component?.setInputValueGraph(this._input);
      })
      .catch((err) => {
        this._error = err instanceof Error ? err.message : String(err);
        this.pushOutputs();
        this.setBusy(false);
        return;
      });
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("input.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    if (name.startsWith("output.")) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set([
  "schema",
  "component",
  "input",
  "attachtoid",
  "trace",
  "tracestate",
]);
/** Names of component outputs. */
const outputs = new Set([
  "output",
  "error",
  "state",
  "state.path",
  "state.value",
]);
