import { Component, ValueGraph } from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

/**
 * No-op component used to mock platform-specific components e.g., mock
 * client components on the backend.
 */
export default class MockComponent implements Component {
  static getSchema(): Schema {
    return {};
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  destroy(): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setInputValueGraph(graph: ValueGraph): void {}
  getOutputValueGraph(): ValueGraph {
    return {};
  }
}
