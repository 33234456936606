import { AppContext } from "../classes/app-context";
import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  Unsubscribable,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

export enum Status {
  PENDING = "pending",
  FAILED = "failed",
  SUCCEEDED = "succeeded",
}

enum ErrorCode {
  BAD_DATA_TYPE = "hyperseed/bad-data-type",
  APP_NAME_UNSET = "hyperseed/app-name-unset",
  ORG_ID_UNSET = "hyperseed/org-id-unset",
  COLLECTION_UNSET = "hyperseed/collection-unset",
  BAD_COLLECTION = "hyperseed/bad-collection",
}

/**
 * Represents a Firestore delete error.
 */
export class FirestoreDeleteError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Regular expression used to remove leading and trailing slashes from
 * a path.
 */
const slashesRE = /^\/+|\/+$/g;

/**
 * Deletes a document from Firestore.
 */
export abstract class FirestoreDeleteBase implements Component {
  private _invokedBy: ComponentInvoker;

  /** App name, used in the Firestore document path */
  private _appName?: string;
  /** Org ID of data owner, used in the Firestore document path */
  private _orgId?: string;
  /**
   * Collection path as a slash-separated Firestore path following
   * org/{orgId}/apps/{appName}.
   *
   * Path must be an odd number of path segments.
   */
  private _collection?: string;
  /** The IDs of the documents to delete */
  private _ids?: string[];
  /** Flag for caller to request delete synchronization */
  private _delete = false;
  /** Flag to check delete synchronization state transition */
  private _prevDelete = false;

  /** The status after a Firestore operation was triggered */
  private _status: Status | null = null;
  /** A description of the error if if status is FAILED */
  private _error: string | null = null;
  /** A code identifying the error if status is FAILED */
  private _errorCode: ErrorCode | string | null = null;
  /**
   * Pulses true for a single frame after delete operation completes
   * (whether it succeeded or failed)
   */
  private _finished = false;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   appName: { _input: null },
   *   collection: { _input: null },
   *   ids: {
   *     _input: null,
   *     _iscollection: true,
   *   },
   *   delete: { _input: null },
   *   // Outputs
   *   finished: { _output: null },
   *   status: { _output: null },
   *   error: { _output: null },
   *   errorCode: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        if (context.orgId !== this._orgId) {
          this._orgId = context.orgId;
        }
      },
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  private reset() {
    this._finished = false;
    this.pushOutputs();
    this._invokedBy.setBusy(false);
  }

  setInputValueGraph(graph: ValueGraph): void {
    if (this._status === Status.PENDING) {
      return;
    }
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "appname":
          this._appName = value?.toString();
          break;
        case "collection":
          this._collection = value?.toString();
          this._collection = this._collection?.replace(slashesRE, "");
          break;
        case "ids":
          this._ids = value as string[];
          if (!Array.isArray(this._ids)) this._ids = [this._ids];
          break;
        case "delete":
          this._delete = Boolean(value);
          break;
      }
    });
    if (this._delete !== this._prevDelete) {
      this._prevDelete = this._delete;
      if (this._delete) {
        this._invokedBy.setBusy(true);
        queueFrame(() => this.callDelete());
      }
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      finished: this._finished,
      status: this._status,
      error: this._error,
      errorCode: this._errorCode,
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (this._appName === undefined) {
        throw new FirestoreDeleteError(
          "appName is missing",
          ErrorCode.APP_NAME_UNSET
        );
      }
      if (this._orgId === undefined) {
        throw new FirestoreDeleteError(
          "orgId is missing",
          ErrorCode.ORG_ID_UNSET
        );
      }
      if (!this._collection) {
        throw new FirestoreDeleteError(
          "collection is missing",
          ErrorCode.COLLECTION_UNSET
        );
      }
      const segments = this._collection.split("/");
      if (segments.length % 2 !== 1) {
        throw new FirestoreDeleteError(
          "collection needs odd number of path segments",
          ErrorCode.BAD_COLLECTION
        );
      }
    } catch (err) {
      this._error = (err as FirestoreDeleteError).message;
      this._errorCode = (err as FirestoreDeleteError).code;
      this._status = Status.FAILED;
      this.pushOutputs();
      this._invokedBy.setBusy(false);
      return false;
    }
    return true;
  }

  /**
   * Calls the asynchronuous delete method and sets the output
   * properties of the component accordingly.
   */
  private callDelete() {
    if (!this.validateInputs()) return;
    this._status = Status.PENDING;
    this._error = null;
    this._errorCode = null;
    this.pushOutputs();
    this.delete(
      this._orgId as string,
      this._appName as string,
      this._collection as string,
      this._ids ?? []
    )
      .then(() => {
        this._error = null;
        this._errorCode = null;
        this._status = Status.SUCCEEDED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((err) => {
        if (err instanceof FirestoreDeleteError) {
          this._error = err.message;
          this._errorCode = err.code;
        } else {
          this._error = String(err);
          this._errorCode = (err as { code: string }).code ?? "unknown";
        }
        this._status = Status.FAILED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  /**
   * Deletes documents from Firestore.
   *
   * @param orgId the org ID of the data owner, used in the Firestore
   *   document path
   * @param appName the app name, used in the Firestore document path
   * @param collection the relative collection path
   * @param ids the document IDs to delete
   * @throws Error if delete failed
   */
  protected abstract delete(
    orgId: string,
    appName: string,
    collection: string,
    ids: string[]
  ): Promise<void>;

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["appname", "collection", "ids", "delete"]);
/** Names of component outputs. */
const outputs = new Set(["finished", "status", "error", "errorcode"]);
