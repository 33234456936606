import {
  ComponentInvoker,
  DOMComponent,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

/**
 * UIPointerComponent is the abstract base class for all pointer related
 * components, like mouse and drag&drop.
 */
export default abstract class UIPointerComponent implements DOMComponent {
  protected _invokedBy: ComponentInvoker;
  protected _parentDOMNode: Element | null = null;

  protected _stopPropagation = false;
  protected _preventDefault: boolean | null = null;

  protected _buttons: number | null = null;
  protected _altKey = false;
  protected _ctrlKey = false;
  protected _metaKey = false;
  protected _shiftKey = false;
  protected _clientX: number | null = null;
  protected _clientY: number | null = null;
  protected _offsetX: number | null = null;
  protected _offsetY: number | null = null;
  protected _pageX: number | null = null;
  protected _pageY: number | null = null;
  protected _screenX: number | null = null;
  protected _screenY: number | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      stopPropagation: { _input: null },
      preventDefault: { _input: null },

      buttons: { _output: {} },
      altKey: { _output: {} },
      ctrlKey: { _output: {} },
      metaKey: { _output: {} },
      shiftKey: { _output: {} },
      clientX: { _output: {} },
      clientY: { _output: {} },
      offsetX: { _output: {} },
      offsetY: { _output: {} },
      pageX: { _output: {} },
      pageY: { _output: {} },
      screenX: { _output: {} },
      screenY: { _output: {} },
    };
  }

  get DOMNode(): null {
    return null;
  }

  destroy(): void {
    this.detachDOM();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      if (value !== undefined) this.setDOMElementProperty(key, value);
    });
  }

  getOutputValueGraph(): ValueGraph {
    return {
      buttons: this._buttons,
      altKey: this._altKey,
      ctrlKey: this._ctrlKey,
      metaKey: this._metaKey,
      shiftKey: this._shiftKey,
      clientX: this._clientX,
      clientY: this._clientY,
      offsetX: this._offsetX,
      offsetY: this._offsetY,
      pageX: this._pageX,
      pageY: this._pageY,
      screenX: this._screenX,
      screenY: this._screenY,
    };
  }

  attachDOM(parentDOMNode: Element): void {
    // already attached to the same parent -> do nothing
    if (parentDOMNode === this._parentDOMNode) return;

    // already attached to a different parent -> as pointer components bind
    // events to the parent, they must be detached first before they get
    // re-attached
    if (this._parentDOMNode) this.detachDOM();

    this._parentDOMNode = parentDOMNode;
  }

  detachDOM(): void {
    this._parentDOMNode = null;
  }

  /**
   * Sets the DOM property according to key/value pairs found in the input value
   * graph. Can be overridden in child classes to add additional properties. In
   * this case `super.setDOMElementProperty` should be called.
   * @param key The key in the input value graph.
   * @param value The corresponding input value.
   */
  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    switch (key.toLowerCase()) {
      case "stoppropagation":
        this._stopPropagation = Boolean(value);
        break;
      case "preventdefault":
        this._preventDefault = value === null ? null : Boolean(value);
        break;
    }
  }

  protected setPointerAttributes(event: MouseEvent): void {
    this._buttons = event.buttons;
    this._altKey = event.altKey;
    this._ctrlKey = event.ctrlKey;
    this._metaKey = event.metaKey;
    this._shiftKey = event.shiftKey;
    this._clientX = event.clientX;
    this._clientY = event.clientY;
    this._offsetX = event.offsetX;
    this._offsetY = event.offsetY;
    this._pageX = event.pageX;
    this._pageY = event.pageY;
    this._screenX = event.screenX;
    this._screenY = event.screenY;
  }

  protected propagationAndDefault(
    event: MouseEvent,
    preventDefaultByDefault = false
  ): void {
    if (this._stopPropagation) {
      event.stopPropagation();
    }
    if (
      // preventDefault being null results in default behavior
      (preventDefaultByDefault && this._preventDefault === null) ||
      this._preventDefault
    ) {
      // this is specifically for enabling and disabling drop targets
      // if attribute is not defined, then by default prevent the default behavior
      // otherwise prevent default behavior just in case the property is true
      event.preventDefault();
    }
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  protected addEventListenerToParent(
    type: string,
    listener: (this: Element, ev: Event) => void
  ): void {
    this._parentDOMNode?.addEventListener(type, listener);
  }

  protected removeEventListenerFromParent(
    type: string,
    listener: (this: Element, ev: Event) => void
  ): void {
    this._parentDOMNode?.removeEventListener(type, listener);
  }
}
