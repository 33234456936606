import "bootstrap/dist/css/bootstrap.min.css";
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

import { AppContext } from "@shared/src/classes/app-context";
import { Brain, BrainDependencies } from "@shared/src/classes/brain";
import { ConsolePerfCounter } from "@shared/src/classes/perf-counter";
import { SchemaLoader } from "@shared/src/schema/schema-global";
import {
  HttpYAMLSchemaLoader,
  HttpYAMLFolderSchemaLoader,
} from "@shared/src/schema/schema-loader";

import { registerSystemComponents } from "./components/register";
import "./css/hyperseed.css";
import { environment } from "./environments/environment";
import { version } from "./environments/version";
import { authorize } from "./quickbooks-auth";

firebase.initializeApp(environment.firebase);

const isLocalhost = window.location.hostname === "localhost";

const defaultSchema = "/schema/billing";

// Use Firebase Authentication, Functions, and Storage emulators when
// developing locally.
if (isLocalhost) {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.storage().useEmulator("localhost", 9199);
} else {
  // Enable analytics for production but leave it disabled for
  // development.
  firebase.analytics();
}

/**
 * Returns true if the current location points to a schema handled by
 * Cloud Storage.
 */
function isStorageSchemaRoute(): boolean {
  const path = window.location.pathname;
  return path.startsWith("/templates/") || path.startsWith("/users/");
}

async function getSchemaUrl(): Promise<string> {
  const path = window.location.pathname;
  if (path.length < 2) {
    throw new Error("Unable to load schema");
  }
  let filePath = path.substring(1); // strip leading "/"
  if (!filePath.endsWith(".yaml")) {
    filePath += ".yaml";
  }
  const storageRef = firebase.storage().ref(filePath);
  return storageRef.getDownloadURL() as Promise<string>;
}

async function main() {
  const path = window.location.pathname;
  if (path === "/oauth") {
    await authorize();
    return;
  }
  const body = document.body;
  let schemaLoader: SchemaLoader;
  try {
    if (isStorageSchemaRoute()) {
      schemaLoader = new HttpYAMLSchemaLoader(await getSchemaUrl());
    } else if (await HttpYAMLFolderSchemaLoader.manifestExists(path)) {
      schemaLoader = new HttpYAMLFolderSchemaLoader(path);
    } else {
      // For all other routes, load the default schema.
      schemaLoader = new HttpYAMLFolderSchemaLoader(defaultSchema);
    }
  } catch (error) {
    console.error("error: ", error);
    // The error might be a FirebaseStorageError, which does not appear
    // to be exported by the Firebase SDK.
    // https://firebase.google.com/docs/reference/js/v8/firebase.storage.FirebaseStorageError#code
    const code = (error as { code: string }).code;
    switch (code) {
      case "storage/object-not-found":
        body.innerHTML = "The content was not found.";
        break;
      case "storage/unauthorized":
        body.innerHTML = "User does not have permission to access the content.";
        break;
      default:
        body.innerHTML = "Loading the content failed.";
    }
    return;
  }
  const deps: BrainDependencies = {
    schemaLoader,
    perfCounter: new ConsolePerfCounter(),
    appContext: new AppContext(),
  };

  // turn tracing on when there is a url query parameter `trace` (e.g. set it to
  // `trace=*`)
  const url = new URL(document.location.href);
  if (deps.perfCounter)
    deps.perfCounter.trace = url.searchParams.get("trace") ?? "";

  registerSystemComponents();
  const theBrain = new Brain(deps);
  await theBrain.init();
  theBrain.attachDOM(body);
  // TODO: as soon as attaching the DOM after a first behavior frame does not
  // brake DOM visual elements attached to component outputs, we should move
  // setting the location above attaching the DOM
  await theBrain.setInputValueGraphAndWait({
    route: window.location.pathname,
    origin: window.location.origin,
    version: version.tag || version.commit,
  });

  // TODO: make the document manipulation dynamic and more versatile
  // set the document title in the DOM html <head> element
  const docTitle = theBrain.main?.rootInstance?.getAttributeValue("docTitle");
  const titleElement = document.querySelector("head title");
  if (docTitle && titleElement) titleElement.innerHTML = docTitle.toString();

  // adds an internal css definition to the DOM html <head> element
  const docStyle = theBrain.main?.rootInstance?.getAttributeValue("docStyle");
  const styleElement = document.createElement("style");
  if (docStyle) styleElement.innerHTML = docStyle.toString();

  const headElement = document.querySelector("head");
  if (headElement) headElement.appendChild(styleElement);
}

main().catch(console.error);
