import { makeArray } from "@shared/src/behavior/behavior-commons";
import queueFrame from "@shared/src/classes/frame-queue";
import Instance from "@shared/src/classes/instance";

import UIHTMLElementComponent from "../../../shared/src/components/ui-html-element-component";
import {
  ComponentInvoker,
  isValue,
  ValueGraph,
  ValueGraphData,
} from "../../../shared/src/interfaces/global-interfaces";
import { Schema, Value } from "../../../shared/src/schema/schema-global";

/**
 * Contains the values of one row in the Google chart
 */
interface Row {
  // TODO: allow values and formatted values
  columns: Value[];
}

/**
 * Loads the Google charts loader script.
 * @returns a promise which is resolved when the charts loader is loaded.
 */
export function loadGoogleLoader(): Promise<void> {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://www.gstatic.com/charts/loader.js";
    document.head.appendChild(script);

    script.onload = () => {
      resolve();
    };
  });
}

/**
 * The GoogleChartsComponent component renders a Google chart in the UI. For
 * documentation of the chart types and their properties (mainly regarding the
 * options object) visit the Google charts documentation
 * https://developers.google.com/chart/interactive/docs/gallery
 */
export default class GoogleChartsComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLElement;

  private _type: string | null = null;
  private _columns: google.visualization.DataTableColumnDescription[] = [];
  private _rows: Row[] = [];
  private _options: Record<string, unknown> = {};
  private _chart: google.visualization.ChartWrapper | null = null;
  private _selection: google.visualization.ChartSelection[] = [];
  private _ready = false;
  private _error: { id: string | null; message: string | null } = {
    id: null,
    message: null,
  };

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);
    this._DOMNode = document.createElement("div");

    void google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(() => this.initChart());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.type = { _input: null };
    schema.columns = {
      _iscollection: true,
      _input: null,
      type: { _input: null },
      label: { _input: null },
      id: { _input: null },
      role: { _input: null },
      pattern: { _input: null },
    };
    schema.rows = {
      _iscollection: true,
      _input: null,
      columns: {
        _iscollection: true,
        _input: null,
      },
    };
    schema.options = { _input: null };
    schema.setSelection = {
      _iscollection: true,
      _input: null,
      row: { _input: null },
      column: { _input: null },
    };
    schema.selection = {
      _iscollection: true,
      _output: null,
      row: { _output: null },
      column: { _output: null },
    };
    schema.error = {
      _output: null,
      id: { _output: null },
      message: { _output: null },
    };
    return schema;
  }

  get DOMNode(): HTMLElement {
    return this._DOMNode;
  }

  setInputValueGraph(graph: ValueGraph): void {
    super.setInputValueGraph(graph);

    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "type":
          this._type = value?.toString() ?? null;
          break;
        case "columns":
          this._columns = [];
          if (value === undefined || value === null) break;
          for (const column of makeArray(value)) {
            // TODO: allow to have a simple value just defining the type?
            if (isValue(column) || column instanceof Instance) continue;
            const c: Record<string, unknown> = {};
            Object.entries(column).forEach(([key, value]) => {
              // map all properties to their lowercase equivalent as this fits
              // the Google charts definition
              if (value !== null && value !== undefined)
                c[key.toLowerCase()] = value.toString();
            });
            this._columns.push(c);
          }
          break;
        case "rows":
          this._rows = value as unknown as Row[];
          break;
        case "options":
          if (
            value === undefined ||
            isValue(value) ||
            value instanceof Instance ||
            Array.isArray(value)
          ) {
            // if options is not a valid object reset all options
            this._options = {};
          } else {
            this._options = value;
          }
          break;
        case "setselection": {
          if (
            value === undefined ||
            isValue(value) ||
            value instanceof Instance
          ) {
            this._selection = []; // will deselect all
          } else {
            value = makeArray(value);
            // selection is only updated when at least one array element is set
            if (!value.length) break;
            this._selection = value.map((item) => {
              return {
                row: (item as google.visualization.ChartSelection).row ?? null,
                column:
                  (item as google.visualization.ChartSelection).column ?? null,
              };
            });
          }
        }
      }
    });
    queueFrame(() => this.drawChart());
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.selection = this._selection as unknown as ValueGraphData[];
    result.error = this._error;

    return result;
  }

  /**
   * Reacts on resizing of the div element the chart is included in by redrawing
   * it.
   */
  protected onResize(): void {
    super.onResize();
    const r = this.DOMNode.getBoundingClientRect();
    // only redraw the chart if width and height are larger than 0 to avoid
    // animations after the div (or a parent) has been hidden
    if (r.height && r.width) this.drawChart();
    // TODO: investigate if there is a better way to prevent re-scaling after
    // the chart gets visible again
  }

  /** Instantiates and draws the chart the first time. Call it when the Google
   * charts library has finished loading. */
  private initChart(): void {
    if (!this._chart) this._chart = new google.visualization.ChartWrapper();

    google.visualization.events.addListener(this._chart, "select", () =>
      this.onSelect()
    );
    google.visualization.events.addListener(this._chart, "ready", () =>
      this.onReady()
    );
    google.visualization.events.addListener(
      this._chart,
      "error",
      (id: unknown, message: unknown) => this.onError(id, message)
    );

    this.drawChart();
  }

  /** Initialises the Google data table from the columns and rows data and draws
   * the chart within the enclosing div element */
  private drawChart(): void {
    // we reset the error but do not update the outputs before the ready event
    // has been received
    this._ready = false;
    this._error = { id: null, message: null };

    // if not yet initialised just skip drawing, will be called on initChart
    // eventually
    if (!this._chart) return;

    try {
      this._chart.setChartType(this._type ?? "ColumnChart");

      const data = new google.visualization.DataTable();
      for (const column of this._columns) {
        data.addColumn(column);
      }
      for (const row of this._rows) {
        if (Array.isArray(row.columns)) {
          data.addRow(row.columns);
        } else {
          // add an empty row when there is no columns property or it is not an
          // array
          data.addRow();
        }
      }
      this._chart.setDataTable(data);

      this._chart.setOptions(this._options);

      this._chart.draw(this._DOMNode);
    } catch (err) {
      this._error = {
        id: err instanceof Error ? err.name : "unknown",
        message: err instanceof Error ? err.message : String(err),
      };
      this.pushOutputs();
    }
  }

  /**
   * Event handler pushing the outputs when a selection event was triggered
   */
  private onSelect(): void {
    // ignore it when chart is still busy as these are wrong updates
    if (!this._ready) return;

    this._selection =
      this._chart
        ?.getChart()
        ?.getSelection()
        .map((item) => {
          return { row: item.row ?? null, column: item.column ?? null };
        }) ?? [];
    this.pushOutputs();
  }

  /**
   * Event handler setting the ready output when the chart signals it has
   * finished rendering
   */
  private onReady(): void {
    this._chart?.getChart()?.setSelection(this._selection);
    this._ready = true;
    this.pushOutputs();
  }

  /**
   * Event handler setting the ready output when the chart signal has
   * finished rendering
   */
  private onError(id: unknown, message: unknown): void {
    this._error = { id: id as string, message: message as string };
    this.pushOutputs();
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (super.isInput(name)) return true;
    if (inputs.has(name)) return true;
    if (name.startsWith("options.")) return true;
    // allows using formatted values using { v: xxx, f: "xxx" }
    if (name.startsWith("rows.columns.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (super.isOutput(name)) return true;
    if (outputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set([
  "type",
  "columns",
  "columns.type",
  "columns.label",
  "columns.id",
  "columns.role",
  "columns.pattern",
  "rows",
  "rows.columns",
  "options",
  "setselection",
  "setselection.row",
  "setselection.column",
]);
/** Names of component outputs. */
const outputs = new Set([
  "selection",
  "selection.row",
  "selection.column",
  "ready",
  "error",
  "error.id",
  "error.message",
]);
