import queueFrame from "../classes/frame-queue";
import { ValueGraph } from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIPointerComponent from "./ui-pointer-component";

/**
 * UIMouseComponent implements mouse capturing, thus listens to all mouse events
 * and propagates the mouse state.
 */
export default class UIMouseComponent extends UIPointerComponent {
  private _hover = false;
  private _down = false;
  private _clicked = false;
  private _dblClicked = false;

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.hover = { _output: {} };
    schema.down = { _output: {} };
    schema.clicked = { _output: {} };
    schema.dblClicked = { _output: {} };

    return schema;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.hover = this._hover;
    result.down = this._down;
    result.clicked = this._clicked;
    result.dblClicked = this._dblClicked;

    return result;
  }

  attachDOM(parentDOMNode: Element): void {
    super.attachDOM(parentDOMNode);

    this.addEventListenerToParent("click", this.onClick);
    this.addEventListenerToParent("dblclick", this.onDblClick);
    this.addEventListenerToParent("mouseover", this.onMouseOver);
    this.addEventListenerToParent("mouseout", this.onMouseOut);
    this.addEventListenerToParent("mousemove", this.onMouseMove);
    this.addEventListenerToParent("mousedown", this.onMouseDown);
    this.addEventListenerToParent("mouseup", this.onMouseUp);
  }

  detachDOM(): void {
    this.removeEventListenerFromParent("click", this.onClick);
    this.removeEventListenerFromParent("dblclick", this.onDblClick);
    this.removeEventListenerFromParent("mouseover", this.onMouseOver);
    this.removeEventListenerFromParent("mouseout", this.onMouseOut);
    this.removeEventListenerFromParent("mousemove", this.onMouseMove);
    this.removeEventListenerFromParent("mousedown", this.onMouseDown);
    this.removeEventListenerFromParent("mouseup", this.onMouseUp);

    super.detachDOM();

    this._hover = false;
    this._down = false;

    queueFrame(() => this.pushOutputs());
  }

  // TODO: to avoid adding multiple instances of the same callback function, the
  // function at the time being must be static as by not having a DOM node on
  // the mouse, keyboard, drag & drop sensors, they runtime considers those not
  // yet being added. We should find a cleaner way of doing this. see also
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#memory_issues
  private onClick = (event: Event): void => {
    this._clicked = true;
    this.setPointerAttributes(event as MouseEvent);
    this.propagationAndDefault(event as MouseEvent);
    this.pushOutputs();
    queueFrame(() => {
      this._clicked = false;
      this.pushOutputs();
    });
  };

  private onDblClick = (event: Event): void => {
    this._dblClicked = true;
    this.setPointerAttributes(event as MouseEvent);
    this.propagationAndDefault(event as MouseEvent);
    this.pushOutputs();
    queueFrame(() => {
      this._dblClicked = false;
      this.pushOutputs();
    });
  };

  private onMouseOver = (event: Event): void => {
    // other pointer properties are not set  mainly because firefox sends wrong
    // values e.g. for `event.buttons`
    this.propagationAndDefault(event as MouseEvent);
    this._hover = true;
    this.pushOutputs();
  };

  private onMouseOut = (event: Event): void => {
    // other pointer properties are not set  mainly because firefox sends wrong
    // values e.g. for `event.buttons`
    this.propagationAndDefault(event as MouseEvent);
    this._hover = false;
    this._down = false;
    this.pushOutputs();
  };

  private onMouseMove = (event: Event): void => {
    this.setPointerAttributes(event as MouseEvent);
    this.propagationAndDefault(event as MouseEvent);
    // Resetting the buttons when e.g. the mouse pointer has before left the DOM
    // element, then the buttons were released and now the mouse returns to this
    // element. Setting always requires the distinct mousedown event.
    if (!this._buttons) this._down = false;
    this.pushOutputs();
  };

  private onMouseDown = (event: Event): void => {
    this.setPointerAttributes(event as MouseEvent);
    this.propagationAndDefault(event as MouseEvent);
    this._down = Boolean(this._buttons);
    this.pushOutputs();
  };

  private onMouseUp = (event: Event): void => {
    this.setPointerAttributes(event as MouseEvent);
    this.propagationAndDefault(event as MouseEvent);
    this._down = Boolean(this._buttons);
    this.pushOutputs();
  };
}
