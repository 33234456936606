import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * Represents a button input element in the DOM tree.
 */
export default class UIButtonComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLButtonElement;

  private _clicked = false;
  private _pressed = false;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("button");
    this._DOMNode.appendChild(document.createTextNode(""));

    this._DOMNode.addEventListener("click", () => this.onClick());
    this._DOMNode.addEventListener("mousedown", () => this.onMouseDown());
    this._DOMNode.addEventListener("mouseup", () => this.onMouseUp());
    this._DOMNode.addEventListener("mouseout", () => this.onMouseOut());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.disabled = { _input: null };
    schema.label = { _input: null };

    schema.clicked = { _output: {} };
    schema.pressed = { _output: {} };

    return schema;
  }

  get DOMNode(): HTMLButtonElement {
    return this._DOMNode;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.clicked = this._clicked;
    result.pressed = this._pressed;

    return result;
  }

  detachDOM(): void {
    super.detachDOM();
    // reset the button state as it will no longer receive events from the UI
    // do this on the next behavior frame to ensure correct differential behavior
    // TODO: when we store the state we do not have to send this every time
    this._pressed = false;
    queueFrame(() => this.pushOutputs());
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "disabled":
        this._DOMNode.disabled = Boolean(value);
        break;
      case "label":
        this.setOrRemoveTextNode(value);
        break;
    }
  }

  private onClick() {
    this._clicked = true;
    this.pushOutputs();
    queueFrame(() => {
      this._clicked = false;
      this.pushOutputs();
    });
  }

  private onMouseDown() {
    this._pressed = true;
    this.pushOutputs();
  }

  private onMouseUp() {
    this._pressed = false;
    this.pushOutputs();
  }

  private onMouseOut() {
    this._pressed = false;
    this.pushOutputs();
  }
}
