import firebase from "firebase/app";
import "firebase/storage";

import queueFrame from "@shared/src/classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

enum Status {
  LIST_ALL_PENDING = "list-all-pending",
  LIST_ALL_FAILED = "list-all-failed",
  LIST_ALL_SUCCEEDED = "list-all-succeeded",
}

/**
 * Represents a reference to a Google Cloud Storage object. This is a
 * pared-down version of firebase.storage.Reference.
 */
type Reference = { fullPath: string; name: string };

/**
 * Represents the results of a Cloud Storage list operation. This is a
 * pared-down version of firebase.storage.ListResult.
 */
type ListResult = { prefixes: Reference[]; items: Reference[] };

/**
 * Lists the contents of a Cloud Storage path.
 */
export default class StorageListComponent implements Component {
  private _invokedBy: ComponentInvoker;

  /** Triggers listing contents of a Cloud Storage path when true */
  private _listAll = false;
  /** Cloud Storage bucket (null indicates the default bucket) */
  private _bucket: string | null = null;
  /** Cloud Storage path to read or list */
  private _path: string | null = null;

  /** The status after list has been triggered */
  private _status: Status | null = null;
  /** The error message if an error occured during list */
  private _error: string | null = null;

  /** The list data read from Cloud Storage */
  private _data: ListResult | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      bucket: { _input: null },
      path: { _input: null },
      read: { _input: false },
      listAll: { _input: false },

      error: { _output: null },
      status: { _output: null },
      data: {
        _output: {},
        prefixes: {
          _iscollection: true,
          _output: {},
          fullPath: {
            _output: {},
          },
          name: {
            _output: {},
          },
        },
        items: {
          _iscollection: true,
          _output: {},
          fullPath: {
            _output: {},
          },
          name: {
            _output: {},
          },
        },
      },
    };
  }

  destroy(): void {
    // nothing to be destroyed
  }

  setInputValueGraph(graph: ValueGraph): void {
    console.log("graph:", graph);
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "listall":
          this._listAll = Boolean(value);
          break;
        case "bucket":
          this._bucket = value?.toString() ?? null;
          break;
        case "path":
          this._path = value?.toString() ?? null;
          break;
      }
    });

    // TODO: call listAll only when the value turns true
    if (this._listAll) queueFrame(() => this.callListAll());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      status: this._status,
      error: this._error,
      data: this._data,
    };
  }

  /**
   * Calls the asynchronuous listAll method and sets the output
   * properties of the component accordingly.
   */
  private callListAll() {
    this._status = Status.LIST_ALL_PENDING;
    this._error = null;
    this.pushOutputs();

    this.listAll(this._bucket?.toString() ?? null, this._path?.toString() ?? "")
      .then((data: ListResult) => {
        this._error = null;
        this._status = Status.LIST_ALL_SUCCEEDED;
        this._data = data;
        console.log("listAll data:", data);
        this.pushOutputs();
      })
      .catch((err) => {
        this._error = (err as { code: string }).code ?? "listAll failed";
        this._status = Status.LIST_ALL_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Lists a Cloud Storage prefix path.
   *
   * This method is meant for small
   * @see https://firebase.google.com/docs/storage/web/list-files
   *
   * @param bucket the bucket name; if null, the default bucket is used
   * @param path the path to the Cloud Storage file
   * @returns metadata of the contents of the given path
   * @throws Error if list failed
   */
  private async listAll(
    bucket: string | null,
    path: string
  ): Promise<ListResult> {
    try {
      const storage =
        bucket === null ? firebase.storage() : firebase.app().storage(bucket);
      const storageRef = storage.ref(path);
      const result = await storageRef.listAll();
      const data: ListResult = {
        prefixes: [],
        items: [],
      };
      result.prefixes.map((p) =>
        data.prefixes.push({ fullPath: p.fullPath, name: p.name })
      );
      result.items.map((p) =>
        data.items.push({ fullPath: p.fullPath, name: p.name })
      );
      return data;
    } catch (err) {
      console.error("listAll from Cloud Storage failed:", err);
      throw err;
    }
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
