import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * The UI iframe component represents an iframe element in the DOM tree.
 */
export default class UIIFrameComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLIFrameElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("iframe");
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.src = { _input: null };
    return schema;
  }

  get DOMNode(): HTMLIFrameElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "src":
        if (value !== null) {
          this.DOMNode.src = value.toString();
        } else {
          this.DOMNode.removeAttribute("src");
        }
        break;
    }
  }
}
