import { toNumber } from "../behavior/behavior-commons";
import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

/**
 * The interval component allows setting an interval when its output should
 * repeatedly be triggered with a single-frame `true`.
 */
export default class IntervalComponent implements Component {
  private _invokedBy: ComponentInvoker;
  /** the interval in milliseconds */
  private _interval = 0;
  /** the recent state of the interval trigger */
  private _trigger = false;
  /** the timestamp in UTC of the last triggered interval */
  private _timestamp: Date | null = null;
  private _timer = 0 as unknown as ReturnType<typeof setInterval>;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      /** The interval in milliseconds until when the next signal is triggered.
       * A value of 0 or less than 0 deactivates the timer */
      interval: { _input: null },
      /** Edge output set to `true` for a single frame to signal the end of the
       * interval */
      trigger: { _output: {} },
      /** The timestamp in UTC of the last triggered interval, remains also when
       * interval has been disabled */
      timestamp: { _output: {} },
    };
  }

  private clearTimer() {
    if (this._timer) clearInterval(this._timer);
    this._timer = 0 as unknown as ReturnType<typeof setInterval>;
  }

  destroy(): void {
    this.clearTimer();
  }

  /**
   * Sets the single frame output value to true, notifies the calling instance
   * and queues the reset for its very next behavior frame. If the output value
   * is already true it skips it for this time.
   */
  private setValue() {
    // TODO: do not swallow events just because the previous event is still
    // processing. Possible solution: the value is set to 1 or to the number of
    // skipped triggers. Anyhow very unlikely to happen.
    if (this._trigger === true) return;

    this._trigger = true;
    this._timestamp = new Date(); // set to the current date and time
    this.pushOutputs();

    // this queues the reset of the single frame output value right after the
    // next behavior frame of the invoker has been queued
    queueFrame(() => {
      this._trigger = false;
      this.pushOutputs();
    });
  }

  setInputValueGraph(graph: ValueGraph): void {
    let interval = undefined;

    // TODO: there is too much vodoo because we use JS objects and at the same
    // time say that all symbols should be case insensitive. Should we go for
    // case sensitive?

    Object.entries(graph).forEach(([key, value]) => {
      if (key.toLowerCase() === "interval") {
        if (typeof value === "object" && value !== null)
          throw new Error(
            `Incompatible type for the 'interval' property (seed.system.interval)`
          );

        interval = toNumber(value ?? null);
      }
    });

    if (interval !== undefined && interval !== this._interval) {
      this._interval = interval;
      this.updateInterval();
    }
  }

  private updateInterval() {
    if (this._interval > 0) {
      this.clearTimer();
      this._timer = setInterval(() => this.setValue(), this._interval);
      this._invokedBy.setBusy(true);
    } else {
      this.clearTimer();
      this._invokedBy.setBusy(false);
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      trigger: this._trigger,
      timestamp: this._timestamp,
    };
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
