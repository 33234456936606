import queueFrame from "../classes/frame-queue";
import Instance from "../classes/instance";
import {
  ComponentInvoker,
  isValue,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

// TODO: allow the use of optgroups

// TODO: enable multi selects (currently disabled as the outputs do not yet
// support it)

/**
 * Represents a select element in the DOM tree including its options. Inputs and
 * outputs work as the HTML DOM element except of the following:
 *
 * - `value`: When setting a value that does not exist in the options list via
 *   `setValue`, the `value` output still shows the value being set (DOM would
 *   return `""`). If no option is selected `null` is returned (DOM would return
 *   `""`), while an option with a value `""` is returned as `""` (same as DOM).
 * - `selectedIndex`: If no option is selected, `null` is returned (DOM would
 *   return -1).
 */
export default class UISelectComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLSelectElement;

  private _selectedIndex = -1;
  private _value: string | null = null;
  private _changed = false;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("select");
    this._DOMNode.addEventListener("change", () => this.onChange());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.disabled = { _input: null };
    schema.required = { _input: null };

    //schema.multiple = { _input: null };
    schema.size = { _input: null };
    schema.options = {
      _input: null,
      _iscollection: true,
      value: { _input: null },
      text: { _input: null },
    };
    schema.setValue = { _input: null };
    schema.setSelectedIndex = { _input: null };

    schema.value = { _output: {} };
    schema.selectedIndex = { _output: {} };
    schema.changed = { _output: {} };
    return schema;
  }

  get DOMNode(): HTMLSelectElement {
    return this._DOMNode;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    // return nulls in case no item has been selected and value is not set
    result.value =
      this._selectedIndex < 0 && this._value === "" ? null : this._value;
    result.selectedIndex = this._selectedIndex < 0 ? null : this._selectedIndex;
    result.changed = this._changed;

    return result;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      // TODO: move disabled and required to an InputElementComponent?
      case "disabled":
        this._DOMNode.disabled = Boolean(value);
        break;
      case "required":
        this._DOMNode.required = Boolean(value);
        break;

      /*case "multiple":
        this._DOMNode.multiple = Boolean(value);
        break;*/
      case "size":
        this.setOrRemoveAttribute("size", value);
        break;
      case "setvalue":
        if (value !== null) {
          this._value = value.toString();
          this._DOMNode.value = this._value;
          this._selectedIndex = this._DOMNode.selectedIndex;
          // TODO: only queue value propagation once (together with selectedIndex)
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setselectedindex":
        if (value !== null) {
          this._selectedIndex = Number(value);
          this._DOMNode.selectedIndex = this._selectedIndex;
          this._value = this._DOMNode.value;
          // TODO: only queue value propagation once (together with value)
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "options":
        if (Array.isArray(value)) {
          this.setOptions(value);
        } else {
          this.setOptions([value]);
        }
        break;
    }
  }

  private setOptions(data: ValueGraphData[]) {
    // TODO: do not rebuild the entire options element list, but only apply
    // changes so that the option list does not flicker while updating just the
    // value or selectedIndex.

    while (this._DOMNode.lastChild) {
      this._DOMNode.removeChild(this._DOMNode.lastChild);
    }
    const options = this.convertOptionsArray(data);
    for (const option of options) {
      const node = document.createElement("option");
      node.value = option.value;
      node.text = option.text;
      this._DOMNode.appendChild(node);
    }

    this._DOMNode.value = this._value ?? "";
    this._selectedIndex = this._DOMNode.selectedIndex;
    queueFrame(() => this.pushOutputs());
  }

  /**
   * Converts an input value graph array into an array of objects with value and
   * text properties defining the options of the select element.
   * @param options An array of input value graph items.
   * @returns An array of objects, each with value and text.
   */
  private convertOptionsArray(
    dataArray: ValueGraphData[]
  ): { value: string; text: string }[] {
    const result = [];
    for (const data of dataArray) {
      const p = this.convertOptionsItem(data);
      if (p) result.push(p);
    }
    return result;
  }

  /**
   * Converts a single input value graph item into an object with a value and a
   * text property defining one option element of the select element.
   * @param data An input value graph item.
   * @returns An objects with value and text.
   */
  private convertOptionsItem(
    data: ValueGraphData
  ): { value: string; text: string } | undefined {
    if (data === null) return undefined;

    let optionValue: string | undefined = undefined;
    let optionText: string | undefined = undefined;

    if (isValue(data) || data instanceof Instance) {
      // this allows to just set a value for the options element without using
      // properties `value` and `text` below
      optionValue = data.toString();
    } else {
      Object.entries(data).forEach(([key, value]) => {
        if (value === undefined) return;
        if (key.toLowerCase() === "value") {
          optionValue = value?.toString() ?? "";
        } else if (key.toLowerCase() === "text") {
          optionText = value?.toString() ?? "";
        }
      });
    }

    if (optionValue !== undefined && optionText !== undefined)
      return { value: optionValue, text: optionText };
    else if (optionValue !== undefined)
      return { value: optionValue, text: optionValue };
    else if (optionText !== undefined)
      return { value: optionText, text: optionText };
    else return undefined;
  }

  private onChange() {
    this._selectedIndex = this._DOMNode.selectedIndex;
    this._value = this._DOMNode.value;

    this._changed = true;
    this.pushOutputs();
    queueFrame(() => {
      this._changed = false;
      this.pushOutputs();
    });
  }
}
