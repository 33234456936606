import { PerfCounter, ValueGraph } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";

// a performance counter class that does nothing
export class SilentPerfCounter implements PerfCounter {
  expressionEvaluated(): void {
    // do nothing
  }

  behaviorFrameFinished(): void {
    // do nothing
  }

  valueSet(): void {
    // do nothing
  }

  get trace(): string {
    return "";
  }

  get traceFrames(): boolean {
    return false;
  }

  traceThis(): boolean {
    return false;
  }
}

// a performance counter class writing to console.log
export class ConsolePerfCounter implements PerfCounter {
  protected static expressionEvaluations = 0;
  protected static behaviorFramesFinished = 0;

  private _trace: RegExp | null = null;
  traceFrames = false;

  expressionEvaluated(): void {
    ConsolePerfCounter.expressionEvaluations += 1;
    if (ConsolePerfCounter.expressionEvaluations % 1000 == 0) {
      console.log(
        "Expressions evaluated",
        ConsolePerfCounter.expressionEvaluations
      );
    }
  }

  behaviorFrameFinished(component?: string, graph?: ValueGraph): void {
    if (this.traceFrames) console.log(component, graph);
    ConsolePerfCounter.behaviorFramesFinished += 1;
    if (ConsolePerfCounter.behaviorFramesFinished % 100 == 0) {
      console.log(
        "Behavior frames finished",
        ConsolePerfCounter.behaviorFramesFinished
      );
    }
  }

  valueSet(
    path: string,
    value: Value | Value[],
    before: Value | Value[],
    after: Value | Value[]
  ): void {
    if (this.trace)
      console.log(path, {
        before: before,
        after: after,
        result: value,
      });
  }

  set trace(t: string) {
    this._trace = t ? this.toRegEx(t) : null;
  }

  get trace(): string {
    return this._trace?.source ?? "";
  }

  traceThis(path: string): boolean {
    return this._trace !== null ? this._trace.test(path) : false;
  }

  /**
   * Converts a match pattern from a string to a case insensitive RegExp by
   * escaping `.` and replacing `*`.
   * @param s A string with a match pattern
   * @returns The regular expression
   */
  private toRegEx(s: string): RegExp {
    // first escape all '.', then replace all '*' with '.*'
    s = s.replace(/\./g, "\\.").replace(/\*/g, ".*");
    // make the search case insensitive
    return RegExp(s, "i");
  }
}
