import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import SVGAnimateComponent from "./svg-animate-component";

/**
 * Represents an SVG animate motion element in the DOM tree. Details about the
 * circle element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/animateMotion
 */
export default class SVGAnimateMotionComponent extends SVGAnimateComponent {
  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy, "animateMotion");
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.keyPoints = { _input: null };
    schema.path = { _input: null };
    schema.rotate = { _input: null };
    return schema;
  }

  get DOMNode(): SVGAnimateMotionElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "keypoints":
        this.setOrRemoveAttribute("keyPoints", value);
        break;
      case "path":
      case "rotate":
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
    }
  }
}
