import { isSchema } from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import { getEntryPoint } from "../schema/schema-utils";
import CellType from "./cell-type";

/**
 * Iterates through a schema and adds all top level objects as custom
 * components as `cellType` trees to the component library.
 *
 * @param schema The schema from which to populate the custom components.
 */
export class ComponentLibrary {
  readonly schema: Schema;
  private _components = new Map<string, CellType>();

  constructor(schema: Schema) {
    this.schema = schema;
    if (!getEntryPoint(schema, "main")) {
      schema = { main: schema };
    }

    Object.entries(schema).forEach(([key, component]) => {
      if (!isSchema(component))
        throw new Error(`Invalid component declaration for ${key}`);

      this._components.set(
        key.toLowerCase(),
        new CellType(component, key, null, schema)
      );
    });
  }

  get(name: string): CellType | undefined {
    return this._components.get(name.toLowerCase());
  }
}
