import Decimal from "decimal.js";
import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { BehaviorMode, BehaviorNode, createBehaviorNode } from "./behavior";
import { toNumber, compareValues, toBoolean } from "./behavior-commons";

/**
 * Factory function to create a binary operator behavior node from a jsep token.
 * @param token The jsep token from which to create the behavior node.
 * @returns The binary operator behavior node.
 */
export function createBinaryBehaviorNode(
  token: jsep.BinaryExpression,
  mode: BehaviorMode
): BehaviorNode {
  switch (token.operator) {
    case "+":
      return new PlusBinaryBehaviorNode(token, mode);
    case "-":
      return new MinusBinaryBehaviorNode(token, mode);
    case "*":
      return new MultiplyBinaryBehaviorNode(token, mode);
    case "/":
      return new DivideBinaryBehaviorNode(token, mode);
    case "%":
      return new ModuloBinaryBehaviorNode(token, mode);
    case "==":
    case "=":
      return new EqualBinaryBehaviorNode(token, mode);
    case "!=":
    case "<>":
      return new UnequalBinaryBehaviorNode(token, mode);
    case "<":
      return new LessThanBinaryBehaviorNode(token, mode);
    case ">":
      return new MoreThanBinaryBehaviorNode(token, mode);
    case "<=":
      return new LessThanOrEqualBinaryBehaviorNode(token, mode);
    case ">=":
      return new MoreThanOrEqualBinaryBehaviorNode(token, mode);
    case "&&":
      return new LogicalAndBinaryBehaviorNode(token, mode);
    case "||":
      return new LogicalOrBinaryBehaviorNode(token, mode);
    default:
      // throw exception for any other operator currently not implemented
      throw "Binary operator " + token.operator + " not implemented";
  }
}

class PlusBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.add(
        toNumber(this.left.evaluate(scope, calledFrom)),
        toNumber(this.right.evaluate(scope, calledFrom))
      )
    );
  }
}

class MinusBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return Number(
      Decimal.sub(
        toNumber(this.left.evaluate(scope, calledFrom)),
        toNumber(this.right.evaluate(scope, calledFrom))
      )
    );
  }
}

class MultiplyBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return Number(
      Decimal.mul(
        toNumber(this.left.evaluate(scope, calledFrom)),
        toNumber(this.right.evaluate(scope, calledFrom))
      )
    );
  }
}

class DivideBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return Number(
      Decimal.div(
        toNumber(this.left.evaluate(scope, calledFrom)),
        toNumber(this.right.evaluate(scope, calledFrom))
      )
    );
  }
}

class ModuloBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return Number(
      Decimal.mod(
        toNumber(this.left.evaluate(scope, calledFrom)),
        toNumber(this.right.evaluate(scope, calledFrom))
      )
    );
  }
}

class EqualBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      compareValues(
        this.left.evaluate(scope, calledFrom),
        this.right.evaluate(scope, calledFrom)
      ) == 0
    );
  }
}

class UnequalBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      compareValues(
        this.left.evaluate(scope, calledFrom),
        this.right.evaluate(scope, calledFrom)
      ) != 0
    );
  }
}

class LessThanBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      compareValues(
        this.left.evaluate(scope, calledFrom),
        this.right.evaluate(scope, calledFrom)
      ) == -1
    );
  }
}

class MoreThanBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      compareValues(
        this.left.evaluate(scope, calledFrom),
        this.right.evaluate(scope, calledFrom)
      ) == +1
    );
  }
}

class LessThanOrEqualBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      compareValues(
        this.left.evaluate(scope, calledFrom),
        this.right.evaluate(scope, calledFrom)
      ) != +1
    );
  }
}

class MoreThanOrEqualBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      compareValues(
        this.left.evaluate(scope, calledFrom),
        this.right.evaluate(scope, calledFrom)
      ) != -1
    );
  }
}

class LogicalAndBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      toBoolean(this.left.evaluate(scope, calledFrom)) &&
      toBoolean(this.right.evaluate(scope, calledFrom))
    );
  }
}

class LogicalOrBinaryBehaviorNode implements BehaviorNode {
  private left: BehaviorNode;
  private right: BehaviorNode;

  constructor(token: jsep.BinaryExpression, mode: BehaviorMode) {
    this.left = createBehaviorNode(token.left, mode);
    this.right = createBehaviorNode(token.right, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return (
      toBoolean(this.left.evaluate(scope, calledFrom)) ||
      toBoolean(this.right.evaluate(scope, calledFrom))
    );
  }
}
