import firebase from "firebase/app";
import "firebase/functions";
import "firebase/storage";

import { AuthError } from "@shared/src/auth";
import queueFrame from "@shared/src/classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  SchemaPublisher,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

enum Status {
  PUBLISH_PENDING = "publish-pending",
  PUBLISH_FAILED = "publish-failed",
  PUBLISH_SUCCESSFUL = "publish-successful",
}

/**
 * The publish component publishes a Hyperseed schema to the Firebase
 * instance with a defined appName.
 */
export default class PublishComponent implements Component, SchemaPublisher {
  private _invokedBy: ComponentInvoker;

  /** triggers the publish rpc call when true */
  private _publish = false;
  /** the Hyperseed application name */
  private _appName: string | null = null;
  /** the schema contents as a YAML string */
  private _schema: string | null = null;
  /** makes the app publicly accessible when true */
  private _makePublic = false;

  /** the Cloud Storage URL of the published schema file */
  private _publishedUrl: string | null = null;
  /** The status after publishing has been triggered */
  private _status: Status | null = null;
  /** The error message if an error occured during publishing */
  private _error: string | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      publish: { _input: false },
      appName: { _input: null },
      schema: { _input: null },
      makePublic: { _input: false },

      publishedUrl: { _output: null },
      status: { _output: null },
      error: { _output: null },
    };
  }

  destroy(): void {
    // nothing to be destroyed
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "publish":
          this._publish = Boolean(value);
          break;
        case "appname":
          this._appName = value?.toString() ?? null;
          break;
        case "schema":
          this._schema = value?.toString() ?? null;
          break;
        case "makepublic":
          this._makePublic = Boolean(value);
          break;
      }
    });

    // publish is called each time the publish property is true and any of the
    // values has been set. I.e. a schema that updates while the publish
    // property is true triggers another publishing just as setting just the
    // publish property.
    if (this._publish) queueFrame(() => this.publishSchema());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      publishedUrl: this._publishedUrl,
      status: this._status,
      error: this._error,
    };
  }

  /**
   * Checks the parameters for the publishing call and calls the publishing
   * function if they are valid, otherwise sets the error and status properties
   * of the component accordingly.
   */
  private publishSchema() {
    if (this._appName == null || this._schema == null) {
      this._error = "Either appName or schema is an invalid string or null.";
      this._status = Status.PUBLISH_FAILED;
      this.pushOutputs();
      console.error(this._error);
      return;
    }

    this._status = Status.PUBLISH_PENDING;
    this._error = null;
    this.pushOutputs();

    this.publish(this._appName, this._schema, this._makePublic)
      .then((publishedUrl) => {
        this._publishedUrl = publishedUrl;
        this._status = Status.PUBLISH_SUCCESSFUL;
        this._error = null;
        this.pushOutputs();
      })
      .catch((err) => {
        this._error = err instanceof Error ? err.message : "publish failed";
        this._status = Status.PUBLISH_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Publishes a schema file by uploading it to the default Cloud
   * Storage bucket of the target Firebase project.
   *
   * @param appName the Seed application name
   * @param schema the schema contents as a YAML string
   * @returns the Cloud Storage URL of the published schema file
   * @throws AuthError if the authenticated user ID could not be
   *   detected
   */
  // TODO: Does this method need to be public?
  public async publish(
    appName: string,
    schema: string,
    makePublic: boolean
  ): Promise<string> {
    const userId = localStorage.getItem("userId");
    if (userId === null) {
      throw new AuthError("user not authenticated");
    }
    const visibility = makePublic ? "public" : "private";
    const filePathSansExt = `users/${userId}/${visibility}/${appName}`;
    const filePath = `${filePathSansExt}.yaml`;
    const cacheControl = "max-age=10";
    const contentType = "application/x-yaml";
    const storageRef = firebase.storage().ref(filePath);
    const file = new File([schema], filePath, {
      type: contentType,
    });
    const metadata = { cacheControl, contentType };
    await storageRef.put(file, metadata);
    const schemaUrl = window.location.href + filePathSansExt;
    return schemaUrl;
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
