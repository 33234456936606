import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import {
  ErrorCode,
  FirestoreQueryBase,
  FirestoreQueryError,
  OrderByClause,
  WhereClause,
} from "@shared/src/components/firestore-query-base";
import {
  ComponentInvoker,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";

import { documentId } from "../firestore";

export default class FirestoreQueryComponent extends FirestoreQueryBase {
  private _db: firebase.firestore.Firestore;
  /** Stores snapshots of the documents at the start of the respective pages,
   * where array index 1 stores the document of the 2nd page. For page index 0
   * (the first page) no document snapshot will be stored. Each page start will
   * be reset when the previous page was loaded. */
  private _pagesStartAt: firebase.firestore.DocumentSnapshot[] = [];

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);
    this._db = firebase.firestore();
  }

  private buildQuery(
    orgId: string,
    collection: string,
    ids: string[],
    appName: string,
    orgRoot?: string,
    where?: WhereClause[],
    orderBy?: OrderByClause[],
    limit?: number,
    page?: number
  ): firebase.firestore.CollectionReference | firebase.firestore.Query {
    const collectionPath = orgRoot
      ? `orgs/${orgId}/${orgRoot}/${collection}`
      : `orgs/${orgId}/apps/${appName}/${collection}`;
    const collectionRef = this._db.collection(collectionPath);
    let builder:
      | firebase.firestore.CollectionReference
      | firebase.firestore.Query = collectionRef;
    if (ids?.length > 0) {
      builder = builder.where(documentId(), "in", ids);
    }
    if (where) {
      for (const clause of where) {
        builder = builder.where(
          clause.field,
          clause.op as firebase.firestore.WhereFilterOp,
          clause.value
        );
      }
    }
    if (orderBy) {
      for (const clause of orderBy) {
        builder = builder.orderBy(
          clause.field,
          clause.descending ? "desc" : "asc"
        );
      }
    }
    // we always sort by document ID to allow paging
    builder = builder.orderBy(documentId());
    if (limit && !isNaN(limit)) {
      builder = builder.limit(limit);
    }
    if (page && !isNaN(page)) {
      // will be skipped for first page (as page == 0)
      // throws error when trying to load not consecutive pages
      if (page >= this._pagesStartAt.length)
        throw new FirestoreQueryError("cannot load page", ErrorCode.BAD_PAGE);
      builder = builder.startAt(this._pagesStartAt[page]);
    }
    return builder;
  }

  protected listen(
    orgId: string,
    collection: string,
    ids: string[],
    appName: string,
    orgRoot?: string,
    where?: WhereClause[],
    orderBy?: OrderByClause[],
    limit?: number,
    page?: number
  ): void {
    const builder = this.buildQuery(
      orgId,
      collection,
      ids,
      appName,
      orgRoot,
      where,
      orderBy,
      limit,
      page
    );
    this._unsubscribe?.();
    this._unsubscribe = builder.onSnapshot({
      next: (snapshot) => {
        const results: ValueGraph[] = [];
        snapshot.forEach((doc) => {
          const docData = { id: doc.id, ...doc.data() };
          results.push(this.convertTimestamp(docData) as ValueGraph);
        });
        // remember the first document of the next page
        // TODO: in case there are less documents than returned, we currently
        // store the wrong docRef. As long as we do not request another page
        // when hasMore is false, it's not an issue
        this._pagesStartAt[(page ?? 0) + 1] =
          snapshot.docs[snapshot.docs.length - 1];
        // and remove all references beyond this one as we only allow stepping
        // one page by one
        this._pagesStartAt.splice((page ?? 0) + 2);
        this.update(results, null, null);
      },
      error: (error: firebase.firestore.FirestoreError) => {
        // Log the error unless currentUser is null, which can happen
        // when the user logs out while the component is listening.
        if (firebase.auth().currentUser !== null) {
          console.error("onSnapshot failed:", error);
        }
        this.update(undefined, error.message, error.code);
      },
    });
  }

  protected async query(
    orgId: string,
    collection: string,
    ids: string[],
    appName: string,
    orgRoot?: string,
    where?: WhereClause[],
    orderBy?: OrderByClause[],
    limit?: number,
    page?: number
  ): Promise<ValueGraph[]> {
    try {
      const builder = this.buildQuery(
        orgId,
        collection,
        ids,
        appName,
        orgRoot,
        where,
        orderBy,
        limit,
        page
      );
      const snapshot = await builder.get();
      const results: ValueGraph[] = [];
      snapshot.forEach((doc) => {
        const docData = { id: doc.id, ...doc.data() };
        results.push(this.convertTimestamp(docData) as ValueGraph);
      });
      // remember the first document of the next page
      // TODO: in case there are less documents than returned, we currently
      // store the wrong docRef. As long as we do not request another page
      // when hasMore is false, it's not an issue
      this._pagesStartAt[(page ?? 0) + 1] =
        snapshot.docs[snapshot.docs.length - 1];
      // and remove all references beyond this one as we only allow stepping
      // one page by one
      this._pagesStartAt.splice((page ?? 0) + 2);
      return results;
    } catch (err) {
      if (err instanceof FirestoreQueryError) throw err;
      console.error("query failed:", err);
      throw err;
    }
  }
}
