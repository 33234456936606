import { makeArray, toBoolean } from "../behavior/behavior-commons";
import queueFrame from "../classes/frame-queue";
import Instance from "../classes/instance";
import {
  ComponentInvoker,
  DOMComponent,
  isValue,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

interface keyboardFilter {
  code: string | null;
  key: string | null;
  altKey: boolean | null;
  ctrlKey: boolean | null;
  metaKey: boolean | null;
  shiftKey: boolean | null;
}

/**
 * UIKeyboardComponent captures keyboard entries, thus listens to keyboard events.
 */
export default class UIKeyboardComponent implements DOMComponent {
  private _invokedBy: ComponentInvoker;
  private _parentDOMNode: Element | null = null;

  private _stopPropagation: boolean | keyboardFilter[] = false;
  private _preventDefault: boolean | keyboardFilter[] = false;

  // these represent the event (single frame values)
  private _keyDown = false;
  private _keyUp = false;

  // state of the last key event is kept
  private _code: string | null = null;
  private _key: string | null = null;
  private _location = 0;
  private _repeat = false;
  private _altKey = false;
  private _ctrlKey = false;
  private _metaKey = false;
  private _shiftKey = false;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // stopPropagation can be set to a primitive boolean or with an array of
      // keyboard filters, where on the first match propagation is stopped
      stopPropagation: {
        _input: null,
        _iscollection: true,
        code: { _input: null },
        key: { _input: null },
        altKey: { _input: null },
        ctrlKey: { _input: null },
        metaKey: { _input: null },
        shiftKey: { _input: null },
      },
      // preventDefault can be set to a primitive boolean or with an array of
      // keyboard filters, where on the first match the default behavior is
      // prevented
      preventDefault: {
        _input: null,
        _iscollection: true,
        code: { _input: null },
        key: { _input: null },
        altKey: { _input: null },
        ctrlKey: { _input: null },
        metaKey: { _input: null },
        shiftKey: { _input: null },
      },

      keyDown: { _output: {} },
      keyUp: { _output: {} },
      code: { _output: {} },
      key: { _output: {} },
      location: { _output: {} },
      repeat: { _output: {} },
      altKey: { _output: {} },
      ctrlKey: { _output: {} },
      metaKey: { _output: {} },
      shiftKey: { _output: {} },
    };
  }

  get DOMNode(): null {
    return null;
  }

  destroy(): void {
    this.detachDOM();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      if (value !== undefined) this.setDOMElementProperty(key, value);
    });
  }

  getOutputValueGraph(): ValueGraph {
    return {
      keyDown: this._keyDown,
      keyUp: this._keyUp,
      code: this._code,
      key: this._key,
      location: this._location,
      repeat: this._repeat,
      altKey: this._altKey,
      ctrlKey: this._ctrlKey,
      metaKey: this._metaKey,
      shiftKey: this._shiftKey,
    };
  }

  attachDOM(parentDOMNode: Element): void {
    // DOM node already set -> do nothing
    if (parentDOMNode === this._parentDOMNode) return;

    // already attached to a different element -> as the keyboard component
    // binds events to the parent DOM element, it must be detached first before
    // it gets re-attached
    if (this._parentDOMNode) this.detachDOM();

    this._parentDOMNode = parentDOMNode;

    this._parentDOMNode.addEventListener("keydown", this.onKeyDown);
    this._parentDOMNode.addEventListener("keyup", this.onKeyUp);
  }

  detachDOM(): void {
    if (!this._parentDOMNode) return;

    this._parentDOMNode.removeEventListener("keydown", this.onKeyDown);
    this._parentDOMNode.removeEventListener("keyup", this.onKeyUp);

    this._parentDOMNode = null;
  }

  private canReceiveDOMFocus(node: Element): boolean {
    return ["INPUT", "TEXTAREA", "BUTTON", "SELECT"].includes(node.nodeName);
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    switch (key.toLowerCase()) {
      case "stoppropagation":
        this._stopPropagation = this.getKeyboardFilter(value);
        break;
      case "preventdefault":
        this._preventDefault = this.getKeyboardFilter(value);
        break;
    }
  }

  /**
   * Returns an array of keyboardFilter entries.
   * @param data Defines which keyboard events to filter.
   */
  private getKeyboardFilter(
    data: ValueGraphData | ValueGraphData[]
  ): boolean | keyboardFilter[] {
    function getFilterItem(item: ValueGraphData): keyboardFilter {
      const result: keyboardFilter = {
        code: null,
        key: null,
        altKey: null,
        ctrlKey: null,
        metaKey: null,
        shiftKey: null,
      };

      // primitive values return an empty keyboard filter
      if (isValue(item)) return result;

      if (item instanceof Instance) item = item.getValueGraph();

      Object.entries(item).forEach(([key, value]) => {
        if (value !== undefined) {
          switch (key.toLowerCase()) {
            case "code":
              result.code = value?.toString() ?? null;
              break;
            case "key":
              result.key = value?.toString() ?? null;
              break;
            case "altkey":
              result.altKey = value == null ? null : toBoolean(value);
              break;
            case "ctrlkey":
              result.ctrlKey = value == null ? null : toBoolean(value);
              break;
            case "metakey":
              result.metaKey = value == null ? null : toBoolean(value);
              break;
            case "shiftkey":
              result.shiftKey = value == null ? null : toBoolean(value);
              break;
          }
        }
      });

      return result;
    }

    if (isValue(data)) {
      // a primitive value is returned as a boolean value
      return Boolean(data);
    } else {
      if (data instanceof Instance) data = data.getValueGraph();
      data = makeArray(data);

      // an object or an array sets the properties for each entry of the array
      // accordingly
      // TODO: we might want to remove empty entries (all properties are null)
      return data.map((item) => getFilterItem(item));
    }
  }

  // TODO: to avoid adding multiple instances of the same callback function, the
  // function at the time being must be static as by not having a DOM node on
  // the mouse, keyboard, drag & drop sensors, the runtime considers those not
  // yet being added. We should find a cleaner way of doing this. see also
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#memory_issues
  private onKeyDown = (event: Event): void => {
    // only handle events which are targeting the parent DOM node
    // TODO: allow the user to define if this should be the case?
    if (event.target !== this._parentDOMNode) return;

    this._keyDown = true;
    this.setKeyboardAttributes(event as KeyboardEvent);
    this.pushOutputs();

    queueFrame(() => {
      this._keyDown = false;
      this.pushOutputs();
    });
  };

  private onKeyUp = (event: Event): void => {
    // only handle events which are targeting the parent DOM node
    // TODO: allow the user to define if this should be the case?
    if (event.target !== this._parentDOMNode) return;

    this._keyUp = true;
    this.setKeyboardAttributes(event as KeyboardEvent);
    this.pushOutputs();

    queueFrame(() => {
      this._keyUp = false;
      this.pushOutputs();
    });
  };

  protected setKeyboardAttributes(event: KeyboardEvent): void {
    function checkFilter(item: keyboardFilter): boolean {
      // works by checking that `code` or `key` and all additional keys not
      // being null must match
      return (
        (item.code === event.code || item.key === event.key) &&
        (item.altKey === null || item.altKey === event.altKey) &&
        (item.ctrlKey === null || item.ctrlKey === event.ctrlKey) &&
        (item.metaKey === null || item.metaKey === event.metaKey) &&
        (item.shiftKey === null || item.shiftKey === event.shiftKey)
      );
    }

    this._code = event.code;
    this._key = event.key;
    this._location = event.location;
    this._repeat = event.repeat;
    this._altKey = event.altKey;
    this._ctrlKey = event.ctrlKey;
    this._metaKey = event.metaKey;
    this._shiftKey = event.shiftKey;

    if (
      this._stopPropagation === true ||
      (Array.isArray(this._stopPropagation) &&
        this._stopPropagation.reduce(
          (result, item) => result || checkFilter(item),
          false as boolean
        ))
    ) {
      event.stopPropagation();
    }

    if (
      this._preventDefault === true ||
      (Array.isArray(this._preventDefault) &&
        this._preventDefault.reduce(
          (result, item) => result || checkFilter(item),
          false as boolean
        ))
    ) {
      event.preventDefault();
    }
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
