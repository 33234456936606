import { backOff } from "exponential-backoff";
import firebase from "firebase/app";
import "firebase/functions";

import { AppContext } from "@shared/src/classes/app-context";
import queueFrame from "@shared/src/classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import * as rpc from "@shared/src/rpc";
import { Schema } from "@shared/src/schema/schema-global";

import { getDefaultBackoffOptions, translateErrorCode } from "../callable";

enum Status {
  IDLE = "request-idle",
  PENDING = "request-pending",
  SUCCEEDED = "request-succeeded",
  FAILED = "request-failed",
}

/**
 * The remote component allows caller to invoke a remote Hyperseed
 * application over HTTP.
 */
export default class RemoteComponent implements InitializableComponent {
  private _invokedBy: ComponentInvoker;
  private _orgId: string | null = null;
  private _schemaPath: string | null = null;
  private _component: string | null = null;
  private _input: ValueGraph | null = null;
  private _output: ValueGraph | null = null;
  private _triggered = false;

  /** The remote component request status */
  private _status: Status = Status.IDLE;
  /** The error message if an error occured during execution */
  private _error: string | null = null;

  private _statusCode: number | null = null;
  private _statusText: string | null = null;

  private _contextSubscription?: Unsubscribable;

  private _backoffStartingDelay = 100;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   schemaPath: { _input: null },
   *   component: { _input: null },
   *   input: {
   *     _input: null,
   *     _iscollection: true,
   *     property: {
   *       _input: null,
   *     },
   *   },
   *   trigger: { _input: null },
   *   // Outputs
   *   output: {
   *     _input: null,
   *     _iscollection: true,
   *     property: {
   *       _input: null,
   *     },
   *   },
   *   status: { _output: null },
   *   statusCode: { _output: null },
   *   statusText: { _output: null },
   *   error: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        if (context.orgId !== this._orgId) {
          this._orgId = context.orgId ?? null;
        }
      },
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  private execute(): void {
    if (this._orgId === null || this._schemaPath === null) {
      this._output = null;
      this._error = `Hyperseed remote failed: orgId and schemaPath must be set when triggered`;
      this._status = Status.FAILED;
      [this._statusCode, this._statusText] =
        translateErrorCode("invalid-argument");
      this.pushOutputs();
      this._invokedBy.setBusy(false);
      return;
    }
    this._error = null;
    this._status = Status.PENDING;
    this._statusCode = null;
    this._statusText = null;
    this.pushOutputs();

    const runFn = firebase.functions().httpsCallable("hyperseed-run");
    const request: rpc.HyperseedRunRequest = {
      schemaPath: this._schemaPath,
      component: this._component ?? "main",
      input: this._input ?? {},
      orgId: this._orgId,
    };
    const backoffOptions = getDefaultBackoffOptions({
      startingDelay: this._backoffStartingDelay,
    });
    backOff(() => runFn(request), backoffOptions)
      .then((result) => {
        const response = result.data as rpc.HyperseedRunResponse;
        this._output = response.output;
        this._status = Status.SUCCEEDED;
        this._statusCode = 200;
        this._statusText = "OK";
        this.pushOutputs();
        this._invokedBy.setBusy(false);
      })
      .catch((error: firebase.functions.HttpsError) => {
        this._output = null;
        this._error = "Hyperseed remote failed: " + error.message;
        this._status = Status.FAILED;
        [this._statusCode, this._statusText] = translateErrorCode(error.code);
        this.pushOutputs();
        this._invokedBy.setBusy(false);
      });
  }

  /**
   * Sets the backoff starting delay in milliseconds. Intended for use
   * by unit tests.
   * @see https://www.npmjs.com/package/exponential-backoff
   */
  setBackoffStartingDelay(delay: number) {
    this._backoffStartingDelay = delay;
  }

  setInputValueGraph(graph: ValueGraph): void {
    let trigger = false;
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "schemapath":
          this._schemaPath = value?.toString() ?? null;
          break;
        case "component":
          this._component = value?.toString() ?? null;
          break;
        case "input":
          this._input = value as unknown as ValueGraph;
          break;
        case "trigger":
          trigger = Boolean(value);
          break;
      }
    });
    // TODO: Consider aborting request if inputs are updated while
    // status is pending. Or implement input queue...
    if (this._status === Status.PENDING) {
      // log error just in case remote execution is triggered while pending
      if (trigger && !this._triggered)
        console.error(
          "Remote component trigger ignored while component is pending"
        );

      this._triggered = trigger;
      return;
    }

    this._triggered = trigger;
    if (trigger) {
      this._invokedBy.setBusy(true);
      queueFrame(() => this.execute());
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      output: this._output,
      status: this._status,
      statusCode: this._statusCode,
      statusText: this._statusText,
      error: this._error,
    };
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("input.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    if (name.startsWith("output.")) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["schemapath", "component", "input", "trigger"]);
/** Names of component outputs. */
const outputs = new Set([
  "output",
  "status",
  "statuscode",
  "statustext",
  "error",
]);
