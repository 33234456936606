import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIViewComponent from "./ui-view-component";

/**
 * The UI link component represents an anchor element in the DOM tree.
 */
export default class UILinkComponent extends UIViewComponent {
  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy, "a");
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.href = { _input: null };
    schema.rel = { _input: null };
    schema.target = { _input: null };
    schema.type = { _input: null };
    schema.download = { _input: null };
    return schema;
  }

  get DOMNode(): HTMLAnchorElement {
    return this._DOMNode as HTMLAnchorElement;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "href":
      case "rel":
      case "target":
      case "type":
      case "download":
        // potentially make use of this.DOMNode.* property
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
    }
  }
}
