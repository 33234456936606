import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { delta, changed } from "./behavior-commons";

export function createDeltaFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "valueup":
      return new valueUpFunctionBehaviorNode(token, mode);
    case "valuedown":
      return new valueDownFunctionBehaviorNode(token, mode);
    case "valuedelta":
      return new valueDeltaFunctionBehaviorNode(token, mode);
    case "valuechanged":
      return new valueChangedFunctionBehaviorNode(token, mode);
  }
}

/**
 * Returns true if the current value of the reference is larger than the
 * previous value of this reference, otherwise returns false. If the current or
 * the previous value is not a number (NaN), NaN is returned.
 * @name valueUp() function
 * @param reference The reference to a cell to be investigated.
 * @returns true if the current value is larger than the previous value.
 */
class valueUpFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): boolean | number {
    const result = delta(this.arguments[0].evaluate(scope, calledFrom));
    return isNaN(result) ? NaN : result > 0;
  }
}

/**
 * Returns true if the current value of the reference is smaller than the
 * previous value of this reference, otherwise returns false. If the current or
 * the previous value is not a number (NaN), NaN is returned.
 * @name valueDown() function
 * @param reference The reference to a cell to be investigated.
 * @returns true if the current value is smaller than the previous value.
 */
class valueDownFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): boolean | number {
    const result = delta(this.arguments[0].evaluate(scope, calledFrom));
    return isNaN(result) ? NaN : result < 0;
  }
}

/**
 * Returns the difference of the current value of the reference to the previous
 * value of this reference (result = current - previous). If the current or the
 * previous value is not a number (NaN), NaN is returned.
 * @name valueDelta() function
 * @param reference The reference to a cell to be investigated.
 * @returns The difference of the current value to the previous value.
 */
// valueDelta() function
// returns the delta of the current value of the argument compared to its previous value
class valueDeltaFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return delta(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Returns true if the current value of the reference is different from
 * the previous value of this reference (result = current != previous),
 * otherwise returns false.
 * @name valueChanged() function
 * @param reference The reference to a cell to be investigated.
 * @returns true of the current value is different from the previous value.
 */
class valueChangedFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return changed(this.arguments[0].evaluate(scope, calledFrom));
  }
}
