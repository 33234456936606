import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

enum Status {
  VALID = "valid",
  INVALID = "invalid",
}

/**
 * The blob URL component creates a local blob URL for the provided
 * content.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 */
export default class BlobUrlComponent implements Component {
  private _invokedBy: ComponentInvoker;

  /** The blob content */
  private _content: string | null = null;
  /** The blob content's MIME type */
  private _type: string | null = null;

  /** The blob URL */
  private _url: string | null = null;
  /** The component status */
  private _status: Status | null = null;
  /** The error message */
  private _error: string | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // Inputs
      content: { _input: null },
      type: { _input: null },
      // Outputs
      url: { _output: null },
      status: { _output: null },
      error: { _output: null },
    };
  }

  destroy(): void {
    // nothing to be destroyed
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "content":
          this._content = value?.toString() ?? null;
          break;
        case "type":
          this._type = value?.toString() ?? null;
          break;
      }
    });
    this.createUrl();
  }

  getOutputValueGraph(): ValueGraph {
    return {
      url: this._url,
      status: this._status,
      error: this._error,
    };
  }

  private createUrl() {
    if (this._content == null) {
      this._status = Status.VALID;
      this.pushOutputs();
      return;
    }

    const options = {
      type: this._type || "",
    };
    const blob = new Blob([this._content], options);
    this._url = URL.createObjectURL(blob);
    console.log("BlobUrlComponent url:", this._url);
    this._status = Status.VALID;
    this._error = null;
    this.pushOutputs();
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
