import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * Represents a textarea element in the DOM tree.
 */
export default class UITextAreaComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLTextAreaElement;
  private _changed = false;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("textarea");
    this._DOMNode.addEventListener("input", () => this.onInput());
    this._DOMNode.addEventListener("change", () => this.onChange());
    this._DOMNode.addEventListener("select", () => this.onSelect());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.disabled = { _input: null };
    schema.required = { _input: null };

    schema.autocomplete = { _input: null };
    schema.maxLength = { _input: null };
    schema.minLength = { _input: null };
    schema.placeholder = { _input: null };
    schema.readonly = { _input: null };
    schema.setSelectionStart = { _input: null };
    schema.setSelectionEnd = { _input: null };
    schema.setSelectionDirection = { _input: null };
    schema.setSelectAll = { _input: null };
    schema.setValue = { _input: null };

    schema.value = { _output: {} };
    schema.selectionStart = { _output: {} };
    schema.selectionEnd = { _output: {} };
    schema.selectionDirection = { _output: {} };
    schema.changed = { _output: {} };

    return schema;
  }

  get DOMNode(): HTMLTextAreaElement {
    return this._DOMNode;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.value = this._DOMNode.value;
    result.selectionStart = this._DOMNode.selectionStart;
    result.selectionEnd = this._DOMNode.selectionEnd;
    result.selectionDirection = this._DOMNode.selectionDirection;
    result.changed = this._changed;

    return result;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      // TODO: move disabled and required to an InputElementComponent?
      case "disabled":
        this._DOMNode.disabled = Boolean(value);
        break;
      case "required":
        this._DOMNode.required = Boolean(value);
        break;

      case "autocomplete":
        // TODO: potentially use this.DOMNode.autocomplete
        this.setOrRemoveAttribute("autocomplete", value);
        break;
      case "maxlength":
        // TODO: potentially use this.DOMNode.maxLength
        this.setOrRemoveAttribute("maxLength", value);
        break;
      case "minlength":
        // TODO: potentially use this.DOMNode.minLength
        this.setOrRemoveAttribute("minLength", value);
        break;
      case "placeholder":
        // TODO: potentially use this.DOMNode.placeholder
        this.setOrRemoveAttribute("placeholder", value);
        break;
      case "readonly":
        this._DOMNode.readOnly = Boolean(value);
        break;
      case "setselectionstart":
        if (value !== null) {
          this._DOMNode.selectionStart = Number(value);
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setselectionend":
        if (value !== null) {
          this._DOMNode.selectionEnd = Number(value);
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setselectiondirection":
        if (value !== null) {
          this._DOMNode.selectionDirection =
            value?.toString() === "forward"
              ? "forward"
              : value?.toString() === "backward"
              ? "backward"
              : "none";
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setselectall":
        if (value) this._DOMNode.select();
        break;
      case "setvalue":
        if (value !== null) {
          this._DOMNode.value = value?.toString() ?? "";
          // as setting the value does not trigger the event
          queueFrame(() => this.pushOutputs());
        }
        break;
    }
  }

  private onChange() {
    this._changed = true;
    this.pushOutputs();
    queueFrame(() => {
      this._changed = false;
      this.pushOutputs();
    });
  }

  private onInput() {
    this.pushOutputs();
  }

  private onSelect() {
    this.pushOutputs();
  }
}
