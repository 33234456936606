import MockComponent from "@shared/src/components/mock-component";
import SystemComponentFactory from "@shared/src/components/system-component-factory";
import { Component } from "@shared/src/interfaces/global-interfaces";

import AuthResetPasswordComponent from "./auth-reset-password-component";
import AuthSignInComponent from "./auth-signin-component";
import AuthSignUpComponent from "./auth-signup-component";
import BlobUrlComponent from "./blob-url-component";
import CallableComponent from "./callable-component";
import FirestoreDeleteComponent from "./firestore-delete-component";
import FirestoreQueryComponent from "./firestore-query-component";
import FirestoreReadComponent from "./firestore-read-component";
import FirestoreWriteComponent from "./firestore-write-component";
import GoogleChartsComponent from "./google-charts-component";
import HotGlueComponent from "./hotglue-component";
import MakeApiKeyComponent from "./make-api-key-component";
import PublishComponent from "./publish-component";
import {
  QuickBooksConnectionComponent,
  QuickBooksQueryComponent,
  QuickBooksWriteComponent,
} from "./quickbooks-component";
import RemoteComponent from "./remote-component";
import SheetsComponent from "./sheets-component";
import StorageListComponent from "./storage-list-component";
import StorageReadComponent from "./storage-read-component";
import UIContextComponent from "./ui-context";

let systemComponentsRegistered = false;

function _registerSystemComponents() {
  const registry: [string, Component][] = [
    ["seed.auth.signup", AuthSignUpComponent as unknown as Component],
    ["seed.auth.signin", AuthSignInComponent as unknown as Component],
    [
      "seed.auth.reset-password",
      AuthResetPasswordComponent as unknown as Component,
    ],
    ["seed.publish", PublishComponent as unknown as Component],
    ["seed.google-sheets", SheetsComponent as unknown as Component],
    ["seed.callable", CallableComponent as unknown as Component],
    ["seed.remote", RemoteComponent as unknown as Component],
    ["seed.storage.list", StorageListComponent as unknown as Component],
    ["seed.storage.read", StorageReadComponent as unknown as Component],
    ["seed.firestore.delete", FirestoreDeleteComponent as unknown as Component],
    ["seed.firestore.query", FirestoreQueryComponent as unknown as Component],
    ["seed.firestore.read", FirestoreReadComponent as unknown as Component],
    ["seed.firestore.write", FirestoreWriteComponent as unknown as Component],
    ["seed.api.make-api-key", MakeApiKeyComponent as unknown as Component],
    ["seed.google.charts", GoogleChartsComponent as unknown as Component],
    [
      "seed.quickbooks.connection",
      QuickBooksConnectionComponent as unknown as Component,
    ],
    ["seed.quickbooks.query", QuickBooksQueryComponent as unknown as Component],
    ["seed.quickbooks.write", QuickBooksWriteComponent as unknown as Component],
    ["seed.blob-url", BlobUrlComponent as unknown as Component],
    // Mock components used by platforms where they are referenced but
    // not needed, like backend-specific components used in schemas
    // executed in the web client.
    ["seed.task.http", MockComponent as unknown as Component],
    ["seed.ui.context", UIContextComponent as unknown as Component],
    ["seed.integration.hotglue", HotGlueComponent as unknown as Component],
  ];
  registry.forEach(([name, component]) =>
    SystemComponentFactory.registerPlatformComponent(name, component)
  );
}

/**
 * Registers web client platform components.
 */
export function registerSystemComponents(): void {
  if (systemComponentsRegistered) return;
  _registerSystemComponents();
  systemComponentsRegistered = true;
}
