import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

enum Status {
  INVALID = "invalid",
  VALID = "valid",
}

interface Row {
  cells: string[];
}

const quoteRE = /"/g;
const commaRE = /,/g;

function applyQuotes(content: string): string {
  if (typeof content !== "string") {
    return content;
  }
  if (content.match(quoteRE)) {
    const escaped = content.replace(quoteRE, '""');
    return `"${escaped}"`;
  }
  if (content.match(commaRE)) {
    return `"${content}"`;
  }
  return content;
}

export default class CSVGenerateComponent implements Component {
  private _invokedBy: ComponentInvoker;

  /**
   * The columns (header names).
   */
  private _columns: string[] | null = null;
  /**
   * The data to convert to CSV: a collection of rows, each of which is
   * a collection of cells.
   */
  private _rows: Row[] | null = null;

  /** Component status */
  private _status: Status | null = null;
  /** A description of the error if status is INVALID */
  private _error: string | null = null;
  /**
   * The resulting CSV content.
   */
  private _csvData: string | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // Inputs
      columns: {
        _iscollection: true,
        _input: null,
      },
      rows: {
        _iscollection: true,
        _input: null,
        cells: {
          _iscollection: true,
          _input: null,
        },
      },
      // Outputs
      status: { _output: {} },
      error: { _output: null },
      csvData: { _output: null },
    };
  }

  destroy(): void {
    // nothing to destroy
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "columns":
          this._columns = value as unknown as string[];
          break;
        case "rows":
          this._rows = value as unknown as Row[];
          break;
      }
    });
    this.convert();
  }

  getOutputValueGraph(): ValueGraph {
    return {
      status: this._status,
      error: this._error,
      csvData: this._csvData,
    };
  }

  convert(): void {
    if (this._rows === null) {
      this._csvData = null;
      this._error = null;
      this._status = Status.VALID;
      this.pushOutputs();
      return;
    }
    let rows = [];
    if (this._columns !== null) {
      rows.push(this._columns.map(applyQuotes).join(","));
    }
    rows = rows.concat(
      this._rows.map((r) => r.cells.map(applyQuotes).join(","))
    );
    this._csvData = rows.join("\n");
    this._status = Status.VALID;
    this.pushOutputs();
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
