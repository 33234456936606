import { Observer, Unsubscribable } from "../interfaces/global-interfaces";

/**
 * Represents the initial app context state.
 */
interface AppContextInit {
  orgId?: string;
  userId?: string;
}

/**
 * Represents the observable application context. Notifies observers
 * whenever app context is changed. New subscribers immediately receive
 * the last-updated context.
 */
export class AppContext {
  private _observers = new Set<Partial<Observer<AppContext>>>();
  private _orgId?: string;
  private _userId?: string;

  constructor(init: AppContextInit = {}) {
    this._orgId = init.orgId;
    this._userId = init.userId;
  }

  public get orgId(): string | undefined {
    return this._orgId;
  }

  public set orgId(v: string | undefined) {
    if (this._orgId === v) return;
    this._orgId = v;
    this.notifyObservers();
  }

  public get userId(): string | undefined {
    return this._userId;
  }

  public set userId(v: string | undefined) {
    if (this._userId === v) return;
    this._userId = v;
    this.notifyObservers();
  }

  public subscribe(observer: Partial<Observer<AppContext>>): Unsubscribable {
    this._observers.add(observer);
    observer.next?.(this);
    return { unsubscribe: () => this._observers.delete(observer) };
  }

  private notifyObservers(): void {
    for (const o of this._observers) {
      o.next?.(this);
    }
  }
}
