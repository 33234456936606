import * as papa from "papaparse";

import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

enum Status {
  INVALID = "invalid",
  VALID = "valid",
}

export default class CSVParseComponent implements Component {
  private _invokedBy: ComponentInvoker;

  private _csvData: string | null = null;
  private _delimiter = "";
  private _dynamicTyping = false;
  private _skipEmptyLines: boolean | "greedy" = false;
  private _hasHeader = false;

  /** Component status */
  private _status: Status | null = null;
  /** A description of the error if if status is FAILED */
  private _errors: ValueGraph[] | null = null;
  /**
   * Columns names from the header row populated only if hasHeader is
   * true
   */
  private _columns: string[] | null = null;
  /**
   * An array of parsed rows. Each row is either an object keyed by
   * field names in the header or an array (if header is false).
   */
  private _rows: unknown[] | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // Inputs
      csvData: { _input: null },
      delimiter: { _input: null },
      dynamicTyping: { _input: null },
      skipEmptyLines: { _input: null },

      hasHeader: { _input: null },
      // Outputs
      status: { _output: {} },
      errors: {
        // https://www.papaparse.com/docs#errors
        _iscollection: true,
        _output: {},
        type: { _output: {} },
        code: { _output: {} },
        message: { _output: {} },
        row: { _output: {} },
      },
      columns: {
        _iscollection: true,
        _output: {},
      },
      rows: {
        _iscollection: true,
        _output: {},
        cells: {
          _iscollection: true,
          _output: {},
        },
      },
    };
  }

  destroy(): void {
    // nothing to destroy
  }

  setInputValueGraph(graph: ValueGraph): void {
    this._invokedBy.setBusy(true);
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "csvdata":
          this._csvData = value?.toString() ?? null;
          break;
        case "hasheader":
          this._hasHeader = Boolean(value);
          break;
        case "delimiter":
          this._delimiter = value?.toString() ?? this._delimiter;
          break;
        case "dynamictyping":
          this._dynamicTyping = Boolean(value);
          break;
        case "skipemptylines":
          this._skipEmptyLines =
            value?.toString().toLowerCase() === "greedy"
              ? "greedy"
              : Boolean(value);
          break;
      }
    });
    queueFrame(() => this.parse());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      status: this._status,
      errors: this._errors,
      columns: this._columns,
      rows: this._rows as ValueGraph[],
    };
  }

  parse(): void {
    if (this._csvData === null) {
      this._columns = [];
      this._rows = [];
      this._errors = [];
      this._status = Status.VALID;
      this.pushOutputs();
      this._invokedBy.setBusy(false);
      return;
    }

    // https://www.papaparse.com/docs#config
    const config = {
      delimiter: this._delimiter,
      dynamicTyping: this._dynamicTyping,
      skipEmptyLines: this._skipEmptyLines,
    };
    const result = papa.parse(this._csvData ?? "", config);
    this._rows = [];
    result.data.forEach((row, i) => {
      if (i === 0 && this._hasHeader) {
        this._columns = row as string[];
        return;
      }
      this._rows?.push({ cells: row });
    });
    this._errors = result.errors as unknown as ValueGraph[];
    this._status = Status.VALID;
    this.pushOutputs();
    this._invokedBy.setBusy(false);
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
