import Decimal from "decimal.js";

import Instance from "../classes/instance";
import { ValueGraphData } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";

/**
 * Returns the primitive value (or null) of a value, instance, array of values
 * or array of instances.
 * Works like the + operator in JavaScript except of treating a null as
 * an empty string when concatenating into a string.
 * @param value a value, instance or array of values or instances
 * @returns the primitive of value
 */
export function toValue(value: ValueGraphData | ValueGraphData[]): Value {
  if (Array.isArray(value)) {
    let result: Value | undefined = undefined;
    for (const v of value) {
      //if (v === null) continue;
      const add = toValue(v);
      if (add === null) continue;
      if (result === undefined) {
        result = add;
      } else {
        result = addValues(result, add);
      }
    }
    return result == undefined ? null : result;
  } else if (value instanceof Instance) {
    return value.valueOf();
  } else if (
    typeof value === "object" &&
    value !== null &&
    !(value instanceof Date)
  ) {
    if (value._value === undefined) {
      return null;
    } else if (value._value instanceof Instance) {
      return value._value.valueOf();
    } else {
      return value._value;
    }
  } else {
    return value;
  }
}

export function toNumber(value: ValueGraphData | ValueGraphData[]): number {
  return Number(toValue(value));
}

export function toBoolean(value: ValueGraphData | ValueGraphData[]): boolean {
  return Boolean(toValue(value));
}

export function toDatetime(value: ValueGraphData | ValueGraphData[]): Date {
  value = toValue(value);
  return value === null || typeof value === "boolean"
    ? new Date(NaN)
    : new Date(value);
}

/** Helper function that returns true if both values are NaN (not a number). */
export function bothAreNaN(value1: unknown, value2: unknown): boolean {
  // TODO: return NaN instead of `Date { NaN }` ?
  return (
    typeof value1 === "number" &&
    typeof value2 === "number" &&
    isNaN(value1) &&
    isNaN(value2)
  );
}

/**
 * Returns the sum of two values.
 * Works like the + operator in JavaScript except of treating a null as
 * an empty string when concatenating into a string.
 * @param value1 First value being a primitive or null.
 * @param value2 Second value being a primitive or null.
 * @returns The sum of the values.
 */
export function addValues(value1: Value, value2: Value): Value {
  if (typeof value1 === "string" || typeof value2 === "string") {
    return (value1 ?? "").toString() + (value2 ?? "").toString();
  } else if (value1 instanceof Date || value2 instanceof Date) {
    return new Date(Number(value1) + Number(value2));
  } else {
    return Number(Decimal.add(Number(value1), Number(value2)));
  }
}

/**
 * Compares two values or instance references for equality or which one is the
 * larger one.
 * @param value1 First value to compare.
 * @param value2 Second value to compare.
 * @returns 0 if both values are equal, -1 if `value1` < `value` 2, +1 if
 *    `value1` > `value2`. If equality cannot be evaluated `undefined` is
 *    returned. If both values are a single instance of the same cellType,
 *    equality is only given when they are identical, otherwise `undefined` is
 *    returned. In any case the values are converted to primitives before
 *    comparing.
 */
export function compareValues(
  value1: ValueGraphData | ValueGraphData[],
  value2: ValueGraphData | ValueGraphData[]
): number | undefined {
  // TODO: simplify these conversions and the following comparison etc.
  value1 = makeArray(value1);
  value2 = makeArray(value2);
  if (
    Array.isArray(value1) &&
    value1.length == 1 &&
    value1[0] != null &&
    value1[0] instanceof Instance &&
    Array.isArray(value2) &&
    value2.length == 1 &&
    value2[0] != null &&
    value2[0] instanceof Instance &&
    value1[0].cellType == value2[0].cellType
  ) {
    // if both parameters are instances of the same cellType they must be
    // identical to return 0 for equality
    return value1[0] === value2[0] ? 0 : undefined;
  } else {
    let p1 = toValue(value1);
    let p2 = toValue(value2);
    if (p1 instanceof Date) p1 = p1.valueOf();
    if (p2 instanceof Date) p2 = p2.valueOf();
    if (p1 === p2) return 0;
    if ((p1 ?? 0) < (p2 ?? 0)) return -1;
    if ((p1 ?? 0) > (p2 ?? 0)) return 1;
    // solve the case that NaN === NaN is never true and return 0
    if (bothAreNaN(p1, p2)) return 0;
    return undefined;
  }
}

// a valid delta is only resulting from an object with a defined prevValue
// in case of an array the delta of each element is summed up
// TODO: check this for references
export function delta(value: ValueGraphData | ValueGraphData[]): number {
  if (!value) return 0;

  if (!Array.isArray(value)) value = [value];

  let result = new Decimal(0);
  for (const item of value) {
    // delta is evaluated only for instances
    if (item instanceof Instance)
      result = Decimal.add(
        result,
        Decimal.sub(toNumber(item.valueOf()), toNumber(item.prevValueOf()))
      );
  }

  return Number(result);
}

// a valid changed is only resulting from an object with a defined prevValue
// in case of an array any change of any element is considered
// TODO: check this for references
export function changed(value: ValueGraphData | ValueGraphData[]): boolean {
  if (!value) return false;

  if (!Array.isArray(value)) value = [value];

  for (const item of value) {
    // change is evaluated only for instances
    if (
      item instanceof Instance &&
      item.valueOf() != item.prevValueOf() &&
      !bothAreNaN(item.valueOf(), item.prevValueOf())
    )
      return true;
  }

  return false;
}

/**
 * A shortcut to wrap a `value` into an array if `value` is not yet an array.
 * @param value The value to wrap into an array.
 * @returns an array.
 */
export function makeArray(
  value: ValueGraphData | ValueGraphData[]
): ValueGraphData[] {
  return Array.isArray(value) ? value : [value];
}

/**
 * Tests if `value` is `null`, an empty array, an empty object, or an array of
 * empty elements.
 * @param value The value to check.
 * @returns `true` if the value is empty.
 */
export function isEmpty(value: ValueGraphData | ValueGraphData[]): boolean {
  if (value instanceof Instance) value = value.getValueGraph();

  if (Array.isArray(value)) {
    for (const item of value) {
      if (!isEmpty(item)) return false;
    }
    return true;
  }

  if (
    value === null ||
    (typeof value === "object" &&
      !(value instanceof Date) &&
      (Object.entries(value).length === 0 ||
        (Object.entries(value).length === 1 && value._value === null)))
  )
    return true;

  return false;
}

export function isExpression(value: unknown): boolean {
  if (typeof value !== "string") return false;
  // if value is a string and is preceeded with an "=", it is an
  // expression
  return RegExp(/^\s*=\s*/).test(value);
}

export function uid(length = 20): string {
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const generator = () =>
    typeof crypto !== "undefined" &&
    typeof crypto.getRandomValues === "function"
      ? () =>
          chars[
            Math.floor(
              (crypto.getRandomValues(new Uint8Array(1))[0] / 256) * 62
            )
          ]
      : () => chars[Math.floor(Math.random() * 62)];

  const uid = (length: number) => Array.from({ length }, generator()).join("");
  return uid(length);
}
