import { makeArray } from "../behavior/behavior-commons";
import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

// TODO: implment the capture attribute (when needed)

enum Status {
  /** set while the file is loading */
  PENDING = "pending",
  /** the file data is invalid (file failed to load successfully) */
  INVALID = "invalid",
  /** the file data is valid (has been successfully loaded) */
  VALID = "valid",
  /** the file data is stale (has not yet been loaded) */
  STALE = "stale", // will be used for deferred loading once it's implemented
}

/**
 * Encapsulates the files selected by the file input control and loads their
 * content automatically
 */
// TODO: add a property for deferred loading of content
// TODO: make content depending on MIME type
class FileContainer {
  /** The original file object being provided by the file input */
  private _file: File;
  /** the callback function to be called when state has changed */
  private _callback?: () => void;
  /** The status of the particular file */
  private _status: Status = Status.STALE;
  /** The progress of the current read operation from 0 to 1 */
  private _progress: number | null = null;
  /** A description of the error if status is INVALID */
  private _error: string | null = null;
  /** A code identifying the error if status is INVALID */
  private _errorCode: string | null = null;
  /** The data when it was read from the file */
  private _data: string | null = null;

  private _reader: FileReader = new FileReader();

  constructor(file: File, callback?: () => void) {
    this._file = file;
    this._callback = callback;

    this._reader.addEventListener("progress", (ev) => {
      this._status = Status.PENDING;
      this._progress = ev.lengthComputable
        ? ev.total // avoiding div by zero
          ? ev.loaded / ev.total
          : 1
        : null;
      this._error = null;
      this._errorCode = null;
      this._data = null;
      if (this._callback) this._callback();
    });

    this._reader.addEventListener("load", () => {
      this._status = Status.VALID;
      this._progress = 1;
      this._error = null;
      this._errorCode = null;
      this._data = this._reader.result as string;
      if (this._callback) this._callback();
    });

    this._reader.addEventListener("error", () => {
      this._status = Status.INVALID;
      this._progress = null;
      this._error = this._reader.error?.message ?? "Unknown error";
      this._errorCode = this._reader.error?.name ?? "UnknownError";
      this._data = null;
      if (this._callback) this._callback();
    });

    this._status = Status.PENDING;
    this._reader.readAsText(file);
  }

  get name(): string {
    return this._file.name;
  }
  get size(): number {
    return this._file.size;
  }
  get type(): string {
    return this._file.type;
  }
  get lastModified(): Date {
    return new Date(this._file.lastModified);
  }
  get status(): string {
    return this._status;
  }
  get progress(): number | null {
    return this._progress;
  }
  get error(): string | null {
    return this._error;
  }
  get errorCode(): string | null {
    return this._errorCode;
  }
  get data(): string | null {
    return this._data;
  }
}

/**
 * Represents a file input element in the DOM tree including its options.
 */
export default class UIFileInputComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLInputElement;

  /** the files and their metadata and content the user has selected */
  private _files: FileContainer[] = [];

  /** stores the latest state of the `changed` edge output */
  private _changed = false;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("input");
    this._DOMNode.type = "file";
    this._DOMNode.addEventListener("change", () => this.onChange());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.disabled = { _input: null };
    schema.required = { _input: null };

    // schema.capture = { _input: null };
    schema.multiple = { _input: null };
    schema.accept = {
      _input: null,
      _iscollection: true,
    };
    schema.reset = { _input: null };

    schema.files = {
      _iscollection: true,
      _output: null,
      name: { _output: null },
      size: { _output: null },
      type: { _output: null },
      lastModified: { _output: null },
      status: { _output: null },
      progress: { _output: null },
      error: { _output: null },
      errorCode: { _output: null },
      data: { _output: null },
    };
    schema.changed = { _output: null };

    return schema;
  }

  get DOMNode(): HTMLInputElement {
    return this._DOMNode;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.files = [];
    if (!this._DOMNode.files) return result;

    for (const file of this._files) {
      result.files.push({
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        status: file.status,
        progress: file.progress,
        error: file.error,
        errorCode: file.errorCode,
        data: file.data,
      });
    }
    result.changed = this._changed;

    return result;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      // TODO: move disabled and required to an InputElementComponent?
      case "disabled":
        this._DOMNode.disabled = Boolean(value);
        break;
      case "required":
        this._DOMNode.required = Boolean(value);
        break;
      case "multiple":
        this._DOMNode.multiple = Boolean(value);
        break;
      case "accept":
        this.setOrRemoveAttribute(
          "accept",
          makeArray(value)
            .map((item) => item?.toString())
            .join(",")
        );
        break;
      case "reset":
        if (value) this._DOMNode.value = "";
        break;
    }
  }

  private onChange() {
    // TODO: we should avoid loading files that have been loaded before
    this._files = [];
    if (this._DOMNode.files) {
      for (let i = 0; i < this._DOMNode.files.length; i++) {
        if (!this._DOMNode.files[i]) continue;

        this._files.push(
          new FileContainer(this._DOMNode.files[i], () => this.pushOutputs())
        );
      }
    }
    this._changed = true;
    this.pushOutputs();
    queueFrame(() => {
      this._changed = false;
      this.pushOutputs();
    });
  }
}
