import * as qbo from "../api/quickbooks";
import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

enum Status {
  PENDING = "pending",
  FAILED = "failed",
  VALID = "valid",
  STALE = "stale",
}

enum ErrorCode {}

/**
 * Represents a QuickBooks connection error.
 */
export class QuickBooksConnectionError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Abstract base class of the QuickBooks connection component that
 * allows caller to check, establish or remove a connection to
 * QuickBooks Online.
 */
export abstract class QuickBooksConnectionBase implements Component {
  private _invokedBy: ComponentInvoker;
  private _queue: ValueGraph[] = [];

  /** ID of the relevant Hyperseed organization */
  private _orgId?: string;
  /** Connection operation to execute */
  private _op?: qbo.ConnectionOp;

  /** The status after a connection operation was triggered */
  private _status: Status | null = null;
  /** A description of the error if status is FAILED */
  private _error: string | null = null;
  /** A code identifying the error if status is FAILED */
  private _errorCode: ErrorCode | string | null = null;
  /** Realm ID identifying QuickBooks data owner */
  private _realmId?: string;
  /**
   * Pulses true for a single frame after the connection operation
   * completes (whether it succeeded or failed).
   */
  private _finished = false;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // Inputs
      orgId: { _input: null },
      op: { _input: null },
      // Outputs
      finished: { _output: null },
      status: { _output: null },
      error: { _output: null },
      errorCode: { _output: null },
      realmId: { _output: null },
    };
  }

  destroy(): void {
    // nothing to destroy
  }

  private reset() {
    this._finished = false;
    this.pushOutputs();
  }

  setInputValueGraph(graph: ValueGraph): void {
    // First add the input graph to the input queue.
    this._queue.push(graph);
    // If this is the first and only item in the queue, start the
    // processing loop (as otherwise it is already started).
    if (this._queue.length === 1) this.processQueue();
  }

  private processQueue() {
    // Pause queue processing while request is pending.
    if (this._status === Status.PENDING) {
      queueFrame(() => this.processQueue());
      return;
    }
    // Retrieve the first item from the input queue and process it.
    const graph = this._queue.shift();
    if (graph) this.processInput(graph);
    // As long as items are left on the input queue, continue
    // processing the queue.
    if (this._queue.length > 0) queueFrame(() => this.processQueue());
  }

  private processInput(graph: ValueGraph): void {
    if (this._status === Status.PENDING) {
      return;
    }
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "orgid":
          this._orgId = value?.toString();
          break;
        case "op":
          this._op = value?.toString() as qbo.ConnectionOp;
          break;
      }
    });
    if (
      this._op === "check" ||
      this._op === "connect" ||
      this._op === "disconnect"
    ) {
      queueFrame(() => this.callExecute());
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      finished: this._finished,
      status: this._status,
      error: this._error,
      errorCode: this._errorCode,
      realmId: this._realmId,
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (!this._orgId) {
        this._error = null;
        this._errorCode = null;
        this._status = Status.STALE;
        this.pushOutputs();
        return false;
      }
      if (
        this._op !== "check" &&
        this._op !== "connect" &&
        this._op !== "disconnect"
      ) {
        this._error = null;
        this._errorCode = null;
        this._status = Status.STALE;
        this.pushOutputs();
        return false;
      }
    } catch (err) {
      this._error = (err as QuickBooksConnectionError).message;
      this._errorCode = (err as QuickBooksConnectionError).code;
      this._status = Status.FAILED;
      this.pushOutputs();
      return false;
    }
    return true;
  }

  /**
   * Calls the asynchronuous execute method and sets the output
   * properties of the component accordingly.
   */
  private callExecute() {
    if (!this.validateInputs()) return;
    this._status = Status.PENDING;
    this._error = null;
    this.pushOutputs();
    const orgId = this._orgId ?? "unknown";
    const op = this._op ?? "unknown";
    this.execute({ orgId, op })
      .then((response) => {
        this._error = null;
        this._errorCode = null;
        this._status = Status.VALID;
        this._finished = true;
        this._realmId = response.realmId;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((err) => {
        if (err instanceof QuickBooksConnectionError) {
          this._error = err.message;
          this._errorCode = err.code;
        } else {
          this._error = String(err);
          this._errorCode = (err as { code: string }).code ?? "unknown";
        }
        this._status = Status.FAILED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  /**
   * Executes the QuickBooks connection request. Implemented by the
   * platform-specific concrete class.
   *
   * @param request the QuickBooks connection request
   * @return the QuickBooks connection response
   */
  protected abstract execute(
    request: qbo.ConnectionRequest
  ): Promise<qbo.ConnectionResponse>;

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
