import { backOff } from "exponential-backoff";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import { AppContext } from "@shared/src/classes/app-context";
import queueFrame from "@shared/src/classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Org } from "@shared/src/model";
import * as rpc from "@shared/src/rpc";
import { Schema } from "@shared/src/schema/schema-global";

import { getDefaultBackoffOptions } from "../callable";

enum Status {
  PENDING = "pending",
  FAILED = "failed",
  SUCCEEDED = "succeeded",
  STALE = "stale",
}

interface MakeApiError {
  code: rpc.MakeApiErrorCode;
}

/**
 * Calls the api-makeApiKey Firebase Function to create an API key
 * for a Hyperseed org.
 */
export default class MakeApiKeyComponent implements InitializableComponent {
  private _invokedBy: ComponentInvoker;

  /** The org ID of the API key owner */
  private _orgId: string | null = null;
  /** Flag for caller to request API key creation */
  private _make = false;
  /** Flag to check API creation request state transition */
  private _prevMake = false;
  /** Flag for caller to request API key deletion */
  private _delete = false;
  /** Flag to check API deletion request state transition */
  private _prevDelete = false;

  /** The API key */
  private _apiKey: string | null = null;

  /** The status of the API key creation */
  private _status: Status | null = null;
  /** The error code if an error occured during API key creation */
  private _errorCode: rpc.MakeApiErrorCode | null = null;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // Inputs
      make: { _input: false },
      delete: { _input: false },
      // Outputs
      apiKey: { _output: null },
      status: { _output: null },
      errorCode: { _output: null },
    };
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        if (context.orgId ?? null !== this._orgId) {
          this._apiKey = null;
          this._orgId = context.orgId ?? null;
          queueFrame(() => this.callGetApiKey());
        }
      },
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "make":
          this._make = Boolean(value);
          break;
        case "delete":
          this._delete = Boolean(value);
          break;
      }
    });
    if (this._make !== this._prevMake) {
      this._prevMake = this._make;
      if (this._make) queueFrame(() => this.callMakeApiKey());
    } else if (this._delete !== this._prevDelete) {
      this._prevDelete = this._delete;
      if (this._delete) queueFrame(() => this.callDeleteApiKey());
    } else if (this._apiKey === null) {
      queueFrame(() => this.callGetApiKey());
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      apiKey: this._apiKey,
      status: this._status,
      errorCode: this._errorCode,
    };
  }

  /**
   * Calls the asynchronuous `getApiKey` method and sets the output
   * properties of the component accordingly.
   */
  private callGetApiKey() {
    if (this._orgId === null) {
      this._errorCode = null;
      this._status = Status.STALE;
      this.pushOutputs();
      return;
    }
    this._status = Status.PENDING;
    this._errorCode = null;
    this.pushOutputs();
    this.getApiKey(this._orgId?.toString() ?? "")
      .then((apiKey) => {
        this._apiKey = apiKey;
        this._errorCode = null;
        this._status = Status.SUCCEEDED;
        this.pushOutputs();
      })
      .catch((err) => {
        this._apiKey = null;
        this._status = Status.FAILED;
        this._errorCode =
          (err as MakeApiError).code ?? rpc.MakeApiErrorCode.UNKNOWN;
        this.pushOutputs();
      });
  }

  /**
   * Reads org's API key from Firestore.
   *
   * @param orgId org ID of the API key owner
   * @returns the API key or null if does not exist
   * @throws Error if reading API key failed
   */
  private async getApiKey(orgId: string): Promise<string | null> {
    try {
      const ref = firebase.firestore().doc(`orgs/${orgId}`);
      const snap = await ref.get();
      const doc = snap.data() as Org;
      return doc.apiKey ?? null;
    } catch (err) {
      console.error("getApiKey failed:", err);
      throw err;
    }
  }

  /**
   * Calls the asynchronuous `makeApiKey` method and sets the output
   * properties of the component accordingly.
   */
  private callMakeApiKey() {
    if (this._orgId === null) {
      this._errorCode = null;
      this._status = Status.STALE;
      this.pushOutputs();
      return;
    }
    this._status = Status.PENDING;
    this._errorCode = null;
    this.pushOutputs();
    this.makeApiKey(this._orgId?.toString() ?? "")
      .then((apiKey) => {
        if (!apiKey) {
          this._apiKey = null;
          this._status = Status.FAILED;
          this._errorCode = rpc.MakeApiErrorCode.UNKNOWN;
          this.pushOutputs();
          return;
        }
        this._apiKey = apiKey;
        this._errorCode = null;
        this._status = Status.SUCCEEDED;
        this.pushOutputs();
      })
      .catch((err) => {
        this._apiKey = null;
        this._status = Status.FAILED;
        this._errorCode =
          (err as MakeApiError).code ?? rpc.MakeApiErrorCode.UNKNOWN;
        this.pushOutputs();
      });
  }

  /**
   * Creates a new API key and writes it to the org documemt.
   *
   * @param orgId org ID of the API key owner
   * @returns the new API key or null
   * @throws Error if creating API key failed
   */
  private async makeApiKey(orgId: string): Promise<string | null> {
    const makeApiKeyFunc = firebase.functions().httpsCallable("api-makeApiKey");
    const request: rpc.MakeApiKeyRequest = { orgId };
    const backoffOptions = getDefaultBackoffOptions();
    try {
      const result = await backOff(
        () => makeApiKeyFunc(request),
        backoffOptions
      );
      const response = result.data as rpc.MakeApiKeyResponse;
      if (response.errorCode) throw { code: response.errorCode };
      if (response.apiKey) return response.apiKey;
      console.warn("Neither apiKey nor errorCode is set");
      return null;
    } catch (err) {
      console.error("api-makeApiKey failed:", err);
      throw err;
    }
  }

  /**
   * Calls the asynchronuous `deleteApiKey` method and sets the output
   * properties of the component accordingly.
   */
  private callDeleteApiKey() {
    if (this._orgId === null) {
      this._errorCode = null;
      this._status = Status.STALE;
      this.pushOutputs();
      return;
    }
    this._status = Status.PENDING;
    this._errorCode = null;
    this.pushOutputs();
    this.deleteApiKey(this._orgId?.toString() ?? "")
      .then(() => {
        this._apiKey = null;
        this._errorCode = null;
        this._status = Status.SUCCEEDED;
        this.pushOutputs();
      })
      .catch((err) => {
        this._status = Status.FAILED;
        this._errorCode =
          (err as MakeApiError).code ?? rpc.MakeApiErrorCode.UNKNOWN;
        this.pushOutputs();
      });
  }

  /**
   * Deletes the org's API key.
   *
   * @param orgId org ID of the API key owner
   * @throws Error if deleting API key failed
   */
  private async deleteApiKey(orgId: string): Promise<void> {
    const makeApiKeyFunc = firebase
      .functions()
      .httpsCallable("api-deleteApiKey");
    const request: rpc.MakeApiKeyRequest = { orgId };
    try {
      const result = await makeApiKeyFunc(request);
      const response = result.data as rpc.MakeApiKeyResponse;
      if (response.errorCode) throw { code: response.errorCode };
      return;
    } catch (err) {
      console.error("api-makeApiKey failed:", err);
      throw err;
    }
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
