import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { toNumber, makeArray } from "./behavior-commons";

export function createFinancialFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "pv":
      return new pvFunctionBehaviorNode(token, mode);
    case "fv":
      return new fvFunctionBehaviorNode(token, mode);
    case "npv":
      return new npvFunctionBehaviorNode(token, mode);
    case "irr":
      return new irrFunctionBehaviorNode(token, mode);
  }
}

/**
 * Calculates the present value of an annuity investment based on constant-amount
 * periodic payments and a constant interest rate.
 * @name pv() function
 * @param rate The interest rate.
 * @param number_of_periods The amount per period to be paid.
 * @param payment_amount The amount per period to be paid.
 * @returns The present value of an annuity investment.
 */
class pvFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    const rate = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const periods = toNumber(this.arguments[1].evaluate(scope, calledFrom));
    const payment = toNumber(this.arguments[2].evaluate(scope, calledFrom));

    return -(payment * (1 - Math.pow(1 + rate, -periods))) / rate;
  }
}

/**
 * Calculates the future value of an annuity investment based on constant-amount
 * periodic payments and a constant interest rate.
 * @name fv() function
 * @param rate The interest rate.
 * @param number_of_periods The amount per period to be paid.
 * @param payment_amount The amount per period to be paid.
 * @returns The future value of an annuity investment.
 */
class fvFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    const rate = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const periods = toNumber(this.arguments[1].evaluate(scope, calledFrom));
    const payment = toNumber(this.arguments[2].evaluate(scope, calledFrom));

    return -(payment * (Math.pow(1 + rate, periods) - 1)) / rate;
  }
}

/**
 * Calculates the net present value of an investment based on a series of
 * periodic cash flows and a discount rate.
 * @name npv() function
 * @param discount The discount rate of the investment over one period.
 * @param cashflow1 The first future cash flow.
 * @param cashflow2, ... (optional) Additional future cash flows.
 * @returns The net present value of the investment.
 */
class npvFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    const discount = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    let npv = 0;
    let period = 1;

    for (let i = 1; i < this.arguments.length; i++) {
      const cashflows = makeArray(
        this.arguments[i].evaluate(scope, calledFrom)
      )?.map((value) => toNumber(value));

      for (let j = 0; j < cashflows.length; j++) {
        npv += cashflows[j] / Math.pow(1 + discount, period);
        period += 1;
      }
    }

    return npv;
  }
}

/**
 * Calculates the internal rate of return on an investment based on a series of
 * periodic cash flows.
 * @name irr() function
 * @param cashflow_amounts An array or range containing the income or payments associated with the investment.
 * @param rate_guess (optional) An estimate for what the internal rate of return will be (default 0.1).
 * @returns The internal rate of return on an investment.
 */
class irrFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    const cashflows = makeArray(
      this.arguments[0].evaluate(scope, calledFrom)
    )?.map((value) => toNumber(value));
    let pos = false;
    let neg = false;
    for (const cf of cashflows) {
      if (cf > 0) pos = true;
      if (cf < 0) neg = true;
    }
    if (!pos || !neg) return NaN;
    // TODO: return the reason for NaN (use exception?)

    function calcNpv(discount: number) {
      return cashflows.reduce((npv, value, index) => {
        return npv + value / Math.pow(1 + discount, index);
      });
    }

    // TODO: find a better default guess (there are formulas for this)
    let rate =
      this.arguments.length > 1
        ? toNumber(this.arguments[1].evaluate(scope, calledFrom))
        : 0.1;

    let npv = calcNpv(rate);
    let dRate = npv > 0 ? 0.1 : -0.1;

    let tries = 0;

    while (Math.abs(npv) > 0.01) {
      tries++;
      if (tries > 1000) {
        return NaN;
        // TODO: return the reason for NaN (use exception?)
      }

      if (Math.sign(dRate) !== Math.sign(npv)) {
        dRate = -dRate / 2;
      }
      rate += dRate;
      npv = calcNpv(rate);
    }

    return rate;
  }
}
