import firebase from "firebase/app";
import "firebase/firestore";

import {
  Document,
  FirestoreWriteBase,
} from "@shared/src/components/firestore-write-base";
import { stripValueProps } from "@shared/src/helpers/helpers";
import { ComponentInvoker } from "@shared/src/interfaces/global-interfaces";

export default class FirestoreWriteComponent extends FirestoreWriteBase {
  private _db: firebase.firestore.Firestore;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);
    this._db = firebase.firestore();
  }

  protected addMetadata(data: Document): Document {
    const meta = {
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: this._userId ?? "unknown",
    };
    Object.assign(data, meta);
    return data;
  }

  protected async write(
    orgId: string,
    appName: string,
    collection: string,
    data: unknown
  ): Promise<void> {
    // resetting the outputs before the first write operation to have the
    // correct values being set in case an exception is thrown
    this._writtenIds = [];
    this._docsWritten = 0;
    try {
      const collectionPath = `orgs/${orgId}/apps/${appName}/${collection}`;
      const collectionRef = this._db.collection(collectionPath);
      stripValueProps(data);
      if (Array.isArray(data)) {
        let batch: firebase.firestore.WriteBatch | null = null;
        let writtenIds = [];
        let docsWritten = 0;
        let count = 0;
        for (let doc of data as Document[]) {
          if (batch === null) batch = this._db.batch();
          const docRef = doc.id
            ? collectionRef.doc(doc.id)
            : collectionRef.doc();
          delete doc.id;
          doc = this.addMetadata(doc);
          batch.set(docRef, doc as firebase.firestore.DocumentData);
          writtenIds.push(docRef.id);
          docsWritten++;
          count++;
          // Firestore has a batch size limit of 500.
          // https://firebase.google.com/docs/firestore/quotas#writes_and_transactions
          if (count === 500) {
            await batch.commit();
            batch = null;
            this._writtenIds.push(...writtenIds);
            this._docsWritten += docsWritten;
            writtenIds = [];
            docsWritten = 0;
            count = 0;
            this.pushOutputs();
          }
        }
        await batch?.commit();
        this._writtenIds.push(...writtenIds);
        this._docsWritten += docsWritten;
      } else {
        const id = (data as Document).id;
        const docRef = id ? collectionRef.doc(id) : collectionRef.doc();
        delete (data as Document).id;
        data = this.addMetadata(data as Document);
        await docRef.set(data as firebase.firestore.DocumentData);
        this._writtenIds = [docRef.id];
        this._docsWritten = 1;
      }
    } catch (err) {
      console.error("write failed:", err);
      throw err;
    }
  }
}
