import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  DOMComponent,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

/**
 * The UIElement component is the abstract base class for all DOM element
 * components (as of now HTML and SVG).
 */
export default abstract class UIElementComponent implements DOMComponent {
  protected _invokedBy: ComponentInvoker;
  protected _resizeObserver?: ResizeObserver;

  private _focus = false;
  private _blur = false;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;

    // as the entries parameter of the callback function is not yet supported on
    // all browsers, we use the observer just to know when a resize of the
    // element has happened

    // TODO: check for browser support
    // TODO: make observer just if the invoker receives the size outputs
    if (window.ResizeObserver) {
      this._resizeObserver = new ResizeObserver(() => {
        this.onResize();
      });
    }
  }

  static getSchema(): Schema {
    return {
      id: { _input: null },
      style: { _input: null },
      tabindex: { _input: null },
      innerHtml: { _input: null },
      setScrollLeft: { _input: null },
      setScrollTop: { _input: null },
      width: { _output: {} },
      height: { _output: {} },
      scrollLeft: { _output: {} },
      scrollTop: { _output: {} },
      focus: { _output: {} },
      blur: { _output: {} },
    };
  }

  abstract get DOMNode(): HTMLElement | SVGElement;

  destroy(): void {
    this.detachDOM();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      if (value !== undefined) this.setDOMElementProperty(key, value);
    });
  }

  getOutputValueGraph(): ValueGraph {
    const clientRent = this.DOMNode.getBoundingClientRect();
    return {
      width: clientRent.width,
      height: clientRent.height,
      scrollLeft: this.DOMNode.scrollLeft,
      scrollTop: this.DOMNode.scrollTop,
      focus: this._focus,
      blur: this._blur,
    };
  }

  attachDOM(parentDOMNode: Element): void {
    // already attached to the same parent -> do nothing
    if (this.DOMNode.parentNode === parentDOMNode) return;

    // already attached to a different parent: detach first before they get
    // re-attached
    if (this.DOMNode.parentNode) this.detachDOM();

    parentDOMNode.appendChild(this.DOMNode);
    this._resizeObserver?.observe(this.DOMNode);
    this.DOMNode.addEventListener("focus", this.onFocus);
    this.DOMNode.addEventListener("blur", this.onBlur);
    this.DOMNode.addEventListener("scroll", this.onScroll);
  }

  detachDOM(): void {
    this.DOMNode.removeEventListener("focus", this.onFocus);
    this.DOMNode.removeEventListener("blur", this.onBlur);
    this.DOMNode.removeEventListener("scroll", this.onScroll);
    this._resizeObserver?.unobserve(this.DOMNode);
    this.DOMNode.parentNode?.removeChild(this.DOMNode);
  }

  /**
   * Sets the DOM property according to key/value pairs found in the input value
   * graph. Can be overridden in child classes to add additional properties. In
   * this case `super.setDOMElementProperty` should be called.
   * @param key The key in the input value graph.
   * @param value The corresponding input value.
   */
  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    switch (key.toLowerCase()) {
      case "id":
        if (value !== null) {
          this.DOMNode.id = value.toString();
        } else {
          this.DOMNode.removeAttribute("id");
        }
        break;
      case "style":
        if (value !== null) {
          this.DOMNode.style.cssText = value.toString();
        } else {
          this.DOMNode.removeAttribute("style");
        }
        break;
      case "tabindex":
        this.setOrRemoveAttribute("tabindex", value);
        break;
      case "innerhtml":
        // setting innerHtml to an empty string removes all subnodes while
        // setting it to null keeps all subnodes.
        if (value !== null) this.DOMNode.innerHTML = value.toString();
        break;
      case "setscrollleft":
        if (value !== null) {
          this.DOMNode.scrollLeft = Number(value);
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setscrolltop":
        if (value !== null) {
          this.DOMNode.scrollTop = Number(value);
          queueFrame(() => this.pushOutputs());
        }
        break;
    }
  }

  /**
   * Sets or resets an attribute on the DOM element.
   * @param attribute The name of the DOM attribute to set.
   * @param value The value the DOM attribute will be set to. If null, the
   *    attribute will be removed.
   */
  protected setOrRemoveAttribute(
    attribute: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    if (value !== null) {
      this.DOMNode.setAttribute(attribute, value.toString());
    } else {
      this.DOMNode.removeAttribute(attribute);
    }
  }

  /**
   * Propagates the size of this element to the invoking component in logical
   * pixels as a reaction to a resize event. As the change of size is in most
   * cases a result of an input being set, setting the outputs on the invoker
   * is deferred.
   */
  protected onResize(): void {
    queueFrame(() => this.pushOutputs());
  }

  /**
   * Event handler for the focus event setting the focus output to true for a
   * single frame
   */
  private onFocus = (): void => {
    this._focus = true;
    this.pushOutputs();

    queueFrame(() => {
      this._focus = false;
      this.pushOutputs();
    });
  };

  /**
   * Event handler for the blur event setting the blur output to true for a
   * single frame
   */
  private onBlur = (): void => {
    this._blur = true;
    this.pushOutputs();

    queueFrame(() => {
      this._blur = false;
      this.pushOutputs();
    });
  };

  private onScroll = (): void => {
    this.pushOutputs();
  };

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  // TODO: read inputs and outputs by default by investigating the schema
  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set([
  "id",
  "style",
  "tabindex",
  "innerhtml",
  "setscrollleft",
  "setscrolltop",
]);
/** Names of component outputs. */
const outputs = new Set([
  "width",
  "height",
  "scrollleft",
  "scrolltop",
  "focus",
  "blur",
]);
