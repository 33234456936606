import jsep from "jsep";

import Cell from "../classes/cell";
import {
  CellNameWalker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { isEmpty, toBoolean, toValue } from "./behavior-commons";

export function createLogicFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "if":
      return new ifFunctionBehaviorNode(token, mode);
    case "and":
      return new andFunctionBehaviorNode(token, mode);
    case "or":
      return new orFunctionBehaviorNode(token, mode);
    case "xor":
      return new xOrFunctionBehaviorNode(token, mode);
    case "not":
      return new notFunctionBehaviorNode(token, mode);
    case "coalesce":
      return new coalesceFunctionBehaviorNode(token, mode);
  }
}

/**
 * Returns a value from two depending on whether a condition is true or false.
 * @name if() function
 * @param condition The condition to be tested.
 * @param consequent The value returned if condition is true.
 * @param alternate The value returned if condition is false.
 * @returns `consequent` if `condition` is true, otherwise `alternate`.
 */
class ifFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 3);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] {
    return toBoolean(this.arguments[0].evaluate(scope, calledFrom))
      ? this.arguments[1].evaluate(scope, calledFrom)
      : this.arguments[2].evaluate(scope, calledFrom);
  }
}

/**
 * Applies a logical AND operation between all arguments. If the arguments are
 * not boolean, they are converted to booleans first.
 * @name and() function
 * @param value... The values on which to apply the AND operation.
 * @returns The logical result of the AND operations.
 */
class andFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    let result = true;
    for (const argument of this.arguments) {
      result = result && toBoolean(argument.evaluate(scope, calledFrom));
    }
    return result;
  }
}

/**
 * Applies a logical OR operation between all arguments. If the arguments are
 * not boolean, they are converted to booleans first.
 * @name or() function
 * @param value... The values on which to apply the OR operation.
 * @returns The logical result of the OR operations.
 */
class orFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    let result = false;
    for (const argument of this.arguments) {
      result = result || toBoolean(argument.evaluate(scope, calledFrom));
    }
    return result;
  }
}

/**
 * Applies a logical exclusive OR operation between all arguments. If the
 * arguments are not boolean, they are converted to booleans first.
 *
 * The result of XOR is `true` when the number of `true` inputs is odd and
 * `false` when the number of `false` inputs is even.
 * @name xor() function
 * @param value... The values on which to apply the exclusive OR operation.
 * @returns The logical result of the exclusive OR operations.
 */
class xOrFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    let result = toBoolean(this.arguments[0].evaluate(scope, calledFrom));
    for (let i = 1; i < this.arguments.length; i++) {
      result =
        result !== toBoolean(this.arguments[i].evaluate(scope, calledFrom));
    }
    return result;
  }
}

/**
 * Applies a logical NOT operation on the argument. If the argument is
 * not boolean, it is converted to booleans first.
 * @name not() function
 * @param value The value on which to apply the NOT operation.
 * @returns The logical result of the NOT operation.
 */
class notFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return !toValue(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Evaluates its arguments in order and returns the first value that isn't empty
 * or an empty string.
 * @name coalesce() function
 * @param value... The values to be tested.
 * @returns the first value that isn't empty.
 */
class coalesceFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] {
    for (const argument of this.arguments) {
      const value = argument.evaluate(scope, calledFrom);
      if (!(isEmpty(value) || value?.valueOf() === "")) return value;
    }
    return null;
  }
}
