import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { BehaviorMode, BehaviorNode, createBehaviorNode } from "./behavior";
import { toValue, toNumber } from "./behavior-commons";

/**
 * Factory function to create a unary operator behavior node from a jsep token.
 * @param token The jsep token from which to create the behavior node.
 * @returns The unary operator behavior node.
 */
export function createUnaryBehaviorNode(
  token: jsep.UnaryExpression,
  mode: BehaviorMode
): BehaviorNode {
  switch (token.operator) {
    case "!":
      return new NotUnaryBehaviorNode(token, mode);
    case "-":
      return new MinusUnaryBehaviorNode(token, mode);
    case "+":
      return new PlusUnaryBehaviorNode(token, mode);
    default:
      // throw exception for any other operator currently not implemented
      throw "Unary operator " + token.operator + " not implemented";
  }
}

class NotUnaryBehaviorNode implements BehaviorNode {
  private argument: BehaviorNode;

  constructor(token: jsep.UnaryExpression, mode: BehaviorMode) {
    this.argument = createBehaviorNode(token.argument, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    return !toValue(this.argument.evaluate(scope, calledFrom));
  }
}

class MinusUnaryBehaviorNode implements BehaviorNode {
  private argument: BehaviorNode;

  constructor(token: jsep.UnaryExpression, mode: BehaviorMode) {
    this.argument = createBehaviorNode(token.argument, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return -toNumber(this.argument.evaluate(scope, calledFrom));
  }
}

class PlusUnaryBehaviorNode implements BehaviorNode {
  private argument: BehaviorNode;

  constructor(token: jsep.UnaryExpression, mode: BehaviorMode) {
    this.argument = createBehaviorNode(token.argument, mode);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return +toNumber(this.argument.evaluate(scope, calledFrom));
  }
}
