// Waiter implementation was inspired by this post:
// https://dev.to/noseratio/we-can-make-any-javascript-object-await-able-with-then-method-1apl

type Resolve<T> = (v: T | PromiseLike<T>) => void;
type Reject = (e: unknown) => void;
/**
 * Portable representation of the setInterval return type, which differs
 * between browser and Node.js.
 * @see https://stackoverflow.com/a/56239226
 */
type Timeout = ReturnType<typeof setTimeout>;

/**
 * Represents a deferred (i.e., thenable) object.
 */
interface Deferred<T> extends Promise<T> {
  resolve: Resolve<T>;
  reject: Reject;
}

/** The default waiter timeout in milliseconds. */
const defaultTimeout = 5000;

/**
 * A waiter is a promise-like object that can be made to resolve or
 * reject from completely outside the promise definition.
 */
export class Waiter<T = void> {
  private _deferred?: Deferred<T>;
  private _timeout?: Timeout;
  private _timeoutMs: number;
  private _timeoutCallback?: () => void;
  private _clearTimeout?: () => void;
  private _promiseCalled = false;

  /**
   * Waiter constructor.
   *
   * @param timeout The minimum number of milliseconds the waiter will
   *   wait to be resolved before aborting (default: 5000; 0 means no
   *   timeout).
   */
  constructor(timeout = defaultTimeout) {
    this._timeoutMs = timeout;
  }

  /**
   * Registers a callback invoked if the timeout expires. Note that
   * currently only a single `onTimeout` callback may be defined per
   * waiter. Calling this method more than once will replace the
   * existing callback with the most recent one.
   */
  onTimeout(callback: () => void): void {
    this._timeoutCallback = callback;
  }

  /**
   * Creates and returns a promise that will resolve or reject according
   * to the waiter's handling.
   *
   * @returns A promise that is either fulfilled when `resolve()` is
   *   called or rejected when `reject()` is called or when the timeout
   *   expires.
   * @throws `Error` if this method is called more than once on the same
   *   object.
   */
  promise(): Promise<T> {
    if (this._promiseCalled) throw new Error("promise has already been called");
    this._promiseCalled = true;
    let resolve: Resolve<T> | undefined;
    let reject: Reject | undefined;
    const promise = new Promise<T>((...args) => ([resolve, reject] = args));
    this._deferred = {
      resolve,
      reject,
      then: (...args) => promise.then(...args),
    } as Deferred<T>;
    if (this._timeoutMs === 0) return promise;
    const timer = new Promise<T>((_, reject) => {
      this._timeout = setTimeout(() => {
        this._timeoutCallback?.();
        reject(Error(`timeout of ${this._timeoutMs} ms expired`));
      }, this._timeoutMs);
      this._clearTimeout = () => clearTimeout(this._timeout as Timeout);
    });
    return Promise.race([promise, timer]);
  }

  /**
   * Resolves the waiter promise returned from `promise()`.
   *
   * @param value The resolved value to be passed through to the promise
   *   returned from `promise()`. A value is not passed to this method
   *   when the Waiter was created with generic type `void` (the default
   *   behavior).
   */
  resolve(value: T): void {
    this._clearTimeout?.();
    this._deferred?.resolve(value);
  }

  /**
   * Rejects the waiter promise returned from `promise()`.
   *
   * @param reason The reason for the rejection. Can be a string, an
   *   Error instance, or any type.
   */
  reject(reason?: unknown): void {
    this._clearTimeout?.();
    this._deferred?.reject(reason ?? Error("waiter rejected"));
  }

  /**
   * Cancels exsisting timers and shuts down the waiter. If the waiter
   * has a pending promise, it is rejected.
   */
  cancel() {
    this.reject(Error("waiter canceled"));
  }
}

/**
 * Maintains a pool of waiters.
 */
export class WaiterPool<T = void> {
  private _waiters: Waiter<T>[] = [];

  /**
   * Adds a new waiter to the pool.
   *
   * If the waiter's timeout expires, it will be removed from the pool.
   *
   * @param timeout The minimum number of milliseconds the waiter will
   *   wait to be resolved before aborting (default: 5000; 0 means no
   *   timeout).
   * @returns The newly created waiter.
   */
  addWaiter(timeout = defaultTimeout): Waiter<T> {
    const waiter = new Waiter<T>(timeout);
    if (timeout !== 0) {
      waiter.onTimeout(() => {
        // Remove waiter from the pool on timeout.
        const index = this._waiters.indexOf(waiter);
        if (index > -1) {
          this._waiters.splice(index, 1);
        }
      });
    }
    // Add waiter to the pool.
    this._waiters.push(waiter);
    return waiter;
  }

  /**
   * Resolves all waiters in the pool.
   *
   * @param value The resolved value to be passed through to the promise
   *   returned from `promise()`. A value is not passed to this method
   *   when the Waiter was created with generic type `void` (the default
   *   behavior).
   */
  resolveAll(value: T): void {
    this._waiters.forEach((waiter) => waiter.resolve(value));
  }

  /**
   * Rejects all waiters in the pool.
   *
   * @param reason The reason for the rejection. Can be a string, an
   *   Error instance, or any type.
   */
  rejectAll(reason?: unknown): void {
    this._waiters.forEach((waiter) => waiter.reject(reason));
  }

  /**
   * Discards all waiters in the pool and creates a new, empty pool.
   * Old waiter timers are canceled and any pending promises are
   * rejected.
   */
  reset(): void {
    this._waiters.forEach((waiter) => waiter.cancel());
    this._waiters = [];
  }
}
