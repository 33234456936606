import jsepObject from "@jsep-plugin/object";
import jsep from "jsep";

import Cell from "../classes/cell";
import CellType from "../classes/cell-type";
import Instance from "../classes/instance";
import {
  CellNameWalker,
  InstanceBreadcrumb,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { createArrayFunctionBehaviorNode } from "./array-functions";
import { makeArray, toBoolean } from "./behavior-commons";
import { createBinaryBehaviorNode } from "./binary-operators";
import { createConversionFunctionBehaviorNode } from "./conversion-functions";
import { createDateTimeFunctionBehaviorNode } from "./datetime-functions";
import { createDeltaFunctionBehaviorNode } from "./delta-functions";
import { createFinancialFunctionBehaviorNode } from "./financial-functions";
import { createI18nFunctionBehaviorNode } from "./i18n-functions";
import { createLogicFunctionBehaviorNode } from "./logic-functions";
import { createMathematicalFunctionBehaviorNode } from "./mathematical-functions";
import { createStringFunctionBehaviorNode } from "./string-functions";
import { createTestFunctionBehaviorNode } from "./test-functions";
import { createUnaryBehaviorNode } from "./unary-operators";
import { createVariousFunctionBehaviorNode } from "./various-functions";

// register the object plugin
jsep.plugins.register(jsepObject as unknown as jsep.IPlugin);

// additional operators to be compatible with spreadsheet expressions
jsep.addBinaryOp("=", 6);
jsep.addBinaryOp("<>", 6);

export interface JsepError extends Error {
  description: string;
  index: number;
}

/**
 * Type guard to check whether a value is a jsep error.
 * @param value The value to check.
 * @returns `true` if `value` is of type `JsepError`
 */
export function isJsepError(value: unknown): value is JsepError {
  return value instanceof Error && "description" in value && "index" in value;
}

export enum BehaviorMode {
  /**
   * Resolves all identifiers with always full scope. Which is child before
   * grandchild before parent before sibling before children and grandchildren
   * of sibling before grandparent before childdren of Grandparent (and so on).
   * Iterator functions use iterator scope only.
   */
  LEGACY = "legacy",

  /**
   * Resolves object identifiers as `this` before parent before siblings by
   * walking up towards root. Property identifiers are children only. Iterator
   * functions use closest scope, iterator scope is preferred.
   */
  PARENT_SIBLING_CLOSEST = "parent-sibling-closest",

  /**
   * Resolves object identifiers as parent before siblings before children of
   * this by walking downwards from root. Property identifiers are children
   * only. Iterator functions use closest scope, iterator scope is preferred.
   */
  ROOT_PARENT_SIBLING_CLOSEST = "root-parent-sibling-closest",
}

export interface BehaviorNode {
  /**
   * Evaluates the behavior of this node and returns a value, an object, or an
   * array of a value or object.
   *
   * @param scope The scope from which the node is called, usually a cell or
   *    instance.
   * @param calledFrom The `this` scope from which the original expression is
   *    called that this node belongs to.
   * @param breadcrumb Provide an InstanceBreadcrumb, which in case the behavior
   *    node walks up the cell/instance tree will be filled with a map of
   *    Instances that were traversed. In case the behavior node walks down the
   *    cell/instance tree the breadcrumb is used to follow the path that was
   *    walked up before.
   */
  evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell,
    breadcrumb?: InstanceBreadcrumb
  ): ValueGraphData | ValueGraphData[];
}

/** The type guard to identify whether a value is a behavior node. */
export function isBehaviorNode(value: unknown): value is BehaviorNode {
  return typeof value === "object" && value !== null && "evaluate" in value;
}

/**
 * Builds the behavior tree of an expression.
 * @param behavior A string with an expression describing the behavior. The
 *    `behavior` string must be preceded by the equal `=` sign.
 * @param mode The behavior mode that shall be used when evaluating the behavior
 *    expression.
 * @returns The root node of the AST of the parsed expression.
 * @throws When `behavior` is not preceded by the equal `=` sign.
 */
export function createBehaviorTree(
  behavior: string,
  mode = BehaviorMode.ROOT_PARENT_SIBLING_CLOSEST
): BehaviorNode {
  // if behavior is preceeded with an "=", it is a formula to be evalated
  const isExpression = RegExp(/^\s*=\s*/).exec(behavior.toString());
  if (isExpression) {
    try {
      return createBehaviorNode(
        jsep(behavior.toString().substring(isExpression[0].length)),
        mode
      );
    } catch (err) {
      // adjust position of error being found by removed leading spaces and `=`
      if (isJsepError(err)) err.index += isExpression[0].length;
      throw err;
    }
  } else {
    throw new Error(
      "Argument of createBehaviorTree must be an expression string"
    );
  }
}

/**
 * Creates the behavior node and its child nodes from a jsep node tree that was
 * generated while an expression has been parsed by jsep.
 * @param token A jsep expression node.
 * @param mode The behavior mode that shall be used when evaluating the behavior
 *    expression.
 * @returns A behavior node implementing the jsep behavior token.
 */
export function createBehaviorNode(
  token: jsep.Expression,
  mode: BehaviorMode
): BehaviorNode {
  switch (token.type) {
    case "Literal":
      return new LiteralBehaviorNode(token as jsep.Literal);
    case "Identifier":
      if (mode === BehaviorMode.LEGACY)
        return new LegacyIdentifierBehaviorNode(token as jsep.Identifier);
      else
        switch ((token as jsep.Identifier).name.toLowerCase()) {
          case "root":
            return new RootObjectIdentifierBehaviorNode();
          case "iterator":
            return new IteratorObjectIdentifierBehaviorNode();
          default:
            return new ObjectIdentifierBehaviorNode(
              token as jsep.Identifier,
              mode
            );
        }
    case "MemberExpression":
      return new MemberBehaviorNode(token as jsep.MemberExpression, mode);
    case "ThisExpression":
      return new ThisBehaviorNode();
    case "ConditionalExpression":
      return new ConditionalBehaviorNode(
        token as jsep.ConditionalExpression,
        mode
      );
    case "ArrayExpression":
      return new ArrayBehaviorNode(token as jsep.ArrayExpression, mode);
    case "UnaryExpression":
      return createUnaryBehaviorNode(token as jsep.UnaryExpression, mode);
    case "BinaryExpression":
    case "LogicalExpression":
      return createBinaryBehaviorNode(token as jsep.BinaryExpression, mode);
    case "CallExpression":
      return createFunctionBehaviorNode(token as jsep.CallExpression, mode);
    case "ObjectExpression":
      return new ObjectBehaviorNode(token as jsepObject.ObjectExpression, mode);
    case "Compound":
    default:
      throw new Error(token.type + " tokens are not supported");
  }
}

class LiteralBehaviorNode implements BehaviorNode {
  private _value: Value;

  constructor(token: jsep.Literal) {
    if (token.value instanceof RegExp)
      throw new Error(
        "Regular expressions as literals are currently not supported"
      );

    this._value = token.value;
  }

  public evaluate(): Value {
    return this._value;
  }
}

class LegacyIdentifierBehaviorNode implements BehaviorNode {
  private _name: string;

  constructor(token: jsep.Identifier) {
    this._name = token.name.toLowerCase();
  }

  public evaluate(scope?: CellNameWalker[]): Instance | Instance[] {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync
    if (!scope)
      throw new Error(
        "A cell identifier cannot be used outside of a cell graph"
      );

    const result: Instance[] = [];
    for (const item of scope) {
      result.push(...item.getInstancesByCellName(this._name));
    }
    // TODO: we should choose whther to return a value or an array depending on
    // the relationship between scope and identifier and not if we have just a
    // single item or not
    return result.length === 1 ? result[0] : result;
  }
}

class ObjectIdentifierBehaviorNode implements BehaviorNode {
  private _name: string;
  private _fromRoot: boolean;

  constructor(token: jsep.Identifier, mode: BehaviorMode) {
    this._name = token.name.toLowerCase();
    this._fromRoot = mode === BehaviorMode.ROOT_PARENT_SIBLING_CLOSEST;
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell,
    breadcrumb?: InstanceBreadcrumb
  ): Instance | Instance[] {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync

    if (!scope)
      throw new Error(
        "A cell identifier cannot be used outside of a cell graph"
      );

    const result: Instance[] = [];
    for (const item of scope) {
      if (
        (item.cellType.hasParentOrSiblingOrIsThis(this._name) ||
          // includes children when item is an instance (required for iterator
          // functions)
          (item instanceof Instance &&
            item.cellType.hasAttribute(this._name))) &&
        !calledFrom?.cellType.hasParent(this._name)
      ) {
        result.push(
          ...item.getParentOrSiblingOrThisInstancesByCellName(
            this._name,
            this._fromRoot,
            breadcrumb
          )
        );
      } else if (calledFrom) {
        result.push(
          ...calledFrom.getParentOrSiblingOrThisInstancesByCellName(
            this._name,
            this._fromRoot,
            breadcrumb
          )
        );
      } else throw new Error(`Unknown name '${this._name}'`);
    }
    // TODO: we should choose whether to return a value or an array depending on
    // the relationship between scope and identifier and not if we have just a
    // single item or not
    return result.length === 1 ? result[0] : result;
  }
}

class RootObjectIdentifierBehaviorNode implements BehaviorNode {
  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): Instance | null {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync

    if (!calledFrom)
      throw new Error(
        "The root identifier cannot be used outside of a cell graph"
      );

    return calledFrom.root.rootInstance;
  }
}

class IteratorObjectIdentifierBehaviorNode implements BehaviorNode {
  public evaluate(scope?: CellNameWalker[]): Instance {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync

    if (!scope || scope.length !== 1 || !(scope[0] instanceof Instance))
      throw new Error(
        "The iterator identifier cannot be used outside of an array function"
      );

    return scope[0];
  }
}

class PropertyOfThisIdentifierBehaviorNode implements BehaviorNode {
  private _name: string;
  private _fromRoot: boolean;

  constructor(token: jsep.Identifier, mode: BehaviorMode) {
    this._name = token.name.toLowerCase();
    this._fromRoot = mode === BehaviorMode.ROOT_PARENT_SIBLING_CLOSEST;
  }

  public evaluate(scope?: CellNameWalker[]): Instance | Instance[] {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync

    if (!scope)
      throw new Error("`this` cannot be used outside of a cell graph");

    const result: Instance[] = [];
    for (const item of scope) {
      if (item.cellType.hasParentOrSiblingOrIsThis(this._name)) {
        result.push(
          ...item.getParentOrSiblingOrThisInstancesByCellName(
            this._name,
            this._fromRoot
          )
        );
      } else if (item.cellType.hasAttribute(this._name)) {
        result.push(...item.getChildInstancesByCellName(this._name));
      } else throw new Error(`Unknown name '${this._name}'`);
    }

    // TODO: we should choose whether to return a value or an array depending on
    // the relationship between scope and identifier and not if we have just a
    // single item or not
    return result.length === 1 ? result[0] : result;
  }
}

class PropertyIdentifierBehaviorNode implements BehaviorNode {
  private _name: string;

  constructor(token: jsep.Identifier) {
    this._name = token.name.toLowerCase();
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell,
    breadcrumb?: InstanceBreadcrumb
  ): Instance | Instance[] {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync
    if (!scope)
      throw new Error(
        "A cell identifier cannot be used outside of a cell graph"
      );

    const result: Instance[] = [];
    for (const item of scope) {
      result.push(...item.getChildInstancesByCellName(this._name, breadcrumb));
    }
    // TODO: we should choose whether to return a value or an array depending on
    // the relationship between scope and identifier and not if we have just a
    // single item or not
    return result.length === 1 ? result[0] : result;
  }
}

class MemberBehaviorNode implements BehaviorNode {
  private _object: BehaviorNode;
  private _property: BehaviorNode;

  constructor(token: jsep.MemberExpression, mode: BehaviorMode) {
    if (mode === BehaviorMode.LEGACY) {
      // object and property identifier in legacy mode uses default node creation
      this._object = createBehaviorNode(token.object, mode);
      this._property = createBehaviorNode(token.property, mode);
    } else {
      // object identifier is created by default node creation
      this._object = createBehaviorNode(token.object, mode);

      // and a property identifier node is created for property nodes
      if (token.property.type === "Identifier") {
        // identifier tokens are treated particularly
        if (
          this._object instanceof ThisBehaviorNode ||
          this._object instanceof IteratorObjectIdentifierBehaviorNode
        ) {
          // after `this` and `iterator` a special property node is created
          // which can access parents, siblings, and direct children and which
          // ignores the breadcrumb
          this._property = new PropertyOfThisIdentifierBehaviorNode(
            token.property as jsep.Identifier,
            mode
          );
        } else {
          this._property = new PropertyIdentifierBehaviorNode(
            token.property as jsep.Identifier
          );
        }
      } else {
        // all other tokens are created by the default node creation
        this._property = createBehaviorNode(token.property, mode);
      }
    }
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell,
    breadcrumb?: InstanceBreadcrumb
  ): ValueGraphData | ValueGraphData[] {
    if (this._object instanceof ThisBehaviorNode) {
      // in case the property is a parent of `this`, the property must not be
      // evaluated in the scope of each instance of the cell, but of the cell
      // itself. Therefore we do not call evaluate on the `this` behavior node.
      return this._property.evaluate(
        calledFrom ? [calledFrom] : undefined,
        calledFrom
      );
    } else {
      // if this member behavior node is called without a breadcrumb, initialize
      // it here as this is the root node for the identifier path
      if (!breadcrumb) breadcrumb = new Map<CellType, Instance>();
      const newScope = makeArray(
        this._object.evaluate(scope, calledFrom, breadcrumb)
      );

      const cleanScope: Instance[] = [];
      for (const i of newScope) {
        if (i instanceof Instance) cleanScope.push(i);
      }

      return this._property.evaluate(cleanScope, calledFrom, breadcrumb);
    }
  }
}

class ThisBehaviorNode implements BehaviorNode {
  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Instance[] {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync
    if (!calledFrom)
      throw new Error("`this` cannot be used outside of a cell graph");

    return calledFrom.instances;
  }
}

class ConditionalBehaviorNode implements BehaviorNode {
  protected test: BehaviorNode;
  protected consequent: BehaviorNode;
  protected alternate: BehaviorNode;

  constructor(token: jsep.ConditionalExpression, mode: BehaviorMode) {
    this.test = createBehaviorNode(token.test, mode);
    this.consequent = createBehaviorNode(token.consequent, mode);
    this.alternate = createBehaviorNode(token.alternate, mode);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] {
    return toBoolean(this.test.evaluate(scope, calledFrom))
      ? this.consequent.evaluate(scope, calledFrom)
      : this.alternate.evaluate(scope, calledFrom);
  }
}

class ArrayBehaviorNode implements BehaviorNode {
  protected elements: BehaviorNode[] = [];

  constructor(token: jsep.ArrayExpression, mode: BehaviorMode) {
    for (const element of token.elements) {
      this.elements.push(createBehaviorNode(element, mode));
    }
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array: ValueGraphData[] = [];

    for (const element of this.elements) {
      const item = element.evaluate(scope, calledFrom);
      if (Array.isArray(item)) {
        Array.prototype.push.apply(array, item);
      } else {
        array.push(item);
      }
    }

    return array;
  }
}

class ObjectBehaviorNode implements BehaviorNode {
  private _properties: { key: string; value?: BehaviorNode }[] = [];

  constructor(token: jsepObject.ObjectExpression, mode: BehaviorMode) {
    for (const property of token.properties) {
      const key = property.key;
      const value = property.value;

      if (key.type !== "Identifier")
        throw new Error(`Invalid object property identifier type ${key.type}`);

      this._properties.push({
        key: (key as jsep.Identifier).name,
        value:
          value === undefined ? undefined : createBehaviorNode(value, mode),
      });
    }
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraph {
    const result: ValueGraph = {};

    for (const property of this._properties) {
      result[property.key] = property.value?.evaluate(scope, calledFrom);
    }

    return result;
  }
}

/**
 * Factory function to create a function behavior node from a jsep token.
 * @param token The jsep token from which to create the behavior node.
 * @returns The function behavior node.
 */
export function createFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode {
  if (token.callee.type != "Identifier")
    throw "Invalid function name (member expressions are not allowed)";

  const node =
    createLogicFunctionBehaviorNode(token, mode) ||
    createConversionFunctionBehaviorNode(token, mode) ||
    createTestFunctionBehaviorNode(token, mode) ||
    createMathematicalFunctionBehaviorNode(token, mode) ||
    createFinancialFunctionBehaviorNode(token, mode) ||
    createStringFunctionBehaviorNode(token, mode) ||
    createDateTimeFunctionBehaviorNode(token, mode) ||
    createI18nFunctionBehaviorNode(token, mode) ||
    createArrayFunctionBehaviorNode(token, mode) ||
    createVariousFunctionBehaviorNode(token, mode) ||
    createDeltaFunctionBehaviorNode(token, mode);

  if (node) return node;

  // else throw exception for any other function name currently not implemented
  throw (
    "Function " + (token.callee as jsep.Identifier).name + " not implemented"
  );
}

/**
 * Checks a jsep CallExpression function token against the minimum and maximum
 * number of arguments and returns the arguments as an array of behavior nodes.
 *
 * @param token The function token defining the arguments of the function.
 * @param minArgs The minimum number if arguments this function requires.
 * @param maxArgs (optional) The maximum number of arguments this function can
 *    have. If undefined the function can have an arbitrary number of arguments.
 * @returns The array of behavior nodes for the arguments.
 */
export function getArgumentNodes(
  token: jsep.CallExpression,
  mode: BehaviorMode,
  minArgs: number,
  maxArgs?: number
): BehaviorNode[] {
  if (minArgs !== undefined && token.arguments.length < minArgs)
    throw "Not enough arguments in function call";
  if (maxArgs !== undefined && token.arguments.length > maxArgs)
    throw "Too many arguments in function call";

  const args: BehaviorNode[] = [];

  for (const argument of token.arguments) {
    args.push(createBehaviorNode(argument, mode));
  }

  return args;
}
