import firebase from "firebase/app";
import "firebase/firestore";

import { FirestoreDeleteBase } from "@shared/src/components/firestore-delete-base";
import { ComponentInvoker } from "@shared/src/interfaces/global-interfaces";

export default class FirestoreDeleteComponent extends FirestoreDeleteBase {
  private _db: firebase.firestore.Firestore;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);
    this._db = firebase.firestore();
  }

  protected async delete(
    orgId: string,
    appName: string,
    collection: string,
    ids: string[]
  ): Promise<void> {
    try {
      const collectionPath = `orgs/${orgId}/apps/${appName}/${collection}`;
      const collectionRef = this._db.collection(collectionPath);
      let idBuffer = [];
      const maxBufferSize = 20;
      for (let i = 0; i < ids.length; i++) {
        idBuffer.push(ids[i]);
        if (idBuffer.length === maxBufferSize || i === ids.length - 1) {
          const promises = idBuffer.map((id) => collectionRef.doc(id).delete());
          idBuffer = [];
          await Promise.all(promises);
        }
      }
    } catch (err) {
      console.error("delete failed:", err);
      throw err;
    }
  }
}
