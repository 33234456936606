import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { toDatetime, toNumber } from "./behavior-commons";

export function createI18nFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "formatdecimal":
      return new formatDecimalFunctionBehaviorNode(token, mode);
    case "formatcurrency":
      return new formatCurrencyFunctionBehaviorNode(token, mode);
    case "formatpercent":
      return new formatPercentFunctionBehaviorNode(token, mode);
    case "formatdatetime":
      return new formatDatetimeFunctionBehaviorNode(token, mode);
  }
}

/**
 * Formats a decimal number according to locale and formatting options.
 * @name formatDecimal() function
 * @param amount The number to be formatted.
 * @param locale (optional) A BCP 47 language tag for the locale to be used,
 *    e.g. 'en-US'. If null the default locale will be used.
 * @param minIntegerDigits (optional) The minimum number of integer digits to
 *    use. Possible values are from 1 to 21; the default is 1.
 * @param minFractionDigits (optional) The minimum number of fraction digits to
 *    use. Possible values are from 0 to 20; the default is 0.
 * @param maxFractionDigits (optional) The maximum number of fraction digits to
 *    use. Possible values are from 0 to 20; the default is the larger of
 *    minFractionDigits and 3.
 * @returns A string with the formatted number.
 */
class formatDecimalFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 5);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const amount = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const locale =
      this.arguments.length > 1
        ? this.arguments[1].evaluate(scope, calledFrom)?.toString()
        : undefined;
    const minIntegerDigits =
      this.arguments.length > 2
        ? toNumber(this.arguments[2].evaluate(scope, calledFrom))
        : undefined;
    const minFractionDigits =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : undefined;
    const maxFractionDigits =
      this.arguments.length > 4
        ? toNumber(this.arguments[4].evaluate(scope, calledFrom))
        : undefined;

    try {
      return Intl.NumberFormat(locale, {
        style: "decimal",
        minimumIntegerDigits: minIntegerDigits,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
      }).format(amount);
    } catch (err) {
      return err instanceof Error ? err.message : String(err);
    }
  }
}

/**
 * Formats a currency number according to locale and formatting options.
 * @name formatCurrency() function
 * @param amount The number to be formatted.
 * @param currency The currency to use in currency formatting. Possible values
 *    are the ISO 4217 currency codes.
 * @param locale (optional) A BCP 47 language tag for the locale to be used,
 *    e.g. 'en-US'. If empty or null the default locale will be used.
 * @param minIntegerDigits (optional) The minimum number of integer digits to
 *    use. Possible values are from 1 to 21; the default is 1.
 * @param minFractionDigits (optional) The minimum number of fraction digits to
 *    use. Possible values are from 0 to 20; the default is the number of minor
 *    unit digits provided by the ISO 4217 currency code list (2 if the list
 *    doesn't provide that information).
 * @param maxFractionDigits (optional) The maximum number of fraction digits to
 *    use. Possible values are from 0 to 20; the default is the larger of
 *    minFractionDigits and the number of minor unit digits provided by the ISO
 *    4217 currency code list (2 if the list doesn't provide that information).
 * @returns A string with the formatted number.
 */
class formatCurrencyFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 6);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const amount = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const currency = this.arguments[1].evaluate(scope, calledFrom)?.toString();
    const locale =
      this.arguments.length > 2
        ? this.arguments[2].evaluate(scope, calledFrom)?.toString()
        : undefined;
    const minIntegerDigits =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : undefined;
    const minFractionDigits =
      this.arguments.length > 4
        ? toNumber(this.arguments[4].evaluate(scope, calledFrom))
        : undefined;
    const maxFractionDigits =
      this.arguments.length > 5
        ? toNumber(this.arguments[5].evaluate(scope, calledFrom))
        : undefined;

    try {
      return Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumIntegerDigits: minIntegerDigits,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
      }).format(amount);
    } catch (err) {
      return err instanceof Error ? err.message : String(err);
    }
  }
}

/**
 * Formats a percentage according to locale and formatting options.
 * @name formatPercent() function
 * @param amount The number to be formatted.
 * @param locale (optional) A BCP 47 language tag for the locale to be used,
 *    e.g. 'en-US'. If empty or null the default locale will be used.
 * @param minIntegerDigits (optional) The minimum number of integer digits to
 *    use. Possible values are from 1 to 21; the default is 1.
 * @param minFractionDigits (optional) The minimum number of fraction digits to
 *    use. Possible values are from 0 to 20; the default is 0.
 * @param maxFractionDigits (optional) The maximum number of fraction digits to
 *    use. Possible values are from 0 to 20; the default is the larger of
 *    minFractionDigits and 0.
 * @returns A string with the formatted number.
 */
class formatPercentFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 5);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const amount = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const locale =
      this.arguments.length > 1
        ? this.arguments[1].evaluate(scope, calledFrom)?.toString()
        : undefined;
    const minIntegerDigits =
      this.arguments.length > 2
        ? toNumber(this.arguments[2].evaluate(scope, calledFrom))
        : undefined;
    const minFractionDigits =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : undefined;
    const maxFractionDigits =
      this.arguments.length > 4
        ? toNumber(this.arguments[4].evaluate(scope, calledFrom))
        : undefined;

    try {
      return Intl.NumberFormat(locale, {
        style: "percent",
        minimumIntegerDigits: minIntegerDigits,
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
      }).format(amount);
    } catch (err) {
      return err instanceof Error ? err.message : String(err);
    }
  }
}

/**
 * Formats a datetime according to locale and formatting options.
 * @name formatDatetime() function
 * @param datetime The date and time to be formatted.
 * @param locale (optional) A BCP 47 language tag for the locale to be used,
 *    e.g. 'en-US'. If null the default locale will be used.
 * @param dateStyle (optional) The date formatting style to use (`full`, `long`,
 *    `medium`, or `short`).
 * @param timeStyle (optional) The time formatting style to use (`full`, `long`,
 *    `medium`, or `short`).
 * @param timeZone (optional) The time zone to use, "UTC"; the default is the
 *    runtime's default time zone.
 * @returns A string with the formatted datetime.
 */
class formatDatetimeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 5);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const datetime = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const locale =
      this.arguments.length > 1
        ? this.arguments[1].evaluate(scope, calledFrom)?.toString()
        : undefined;
    const dateStyle =
      this.arguments.length > 2
        ? this.arguments[2].evaluate(scope, calledFrom)?.toString() ?? undefined
        : undefined;
    const timeStyle =
      this.arguments.length > 3
        ? this.arguments[3].evaluate(scope, calledFrom)?.toString() ?? undefined
        : undefined;
    const timeZone =
      this.arguments.length > 4
        ? this.arguments[4].evaluate(scope, calledFrom)?.toString() ?? undefined
        : undefined;

    try {
      return Intl.DateTimeFormat(locale, {
        timeZone: timeZone,
        dateStyle: dateStyle,
        timeStyle: timeStyle,
      } as Intl.DateTimeFormatOptions).format(datetime);
    } catch (err) {
      return err instanceof Error ? err.message : String(err);
    }
  }
}
