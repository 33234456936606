// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  html {
    font-size: 8pt;
  }

  .form-control-print {
    border: none !important;
    padding: 0 !important;
  }
  .form-select-print {
    border: none !important;
    padding: 0 !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/hyperseed.css"],"names":[],"mappings":"AAAA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,uBAAuB;IACvB,qBAAqB;EACvB;EACA;IACE,uBAAuB;IACvB,qBAAqB;EACvB;AACF","sourcesContent":["@media print {\n  html {\n    font-size: 8pt;\n  }\n\n  .form-control-print {\n    border: none !important;\n    padding: 0 !important;\n  }\n  .form-select-print {\n    border: none !important;\n    padding: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
