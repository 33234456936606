import {
  Component,
  ComponentInvoker,
  DOMComponent,
  InitializableComponent,
  isFrameObserver,
  PerfCounter,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { WaiterPool } from "../waiter";
import CellType from "./cell-type";
import { ComponentLibrary } from "./component-library";
import queueFrame from "./frame-queue";
import Instance from "./instance";

type PublishOutputUpdated = () => void;

/**
 * The class that encapsulates a component defined by a schema, thus a custom
 * component.
 */
export default class CustomComponent
  implements DOMComponent, InitializableComponent
{
  /** Provides the component library to be used. */
  readonly library: ComponentLibrary;
  /** Returns the invoker from which this component was instantiated, most
   * commonly the parent `Instance`. */
  public get invokedBy(): ComponentInvoker | undefined {
    return this._invokedBy;
  }
  private _invokedBy?: ComponentInvoker;
  /** Provides the performance counter to be used. */
  readonly perfCounter?: PerfCounter;

  // TODO: If somehow possible do not expose the rootInstance as it is mostly
  // just about the internal construction of a custom component.
  /** Returns the root instance of this component. */
  public get rootInstance(): Instance | null {
    return this._rootInstance;
  }
  private _rootInstance: Instance | null = null;

  /** flag to avoid duplicate queuing of frames */
  private _behaviorAlreadyQueued = false;
  /** in case this component is currently executing a frame, the next behavior
   * frame should be queued at the of it */
  private _queueBehaviorAtEndOfFrame = false;
  /** flag to indicate that this component is currently executing a behavior
   * frame, so the next one being queued will be deferred */
  private _isExecutingFrame = false;
  private _getsDestroyed = false;
  /** Pool of waiters to be resolved when behavior settles. */
  private _waiterPool = new WaiterPool();
  private _outputUpdated = false;
  private _publishOutputUpdated: PublishOutputUpdated | undefined;

  /** A map of busy sub-components. An empty map means that no sub-component is
   * busy anymore */
  private _busyComponents = new Map<Component, boolean>();
  /** remembers the most recent busy state being reported to the invoiker of
   * this component */
  private _wasBusy = false;

  constructor(
    library: ComponentLibrary,
    invokedBy?: ComponentInvoker,
    perfCounter?: PerfCounter
  ) {
    this.library = library;
    this._invokedBy = invokedBy;
    this.perfCounter = perfCounter;
  }

  /**
   * Initializes the component using the schema from the component library with
   * the given component type name or using a cellType tree defining the
   * component. It queues the first behavior frame (before any sub-components)
   * and marks its outputs to get propagated in this first frame.
   * @param name The name of the component type or a cellType defining the
   *    component.
   */
  init(type: string | CellType): void {
    if (type instanceof CellType) {
      this._rootInstance = new Instance(this, null, type);
    } else {
      const cellType = this.library.get(type.toLowerCase());
      if (!cellType)
        throw new Error(`Component \`${type}\` not found during runtime`);
      this._rootInstance = new Instance(this, null, cellType);
    }

    this._rootInstance.init({});

    // Queue the behavior of this component before the sub-components queue
    // their first behavior frame making sure it sets its initial state. The
    // call is deferred to have the inputs set correctly by the invoker
    this.queueBehaveAll();

    // mark outputs to be initially updated so that static values are propagated
    // at the end of the first behavior frame
    this._outputUpdated = true;
  }

  public destroy(): void {
    delete this._invokedBy;
    this._getsDestroyed = true;
    this._rootInstance?.destroy();
    this._rootInstance = null;
  }

  /**
   * Determines whether any behavior is currently pending.
   *
   * @returns `true` if any behavior is pending in the current behavior
   *    loop frame of this component or any nested component.
   */
  public get isBusy(): boolean {
    return (
      this._behaviorAlreadyQueued ||
      this._queueBehaviorAtEndOfFrame ||
      this._busyComponents.size > 0
    );
  }

  /**
   * Allows caller to wait for behavior to settle.
   *
   * @param timeout the number of milliseconds to wait before aborting
   * @returns a promise that is resolved when behavior has settled or
   *   rejected if the timeout expires
   */
  private behaviorSettled(timeout: number): Promise<void> {
    const waiter = this._waiterPool.addWaiter(timeout);
    return waiter.promise();
  }

  public async waitUntilSettled(timeout = 5000): Promise<void> {
    if (!this.isBusy) return;

    await this.behaviorSettled(timeout).catch((e) => {
      throw e instanceof Error ? e : new Error(e as string);
    });
  }

  /**
   * @param graph A JavaScript object with the input values. The keys must
   *    correspond to the schema of the component. If the name of an input is
   *    not found in the schema, the value is ignored.
   */
  public setInputValueGraph(graph: ValueGraph): void {
    this._rootInstance?.setInputValueGraph(graph);
  }

  /**
   * @returns A JavaScript object with the output values. The keys correspond to
   *    the schema of the component.
   */
  public getOutputValueGraph(): ValueGraph {
    return this._rootInstance?.getOutputValueGraph() ?? {};
  }

  /**
   * Runs a behavior execution "frame", which re-evaluates each cell in the seed
   * graph.
   *
   * It supports delta evaluation via calling setSkippedPrevValues.
   *
   * Additionally it writes all updated output values to the calling instance
   * and to any output listener.
   */
  public behaveAll(): void {
    this._behaviorAlreadyQueued = false;
    this._queueBehaviorAtEndOfFrame = false;
    this._isExecutingFrame = true;

    this._rootInstance?.behaveAll();

    if (this._outputUpdated) {
      // TODO: combine propagation of updated outputs via one unified interface
      if (this._invokedBy) {
        const valueGraph = this.getOutputValueGraph();
        this._invokedBy.setOutputValueGraph(valueGraph);
      }
      if (this._publishOutputUpdated !== undefined) {
        this._publishOutputUpdated();
      }
      this._outputUpdated = false;
    }

    // TODO: replace abortion of behavior when the component gets destroyed with
    // timeout instead of instant abortion to allow the component to finish its
    // job (e.g. persisting data)
    if (
      this._queueBehaviorAtEndOfFrame &&
      !this._behaviorAlreadyQueued &&
      !this._getsDestroyed
    ) {
      queueFrame(() => this.behaveAll());
      this._behaviorAlreadyQueued = true;
      this._queueBehaviorAtEndOfFrame = false;
    }

    // cells where behavior has been skipped (inputs and cells receiving
    // outputs) must reset their previous value as inputs/outputs are not set
    // on each frame of this component
    this._rootInstance?.setSkippedPrevValues();

    if (this.perfCounter?.traceFrames) {
      this.perfCounter?.behaviorFrameFinished(
        this._rootInstance?.cellType.originalName,
        this._rootInstance?.getValueGraph()
      );
    } else {
      this.perfCounter?.behaviorFrameFinished();
    }

    if (isFrameObserver(this._invokedBy))
      this._invokedBy.behaviorFrameFinished();

    this.propagateBusyState();

    if (!this.isBusy) {
      // Resolve the behavior settlement waiters, then clear the pool
      // for the next frame.
      this._waiterPool.resolveAll();
      this._waiterPool.reset();
    }

    this._isExecutingFrame = false;
  }

  /**
   * Queues the next behavior execution frame or skips this, if such is already
   * queued. Should be called on each value that is updated, i.e. has a
   * different state as before.
   *
   * For internal dependencies the queueing must be deferred to the end of the
   * behavior frame. This will queue them after external dependencies which
   * allows single frame event values to be considered in external dependencies.
   */
  public queueBehaveAll(): void {
    if (
      this._behaviorAlreadyQueued ||
      this._queueBehaviorAtEndOfFrame ||
      this._getsDestroyed
    )
      return;

    if (this._isExecutingFrame) {
      this._queueBehaviorAtEndOfFrame = true;
    } else {
      queueFrame(() => this.behaveAll());
      this._behaviorAlreadyQueued = true;
    }

    this.propagateBusyState();
  }

  /**
   * Bubbles up the busy state in case it has changed and the component is
   * currently not being destroyed.
   */
  private propagateBusyState(): void {
    if (this.isBusy !== this._wasBusy && !this._getsDestroyed) {
      this._invokedBy?.setBusy(this.isBusy);
      this._wasBusy = this.isBusy;
    }
  }

  /**
   * Adds or removes a component from the map of busy sub-components. An empty
   * map means that no sub-component is busy any more.
   * @param component The component to be added or removed.
   * @param busy `true` if the component should be added to the busy map,
   *  `false` if it should be removed.
   */
  public setBusy(component: Component, busy: boolean): void {
    if (busy) {
      this._busyComponents.set(component, true);
    } else {
      this._busyComponents.delete(component);
    }

    // instantly propagate busy state only in case this component is busy as
    // otherwise the invoker could be temporarily un-busy
    if (this.isBusy) this.propagateBusyState();
    // otherwise queue a frame to process the waiters accordingly
    else if (!this._isExecutingFrame) this.queueBehaveAll();
  }

  /**
   * Registers a callback that is invoked on each behavior frame if one
   * or more output values were updated. Intended to be called by the
   * brain.
   *
   * @param callback a callback function that is executed at the end of
   *   each behavior frame if one or more output values was updated
   *   during the frame
   */
  public subscribeToOutputUpdates(callback: PublishOutputUpdated): void {
    this._publishOutputUpdated = callback;
  }

  /**
   * Informs the component that at least one of its output values has
   * been updated in the current behavior frame. Intended to be called
   * from instances of the Cell class.
   */
  public markOutputUpdated(): void {
    this._outputUpdated = true;
  }

  /**
   * Returns the DOM node of the the root instance.
   * @returns The root instance's DOM node, or `null` if the root instance is not attached to the DOM.
   */
  public get DOMNode(): Element | null {
    return this._rootInstance?.DOMNode ?? null;
  }

  /**
   * Attaches the root instance of the component to the DOM node that either the
   * calling instance or the instantiating brain provides.
   */
  public attachDOM(parentDOMNode: Element): void {
    this._rootInstance?.attachDOM(parentDOMNode);
  }

  /**
   * Detaches the root instance of the component from the DOM node that either
   * the calling instance or the instantiating brain provides.
   */
  public detachDOM(): void {
    this._rootInstance?.detachDOM();
  }
}
