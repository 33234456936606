/**
 * Represents a user's authentication credentials. Stored as a
 * Firestore document in the `_authUsers` collection.
 */
export interface User {
  /** User ID */
  id: string;

  /** Hashed password */
  password: string;

  /** Access roles */
  roles: string[];

  /** User-specific password salt */
  salt: string;

  /** Username / email */
  username: string;

  /** The Firebase project ID associated with the user */
  projectId: string;
}

/**
 * Represents an authtication-related error.
 */
export class AuthError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
