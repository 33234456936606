import { ComponentLibrary } from "../classes/component-library";
import {
  Component,
  ComponentConstructor,
  ComponentInvoker,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import ApiComponent from "./api-component";
import CSVGenerateComponent from "./csv-gen-component";
import CSVParseComponent from "./csv-parse-component";
import IntervalComponent from "./interval-component";
import LocalSchemaComponent from "./local-schema-component";
import SVGAnimateComponent from "./svg-animate-component";
import SVGAnimateMotionComponent from "./svg-animate-motion-component";
import SVGCircleComponent from "./svg-circle-component";
import SVGComponent from "./svg-component";
import SVGGroupComponent from "./svg-group-component";
import SVGPathComponent from "./svg-path-component";
import SVGRectComponent from "./svg-rect-component";
import SVGTextComponent from "./svg-text-component";
import SVGUseComponent from "./svg-use-component";
import UIButtonComponent from "./ui-button-component";
import UIClipboardComponent from "./ui-clipboard-component";
import UIContextMenuComponent from "./ui-contextmenu-component";
import UIDragComponent from "./ui-drag-component";
import UIDropComponent from "./ui-drop-component";
import UIIFrameComponent from "./ui-iframe-component";
import UIImageComponent from "./ui-image-component";
import UIInputCheckboxComponent from "./ui-input-checkbox-component";
import UIInputComponent from "./ui-input-component";
import UIFileInputComponent from "./ui-input-file-component";
import UIInputRadioComponent from "./ui-input-radio-component";
import UIKeyboardComponent from "./ui-keyboard-component";
import UILabelComponent from "./ui-label-component";
import UILinkComponent from "./ui-link-component";
import UIMediaComponent from "./ui-media-component";
import UIMouseComponent from "./ui-mouse-component";
import UISelectComponent from "./ui-select-component";
import UITextAreaComponent from "./ui-textarea-component";
import UIViewComponent from "./ui-view-component";

/**
 * Component interface for static `getSchema` method.
 *
 * Component classes have static `getSchema` methods. This interface is
 * used to get around the limitation that an interface cannot be used to
 * represesnt a class with static methods.
 */
interface GetSchemaType {
  getSchema(): Schema;
}

/**
 * Component interface for static `isInput` and `isOutput` methods.
 *
 * Component classes have static `isInput` and `isOutput` methods. This
 * interface is used to get around the limitation that an interface
 * cannot be used to represent a class with static methods.
 */
interface IsInputOutputType {
  isInput(name: string): boolean;
  isOutput(name: string): boolean;
}

/**
 *  Maps platform component type name to its corresponding class.
 */
const platformMap = new Map<string, Component>();

/**
 *  Maps component type name to the corresponding tag name passed as the
 *  second argument to its constructor.
 *
 * @example "seed.ui.text" -> "span"
 */
const tagNameMap = new Map<string, string>();

// TODO: replace the big switch statement with something like
// https://medium.com/codex/factory-pattern-type-script-implementation-with-type-map-ea422f38862

export default class SystemComponentFactory {
  /**
   * Registers a platform-specific component class corresponding to the
   * given component type name (e.g., "seed.auth.signin").
   *
   * @param type component type e.g., "seed.auth.signin"
   * @param componentClass the imported symbol that refers to the
   *   component class
   * @param tagName optional tag name to be passed as the second
   *   argument to the component's constructore
   */
  static registerPlatformComponent(
    type: string,
    componentClass: Component,
    tagName?: string
  ): void {
    type = type.toLowerCase();
    platformMap.set(type, componentClass);
    if (tagName) tagNameMap.set(type, tagName);
  }

  static Create(
    type: string,
    invokedBy: ComponentInvoker,
    library?: ComponentLibrary
  ): Component | undefined {
    type = type.toLowerCase();
    if (platformMap.has(type)) {
      const ctor = platformMap.get(type) as unknown as ComponentConstructor;
      const tagName = tagNameMap.get(type);
      return new ctor(invokedBy, tagName);
    }
    switch (type) {
      // the system components
      case "seed.system.interval":
        return new IntervalComponent(invokedBy);

      // Frontend components directly rendering to the DOM
      case "seed.ui.view":
        return new UIViewComponent(invokedBy);
      case "seed.ui.view.div":
      case "seed.ui.view.table":
      case "seed.ui.view.tr":
      case "seed.ui.view.td":
      case "seed.ui.view.th":
      case "seed.ui.view.thead":
      case "seed.ui.view.tbody":
      case "seed.ui.view.tfoot":
      case "seed.ui.view.caption":
      case "seed.ui.view.col":
      case "seed.ui.view.colgroup":
      case "seed.ui.view.span":
      case "seed.ui.view.pre":
      case "seed.ui.view.ul":
      case "seed.ui.view.ol":
      case "seed.ui.view.li":
        return new UIViewComponent(invokedBy, type.substring(13));
      case "seed.ui.view.label":
        return new UILabelComponent(invokedBy);

      case "seed.ui.text":
        return new UIViewComponent(invokedBy, "span");
      case "seed.ui.pre":
        return new UIViewComponent(invokedBy, "pre");
      case "seed.ui.list":
        return new UIViewComponent(invokedBy, "ul");
      case "seed.ui.list.ordered":
        return new UIViewComponent(invokedBy, "ol");
      case "seed.ui.listitem":
        return new UIViewComponent(invokedBy, "li");
      case "seed.ui.link":
        return new UILinkComponent(invokedBy);
      case "seed.ui.image":
        return new UIImageComponent(invokedBy);
      case "seed.ui.video":
        return new UIMediaComponent(invokedBy, "video");
      case "seed.ui.audio":
        return new UIMediaComponent(invokedBy, "audio");
      case "seed.ui.iframe":
        return new UIIFrameComponent(invokedBy);
      case "seed.ui.input":
        return new UIInputComponent(invokedBy);
      case "seed.ui.input.checkbox":
        return new UIInputCheckboxComponent(invokedBy);
      case "seed.ui.input.radio":
        return new UIInputRadioComponent(invokedBy);
      case "seed.ui.input.file":
        return new UIFileInputComponent(invokedBy);
      case "seed.ui.textarea":
        return new UITextAreaComponent(invokedBy);
      case "seed.ui.button":
        return new UIButtonComponent(invokedBy);
      case "seed.ui.select":
        return new UISelectComponent(invokedBy);

      // supplementary frontend components not rendering to the DOM
      case "seed.ui.keyboard":
        return new UIKeyboardComponent(invokedBy);
      case "seed.ui.mouse":
        return new UIMouseComponent(invokedBy);
      case "seed.ui.drag":
        return new UIDragComponent(invokedBy);
      case "seed.ui.drop":
        return new UIDropComponent(invokedBy);
      case "seed.ui.contextmenu":
        return new UIContextMenuComponent(invokedBy);
      case "seed.ui.clipboard":
        return new UIClipboardComponent(invokedBy);

      // SVG components (require DOM as well)
      case "seed.svg":
        return new SVGComponent(invokedBy);
      case "seed.svg.group":
        return new SVGGroupComponent(invokedBy);
      case "seed.svg.text":
        return new SVGTextComponent(invokedBy);
      case "seed.svg.rect":
        return new SVGRectComponent(invokedBy);
      case "seed.svg.circle":
        return new SVGCircleComponent(invokedBy);
      case "seed.svg.path":
        return new SVGPathComponent(invokedBy);
      case "seed.svg.animate":
        return new SVGAnimateComponent(invokedBy);
      case "seed.svg.animatemotion":
        return new SVGAnimateMotionComponent(invokedBy);
      case "seed.svg.use":
        return new SVGUseComponent(invokedBy);

      // frontend components that are not directly rendering
      case "seed.api.consumer":
        return new ApiComponent(invokedBy);
      case "seed.local.schema":
        return new LocalSchemaComponent(invokedBy, library);

      case "seed.generate.csv":
        return new CSVGenerateComponent(invokedBy);
      case "seed.parse.csv":
        return new CSVParseComponent(invokedBy);
    }
  }

  static getSchema(type: string): Schema | undefined {
    type = type.toLowerCase();
    if (platformMap.has(type)) {
      const component = platformMap.get(type) as unknown as GetSchemaType;
      return component.getSchema();
    }
    switch (type) {
      case "seed.system.interval":
        return IntervalComponent.getSchema();

      case "seed.ui.view":
        return UIViewComponent.getSchema();
      case "seed.ui.view.div":
      case "seed.ui.view.table":
      case "seed.ui.view.tr":
      case "seed.ui.view.td":
      case "seed.ui.view.th":
      case "seed.ui.view.thead":
      case "seed.ui.view.tbody":
      case "seed.ui.view.tfoot":
      case "seed.ui.view.caption":
      case "seed.ui.view.col":
      case "seed.ui.view.colgroup":
      case "seed.ui.view.span":
      case "seed.ui.view.pre":
      case "seed.ui.view.ul":
      case "seed.ui.view.ol":
      case "seed.ui.view.li":
        return UIViewComponent.getSchema();
      case "seed.ui.view.label":
        return UILabelComponent.getSchema();

      case "seed.ui.text":
      case "seed.ui.pre":
      case "seed.ui.list":
      case "seed.ui.list.ordered":
      case "seed.ui.listitem":
        return UIViewComponent.getSchema();
      case "seed.ui.link":
        return UILinkComponent.getSchema();
      case "seed.ui.image":
        return UIImageComponent.getSchema();
      case "seed.ui.video":
      case "seed.ui.audio":
        return UIMediaComponent.getSchema();
      case "seed.ui.iframe":
        return UIIFrameComponent.getSchema();
      case "seed.ui.input":
        return UIInputComponent.getSchema();
      case "seed.ui.input.checkbox":
        return UIInputCheckboxComponent.getSchema();
      case "seed.ui.input.radio":
        return UIInputRadioComponent.getSchema();
      case "seed.ui.input.file":
        return UIFileInputComponent.getSchema();
      case "seed.ui.textarea":
        return UITextAreaComponent.getSchema();
      case "seed.ui.button":
        return UIButtonComponent.getSchema();
      case "seed.ui.select":
        return UISelectComponent.getSchema();

      case "seed.ui.keyboard":
        return UIKeyboardComponent.getSchema();
      case "seed.ui.mouse":
        return UIMouseComponent.getSchema();
      case "seed.ui.drag":
        return UIDragComponent.getSchema();
      case "seed.ui.drop":
        return UIDropComponent.getSchema();
      case "seed.ui.contextmenu":
        return UIContextMenuComponent.getSchema();
      case "seed.ui.clipboard":
        return UIClipboardComponent.getSchema();

      case "seed.svg":
        return SVGComponent.getSchema();
      case "seed.svg.group":
        return SVGGroupComponent.getSchema();
      case "seed.svg.text":
        return SVGTextComponent.getSchema();
      case "seed.svg.rect":
        return SVGRectComponent.getSchema();
      case "seed.svg.circle":
        return SVGCircleComponent.getSchema();
      case "seed.svg.path":
        return SVGPathComponent.getSchema();
      case "seed.svg.animate":
        return SVGAnimateComponent.getSchema();
      case "seed.svg.animatemotion":
        return SVGAnimateMotionComponent.getSchema();
      case "seed.svg.use":
        return SVGUseComponent.getSchema();

      case "seed.api.consumer":
        return ApiComponent.getSchema();
      case "seed.local.schema":
        return LocalSchemaComponent.getSchema();

      case "seed.generate.csv":
        return CSVGenerateComponent.getSchema();
      case "seed.parse.csv":
        return CSVParseComponent.getSchema();
    }
  }

  static isInput(type: string, name: string): boolean {
    type = type.toLowerCase();
    if (platformMap.has(type)) {
      const component = platformMap.get(type) as unknown as IsInputOutputType;
      if ("isInput" in component) return component.isInput(name);
      return false;
    }
    switch (type) {
      case "seed.api.consumer":
        return ApiComponent.isInput(name);
      case "seed.local.schema":
        return LocalSchemaComponent.isInput(name);
      default:
        return false;
    }
  }

  static isOutput(type: string, name: string): boolean {
    type = type.toLowerCase();
    if (platformMap.has(type)) {
      const component = platformMap.get(type) as unknown as IsInputOutputType;
      if ("isOutput" in component) return component.isOutput(name);
      return false;
    }
    switch (type) {
      case "seed.api.consumer":
        return ApiComponent.isOutput(name);
      case "seed.local.schema":
        return LocalSchemaComponent.isOutput(name);
      default:
        return false;
    }
  }
}
