import * as qbo from "../api/quickbooks";
import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

enum Status {
  PENDING = "pending",
  FAILED = "failed",
  VALID = "valid",
  STALE = "stale",
}

enum ErrorCode {
  REALM_ID_UNSET = "hyperseed/realm-id-unset",
  QUERY_UNSET = "hyperseed/query-unset",
  DATA_UNSET = "hyperseed/data-unset",
  UNKNOWN_RESOURCE = "hyperseed/unknown-resource",
}

/**
 * Represents a QuickBooks query error.
 */
export class QuickBooksQueryError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Abstract base class of the QuickBooks query component that allows
 * caller to query resources via the QuickBooks Online API.
 */
export abstract class QuickBooksQueryBase implements Component {
  private _invokedBy: ComponentInvoker;
  private _queue: ValueGraph[] = [];

  /** ID of the relevant Hyperseed organization */
  private _orgId?: string;
  /** Realm ID identifying QuickBooks data owner */
  private _realmId?: string;
  /** The QuickBooks resource query */
  private _query?: string;

  /** The resource data queried from QuickBooks */
  private _data?: ValueGraph[];
  /** The status after a QuickBooks operation was triggered */
  private _status: Status | null = null;
  /** A description of the error if status is FAILED */
  private _error: string | null = null;
  /** A code identifying the error if status is FAILED */
  private _errorCode: ErrorCode | string | null = null;
  /**
   * Pulses true for a single frame after query operation completes
   * (whether it succeeded or failed)
   */
  private _updated = false;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   orgId: { _input: null },
   *   realmId: { _input: null },
   *   query: { _input: null },
   *   // Outputs
   *   updated: { _output: null },
   *   status: { _output: null },
   *   error: { _output: null },
   *   errorCode: { _output: null },
   *   data: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  destroy(): void {
    // nothing to destroy
  }

  private reset() {
    this._updated = false;
    this.pushOutputs();
  }

  setInputValueGraph(graph: ValueGraph): void {
    // First add the input graph to the input queue
    this._queue.push(graph);
    // If this is the first and only item in the queue, start the processing
    // loop (as otherwise it is already started)
    if (this._queue.length === 1) this.processQueue();
  }

  private processQueue() {
    // Pause queue processing while request is pending.
    //
    // TODO: Should we add some timeout e.g., 10ms, or should we use an
    // observer?
    if (this._status === Status.PENDING) {
      queueFrame(() => this.processQueue());
      return;
    }
    // Retrieve the first item from the input queue and process it.
    const graph = this._queue.shift();
    if (graph) this.processInput(graph);
    // As long as items are left on the input queue, continue
    // processing the queue.
    if (this._queue.length > 0) queueFrame(() => this.processQueue());
  }

  private processInput(graph: ValueGraph): void {
    if (this._status === Status.PENDING) {
      return;
    }
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "orgid":
          this._orgId = value?.toString();
          break;
        case "realmid":
          this._realmId = value?.toString();
          break;
        case "query":
          this._query = value?.toString();
          break;
      }
    });
    queueFrame(() => this.callQuery());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      updated: this._updated,
      status: this._status,
      error: this._error,
      errorCode: this._errorCode,
      data: this._data,
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (this._realmId === undefined) {
        throw new QuickBooksQueryError(
          "realmId is missing",
          ErrorCode.REALM_ID_UNSET
        );
      }
      if (!this._query) {
        throw new QuickBooksQueryError(
          "query is missing",
          ErrorCode.QUERY_UNSET
        );
      }
      if (!this._orgId) {
        this._error = null;
        this._errorCode = null;
        this._status = Status.STALE;
        this.pushOutputs();
        return false;
      }
    } catch (err) {
      this._error = (err as QuickBooksQueryError).message;
      this._errorCode = (err as QuickBooksQueryError).code;
      this._status = Status.FAILED;
      this.pushOutputs();
      return false;
    }
    return true;
  }

  /**
   * Calls the asynchronuous query method and sets the output
   * properties of the component accordingly.
   */
  private callQuery() {
    if (!this.validateInputs()) return;
    this._status = Status.PENDING;
    this._error = null;
    this.pushOutputs();
    const realmId = this._realmId ?? "NONE";
    const query = this._query ?? "NONE";
    const request: qbo.APIRequest = {
      orgId: this._orgId ?? "UNKNOWN",
      method: "GET",
      path: `/v3/company/${realmId}/query?query=${query}`,
    };
    this.query(request)
      .then((response) => {
        this._data = response.body as ValueGraph[];
        this._error = null;
        this._errorCode = null;
        this._status = Status.VALID;
        this._updated = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((err) => {
        if (err instanceof QuickBooksQueryError) {
          this._error = err.message;
          this._errorCode = err.code;
        } else {
          this._error = String(err);
          this._errorCode = (err as { code: string }).code ?? "unknown";
        }
        this._status = Status.FAILED;
        this._updated = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  /**
   * Sends the resource query to QuickBooks. Implemented by the
   * platform-specific concrete class.
   *
   * @param request the API request to write the QuickBooks resource
   * @return the QuickBooks API response
   */
  protected abstract query(request: qbo.APIRequest): Promise<qbo.APIResponse>;

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("data.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["orgid", "realmid", "query"]);
/** Names of component outputs. */
const outputs = new Set(["updated", "status", "error", "errorcode", "data"]);
