import { BackoffOptions } from "exponential-backoff";
import firebase from "firebase/app";

/** Represents HTTP status code/text pair. */
type ErrorCodePair = [number, string];

/**
 * Maps Cloud Functions callable function (i.e., gRPC) error codes to
 * HTTP status codes.
 *
 * @see
 * https://github.com/googleapis/googleapis/blob/master/google/rpc/code.proto
 */
const errorCodeMap = new Map<
  firebase.functions.FunctionsErrorCode,
  ErrorCodePair
>([
  ["failed-precondition", [400, "Bad Request"]],
  ["invalid-argument", [400, "Bad Request"]],
  ["out-of-range", [400, "Bad Request"]],
  ["unauthenticated", [401, "Unauthorized"]],
  ["permission-denied", [403, "Forbidden"]],
  ["not-found", [404, "Not Found"]],
  ["aborted", [409, "Conflict"]],
  ["already-exists", [409, "Conflict"]],
  ["resource-exhausted", [429, "Too Many Requests"]],
  ["cancelled", [499, "Client Closed Request"]],
  ["internal", [500, "Internal Server Error"]],
  ["data-loss", [500, "Internal Server Error"]],
  ["unknown", [500, "Internal Server Error"]],
  ["unimplemented", [501, "Not Implemented"]],
  ["unavailable", [503, "Service Unavailable"]],
  ["deadline-exceeded", [504, "Gateway Timeout"]],
]);

/**
 * Translates gRPC error code received from Cloud Functions callable
 * function to HTTP status code/text pair.
 */
export function translateErrorCode(
  code: firebase.functions.FunctionsErrorCode
): ErrorCodePair {
  return errorCodeMap.get(code) ?? [0, "unknown"];
}

/**
 * Retry predicate for the `exponential-backoff` package. Directs
 * `backOff` to retry on Firebase Functions error codes that are
 * considered retryable.
 * @see https://www.npmjs.com/package/exponential-backoff
 */
export function shouldRetry(error: unknown): boolean {
  const pair = translateErrorCode(
    (error as firebase.functions.HttpsError)?.code
  );
  const code = pair[0];
  // A value of 0 for code means an error code pair was not matched;
  // retry with this unexpected result.
  if (code === 0) return true;
  if (code < 500 && code >= 400 && code !== 429) return false;
  return true;
}

/**
 * Provides default options to the `exponential-backoff` package's
 * `backOff` function.
 *
 * @param overrides Optional object with `BackoffOptions` properties
 *   that override the default options
 * @returns A new `BackoffOptions` object with defaults merged with
 *   optional overrides
 *
 * @see https://www.npmjs.com/package/exponential-backoff
 */
export function getDefaultBackoffOptions(
  overrides?: BackoffOptions
): BackoffOptions {
  return Object.assign(
    {
      numOfAttempts: 3,
      retry: shouldRetry,
    } as BackoffOptions,
    overrides
  );
}
