import firebase from "firebase/app";
import "firebase/firestore";

/**
 * Wraps `firebase.firestore.FieldPath.documemntId` to enable mocking
 * in tests.
 */
export function documentId() {
  return firebase.firestore.FieldPath.documentId();
}
