/**
 * Replaces occurances of an identifier in an expression.
 *
 * It does not replace occurances of the identifier string when it is used as
 * a function name.
 * @param expression The expression in which the identifier should be replaced.
 * @param search The identifier that is searched for and will get replaced.
 * @param replace The identifier that replaces the found occurances.
 * @returns The expression with the replaced identifier.
 */
export function replaceIdentifierHelper(
  expression: string,
  search: string,
  replace: string
): string {
  ////////////////////////////////////////////////////////////////
  // step 1, partition the input string into:
  //  - places to ignore (quoted regions)
  //  - places to perform replacements (unquoted regions)
  //    Note: this proposal would make things a lot easier:
  //      - https://github.com/tc39/proposal-regexp-match-indices
  ////////////////////////////////////////////////////////////////

  const regions: Array<ExpressionRegion> =
    ExpressionRegion.getRegions(expression);

  ////////////////////////////////////////////////////////////////
  // step 2 run replacement on the UNQUOTED regions
  ////////////////////////////////////////////////////////////////

  const replacedString = regions
    .map((region) => {
      const regionString = region.applyRange(expression);
      if (region.type === ExpressionRegionType.UNQUOTED) {
        // use regex /\b${undecorated}\b(?!\s*\()/gi to replace names
        const regexStr = `\\b${search}\\b(?!\\s*\\()`;
        const regex = RegExp(regexStr, "gi");
        const replacementStr = regionString.replace(regex, replace);
        return replacementStr;
      } else {
        return regionString;
      }
    })
    .join("");

  return replacedString;
}

/**
 * Finds all occurances of an identifier in an expression.
 *
 * It does not return occurances of the identifier string when it is used as
 * a function name.
 * @param expression The expression from which the identifiers should be returned.
 * @returns An array of all identifiers that have been found.
 */
export function getIdentifiersHelper(expression: string): string[] {
  ////////////////////////////////////////////////////////////////
  // step 1, partition the input string into:
  //  - places to ignore (quoted regions)
  //  - places to perform the search (unquoted regions)
  //    Note: this proposal would make things a lot easier:
  //      - https://github.com/tc39/proposal-regexp-match-indices
  ////////////////////////////////////////////////////////////////

  const regions: Array<ExpressionRegion> =
    ExpressionRegion.getRegions(expression);

  ////////////////////////////////////////////////////////////////
  // step 2 run search on the UNQUOTED regions
  ////////////////////////////////////////////////////////////////

  const result: string[] = [];
  for (const region of regions) {
    const regionString = region.applyRange(expression);
    if (region.type === ExpressionRegionType.UNQUOTED) {
      // TODO: with member functions (currently not used anyhow) a trailing '.'
      // is included in the identifier name
      const regex = /\b[a-zA-Z_][\w.]*\b(?!\s*\()/g;
      const identifiers = regionString.match(regex) ?? [];
      for (const identifier of identifiers) {
        if (!result.includes(identifier)) result.push(identifier);
      }
    }
  }

  return result;
}

/**
 * Finds all occurances of an identifier in an expression.
 *
 * It does not return occurances of the identifier string when it is used as
 * a function name.
 * @param expression The expression from which the identifiers should be returned.
 * @returns An array of all identifiers that have been found.
 */
export function getRegionsHelper(expression: string): ExpressionRegionExt[] {
  ////////////////////////////////////////////////////////////////
  // step 1, partition the input string into:
  //  - QUOTED regions (will be returned as is)
  //  - UNQUOTED regions that could contain identifiers
  ////////////////////////////////////////////////////////////////

  const regions: Array<ExpressionRegion> =
    ExpressionRegion.getRegions(expression);

  ////////////////////////////////////////////////////////////////
  // step 2 return the QUOTED regions as is and run search on the
  // UNQUOTED regions
  ////////////////////////////////////////////////////////////////

  const result: ExpressionRegionExt[] = [];
  for (const region of regions) {
    const regionString = region.applyRange(expression);
    if (region.type === ExpressionRegionType.QUOTED) {
      result.push({
        type: "quoted",
        content: regionString,
        begin: region.begin,
        end: region.end,
      });
    } else if (region.type === ExpressionRegionType.UNQUOTED) {
      // TODO: with member functions (currently not used anyhow) a trailing '.'
      // is included in the identifier name
      const regex = /\b[a-zA-Z_][\w.]*\b(?!\s*\()/g;
      let match;
      let previousEnd = 0;
      while ((match = regex.exec(regionString)) !== null) {
        // add an unnamed region if the first identifier is not found at the
        // beginning of the unquoted region
        if (match.index > previousEnd) {
          result.push({
            type: "",
            content: regionString.substring(previousEnd, match.index),
            begin: region.begin + previousEnd,
            end: region.begin + match.index,
          });
        }

        // add the identifier region
        result.push({
          type: "identifier",
          content: match[0],
          begin: region.begin + match.index,
          end: region.begin + regex.lastIndex,
        });

        previousEnd = regex.lastIndex;
      }

      // eventually add another unnamed region if the last identifier did not
      // end at the end of the unquoted region
      if (regionString.length > previousEnd) {
        result.push({
          type: "",
          content: regionString.substring(previousEnd, expression.length),
          begin: region.begin + previousEnd,
          end: region.begin + regionString.length,
        });
      }
    }
  }

  return result;
}

////////////////////////////////////////////////////////////////
// Internal Classes and Functions
////////////////////////////////////////////////////////////////

export type ExpressionRegionExt = {
  type: string;
  content: string;
  begin: number;
  end: number;
};

/**
 * Denotes whether a Region of text was quoted or not
 */
enum ExpressionRegionType {
  UNQUOTED,
  QUOTED,
}
/**
 * An ExpressionRegion denotes an area of text that is either quoted, or unquoted.
 *
 * Valid examples of quotes are: \"escaped\","double",'single',`back tick`
 */
export class ExpressionRegion {
  readonly type: ExpressionRegionType;
  readonly begin: number;
  readonly end: number;
  private _content: string | undefined;
  // used when replacing \" to a non-printable "group separator" character so we can match against it much easier
  private static _escapedQuoteReplacement = "\u001D";
  private static _quoteChars = [
    "`",
    "'",
    '"',
    ExpressionRegion._escapedQuoteReplacement,
  ];

  /**
   * Internal contructor, use the static factory methods QUOTED/UNQUOTED instead
   * @param type
   * @param begin
   * @param end
   */
  private constructor(type: ExpressionRegionType, begin: number, end: number) {
    this.type = type;
    this.begin = begin;
    this.end = end;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Factory methods for creating instances of this class
  /////////////////////////////////////////////////////////////////////////////
  /**
   * Create an ExpressionRegion of type QUOTED
   * @param begin beginning position of the region
   * @param end ending position of the region (the index of the character right
   *    after the region ends)
   * @returns an ExpressionRegion of type QUOTED
   */
  static QUOTED(begin: number, end: number): ExpressionRegion {
    return new ExpressionRegion(ExpressionRegionType.QUOTED, begin, end);
  }
  /**
   * Create an ExpressionRegion of type UNQUOTED
   * @param begin beginning position of the region
   * @param end ending position of the region (the index of the character right
   *    after the region ends)
   * @returns an ExpressionRegion of type UNQUOTED
   */
  static UNQUOTED(begin: number, end: number): ExpressionRegion {
    return new ExpressionRegion(ExpressionRegionType.UNQUOTED, begin, end);
  }
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Given a string, return the substring that results from applying this region's
   * begin/end positions (inclusive of end)
   * @param str
   * @returns the string resulting from applying the range of this region to the string passed in
   */
  applyRange(str: string): string {
    if (this._content === undefined) {
      this._content = str.substring(this.begin, this.end);
    }
    return this._content;
  }

  /**
   * Given an expression, return the Regions that are surrounded by quote characters
   * @param expression The expression to parse
   * @returns an array of Quoted ExpressionRegions
   */
  private static getQuotedRegions(expression: string): Array<ExpressionRegion> {
    const escapedExpression = expression.replace(
      /\\"/g,
      ExpressionRegion._escapedQuoteReplacement
    );

    // prettier-ignore
    const quotedRegExpStr = `([${ExpressionRegion._quoteChars.join("")}])(?:(?!\\1|\\\\).|\\\\.)*\\1`;
    const quotedRegExp = RegExp(quotedRegExpStr, "i");

    const quotedRegions: Array<ExpressionRegion> = [];
    let keepSearching = true;
    let remainderToProcess = escapedExpression;
    let expressionCursor = 0;

    while (keepSearching) {
      const matches = quotedRegExp.exec(remainderToProcess);
      if (matches) {
        const match = matches[0];
        const posFound = matches.index; // where in the processedString was this quote char found?
        // if this was as a result of us replacing \" with a single
        // non-printable character then we need to make sure that the end of the
        // Region gets added the number of removed `\` characters when this
        // region is applied against the original expression
        const addToEnd =
          match.match(RegExp(ExpressionRegion._escapedQuoteReplacement, "g"))
            ?.length ?? 0;
        expressionCursor += posFound;
        quotedRegions.push(
          ExpressionRegion.QUOTED(
            expressionCursor,
            expressionCursor + match.length + addToEnd
          )
        );
        remainderToProcess = remainderToProcess.substr(posFound + match.length); // don't need to add one here because we're dealing single char quotes here
        expressionCursor += match.length;
      } else {
        keepSearching = false;
      }
    }
    return quotedRegions;
  }

  /**
   * Given an expression, partition it into an array of distict ExpressionRegion values that denote areas
   * that are ignorable vs included when it comes to identifier replacement processing
   * @param expression The expression to parse
   * @returns an Array of Quoted and Unquoted ExpressionRegions for this given expression
   */
  static getRegions(expression: string): Array<ExpressionRegion> {
    const quotedRegions: Array<ExpressionRegion> =
      ExpressionRegion.getQuotedRegions(expression);

    const regions: Array<ExpressionRegion> = [];
    if (quotedRegions.length === 0) {
      // there were no regions to ignore so the whole expression is deemed fair game for replacement
      regions.push(ExpressionRegion.UNQUOTED(0, expression.length));
    } else {
      for (let i = 0; i < quotedRegions.length; i++) {
        const quotedRegion = quotedRegions[i];
        // Four scenarios
        // 1. the quoted region starts at the begining of the input
        if (quotedRegion.begin === 0) {
          regions.push(quotedRegion);
        } else {
          const prevRegion = regions.pop();
          if (prevRegion) {
            // 2. there was a previous region processed, so fill in the gap between it
            //    and this quoted region with an unquoted region
            regions.push(prevRegion);
            regions.push(
              ExpressionRegion.UNQUOTED(prevRegion.end, quotedRegion.begin)
            );
          } else {
            // 3. there wasn't a previous region, so make this unquoted region the first region
            regions.push(ExpressionRegion.UNQUOTED(0, quotedRegion.begin));
          }
          regions.push(quotedRegion);
        }
        // 4. Finally, if there's nothing after this quoted region, make the rest an unquoted region
        if (
          i + 1 === quotedRegions.length &&
          quotedRegion.end < expression.length
        ) {
          regions.push(
            ExpressionRegion.UNQUOTED(quotedRegion.end, expression.length)
          );
        }
      }
    }

    return regions;
  }
}
