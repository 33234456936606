import fetch from "node-fetch";
import YAML from "yaml";

import { Schema, SchemaLoader, SchemaManifest } from "./schema-global";

/**
 * Takes a JavaScript object and returns it as the schema object.
 */
export class ObjectSchemaLoader implements SchemaLoader {
  protected _schema: Schema;

  constructor(schema: Schema) {
    this._schema = schema;
  }

  getSchema(): Promise<Schema> {
    return Promise.resolve(this._schema);
  }
}

/**
 * Parses a JSON string and returns the schema object.
 */
export class StringJSONSchemaLoader implements SchemaLoader {
  protected _json: string;

  constructor(json: string) {
    this._json = json;
  }

  getSchema(): Promise<Schema> {
    try {
      return Promise.resolve(JSON.parse(this._json) as Schema);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

/**
 * Parses a YAML string and returns the schema object.
 */
export class StringYAMLSchemaLoader implements SchemaLoader {
  protected _yaml: string;

  constructor(yaml: string) {
    this._yaml = yaml;
  }

  getSchema(): Promise<Schema> {
    try {
      return Promise.resolve(
        YAML.parse(this._yaml, { prettyErrors: true }) as Schema
      );
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

/**
 * Fetches a JSON file via http and returns the parsed schema object.
 */
export class HttpJSONSchemaLoader implements SchemaLoader {
  protected _url: string;

  constructor(url: string) {
    this._url = url;
  }

  getSchema(): Promise<Schema> {
    return new Promise((resolve, reject) => {
      fetch(this._url, {
        method: "GET",
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
        .then((response) => response.json())
        .then((json) => resolve(json as Schema))
        .catch((err) => reject(err));
    });
  }
}

/**
 * Fetches a YAML file via http and returns the parsed schema object.
 */
export class HttpYAMLSchemaLoader implements SchemaLoader {
  protected _url: string;

  constructor(url: string) {
    this._url = url;
  }

  getSchema(): Promise<Schema> {
    return new Promise((resolve, reject) => {
      fetch(this._url, {
        method: "GET",
      })
        .then((response) => response.text())
        .then((text) =>
          resolve(YAML.parse(text, { prettyErrors: true }) as Schema)
        )
        .catch((err) => reject(err));
    });
  }
}

const trailingSlash = /\/$/;
const yamlExt = /\.yaml$/;

/**
 * Fetches and assembles multiple YAML files into a schema object.
 *
 * The file names must be listed in a file directly under the path
 * passed to `getSchema` called `manifest.yaml`, and the files listed
 * therein must exist at the same level. The contents of each file are
 * injected into the schema object as a property at the top level named
 * as the file name without the .yaml extension. For example,
 * `Widget.yaml` becomes `Widget: {...}` in the schema object.
 */
export class HttpYAMLFolderSchemaLoader implements SchemaLoader {
  private _path: string;

  constructor(path: string) {
    this._path = path.replace(trailingSlash, "");
  }

  private async assembleSchemaFiles(): Promise<Schema> {
    const manifestUrl = `${this._path}/manifest.yaml`;
    const manifest = await fetch(manifestUrl)
      .then((r) => r.text())
      .then((t) => YAML.parse(t, { prettyErrors: true }) as SchemaManifest);
    const promises = manifest.files.map((f) =>
      fetch(`${this._path}/${f}`)
        .then((r) => r.text())
        .then((t) => YAML.parse(t, { prettyErrors: true }) as Schema)
    );
    const parsed = await Promise.all(promises);
    const schema = {} as Schema;
    parsed.forEach((fragment, i) => {
      const name = manifest.files[i].replace(yamlExt, "");
      if (name in schema) {
        throw new Error(`multipe schema fragments named "${name}" detected`);
      }
      schema[name] = fragment;
    });
    // console.log(`assembled schema for ${manifestUrl}:`, schema);
    return schema;
  }

  getSchema(): Promise<Schema> {
    return this.assembleSchemaFiles();
  }

  /**
   * Returns true if `manifest.yaml` exists directly under `path`.
   * Performs a HEAD request and looks for a content type that starts
   * with `text/yaml` .
   */
  static async manifestExists(path: string): Promise<boolean> {
    path = path.replace(trailingSlash, "");
    const response = await fetch(`${path}/manifest.yaml`, { method: "HEAD" });
    return (
      response.status >= 200 &&
      response.status < 300 &&
      (response.headers.get("content-type")?.startsWith("text/yaml") ?? false)
    );
  }
}
