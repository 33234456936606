import Decimal from "decimal.js";
import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { toNumber } from "./behavior-commons";

export function createMathematicalFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "sqrt":
      return new sqrtFunctionBehaviorNode(token, mode);
    case "abs":
      return new absFunctionBehaviorNode(token, mode);
    case "ceil":
      return new ceilFunctionBehaviorNode(token, mode);
    case "floor":
      return new floorFunctionBehaviorNode(token, mode);
    case "pow":
      return new powFunctionBehaviorNode(token, mode);
    case "round":
      return new roundFunctionBehaviorNode(token, mode);
    case "sign":
      return new signFunctionBehaviorNode(token, mode);
    case "trunc":
      return new truncFunctionBehaviorNode(token, mode);
    case "rand":
    case "random":
      return new randomFunctionBehaviorNode(token, mode);
  }
}

/**
 * Returns the square root of a number.
 * @name sqrt() function
 * @param number The number from which to compute the square root.
 * @returns The square root of the number.
 */
class sqrtFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.sqrt(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Returns the absolute value of a number.
 * @name abs() function
 * @param number The number from which to compute the absolute value.
 * @returns The absolute value of number.
 */
class absFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.abs(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Rounds a number up to the next largest integer.
 * @name ceil() function
 * @param number The number to round up.
 * @returns The next largest integer.
 */
class ceilFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.ceil(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Returns the largest integer less than or equal to a given number.
 * @name floor() function
 * @param number The number from where to get the largest interger less or equal than.
 * @returns The integer less than or equal.
 */
class floorFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.floor(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Returns the `base` to the `exponent` power, as in `base^exponent`.
 * @name pow() function
 * @param base The base.
 * @param exponent The exponent.
 * @returns The `base` to the `exponent` power.
 */
class powFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.pow(
        toNumber(this.arguments[0].evaluate(scope, calledFrom)),
        toNumber(this.arguments[1].evaluate(scope, calledFrom))
      )
    );
  }
}

/**
 * Returns the value of a number rounded to the nearest number with a set number of fractional digits.
 * @name round() function
 * @param number The number to be rounded.
 * @param digits The number of fractional digits. 0 by default.
 * @returns The nearest number to `number` with `digits` fractional digits.
 */
class roundFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    if (this.arguments.length > 1) {
      const digits = toNumber(this.arguments[1].evaluate(scope, calledFrom));
      const factor = Decimal.pow(10, digits);
      return Number(
        Decimal.round(
          Decimal.mul(
            toNumber(this.arguments[0].evaluate(scope, calledFrom)),
            factor
          )
        ).div(factor)
      );
    } else {
      return Number(
        Decimal.round(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
      );
    }
  }
}

/**
 * Returns either a positive or negative +/- 1, indicating the sign of a number
 * passed into the argument. If the number passed into is 0, it
 * will return 0.
 * @name sign() function
 * @param number The number of which the sign shall be returned.
 * @returns The sign of `number`.
 */
class signFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.sign(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Returns the integer part of a number by removing any fractional digits.
 * @name trunc() function
 * @param number The number from which to remove the fractional digits.
 * @returns The integer part of `number`.
 */
class truncFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return Number(
      Decimal.trunc(toNumber(this.arguments[0].evaluate(scope, calledFrom)))
    );
  }
}

/**
 * Returns a floating-point, pseudo-random number in the range 0 to less than 1 (inclusive of 0, but not 1).
 *
 * Be aware that this function returns a new value on every call so that a cell
 * value will continuously be updated.
 * @name random() function (or alternatively `rand()`)
 * @returns A pseudo-random number.
 */
class randomFunctionBehaviorNode implements BehaviorNode {
  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    getArgumentNodes(token, mode, 0, 0);
  }

  public evaluate(): number {
    return Number(Decimal.random());
  }
}
