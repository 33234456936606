import queueFrame from "@shared/src/classes/frame-queue";
import {
  InitializableComponent,
  ComponentInvoker,
  Observer,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

/**
 * Represents the observable web browser context. Notifies observers
 * whenever browser context is changed. New subscribers immediately receive
 * the last-updated context.
 */
class UIContext {
  private _observers = new Set<Partial<Observer<UIContext>>>();
  private _path: string;

  constructor() {
    this._path = window.location.pathname;
    window.addEventListener("popstate", this.onPopState);
  }

  public get path(): string {
    return this._path;
  }

  public set path(path: string) {
    if (this._path === path) return;
    history.pushState(null, "", path);
    this._path = window.location.pathname;
    this.notifyObservers();
  }

  private onPopState = () => {
    this._path = window.location.pathname;
    this.notifyObservers();
  };

  public subscribe(observer: Partial<Observer<UIContext>>): Unsubscribable {
    this._observers.add(observer);
    observer.next?.(this);
    return { unsubscribe: () => this._observers.delete(observer) };
  }

  private notifyObservers(): void {
    for (const o of this._observers) {
      o.next?.(this);
    }
  }
}

export const uiContext = new UIContext();

/**
 * Exposes the web browser context as a system component.
 */
export default class UIContextComponent implements InitializableComponent {
  private _invokedBy: ComponentInvoker;
  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Returns the schema:
   * ```
   * {
   *   // `setPath` navigates the application to a new path if truthy
   *   setPath: { _input: null },
   *   // `path` returns the absolute path according to
   *   // https://developer.mozilla.org/en-US/docs/Web/API/Location/pathname
   *   path: { _output: null },
   *   // `segments` is an array of all segments within the path
   *   segments: {
   *     _iscollection: true,
   *     _output: null,
   *   },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {
      setPath: { _input: null },
      path: { _output: null },
      segments: {
        _iscollection: true,
        _output: null,
      },
    };
  }

  init(): void {
    this._contextSubscription = uiContext.subscribe({
      next: () => {
        // Propagate changes to path to the invoker
        queueFrame(() =>
          this._invokedBy.setOutputValueGraph(this.getOutputValueGraph())
        );
      },
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "setpath":
          // do not set the path if the value is falsy (null or empty string)
          if (value) uiContext.path = value.toString();
          break;
      }
    });
  }

  getOutputValueGraph(): ValueGraph {
    // remove leading and trailing slashes
    const path = uiContext.path.trim().replace(/^\/+|\/+$/g, "");
    return {
      path: uiContext.path,
      segments: path ? path.split("/") : [],
    };
  }
}
