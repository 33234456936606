/**
 * Represents a QuickBooks error.
 */
export class QuickBooksError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * QuickBooks API error codes.
 */
export enum ErrorCode {
  /**
   * Auth token does not exist or the refresh token has expired. User
   * needs to (re-)authorize the Hyperseed QuickBooks app.
   */
  NEED_AUTH = "qbo/need-auth",
  /**
   * User authorization was attempted but failed.
   */
  AUTH_FAILED = "qbo/auth-failed",
}

/**
 * Represents a QuickBooks API request executed in Functions on behalf
 * of the web client or the backend.
 */
export interface APIRequest {
  /**
   * The ID of the relevant Hyperseed organization.
   */
  orgId: string;
  /**
   * The HTTP method used for the QuickBooks API request.
   */
  method: "DELETE" | "GET" | "POST" | "PUT";
  /**
   * Absolute URL path of the QuickBooks API resource. Example:
   * `/v3/company/${realmId}/companyinfo/${realmId}`
   */
  path: string;
  /**
   * Optionally holds the request body for POST, PUT, etc.
   */
  body?: unknown;
}

/**
 * Represents the response of a QuickBooks API request executed on the
 * Hyperseed Functions backend on behalf of the web client or the
 * backend.
 */
export interface APIResponse {
  /**
   * HTTP response code of the backend QuickBooks API call. Example: 200
   */
  status: number;
  /**
   * HTTP response text of the backend QuickBooks API call. Example: "OK"
   */
  statusText: string;
  /**
   * Error code representing a QuickBooks API error seen by the
   * Hyperseed backend.
   */
  errorCode?: ErrorCode;
  /**
   * Optionally holds the response body received by the Hyperseed
   * backend e.g., `AuthResponse`, `GetCommpanyResponse`, etc.
   */
  body?: unknown;
}

/**
 * Represents a connection operation.
 */
export type ConnectionOp = "connect" | "disconnect" | "check" | "unknown";

/**
 * Represents a `quickbooks-connection` request.
 */
export interface ConnectionRequest {
  /**
   * The ID of the relevant organization.
   */
  orgId: string;
  /**
   * The connection operation to execute.
   */
  op: ConnectionOp;
}

/**
 * Represents a `quickbooks-connection` response.
 */
export interface ConnectionResponse {
  /**
   * The realm ID identifying the customer's QuickBooks company,
   * populated on a successful connection.
   */
  realmId?: string;
  /**
   * Error code representing a QuickBooks API error seen by the
   * Hyperseed backend.
   */
  errorCode?: ErrorCode;
}

/**
 * Represents a `quickbooks-getAuthorizeUri` request.
 */
export interface GetAuthorizeUriRequest {
  /**
   * Unique session token used to mitigate CSRF attacks.
   * @see https://developer.intuit.com/app/developer/qbo/docs/develop/authentication-and-authorization/faq#protect-against-csrf
   */
  state: string;
}

/**
 * Represents a `quickbooks-getAuthorizeUri` response.
 */
export interface GetAuthorizeUriResponse {
  uri: string;
}

/**
 * Represents a `quickbooks-createAuthToken` request.
 */
export interface CreateAuthTokenRequest {
  orgId: string;
  uri: string;
}

/**
 * Represents a `quickbooks-apiCall` response for a request to read company info.
 * @see https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/companyinfo#read-companyinfo
 */
export interface GetCommpanyResponse {
  CompanyInfo: CompanyInfo;
  time: Date;
}

/**
 * Represents a `quickbooks-apiCall` request to create an invoice.
 * @see https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/invoice#create-an-invoice
 */
export interface CreateInvoiceRequest {
  CustomerRef: CustomerRef;
  Line: Line[];
}

/**
 * Represents a `quickbooks-apiCall` response for a request to create an invoice.
 * @see https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/invoice#create-an-invoice
 */
export interface CreateInvoiceResponse {
  Invoice: Invoice;
}

/**
 * Represents a physical address.
 */
export interface Address {
  Line1: string;
  Line2: string;
  City: string;
  CountrySubDivisionCode: string;
  PostalCode: string;
  Lat: string;
  Long: string;
  Id: string;
}

/**
 * Represents a Hyperseed client's QuickBooks basic company
 * information.
 * @see https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/companyinfo
 */
export interface CompanyInfo {
  CompanyName: string;
  CompanyAddr: Address;
}

interface CustomerRef {
  value: string;
}

interface ItemRef {
  name: string;
  value: string;
}

interface SalesItemLineDetail {
  ItemRef: ItemRef;
}

interface Line {
  DetailType: "SalesItemLineDetail" | "SubTotalLineDetail";
  Amount: number;
  SalesItemLineDetail: SalesItemLineDetail;
}

interface CustomField {
  DefinitionId: string;
  Type: "StringType";
  Name: string;
}

interface TxnTaxDetail {
  TotalTax: number;
}

interface MetaData {
  CreateTime: Date;
  LastUpdatedTime: Date;
}

/**
 * Represents a QuickBooks invoice.
 * @see https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/invoice
 */
interface Invoice {
  DocNumber: string;
  SyncToken: string;
  domain: "QBO";
  Balance: number;
  BillAddr: Address;
  TxnDate: string;
  TotalAmt: number;
  CustomerRef: CustomerRef;
  ShipAddr: Address;
  LinkedTxn: string[];
  DueDate: string;
  PrintStatus: "NeedToPrint";
  Deposit: number;
  sparse: boolean;
  EmailStatus: "NotSet";
  Line: Line[];
  ApplyTaxAfterDiscount: boolean;
  CustomField: CustomField[];
  Id: string;
  TxnTaxDetail: TxnTaxDetail;
  MetaData: MetaData;
  time: Date;
}
