import * as contentType from "content-type";
import firebase from "firebase/app";
import "firebase/storage";

import queueFrame from "@shared/src/classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

enum Status {
  READ_PENDING = "read-pending",
  READ_FAILED = "read-failed",
  READ_SUCCEEDED = "read-succeeded",
}

/**
 * Supported MIME types. Any type that can be represented as a
 * TypeScript string can be added to `validTypes`.
 */
const validTypes = new Set(["application/x-yaml", "text/plain"]);

/**
 * Reads a plain text file from Cloud Storage.
 */
export default class StorageReadComponent implements Component {
  private _invokedBy: ComponentInvoker;

  /** Triggers the Storage read when true */
  private _read = false;
  /** Cloud Storage bucket (null indicates the default bucket) */
  private _bucket: string | null = null;
  /** Cloud Storage path to read */
  private _path: string | null = null;

  /** The status after read has been triggered */
  private _status: Status | null = null;
  /** The error message if an error occured during read */
  private _error: string | null = null;

  /** The string data read from Cloud Storage */
  private _data: string | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      bucket: { _input: null },
      path: { _input: null },
      read: { _input: false },

      error: { _output: null },
      status: { _output: null },
      data: { _output: null },
    };
  }

  destroy(): void {
    // nothing to be destroyed
  }

  setInputValueGraph(graph: ValueGraph): void {
    console.log("graph:", graph);
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "read":
          this._read = Boolean(value);
          break;
        case "bucket":
          this._bucket = value?.toString() ?? null;
          break;
        case "path":
          this._path = value?.toString() ?? null;
          break;
      }
    });

    // TODO: call read only when the value turns true
    if (this._read) queueFrame(() => this.callRead());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      status: this._status,
      error: this._error,
      data: this._data,
    };
  }

  /**
   * Calls the asynchronuous read method and sets the output properties
   * of the component accordingly.
   */
  private callRead() {
    this._status = Status.READ_PENDING;
    this._error = null;
    this.pushOutputs();

    this.read(this._bucket?.toString() ?? null, this._path?.toString() ?? "")
      .then((data: string) => {
        this._error = null;
        this._status = Status.READ_SUCCEEDED;
        this._data = data;
        this.pushOutputs();
      })
      .catch((err) => {
        this._error = (err as { code: string }).code ?? "read failed";
        this._status = Status.READ_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Reads a file from Cloud Storage.
   *
   * @param bucket the bucket name; if null, the default bucket is used
   * @param path the path to the Cloud Storage file
   * @returns the file contents as a string
   * @throws Error if read failed
   */
  private async read(bucket: string | null, path: string): Promise<string> {
    try {
      const storage =
        bucket === null ? firebase.storage() : firebase.app().storage(bucket);
      const storageRef = storage.ref(path);
      const url = (await storageRef.getDownloadURL()) as string;
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) {
        throw {
          code: "hyperseed/bad-response",
          message: `bad response code "${response.status}"`,
        };
      }
      const metadata = (await storageRef.getMetadata()) as {
        contentType: string;
      };
      const mimeType = contentType.parse(metadata.contentType).type;
      if (!validTypes.has(mimeType)) {
        throw {
          code: "hyperseed/invalid-mime-type",
          message: `unsupported MIME type "${mimeType}"`,
        };
      }
      return response.text();
    } catch (err) {
      console.error("read from Cloud Storage failed:", err);
      throw err;
    }
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
