import { ComponentInvoker } from "../interfaces/global-interfaces";
import UIElementComponent from "./ui-element-component";

/**
 * Represents an SVG g element in the DOM tree. Details about
 * the g element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/g
 */
export default class SVGGroupComponent extends UIElementComponent {
  protected _DOMNode: SVGElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElementNS("http://www.w3.org/2000/svg", "g");
  }

  get DOMNode(): SVGElement {
    return this._DOMNode;
  }
}
