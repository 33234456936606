import queueFrame from "@shared/src/classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

import { environment } from "../environments/environment";

// Google Sheets API reference:
// https://developers.google.com/sheets/api/reference/rest

interface Row {
  columns: string[];
}

/**
 * Represents spreadsheet data in the format passed from this component
 * to its invoking component.
 */
interface Data {
  rows: Row[];
}

/**
 * Respresents spreadsheet data in the format passed from the invoking
 * component to this component.
 */
interface SetData {
  range: string;
  rows: Row[];
}

/**
 * Respresents spreadsheet data in the format exchanged between this
 * component and the Sheets API.
 * https://developers.google.com/sheets/api/reference/rest/v4/spreadsheets.values#ValueRange
 */
interface ValueRange {
  range: string;
  majorDimension: "ROWS";
  values: string[][];
}

export interface Response {
  statuscode: number;
  statustext: string;
}

enum Status {
  IDLE = "request-idle",
  PENDING = "request-pending",
  SUCCEEDED = "request-succeeded",
  FAILED = "request-failed",
}

/** Local storage key for OAuth access token. */
const ACCESS_TOKEN_KEY = "SheetsComponent.accessToken";

/**
 * The Sheets component allows caller to view data from a Google
 * Spreadsheet.
 */
export default class SheetsComponent implements Component {
  private _invokedBy: ComponentInvoker;
  private _spreadsheetId?: string;
  private _valueRenderOption?: string;
  private _dateTimeRenderOption?: string;
  private _apiKey?: string;
  private _range?: string;
  private _response: Response | null = null;
  private _data: Data | null = null;
  private _dataReady = false;
  private _accessToken?: string;

  /** The API request status */
  private _status: Status = Status.IDLE;
  /** The error message if API request failed */
  private _error: string | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      authenticate: {
        _input: false,
      },
      get: {
        _input: false,
      },
      set: {
        _input: false,
      },
      apiKey: {
        _input: null,
      },
      spreadsheetid: {
        _input: null,
      },
      range: {
        _input: null,
      },
      valuerenderoption: {
        _input: null,
      },
      datetimerenderoption: {
        _input: null,
      },
      setData: {
        _input: null,
        range: {
          _input: null,
        },
        rows: {
          _iscollection: true,
          _input: null,
          columns: {
            _iscollection: true,
            _input: null,
          },
        },
      },
      response: {
        _output: {},
        statustext: {
          _output: {},
        },
        statuscode: {
          _output: {},
        },
      },
      data: {
        _output: {},
        rows: {
          _iscollection: true,
          _output: {},
          columns: {
            _iscollection: true,
            _output: {},
          },
        },
      },
      dataReady: {
        _output: {},
      },
      status: {
        _output: {},
      },
      error: {
        _output: {},
      },
    };
  }

  destroy(): void {
    // nothing to destroy
  }

  private reset() {
    this._dataReady = false;
    this.pushOutputs();
  }

  /**
   * Initiates OAuth authentication.
   *
   * Authentication involves submitting a form, which causes a redirect
   * to the auth server and then back to the original URL. The resulting
   * URL contains output from the authentication system in the URL
   * fragment.
   */
  private oauthSignIn(): void {
    if (window.location.hash) window.location.hash = "";
    console.log("authenticating...");
    const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    const form = document.createElement("form");
    form.setAttribute("method", "GET");
    form.setAttribute("action", oauth2Endpoint);
    const redirect_uri = window.location.toString().replace(/#$/, "");
    const params = {
      client_id: environment.sheetsApi.clientId,
      redirect_uri,
      response_type: "token",
      scope: "https://www.googleapis.com/auth/spreadsheets",
      include_granted_scopes: "true",
      state: "auth",
    };
    for (const [name, value] of Object.entries(params)) {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", name);
      input.setAttribute("value", value);
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
  }

  getAccessToken(): string | null {
    if (this._accessToken) return this._accessToken;
    return window.localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  /**
   * Checks URL fragment for OAuth redirect data. If the redirect data
   * is present and authentication was successful, rewrites the fragment
   * to reflect authenticated state.
   */
  private checkUrlFragment() {
    if (!window.location.hash) return;
    const fragment = window.location.hash.slice(1); // strip leading '#'
    const params = new Map<string, string>();
    fragment.split("&").map((pair) => {
      const [key, value] = pair.split("=");
      params.set(key, decodeURIComponent(value));
    });
    if (params.has("access_token")) {
      this._accessToken = params.get("access_token");
      if (this._accessToken)
        window.localStorage.setItem(ACCESS_TOKEN_KEY, this._accessToken);
      window.location.hash = "";
    } else {
      console.error("oauth error:", params.get("error"), window.location.hash);
    }
  }

  /**
   * Transforms a ValueRange object received from the Sheets API to data
   * to be passed to the invoking component.
   */
  private transformValueRange(payload: ValueRange): Data {
    const data: Data = { rows: [] };
    payload.values.map((value) => data.rows.push({ columns: value }));
    return data;
  }

  /**
   * Transforms data received from the invoking component to a
   * ValueRange object to be sent to the Sheets API.
   */
  private transformSetData(setData: SetData): ValueRange {
    const valueRange: ValueRange = {
      range: setData.range,
      majorDimension: "ROWS",
      values: [],
    };
    for (const row of setData.rows) {
      const columns: string[] = [];
      row.columns.map((c) => columns.push(c));
      valueRange.values.push(columns);
    }
    return valueRange;
  }

  private get(): void {
    if (this._spreadsheetId === undefined) return;
    if (this._range === undefined) return;
    this._response = null;
    this._error = null;
    this._status = Status.PENDING;
    this.pushOutputs();
    let response: Response;
    const fetchInit = {
      headers: new Headers(),
      method: "GET",
    };
    const accessToken = this.getAccessToken();
    if (accessToken) {
      fetchInit.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    const [key, id, range] = [
      this._apiKey,
      this._spreadsheetId,
      encodeURIComponent(this._range),
    ];
    let url = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${range}`;
    const query = [];
    if (key) query.push(`key=${key}`);
    if (this._valueRenderOption) {
      query.push(`valueRenderOption=${this._valueRenderOption}`);
    }
    if (this._dateTimeRenderOption) {
      query.push(`dateTimeRenderOption=${this._dateTimeRenderOption}`);
    }
    if (query.length > 0) url += "?" + query.join("&");
    void fetch(url, fetchInit)
      .then((resp) => {
        response = {
          statustext: resp.statusText,
          statuscode: resp.status,
        };
        this._status = resp.status < 400 ? Status.SUCCEEDED : Status.FAILED;
        return resp.json();
      })
      .then((data) => {
        this._response = response;
        if (this._status === Status.FAILED) {
          this._error = (data as { error: { message: string } }).error.message;
          console.error("Sheets API error:", this._error);
          queueFrame(() => this.reset());
          return;
        }
        this._data = this.transformValueRange(data as ValueRange);
        this._dataReady = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((error) => {
        console.error("Sheets API error:", error);
        this._error =
          error instanceof Error ? error.message : (error as string);
        this._status = Status.FAILED;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  private update(setData: SetData): void {
    if (this._spreadsheetId === undefined) return;
    if (this._range === undefined) return;
    this._response = null;
    this._error = null;
    this._status = Status.PENDING;
    this.pushOutputs();
    let response: Response;
    const fetchInit = {
      headers: new Headers(),
      method: "PUT",
      body: JSON.stringify(this.transformSetData(setData)),
    };
    const accessToken = this.getAccessToken();
    if (accessToken) {
      fetchInit.headers.set("Authorization", `Bearer ${accessToken}`);
    }
    const [key, id, range] = [
      this._apiKey,
      this._spreadsheetId,
      encodeURIComponent(this._range),
    ];
    let url = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${range}`;
    // https://developers.google.com/sheets/api/reference/rest/v4/ValueInputOption
    const query = ["valueInputOption=USER_ENTERED"];
    if (key) query.push(`key=${key}`);
    if (query.length > 0) url += "?" + query.join("&");
    void fetch(url, fetchInit)
      .then((resp) => {
        response = {
          statustext: resp.statusText,
          statuscode: resp.status,
        };
        this._status = resp.status < 400 ? Status.SUCCEEDED : Status.FAILED;
        return resp.json();
      })
      .then((data) => {
        console.log("Sheets API response data:", data);
        this._response = response;
        if (this._status === Status.FAILED) {
          this._error = (data as { error: { message: string } }).error.message;
          console.error("Sheets API error:", this._error);
        }
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((error) => {
        console.error("Sheets API error:", error);
        this._error =
          error instanceof Error ? error.message : (error as string);
        this._status = Status.FAILED;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  setInputValueGraph(graph: ValueGraph): void {
    let authenticate = false;
    let get = false;
    let set = false;
    let apiKey: string | undefined;
    let spreadsheetId: string | undefined;
    let range: string | undefined;
    let valueRenderOption: string | undefined;
    let dateTimeRenderOption: string | undefined;
    let setData: SetData;
    this.checkUrlFragment();
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "authenticate":
          authenticate = Boolean(value);
          break;
        case "get":
          get = Boolean(value);
          break;
        case "set":
          set = Boolean(value);
          break;
        case "apikey":
          apiKey = value?.toString();
          break;
        case "spreadsheetid":
          spreadsheetId = value?.toString();
          break;
        case "range":
          range = value?.toString();
          break;
        case "valuerenderoption":
          valueRenderOption = value?.toString();
          break;
        case "datetimerenderoption":
          dateTimeRenderOption = value?.toString();
          break;
        case "setdata":
          setData = value as unknown as SetData;
          break;
      }
    });
    if (authenticate) {
      this.oauthSignIn();
      return;
    }
    if (spreadsheetId === undefined) return;
    // TODO: Consider aborting request if inputs are updated while
    // status is pending.
    if (this._status === Status.PENDING) return;
    this._spreadsheetId = spreadsheetId;
    this._apiKey = apiKey;
    this._range = range;
    this._valueRenderOption = valueRenderOption;
    this._dateTimeRenderOption = dateTimeRenderOption;
    if (get) queueFrame(() => this.get());
    else if (set) queueFrame(() => this.update(setData));
  }

  getOutputValueGraph(): ValueGraph {
    return {
      response: this._response as ValueGraphData,
      data: this._data as ValueGraphData,
      dataReady: this._dataReady,
      status: this._status,
      error: this._error,
    };
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
