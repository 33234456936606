import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIElementComponent from "./ui-element-component";

/**
 * Represents an SVG use element in the DOM tree. Details about the use
 * element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/use
 */
export default class SVGUseComponent extends UIElementComponent {
  protected _DOMNode: SVGUseElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "use"
    );
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.href = { _input: null };
    schema.x = { _input: null };
    schema.y = { _input: null };
    schema.width = { _input: null };
    schema.height = { _input: null };
    return schema;
  }

  get DOMNode(): SVGUseElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "href":
      case "x":
      case "y":
      case "width":
      case "height":
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
    }
  }
}
