import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import Decimal from "decimal.js";
import jsep from "jsep";

import Cell from "../classes/cell";
import {
  CellNameWalker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { toDatetime, toNumber } from "./behavior-commons";

// import timezone plugin
dayjs.extend(timezone);
dayjs.extend(utc);

export function createDateTimeFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "date":
      return new dateFunctionBehaviorNode(token, mode);
    case "time":
      return new timeFunctionBehaviorNode(token, mode);
    case "datetrunc":
      return new dateTruncFunctionBehaviorNode(token, mode);
    case "year":
      return new yearFunctionBehaviorNode(token, mode);
    case "quarter":
      return new quarterFunctionBehaviorNode(token, mode);
    case "month":
      return new monthFunctionBehaviorNode(token, mode);
    case "day":
      return new dayFunctionBehaviorNode(token, mode);
    case "hour":
      return new hourFunctionBehaviorNode(token, mode);
    case "minute":
      return new minuteFunctionBehaviorNode(token, mode);
    case "second":
      return new secondFunctionBehaviorNode(token, mode);
    case "millisecond":
      return new millisecondFunctionBehaviorNode(token, mode);
    case "weekday":
      return new weekdayFunctionBehaviorNode(token, mode);
    case "isoweekday":
      return new isoWeekdayFunctionBehaviorNode(token, mode);
    case "dateadd":
      return new dateAddFunctionBehaviorNode(token, mode);
    case "datesub":
      return new dateSubFunctionBehaviorNode(token, mode);
    case "dateanchor":
      return new dateAnchorFunctionBehaviorNode(token, mode);
    case "datesequence":
      return new dateSequenceFunctionBehaviorNode(token, mode);
    case "edate":
      return new eDateFunctionBehaviorNode(token, mode);
    case "bomonth":
      return new boMonthFunctionBehaviorNode(token, mode);
    case "eomonth":
      return new eoMonthFunctionBehaviorNode(token, mode);
    case "datediff":
    case "datedif":
      return new dateDiffFunctionBehaviorNode(token, mode);
    case "days":
      return new daysFunctionBehaviorNode(token, mode);
    case "now":
      return new nowFunctionBehaviorNode(token, mode);
    case "today":
      return new todayFunctionBehaviorNode(token, mode);
    case "totimezone":
      return new toTimezoneFunctionBehaviorNode(token, mode);
  }
}

/*********************************************************
 * Functions used within the date function classes
 *********************************************************/

/**
 * Adds a number of dateparts to a given date.
 * @param start The start date to add the dateparts to.
 * @param part The datepart to be added.
 * @param number The number of dateparts to be added.
 * @returns The date where `part` has been added `number` times. If `part` is
 *    `month`, `quarter` or `year` then if the `date` day does not exist in the
 *    return month, then the last day of the return month is returned, while the
 *    time of the day is kept.
 */
function addToDate(start: Date, part: string, number: number): Date {
  const date = new Date(start);
  switch (part.trim().toLowerCase()) {
    case "millisecond":
      date.setUTCMilliseconds(date.getUTCMilliseconds() + number);
      return date;
    case "second":
      date.setUTCSeconds(date.getUTCSeconds() + number);
      return date;
    case "minute":
      date.setUTCMinutes(date.getUTCMinutes() + number);
      return date;
    case "hour":
      date.setUTCHours(date.getUTCHours() + number);
      return date;
    case "day":
      date.setUTCDate(date.getUTCDate() + number);
      return date;
    case "week":
      date.setUTCDate(date.getUTCDate() + number * 7);
      return date;
    case "month": {
      // calculate the end of month day of the target month
      const eomonth = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + number + 1, 0)
      );
      // if day does not exist in target month, amend the day of month
      if (date.getUTCDate() > eomonth.getUTCDate())
        date.setUTCDate(eomonth.getUTCDate());
      date.setUTCMonth(date.getUTCMonth() + number);
      return date;
    }
    case "quarter": {
      // calculate the end of month day of the target month
      const eomonth = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + number * 3 + 1, 0)
      );
      // if day does not exist in target month, amend the day of month
      if (date.getUTCDate() > eomonth.getUTCDate())
        date.setUTCDate(eomonth.getUTCDate());
      date.setUTCMonth(date.getUTCMonth() + number * 3);
      return date;
    }
    case "year": {
      // calculate the end of month day of the target month
      const eomonth = new Date(
        Date.UTC(date.getUTCFullYear() + number, date.getUTCMonth() + 1, 0)
      );
      // if day does not exist in target month, amend the day of month
      if (date.getUTCDate() > eomonth.getUTCDate())
        date.setUTCDate(eomonth.getUTCDate());
      date.setUTCFullYear(date.getUTCFullYear() + number);
      return date;
    }
    default:
      throw new Error("Unsupported part parameter in dateAdd() function");
  }
}

/**
 * Calculates the difference between the specified UTC start and end datetime.
 * The difference is calculated by counting of the specified `part` boundaries
 * crossed between `start` and `end`.
 * @param start The start datetime.
 * @param end The end datetime.
 * @param part The datepart to be counted.
 * @returns The count of `part` between `start` and `end`.
 */
function dateDiff(start: Date, end: Date, part: string): number {
  switch (part.trim().toLowerCase()) {
    case "millisecond":
      return Number(end) - Number(start);
    case "second":
      return Number(
        Decimal.trunc(Decimal.div(Number(end), 1000)).sub(
          Decimal.trunc(Decimal.div(Number(start), 1000))
        )
      );
    case "minute":
      return Number(
        Decimal.trunc(Decimal.div(Number(end), 60000)).sub(
          Decimal.trunc(Decimal.div(Number(start), 60000))
        )
      );
    case "hour":
      return Number(
        Decimal.trunc(Decimal.div(Number(end), 3600000)).sub(
          Decimal.trunc(Decimal.div(Number(start), 3600000))
        )
      );
    case "day":
      return Number(
        Decimal.trunc(Decimal.div(Number(end), 86400000)).sub(
          Decimal.trunc(Decimal.div(Number(start), 86400000))
        )
      );
    case "week":
      // calculating with Sunday, Jan 4, 1970 as start of the first week
      return Number(
        Decimal.trunc(Decimal.div(Number(end) - 86400000 * 3, 604800000)).sub(
          Decimal.trunc(Decimal.div(Number(start) - 86400000 * 3, 604800000))
        )
      );
    case "isoweek":
      // calculating with Monday, Jan 5, 1970 as start of the first week
      return Number(
        Decimal.trunc(Decimal.div(Number(end) - 86400000 * 4, 604800000)).sub(
          Decimal.trunc(Decimal.div(Number(start) - 86400000 * 4, 604800000))
        )
      );
    case "month":
      return (
        // prettier-ignore
        (end.getUTCFullYear() * 12 + end.getUTCMonth()) -
        (start.getUTCFullYear() * 12 + start.getUTCMonth())
      );
    case "quarter":
      return (
        // prettier-ignore
        (end.getUTCFullYear() * 4 + Math.trunc(end.getUTCMonth() / 3)) -
        (start.getUTCFullYear() * 4 + Math.trunc(start.getUTCMonth() / 3))
      );
    case "year":
      return end.getUTCFullYear() - start.getUTCFullYear();
    default:
      throw new Error("Unsupported part parameter in dateDiff() function");
  }
}

/*********************************************************
 * Classes implemeneting the behavior functions
 *********************************************************/

/**
 * Converts values for year, month, day, houres, minutes, seconds, milliseconds
 * into a UTC datetime value.
 * @name date() function
 * @param year The year portion of the date.
 * @param month (optional) The month portion of the date (1= January).
 * @param day (optional) The day portion of the date.
 * @param hour (optional) The hours portion of the time.
 * @param minute (optional) The minutes portion of the time.
 * @param second (optional) The seconds portion of the time.
 * @param millisecond (optional) The milliseconds portion of the time.
 * @returns A datetime value representing the datetime.
 */
class dateFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 7);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const year = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const month =
      this.arguments.length > 1
        ? toNumber(this.arguments[1].evaluate(scope, calledFrom))
        : 1;
    const day =
      this.arguments.length > 2
        ? toNumber(this.arguments[2].evaluate(scope, calledFrom))
        : 1;
    const result = new Date(0);
    // minus 1 as setUTCFullYear uses a 0-based month
    result.setUTCFullYear(year, month - 1, day);

    const hours =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : 0;
    const minutes =
      this.arguments.length > 4
        ? toNumber(this.arguments[4].evaluate(scope, calledFrom))
        : 0;
    const seconds =
      this.arguments.length > 5
        ? toNumber(this.arguments[5].evaluate(scope, calledFrom))
        : 0;
    const milliseconds =
      this.arguments.length > 6
        ? toNumber(this.arguments[6].evaluate(scope, calledFrom))
        : 0;
    result.setUTCHours(hours, minutes, seconds, milliseconds);

    return result;
  }
}

/**
 * Converts values for hour, minute, second, and millisecond into a UTC datetime value (which
 * is a UTC datetime value on Jan 1, 1970).
 * @name time() function
 * @param hour The hours portion of the time.
 * @param minute (optional) The minutes portion of the time.
 * @param second (optional) The seconds portion of the time.
 * @param millisecond (optional) The milliseconds portion of the time.
 * @returns A datetime value representing the time.
 */
class timeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 4);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const hours = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    const minutes =
      this.arguments.length > 1
        ? toNumber(this.arguments[1].evaluate(scope, calledFrom))
        : 0;
    const seconds =
      this.arguments.length > 2
        ? toNumber(this.arguments[2].evaluate(scope, calledFrom))
        : 0;
    const milliseconds =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : 0;
    const result = new Date(0);
    result.setUTCHours(hours, minutes, seconds, milliseconds);
    return result;
  }
}

/**
 * Truncates a given UTC date according to the second parameter, which is one of
 * the following values: `second`, `minute`, `hour`, `day`, `week`, `isoweek`,
 * `month`, `quarter`, `year` (all case insensitive). Also allows truncating the
 * date part with `date` (which is same as `day`) and `time`, which returns the
 * time part only.
 * @name dateTrunc() function
 * @param date The date to be truncated.
 * @param part Defines the datepart after which to truncate the datetime.
 * @returns The truncated datetime.
 */
class dateTruncFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const value = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const part =
      this.arguments[1].evaluate(scope, calledFrom)?.toString() ?? "";
    switch (part.trim().toLowerCase()) {
      case "second":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate(),
            value.getUTCHours(),
            value.getUTCMinutes(),
            value.getUTCSeconds()
          )
        );
      case "minute":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate(),
            value.getUTCHours(),
            value.getUTCMinutes()
          )
        );
      case "hour":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate(),
            value.getUTCHours()
          )
        );
      case "time": {
        const result = new Date(0);
        result.setUTCHours(
          value.getUTCHours(),
          value.getUTCMinutes(),
          value.getUTCSeconds(),
          value.getUTCMilliseconds()
        );
        return result;
      }
      case "day":
      case "date":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate()
          )
        );
      case "week":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate() - value.getUTCDay()
          )
        );
      case "isoweek":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate() - value.getUTCDay() + 1
          )
        );
      case "month":
        return new Date(Date.UTC(value.getUTCFullYear(), value.getUTCMonth()));
      case "quarter":
        return new Date(
          Date.UTC(
            value.getUTCFullYear(),
            Math.trunc(value.getUTCMonth() / 3) * 3
          )
        );
      case "year":
        return new Date(Date.UTC(value.getUTCFullYear(), 0));
      default:
        // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
        // otherwise the behavior execution gets out of sync
        throw new Error("Unsupported part parameter in dateTrunc() function");
    }
  }
}

/**
 * Extracts the UTC year from a datetime.
 * @name year() function
 * @param date The datetime from which to extract the year.
 * @returns The 4-digit year value.
 */
class yearFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCFullYear();
  }
}

/**
 * Extracts the UTC quarter from a datetime.
 * @name quarter() function
 * @param date The datetime from which to extract the quarter.
 * @returns The quarter (1...4).
 */
class quarterFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return (
      Math.trunc(
        toDatetime(
          this.arguments[0].evaluate(scope, calledFrom)
        ).getUTCMonth() / 3
      ) + 1
    );
  }
}

/**
 * Extracts the UTC month from a datetime.
 * @name month() function
 * @param date The datetime from which to extract the month.
 * @returns The month (1 = January).
 */
class monthFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return (
      toDatetime(this.arguments[0].evaluate(scope, calledFrom)).getUTCMonth() +
      1
    );
  }
}

/**
 * Extracts the UTC day of the month from a datetime.
 * @name day() function
 * @param date The datetime from which to extract the day.
 * @returns The day of the month.
 */
class dayFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCDate();
  }
}

/**
 * Extracts the UTC hour of the day from a datetime.
 * @name hour() function
 * @param date The datetime from which to extract the hour.
 * @returns The hour of the day.
 */
class hourFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCHours();
  }
}

/**
 * Extracts the UTC minute of the hour from a datetime.
 * @name minute() function
 * @param date The datetime from which to extract the minute.
 * @returns The minute of the hour.
 */
class minuteFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCMinutes();
  }
}

/**
 * Extracts the UTC second of the minute from a datetime.
 * @name second() function
 * @param date The datetime from which to extract the second.
 * @returns The second of the minute.
 */
class secondFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCSeconds();
  }
}

/**
 * Extracts the UTC millisecond of the second from a datetime.
 * @name millisecond() function
 * @param date The datetime from which to extract the millisecond.
 * @returns The millisecond of the second.
 */
class millisecondFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCMilliseconds();
  }
}

/**
 * Extracts the UTC weekday from a datetime.
 * @name weekday() function
 * @param date The datetime from which to extract the weekday.
 * @returns The weekday (1 = Sunday).
 */
class weekdayFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    return (
      toDatetime(this.arguments[0].evaluate(scope, calledFrom)).getUTCDay() + 1
    );
  }
}

/**
 * Extracts the UTC ISO weekday from a datetime.
 * @name isoweekday() function
 * @param date The datetime from which to extract the weekday.
 * @returns The ISO weekday (1 = Monday).
 */
class isoWeekdayFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    const day = toDatetime(
      this.arguments[0].evaluate(scope, calledFrom)
    ).getUTCDay();
    return day == 0 ? 7 : day;
  }
}

/**
 * Adds a number of dateparts to a given date.
 * @name dateAdd() function
 * @param date The date to add the dateparts to.
 * @param part The datepart to be added.
 * @param number (optional) The number of dateparts to be added. Default is 1.
 * @returns The date where `part` has been added `number` times. If `part` is
 *    `month`, `quarter` or `year` then if the `date` month has more days than
 *    the return month and the `date` day does not exist in the return month,
 *    then the last day of the return month is returned, while the time of the
 *    day is kept.
 */
class dateAddFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const date = new Date(
      toDatetime(this.arguments[0].evaluate(scope, calledFrom))
    );
    const part =
      this.arguments[1].evaluate(scope, calledFrom)?.toString() ?? "";
    const number =
      this.arguments.length > 2
        ? toNumber(this.arguments[2].evaluate(scope, calledFrom))
        : 1;

    return addToDate(date, part, number);
  }
}

/**
 * Subtracts a number of dateparts from a given date.
 * @name dateSub() function
 * @param date The date from which to subtracts the dateparts.
 * @param part The datepart to be subtracted.
 * @param number (optional) The number of dateparts to be subtracted. Default is 1.
 * @returns The date where `part` has been subtracted `number` times. If `part`
 *    is `month`, `quarter` or `year` then if the `date` month has more days
 *    than the return month and the `date` day does not exist in the return
 *    month, then the last day of the return month is returned, while the time
 *    of the day is kept.
 */
class dateSubFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const date = new Date(
      toDatetime(this.arguments[0].evaluate(scope, calledFrom))
    );
    const part =
      this.arguments[1].evaluate(scope, calledFrom)?.toString() ?? "";
    const number =
      this.arguments.length > 2
        ? toNumber(this.arguments[2].evaluate(scope, calledFrom))
        : 1;

    return addToDate(date, part, -number);
  }
}

/**
 * Returns a date that corresponds to an anchor date before or on the start date
 * of a sequence of dates with an interval defined by a number of dateparts for
 * each interval.
 * @name dateAnchor() function
 * @param start The start date of a sequence.
 * @param anchor The reference date anchor.
 * @param part The datepart by which to look for the anchor.
 * @param number (optional) The number of dateparts to be added for each period.
 *    Defaults to 1.
 * @returns The date before or on the start date.
 */
class dateAnchorFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 4);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const start = new Date(
      toDatetime(this.arguments[0].evaluate(scope, calledFrom))
    );
    let anchor = new Date(
      toDatetime(this.arguments[1].evaluate(scope, calledFrom))
    );
    const part =
      this.arguments[2].evaluate(scope, calledFrom)?.toString() ?? "";
    const number =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : 1;

    // TODO: shall we check for date validity? Currently when anchor is set to
    // `null`, the function fails by returning one period with an invalid end
    // date

    // move early anchors first after or on the start date
    while (anchor < start) anchor = addToDate(anchor, part, number);
    // then find the first anchor before or on the start date
    while (anchor > start) anchor = addToDate(anchor, part, -number);

    return anchor;
  }
}

/**
 * Returns a sequence of periods with their start and end dates as an array of
 * objects. The object value is the start date.
 * @name dateSequence() function
 * @param start The start date of the sequence.
 * @param end The end date of the sequence.
 * @param part The datepart to be added.
 * @param number (optional) The number of dateparts to be added for each period.
 *    Defaults to 1.
 * @param anchor (optional) The anchor date for subsequent periods in case the
 *    first period should not be a full period. Defaults to `start`.
 * @returns An array of items with start and end dates, where between each item
 *    `part` has been added `number` times. If `part` is `month`, `quarter` or
 *    `year` then if the `start` day does not exist in the period end month,
 *    then the last day of the period end month is returned, while the time of
 *    the day is kept.
 */
class dateSequenceFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 5);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const start = new Date(
      toDatetime(this.arguments[0].evaluate(scope, calledFrom))
    );
    const end = new Date(
      toDatetime(this.arguments[1].evaluate(scope, calledFrom))
    );
    const part =
      this.arguments[2].evaluate(scope, calledFrom)?.toString() ?? "";
    const number =
      this.arguments.length > 3
        ? toNumber(this.arguments[3].evaluate(scope, calledFrom))
        : 1;
    let anchor =
      this.arguments.length > 4
        ? new Date(toDatetime(this.arguments[4].evaluate(scope, calledFrom)))
        : start;

    // TODO: shall we check for date validity? Currently when anchor is set to
    // `null`, the function fails by returning one period with an invalid end
    // date

    const result = [];
    // move early anchors first after or on the start date
    while (anchor < start) anchor = addToDate(anchor, part, number);
    // then find the first anchor before or on the start date
    while (anchor > start) anchor = addToDate(anchor, part, -number);

    let periodStart; // the rolling start date
    let periodEnd = start; // the rolling end date

    let i = 0;

    // the time periods being added must never be of zero or negative length
    while (periodEnd < end) {
      i++;
      periodStart = periodEnd;
      periodEnd = new Date(
        Math.min(Number(addToDate(anchor, part, number * i)), Number(end))
      );
      result.push({
        _value: new Date(periodStart),
        start: new Date(periodStart),
        end: new Date(periodEnd),
      });
    }

    return result;
  }
}

/**
 * Calculates the date that is a given number of months before or after a start
 * date.
 * @name eDate() function
 * @param date The start date for the calculation.
 * @param months The number of months.
 * @returns The date which is `months` before or after `date`. When `date` is a
 *    datetime the result is truncated at a full day. If the `date` month has
 *    more days than the return month and the `date` day does not exist in the
 *    return month, then the last day of the return month is returned.
 */
class eDateFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const date = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const months = toNumber(this.arguments[1].evaluate(scope, calledFrom));
    // calculate the end of month day of the target month
    const eomonth = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + months + 1, 0)
    );
    return new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth() + months,
        // if day does not exist in target month, return the end of month
        Math.min(date.getUTCDate(), eomonth.getUTCDate())
      )
    );
  }
}

/**
 * Calculates the first day of the month that is a given number of months before
 * or after a start date.
 * @name boMonth() function
 * @param date The start date for the calculation.
 * @param months (optional) The number of months. The default is 0.
 * @returns The day at the beginning of the month which is `months` before or
 *    after `date`.
 */
class boMonthFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const date = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const months =
      this.arguments.length > 1
        ? toNumber(this.arguments[1].evaluate(scope, calledFrom))
        : 0;
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + months)
    );
  }
}

/**
 * Calculates the last day of the month that is a given number of months before
 * or after a start date.
 * @name eoMonth() function
 * @param date The start date for the calculation.
 * @param months (optional) The number of months. The default is 0.
 * @returns The day at the end of the month which is `months` before or after
 *    `date`.
 */
class eoMonthFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const date = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const months =
      this.arguments.length > 1
        ? toNumber(this.arguments[1].evaluate(scope, calledFrom))
        : 0;
    return new Date(
      // 0 for the day corresponds to the last day of the previous month
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + months + 1, 0)
    );
  }
}

/**
 * Calculates the difference between the specified UTC start and end datetime.
 * The difference is calculated by counting of the specified `part` boundaries
 * crossed between `start` and `end`.
 * @name dateDiff() function
 * @param start The start datetime.
 * @param end The end datetime.
 * @param part The datepart to be counted.
 * @returns The count of `part` between `start` and `end`.
 */
class dateDiffFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    const start = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const end = toDatetime(this.arguments[1].evaluate(scope, calledFrom));
    const part =
      this.arguments[2].evaluate(scope, calledFrom)?.toString() ?? "";

    return dateDiff(start, end, part);
  }
}

/**
 * Calculates the number of days between a UTC start and an end datetime.
 * @name days() function
 * @param start The start datetime.
 * @param end The end datetime.
 * @returns The number of days.
 */
class daysFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    const start = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const end = toDatetime(this.arguments[1].evaluate(scope, calledFrom));
    return dateDiff(start, end, "day");
  }
}

/**
 * Returns the current date and time. Use carefully, as it is results in endless
 * recalculations of the expression if directly stored in a cell. Use the
 * interval component in case a component shall update on a predefined interval.
 * @name now() function
 * @returns The current date and time.
 */
class nowFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 0, 0);
  }

  public evaluate(): Date {
    return new Date();
  }
}

/**
 * Returns the current date at UTC midnight. Does not trigger recalculation as
 * it will not update its value until the next day. Use the interval component
 * in case a component shall update once a day.
 * @name today() function
 * @returns The current date at 00:00:00 UTC.
 */
class todayFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 0, 0);
  }

  public evaluate(): Date {
    const date = new Date();
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    );
  }
}

/**
 * Converts a UTC datetime to a different timezone.
 * @name toTimezone() function
 * @param date The datetime to be converted.
 * @param timezone The timezone to which to convert to.
 * @returns The converted datetime.
 * @example toTimezone()
 */
class toTimezoneFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Date {
    const date = toDatetime(this.arguments[0].evaluate(scope, calledFrom));
    const timezone = this.arguments[1].evaluate(scope, calledFrom)?.toString();
    return timezone ? dayjs(date).tz("UTC").tz(timezone, true).toDate() : date;
  }
}
