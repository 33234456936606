import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIElementComponent from "./ui-element-component";

/**
 * Represents an SVG svg or g element in the DOM tree. Details about the svg
 * element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/svg Details about
 * the g element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/g
 */
export default class SVGComponent extends UIElementComponent {
  protected _DOMNode: SVGElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.viewBox = { _input: null };
    schema.preserveAspectRatio = { _input: null };
    schema.x = { _input: null };
    schema.y = { _input: null };
    schema.width = { _input: null };
    schema.height = { _input: null };
    return schema;
  }

  get DOMNode(): SVGElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "viewbox":
        this.setOrRemoveAttribute("viewBox", value);
        break;
      case "preserveaspectratio":
        this.setOrRemoveAttribute("preserveAspectRatio", value);
        break;
      case "x":
      case "y":
      case "width":
      case "height":
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
    }
  }
}
