import { AppContext } from "@shared/src/classes/app-context";
import queueFrame from "@shared/src/classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

import { environment } from "../environments/environment";

/// <reference types="HotGlue" />

enum Status {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
  STALE = "stale",
}

const FLOW_ID = environment.hotglue.flows.salesforce;
const SOURCE = "salesforce";

export default class HotGlueComponent implements InitializableComponent {
  private _invokedBy: ComponentInvoker;

  private _orgId: string | null = null;

  /** Flag for caller to invoke the hotglue link function */
  private _link = false;
  /** Flag to check hotglue link request state transition */
  private _prevLink = false;

  /** The remote component request status */
  private _status: Status = Status.IDLE;
  /** The error message if an error occured during execution */
  private _error: string | null = null;
  /** Name of the linked tap (source) e.g. "salesforce" or null if not linked */
  private _tap: string | null = null;

  /** The busy state of this component */
  private _isBusy = false;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      // Inputs
      link: { _input: false },
      // Outputs
      error: { _output: null },
      status: { _output: null },
      tap: { _output: null },
    };
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        if (context.orgId !== this._orgId) {
          this._orgId = context.orgId ?? null;
          this.setBusy(true);
          queueFrame(() => this.getLinkedSources());
        }
      },
    });
    HotGlue.mount({
      api_key: environment.hotglue.apiKey,
      env_id: environment.hotglue.envId,
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "link":
          this._link = Boolean(value);
          break;
      }
    });
    if (this._link !== this._prevLink) {
      this._prevLink = this._link;
      if (this._link) queueFrame(() => this.link());
    } else if (this._tap === null) {
      queueFrame(() => this.getLinkedSources());
    }
  }

  private reset() {
    this._error = null;
    this._status = Status.IDLE;
    this.setBusy(false);
    this.pushOutputs();
  }

  getLinkedSources() {
    if (this._orgId === null) {
      this._error = null;
      this._status = Status.STALE;
      this.pushOutputs();
      return;
    }
    this._status = Status.PENDING;
    this._error = null;
    this.pushOutputs();
    HotGlue.getLinkedSources(FLOW_ID, this._orgId)
      .then((sources) => {
        this._error = null;
        this._status = Status.SUCCEEDED;
        if (sources?.length > 0) {
          this._tap = sources[0].tap;
        }
        this.setBusy(false);
        this.pushOutputs();
      })
      .catch((err) => {
        this._status = Status.FAILED;
        this._error = (err as Error).message ?? err;
        this.setBusy(false);
        this.pushOutputs();
      });
  }

  link() {
    if (this._orgId === null) {
      this._error = null;
      this._status = Status.STALE;
      this.pushOutputs();
      return;
    }
    this._status = Status.PENDING;
    this._error = null;
    this.pushOutputs();
    HotGlue.link(this._orgId, FLOW_ID, SOURCE, false, {
      hideBackButtons: true,
      listener: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSourceLinked: (source: HotGlue.LinkedSource, flowId: string) => {
          this._error = null;
          this._status = Status.SUCCEEDED;
          this._tap = source.tap;
          this.setBusy(false);
          this.pushOutputs();
          HotGlue.close();
          HotGlue.createJob(flowId, this._orgId ?? "unknown")
            .then((r: HotGlue.CreateJobResult) =>
              console.log(`HotGlue job created: ${r.job_id}`, r)
            )
            .catch((err) => console.error("HotGlue.createJob failed:", err));
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSourceUnlinked: (source: HotGlue.LinkedSource, flowId: string) => {
          this._error = null;
          this._status = Status.SUCCEEDED;
          this._tap = null;
          this.setBusy(false);
          this.pushOutputs();
          HotGlue.close();
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSourceLinkCanceled: (tap: string, flowId: string) => {
          this.reset();
        },
        onWidgetClose: () => {
          this.reset();
        },
      },
    });
  }

  getOutputValueGraph(): ValueGraph {
    return {
      status: this._status,
      error: this._error,
      tap: this._tap,
    };
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  /**
   * Sets the busy state of the component and propagates it to the
   * invoker if it has changed.
   */
  protected setBusy(busy: boolean) {
    if (busy !== this._isBusy) {
      this._isBusy = busy;
      this._invokedBy.setBusy(busy);
    }
  }
}
