import queueFrame from "../classes/frame-queue";
import { ValueGraph } from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIPointerComponent from "./ui-pointer-component";

interface DataTransfer {
  _value?: string;
  type: string;
  data: string;
  [key: string]: string | undefined;
}

/**
 * UIDropComponent captures the dropping of DOM elements, thus listens to
 * drop events.
 */
export default class UIDropComponent extends UIPointerComponent {
  private _dropped = false;
  private _dragover = false;
  private _dataTransfer: DataTransfer[] = [];

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.dropped = { _output: {} };
    schema.dragover = { _output: {} };
    schema.dataTransfer = {
      _iscollection: true,
      _output: null,
      type: { _output: null },
      data: { _output: null },
    };

    return schema;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();
    result.dropped = this._dropped;
    result.dragover = this._dragover;
    result.dataTransfer = this._dataTransfer;
    return result;
  }

  attachDOM(parentDOMNode: Element): void {
    super.attachDOM(parentDOMNode);

    this.addEventListenerToParent("dragenter", this.onDragEnter);
    this.addEventListenerToParent("dragover", this.onDragOver);
    this.addEventListenerToParent("dragleave", this.onDragLeave);
    this.addEventListenerToParent("drop", this.onDrop);
  }

  detachDOM(): void {
    this.removeEventListenerFromParent("dragenter", this.onDragEnter);
    this.removeEventListenerFromParent("dragover", this.onDragOver);
    this.removeEventListenerFromParent("dragleave", this.onDragLeave);
    this.removeEventListenerFromParent("drop", this.onDrop);

    super.detachDOM();

    this._dragover = false;
    queueFrame(() => this.pushOutputs());
  }

  // TODO: to avoid adding multiple instances of the same callback function, the
  // function at the time being must be static as by not having a DOM node on
  // the mouse, keyboard, drag & drop sensors, the runtime considers those not
  // yet being added. We should find a cleaner way of doing this. see also
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#memory_issues

  private onDragEnter = (event: Event): void => {
    this._dropped = false;
    this._dragover = true;
    // according to
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
    // both the dragenter and dragover events must be cancelled to prevent the
    // default handling
    this.setPointerAttributes(event as DragEvent);
    this.propagationAndDefault(event as DragEvent, true);
    this.pushOutputs();
  };

  private onDragOver = (event: Event): void => {
    this._dropped = false;
    this._dragover = true;
    // according to
    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
    // both the dragenter and dragover events must be cancelled to prevent the
    // default handling
    this.setPointerAttributes(event as DragEvent);
    this.propagationAndDefault(event as DragEvent, true);
    this.pushOutputs();
  };

  private onDragLeave = (event: Event): void => {
    this._dropped = false;
    this._dragover = false;
    // we prevent default handling in any case, as at least with Firefox it was
    // leading to not process the events correctly within the application (and
    // e.g. tried to open a text/plain datatype as a URL)
    this.setPointerAttributes(event as DragEvent);
    this.propagationAndDefault(event as DragEvent, true);
    this.pushOutputs();
  };

  private onDrop = (event: Event): void => {
    this._dataTransfer = []; // reset result array
    const dt = (event as DragEvent).dataTransfer;
    if (dt) {
      for (let i = 0; i < dt.items.length; i++) {
        const dtItem = dt.items[i];
        const type = dtItem.type;
        const data = dt.getData(type);
        if (dtItem.type === "text/plain") {
          // on text/plain also set the default value to the first element in the array
          this._dataTransfer.unshift({
            _value: data,
            type: type,
            data: data,
          });
        } else {
          this._dataTransfer.push({ type: type, data: data });
        }
      }
    }

    this._dropped = true;
    this._dragover = false;
    // we prevent default handling in any case, as at least with Firefox it was
    // leading to not process the events correctly within the application (and
    // e.g. tried to open a text/plain datatype as a URL)
    this.setPointerAttributes(event as DragEvent);
    this.propagationAndDefault(event as DragEvent, true);

    this.pushOutputs();
    // queue the reset for the single frame value
    queueFrame(() => {
      this._dropped = false;
      this.pushOutputs();
    });
  };
}
