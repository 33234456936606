import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";

import queueFrame from "@shared/src/classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

enum Status {
  RESET_PENDING = "reset-pending",
  RESET_FAILED = "reset-failed",
  RESET_SUCCEEDED = "reset-succeeded",
}

/**
 * Requests a Firebase Auth reset password email to be sent to the
 * user.
 */
export default class AuthResetPasswordComponent implements Component {
  private _invokedBy: ComponentInvoker;

  /** Triggers the password reset when true */
  private _reset = false;
  /** User's email address */
  private _email: string | null = null;

  /** The status after signup or signout has been triggered */
  private _status: Status | null = null;
  /** The error message if an error occured during signup or signout */
  private _error: string | null = null;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      reset: { _input: false },
      email: { _input: null },

      error: { _output: null },
      status: { _output: null },
    };
  }

  destroy(): void {
    // nothing to be destroyed
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "reset":
          this._reset = Boolean(value);
          break;
        case "email":
          this._email = value?.toString() ?? null;
          break;
      }
    });

    // TODO: call reset only when the value turns true
    if (this._reset) queueFrame(() => this.callReset());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      status: this._status,
      error: this._error,
    };
  }

  /**
   * Calls the asynchronuous reset method and sets the output
   * properties of the component accordingly.
   */
  private callReset() {
    this._status = Status.RESET_PENDING;
    this._error = null;
    this.pushOutputs();

    this.reset(this._email?.toString() ?? "")
      .then(() => {
        this._error = null;
        this._status = Status.RESET_SUCCEEDED;
        this.pushOutputs();
      })
      .catch((err) => {
        this._error = (err as { code: string }).code ?? "reset failed";
        this._status = Status.RESET_FAILED;
        this.pushOutputs();
      });
  }

  /**
   * Sends a password reset email.
   *
   * @param email user's email address
   * @throws Error if reset failed
   */
  private async reset(email: string): Promise<void> {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
    } catch (err) {
      console.error("send password email failed:", err);
      throw err;
    }
  }

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
