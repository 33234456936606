import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  DOMComponent,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

/**
 * UIClipboardComponent listens to clipboard events entries and allows to
 * interact with the clipboard.
 */
export default class UIClipboardComponent implements DOMComponent {
  private _invokedBy: ComponentInvoker;
  private _parentDOMNode: Element | null = null;
  private _target: EventTarget | null = null;

  // these represent the event (single frame values)
  private _copy = false;
  private _cut = false;
  private _paste = false;

  private _setText: string | null = null;
  private _text: string | null = null;

  private _stopPropagation = false;
  private _preventDefault = true;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  static getSchema(): Schema {
    return {
      setText: { _input: null },
      stopPropagation: { _input: null },
      // prevents default behavior by default, can be overridden by setting to
      // `false` explicitly
      preventDefault: { _input: true },
      copy: { _output: {} },
      cut: { _output: {} },
      paste: { _output: {} },
      text: { _output: {} },
    };
  }

  get DOMNode(): null {
    return null;
  }

  destroy(): void {
    this.detachDOM();
  }

  setInputValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      if (value === undefined) return;
      switch (key.toLowerCase()) {
        case "settext":
          this._setText = value?.toString() ?? null;
          break;
        case "stoppropagation":
          this._stopPropagation = Boolean(value);
          break;
        case "preventdefault":
          this._preventDefault = value === null ? true : Boolean(value);
          break;
      }
    });
  }

  getOutputValueGraph(): ValueGraph {
    return {
      copy: this._copy,
      cut: this._cut,
      paste: this._paste,
      text: this._text,
    };
  }

  attachDOM(parentDOMNode: Element): void {
    this._parentDOMNode = parentDOMNode;
    // on all non editable elements we listen on body, as it is not 100% clear
    // which element is receiving the events in case no content has been
    // selected
    const target = this.isEditableNode(parentDOMNode)
      ? parentDOMNode
      : parentDOMNode.ownerDocument.body;

    // target already set -> do nothing
    if (target === this._target) return;

    // already attached to a different target -> as the clipboard component binds
    // events to the target, it must be detached first before it gets
    // re-attached
    if (this._target) this.detachDOM();

    this._target = target;

    this._target.addEventListener("copy", this.onCopy);
    this._target.addEventListener("cut", this.onCut);
    this._target.addEventListener("paste", this.onPaste);
  }

  detachDOM(): void {
    if (!this._target) return;

    this._target.removeEventListener("copy", this.onCopy);
    this._target.removeEventListener("cut", this.onCut);
    this._target.removeEventListener("paste", this.onPaste);

    this._target = null;
  }

  private isEditableNode(node: EventTarget | null): boolean {
    return (
      node instanceof HTMLInputElement || node instanceof HTMLTextAreaElement
    );
  }

  // TODO: to avoid adding multiple instances of the same callback function, the
  // function at the time being must be static as by not attaching a DOM node to
  // the clipboard component, the runtime considers those not
  // yet being added. We should find a cleaner way of doing this. see also
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#memory_issues
  private onCopy = (event: Event): void => {
    // skip if the DOM node is not active
    if (document.activeElement !== this._parentDOMNode) return;

    (event as ClipboardEvent).clipboardData?.setData(
      "text/plain",
      this._setText ?? ""
    );
    if (this._stopPropagation) event.stopPropagation();
    if (this._preventDefault) event.preventDefault();

    this._copy = true;
    this.pushOutputs();
    queueFrame(() => {
      this._copy = false;
      this.pushOutputs();
    });
  };

  private onCut = (event: Event): void => {
    // skip if the DOM node is not active
    if (document.activeElement !== this._parentDOMNode) return;

    (event as ClipboardEvent).clipboardData?.setData(
      "text/plain",
      this._setText ?? ""
    );
    if (this._stopPropagation) event.stopPropagation();
    if (this._preventDefault) event.preventDefault();

    this._cut = true;
    this.pushOutputs();
    queueFrame(() => {
      this._cut = false;
      this.pushOutputs();
    });
  };

  private onPaste = (event: Event): void => {
    // skip if the DOM node is not active
    if (document.activeElement !== this._parentDOMNode) return;

    this._text =
      (event as ClipboardEvent).clipboardData?.getData("text/plain") ?? null;
    if (this._stopPropagation) event.stopPropagation();
    if (this._preventDefault) event.preventDefault();

    this._paste = true;
    this.pushOutputs();
    queueFrame(() => {
      this._paste = false;
      this.pushOutputs();
    });
  };

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }
}
