import { ValueGraph, ValueGraphData } from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIElementComponent from "./ui-element-component";

/**
 * The UIHTMLElement component is the abstract base class for all HTML DOM element
 * components.
 */
export default abstract class UIHTMLElementComponent extends UIElementComponent {
  // most recent status of focus and blur in case the DOM element was not yet
  // mounted when the values were set
  private _setFocus = false;
  private _preventScroll = false;
  private _setBlur = false;

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.hidden = { _input: null };
    schema.className = { _input: null };
    schema.title = { _input: null };
    schema.setFocus = { _input: null };
    schema.setBlur = { _input: null };
    schema.spellcheck = { _input: null };
    return schema;
  }

  abstract get DOMNode(): HTMLElement;

  // TODO: When an outer DOM element is unhidden and an inner element shall
  // receive the focus at the same time, the inner element will get the setFocus
  // trigger before the parent is visible. This is to be fixed on the frame
  // execution of the invoker.

  setInputValueGraph(graph: ValueGraph): void {
    super.setInputValueGraph(graph);

    // The focus/blur actions are executed at the end to make sure hidden etc.
    // is set before.
    this.triggerFocusOrBlur();
  }

  attachDOM(parentDOMNode: Element): void {
    super.attachDOM(parentDOMNode);
    this.triggerFocusOrBlur();
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "hidden":
        this.DOMNode.hidden = Boolean(value);
        break;
      case "classname":
        if (value !== null) {
          this.DOMNode.className = value.toString();
        } else {
          this.DOMNode.removeAttribute("class");
        }
        break;
      case "title":
        if (value !== null) {
          this.DOMNode.title = value.toString();
        } else {
          this.DOMNode.removeAttribute("title");
        }
        break;
      case "setfocus": {
        if (value) {
          this._setFocus = true;
          this._preventScroll =
            value.toString().trim().toLowerCase() === "preventscroll";
        }
        break;
      }
      case "setblur":
        if (value) this._setBlur = true;
        break;
      case "spellcheck":
        this.DOMNode.spellcheck = Boolean(value);
        break;
    }
  }

  private triggerFocusOrBlur() {
    // do not trigger the focus/blur when this DOM node is not yet mounted to
    // the DOM tree
    if (!this.DOMNode.parentNode) return;

    if (this._setFocus) {
      this.DOMNode.focus({
        preventScroll: this._preventScroll,
      });
      this._setFocus = false;
      this._setBlur = false;
    } else if (this._setBlur) {
      this.DOMNode.blur();
      this._setBlur = false;
    }
  }

  protected setOrRemoveTextNode(
    value: ValueGraphData | ValueGraphData[]
  ): void {
    if (value !== null) {
      // make sure a text node exists under the node and if not create it
      if (
        this.DOMNode.firstChild &&
        this.DOMNode.firstChild.nodeType === Node.TEXT_NODE
      ) {
        this.DOMNode.firstChild.nodeValue = value.toString();
      } else {
        this.DOMNode.insertBefore(
          document.createTextNode(value.toString()),
          this.DOMNode.firstChild
        );
      }
    } else {
      // if no text is to be shown we remove an existing text node entirely
      if (
        this.DOMNode.firstChild &&
        this.DOMNode.firstChild.nodeType === Node.TEXT_NODE
      )
        this.DOMNode.removeChild(this.DOMNode.firstChild);
    }
  }

  static isInput(name: string): boolean {
    if (super.isInput(name)) return true;
    if (inputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set([
  "hidden",
  "classname",
  "title",
  "setfocus",
  "setblur",
  "spellcheck",
]);
