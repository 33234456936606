import { BehaviorNode, isBehaviorNode } from "../behavior/behavior";
import { compareValues, toValue } from "../behavior/behavior-commons";
import {
  CellNameWalker,
  InstanceBreadcrumb,
  isValue,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import CellType, { InitGraphData } from "./cell-type";
import CustomComponent from "./custom-component";
import Instance from "./instance";

export enum CellBehaviorType {
  CONSTANT = 1,
}

/**
 * Implements a cell defined by the given `cellType` and attaches it to its
 * parent instance. If a cell does not have a parent instance it is considered
 * to being the root cell of the brain it belongs to.
 */
export default class Cell implements CellNameWalker {
  readonly cellType: CellType;
  readonly parentInstance: Instance;
  readonly instances: Instance[] = [];
  /** References the behavior defined in cellType or if set to `CONSTANT`, the
   * cell must not be overwritten by a parent's expression. Overwriting is only
   * permitted when cellBehavior is undefined */
  cellBehavior?: BehaviorNode | CellBehaviorType;

  /**
   * Initialises the cell by just setting the cellType and parentInstance
   * property.
   * @param cellType Defines the behavior of this cell.
   * @param parentInstance The instance this cell is attached to.
   */
  constructor(cellType: CellType, parentInstance: Instance) {
    this.cellType = cellType;
    this.parentInstance = parentInstance;
  }

  /**
   * Inititializes the cell and its instances with their constant values or
   * their cell expressions.
   * @param data The value/expression graph to initialize the cell with.
   */
  public init(data: InitGraphData): void {
    if (isBehaviorNode(this.cellType.behavior)) {
      // if the cellType has a behavior then set the cellType behavior as the default cell behavior
      this.cellBehavior = this.cellType.behavior;
    } else if (this.cellType.behavior !== null && data === null) {
      // otherwise the cellType defines a constant cell value and if there is no
      // cell value defined on the parent's constant value, then use the
      // cellType's constant cell value from here
      data = this.cellType.behavior;
    }

    if (isBehaviorNode(data)) {
      // if the init data defines a cell behavior, overwrite the default and set the cell init value to null
      this.cellBehavior = data;
      data = null;
    } else if (
      !isValue(data) &&
      !Array.isArray(data) &&
      isBehaviorNode(data._value)
    ) {
      this.cellBehavior = data._value;
    }

    if (this.cellType.isCollection) {
      // collection cell; enumerate the array and create/init corresponding instance.

      // convert data to an array
      if (data === null) data = [];
      else if (!Array.isArray(data)) data = [data];

      // if data is not an empty array, the cell behavior for this cell shall be
      // ignored and setValueGraph is blocked from updating the value from a
      // parent expression (by setting is to `true`)
      if (data.length !== 0) this.cellBehavior = CellBehaviorType.CONSTANT;

      for (const item of data) {
        const inst = new Instance(this.root, this, this.cellType);
        this.instances.push(inst);
        inst.init(item);
        inst.index = this.instances.length - 1;

        if (this.parentInstance.DOMNode) {
          inst.attachDOM(this.parentInstance.DOMNode);
        } else if (this.parentInstance.DOMNodeId) {
          // ATTENTION: does not work when virtual parent has not been attached to DOM yet
          const node = document.getElementById(this.parentInstance.DOMNodeId);
          if (node) inst.attachDOM(node);
        }
      }
    } else {
      // single-instance cell; create the instance and init the value

      // flatten the array
      if (Array.isArray(data))
        data = toValue(
          data.map((item) =>
            isValue(item) ? item : isValue(item._value) ? item._value : null
          )
        );

      // if data is not null, the cell behavior for this cell shall be ignored
      // and setValueGraph is blocked from updating the value from a parent
      // expression
      if (data !== null) this.cellBehavior = CellBehaviorType.CONSTANT;

      const inst = new Instance(this.root, this, this.cellType);
      this.instances.push(inst);
      inst.init(data);

      if (this.parentInstance.DOMNode) {
        inst.attachDOM(this.parentInstance.DOMNode);
      } else if (this.parentInstance.DOMNodeId) {
        // ATTENTION: does not work when virtual parent has not been attached to DOM yet
        const node = document.getElementById(this.parentInstance.DOMNodeId);
        if (node) inst.attachDOM(node);
      }
    }
  }

  public get root(): CustomComponent {
    return this.parentInstance.root;
  }

  /**
   * Removes this cell and all its instances from memory without deleting
   * it from storage (in case it is a storage type cell)
   */
  public destroy(): void {
    for (const inst of this.instances) {
      inst.destroy();
    }
    this.instances.length = 0;
  }

  /**
   * Notifies depending cells that this cell has changed by queuing behaveAll
   * via the component roots.
   */
  protected notifyDependencies(): void {
    this.root.queueBehaveAll();

    // Notify the root component that output values have changed.
    if (this.cellType.isOutput) {
      this.root.markOutputUpdated();
    }

    // Notify the instance instantiating a component that input values have changed
    if (this.cellType.isConnectedToInput) {
      this.parentInstance.invocationRoot?.markComponentInputUpdated();
    }
  }

  /**
   * Checks whether executing the behavior for this cell should be skipped. This
   * is the case for cells that receive output values from a component and for
   * input cells.
   * @returns `true` when the behavior for this cell shall be skipped.
   */
  private skipBehave(): boolean {
    return this.cellType.isInput || this.cellType.isConnectedToOutput;
  }

  /**
   * Runs the behavior frame on this cell by executing the cell's behavior
   * expression and assiging the resulting value, then runs the behavior on all
   * child instances.
   */
  public behaveAll(): void {
    // first evaluate this cell (if the behavior should not be skipped)
    if (!this.skipBehave()) {
      switch (this.cellType.name) {
        case "_index":
          this.setValue(this.parentInstance.index);
          break;
        case "_busy":
          // the busy attribute is set outside the cell behavior loop as it must
          // consider component inputs to have been updated and the component to
          // report or not report being busy
          break;
        default:
          if (isBehaviorNode(this.cellBehavior)) {
            // if behavior is given then evaluate behavior from this cell's scope
            try {
              // we have to hand over the cell rather than the instance array as the
              // scope, as when the instance array is still empty, the behavior would
              // not get a scope at all
              this.setValueGraph(
                this.cellBehavior.evaluate([this], this),
                true
              );
            } catch (e) {
              // on exceptions during behavior log a warning
              console.warn(
                `${e instanceof Error ? e.message : String(e)} (in ${
                  this.cellType.root.originalName
                }.${this.cellType.originalPathFromRoot})`
              );
              this.setValueGraph(null, true);
            }

            this.root.perfCounter?.expressionEvaluated();
          }
      }
    }

    // then evaluate all child instances
    for (let i = 0; i < this.instances.length; i++) {
      this.instances[i].behaveAll();
    }
  }

  /**
   * Sets the value of this cell usually as a result of its behavior. The value
   * can be either a primitive, or an array of primitives or instance
   * references. If the value of the cell changes, depending cells are notified
   * via `notifyDependencies()`.
   * @param value The value this cell should be set to.
   */
  public setValue(value: Value | Instance | (Instance | Value)[]): void {
    let hasChanged = false;

    this.setPrevValue();

    if (this.cellType.isCollection) {
      hasChanged = this.updateInstancesArray(value);
    } else {
      hasChanged = this.updateInstanceValue(value);
    }

    if (hasChanged && this.root.perfCounter?.trace) {
      const path = this.parentInstance.getSimplifiedOriginalPath(
        this.cellType.originalName
      );

      if (this.root.perfCounter.traceThis(path)) {
        const updated = Array.isArray(value)
          ? value.map((item) => toValue(item))
          : toValue(value);

        const before = this.cellType.isCollection
          ? this.instances.map((item) => item.prevValueOf())
          : this.instances[0]?.prevValueOf() ?? null;
        const after = this.cellType.isCollection
          ? this.instances.map((item) => item.valueOf())
          : this.instances[0]?.valueOf() ?? null;

        this.root.perfCounter.valueSet(path, updated, before, after);
      }
    }

    if (hasChanged) {
      this.notifyDependencies();
    }
  }

  private updateInstanceValue(
    value: Value | Instance | (Instance | Value)[]
  ): boolean {
    let v = null;

    // TODO: consider what to do with value being an object

    if (Array.isArray(value)) {
      // if value is an array with length == 1 and it is a reference to an instance
      // we do not convert it to a primitive
      if (value.length === 1 && value[0] instanceof Instance) {
        v = value[0];
      } else {
        v = toValue(value);
      }
    } else {
      if (value instanceof Instance) {
        v = value;
      } else {
        v = toValue(value);
      }
    }

    if (this.instances.length == 0) {
      // no value there before, which must never be the case
      throw new Error("cell was not initialized correctly");
    }

    // exactly the same means v is pointing at this instance, so nothing changed
    if (v === this.instances[0]) return false;

    const currentValue = this.instances[0].valueOf();
    // old and new are same value
    if (compareValues(v, currentValue) === 0) {
      return false;
    }

    // in any other case set the new value and update the UI
    this.instances[0].setValue(toValue(v));
    return true;
  }

  private updateInstancesArray(
    value: Value | Instance | (Instance | Value)[]
  ): boolean {
    // make value an array
    if (value === null) {
      value = [];
    } else if (!Array.isArray(value)) {
      value = [value];
    }

    // a simple lookup map which will help to understand which instances are
    // obsolete at the end
    const instMap = new Map<Instance, Instance>();
    // the value map maps a value to all instances with this value in the order
    // of their appearance
    const valueMap = new Map<string | number | boolean | null, [Instance]>();

    for (const inst of this.instances) {
      instMap.set(inst, inst);
      const instances = valueMap.get(this.toLookupValue(inst.valueOf()));
      // if value already exists, then add this instance to the array of
      // instances with this value
      if (instances) instances.push(inst);
      // otherwise add this value with the first instance
      else valueMap.set(this.toLookupValue(inst.valueOf()), [inst]);
    }

    // remove all instances from the instance array as they are now kept by the
    // instance map
    this.instances.splice(0, this.instances.length);

    let hasChanged = false;

    // loop through target array
    for (const item of value) {
      // look up if item was already existing in previous instances
      // being an instance of this cell is straight forward
      const inst =
        item instanceof Instance && item.parentCell === this
          ? item
          : valueMap.get(this.toLookupValue(item))?.[0] ?? undefined;

      if (inst) {
        // Item existed before, so move it to current instances array
        this.instances.push(inst);

        // then check if it has the same position as before, if not the array
        // has changed
        if (inst.index !== this.instances.length - 1) {
          inst.index = this.instances.length - 1;
          hasChanged = true;
        }

        // cleaning up the maps so that this instance/value is not found again
        instMap.delete(inst);
        // if the value is in the value map (what it should), delete the
        // instance from the array of instances with this value
        const instances = valueMap.get(this.toLookupValue(inst.valueOf()));
        if (instances) instances.splice(0, 1);
        // finally delete the instances array from the map if it is empty
        if (instances && !instances.length)
          valueMap.delete(this.toLookupValue(inst.valueOf()));
      } else {
        // Item did not exist before, so create and add a new one
        const inst = new Instance(this.root, this, this.cellType);
        this.instances.push(inst);
        inst.init(toValue(item));
        inst.index = this.instances.length - 1;

        if (this.parentInstance.DOMNode) {
          inst.attachDOM(this.parentInstance.DOMNode);
        } else if (this.parentInstance.DOMNodeId) {
          const node = document.getElementById(this.parentInstance.DOMNodeId);
          if (node) inst.attachDOM(node);
        }

        hasChanged = true;
      }
    }

    // eventually loop through the map to delete obsolete instances
    instMap.forEach((inst) => {
      inst.destroy();
      hasChanged = true;
    });

    this.parentInstance.fixOrderOfDOMNodes();

    return hasChanged;
  }

  private toLookupValue(
    value: ValueGraphData
  ): string | number | boolean | null {
    if (value instanceof Date) {
      return value.valueOf();
    } else if (isValue(value)) {
      return value;
    } else if (value instanceof Instance) {
      return this.toLookupValue(value.valueOf());
    } else if (isValue(value._value)) {
      return this.toLookupValue(value._value);
    } else {
      return null;
    }
  }

  /**
   * Returns the value graph of the instances of this cell and all descendants.
   * @returns A graph with the value of this instance and all its cells.
   */
  public getValueGraph(): ValueGraphData | ValueGraphData[] {
    if (this.cellType.isCollection) {
      const result: ValueGraphData[] = [];

      for (const inst of this.instances) {
        const graph = inst.getValueGraph();
        if (Object.keys(graph).length == 1 && graph._value !== undefined) {
          // if the graph object consists of only the value, return just the value
          result.push(graph._value);
        } else {
          result.push(graph);
        }
      }
      return result;
    } else if (this.instances.length > 0) {
      const graph = this.instances[0].getValueGraph();
      if (Object.keys(graph).length == 1 && graph._value !== undefined) {
        // if the graph object consists of only the value, return just the value
        return graph._value;
      } else {
        return graph;
      }
    } else {
      return null;
    }
  }

  /**
   * Sets the value of the instances of this cell and all descendants. Does not
   * set values on cells that have a constant value or cell expression.
   * @param graph The value graph that is used to set the values.
   * @param force When set to `true` the value of this cell is set even when a
   *   behavior (or constant value) for this cell exists. Required when setting
   *   the root value of a cell resulting from an expression.
   */
  public setValueGraph(
    graph: undefined | ValueGraphData | ValueGraphData[],
    force = false
  ): void {
    // if cell has a constant or an expression, then do not set the value
    if (this.cellBehavior && !force) return;

    if (graph === null || graph === undefined) {
      graph = [];
    } else if (!Array.isArray(graph)) {
      graph = [graph];
    }

    graph = this.ResolveReferences(graph);

    this.setValue(
      graph.map((value) =>
        isValue(value) || value instanceof Instance
          ? value
          : value._value ?? null
      )
    );

    for (let i = 0; i < this.instances.length; i++) {
      if (i >= graph.length) break;
      const item = graph[i];
      if (!isValue(item) && !(item instanceof Instance))
        this.instances[i].setValueGraph(item);
    }
  }

  /**
   * Returns the value graph of the instances of this cell and all descendants
   * that are bound to inputs of the instantiated component.
   * @returns A graph with the value of this instance and all cells that map to
   *    inputs of the component.
   */
  public getInputValueGraph(): ValueGraphData | ValueGraphData[] {
    if (this.cellType.isCollection) {
      const result: ValueGraphData[] = [];

      for (const inst of this.instances) {
        const graph = inst.getInputValueGraph();
        if (Object.keys(graph).length == 1 && graph._value !== undefined) {
          // if the graph object consists of only the value, return just the value
          result.push(graph._value);
        } else {
          result.push(graph);
        }
      }
      return result;
    } else if (this.instances.length > 0) {
      const graph = this.instances[0].getInputValueGraph();
      if (Object.keys(graph).length == 1 && graph._value !== undefined) {
        // if the graph object consists of only the value, return just the value
        return graph._value;
      } else {
        return graph;
      }
    } else {
      return null;
    }
  }

  /**
   * Sets the value of the instances of this cell and all descendants that are
   * inputs of the component this cell belongs to.
   * @param graph The value graph that is used to set the values.
   */
  public setInputValueGraph(
    graph: undefined | ValueGraphData | ValueGraphData[]
  ): void {
    if (!this.cellType.isInput) return;

    if (graph === null || graph === undefined) {
      graph = [];
    } else if (!Array.isArray(graph)) {
      graph = [graph];
    }

    graph = this.ResolveReferences(graph);

    this.setValue(
      graph.map((value) =>
        isValue(value) || value instanceof Instance
          ? value
          : value._value ?? null
      )
    );

    for (let i = 0; i < this.instances.length; i++) {
      if (i >= graph.length) break;
      const item = graph[i];
      if (!isValue(item) && !(item instanceof Instance))
        this.instances[i].setInputValueGraph(item);
    }
  }

  /**
   * Returns the value graph of the instances of this cell and all descendants that are outputs
   * of the component this cell belongs to.
   * @returns A graph with the value of this instance and all cells that are outputs of the component.
   */
  public getOutputValueGraph(): ValueGraphData | ValueGraphData[] {
    if (this.cellType.isCollection) {
      const result: ValueGraphData[] = [];

      for (const inst of this.instances) {
        const graph = inst.getOutputValueGraph();
        if (Object.keys(graph).length == 1 && graph._value !== undefined) {
          // if the graph object consists of only the value, return just the value
          result.push(graph._value);
        } else {
          result.push(graph);
        }
      }
      return result;
    } else if (this.instances.length > 0) {
      const graph = this.instances[0].getOutputValueGraph();
      if (Object.keys(graph).length == 1 && graph._value !== undefined) {
        // if the graph object consists of only the value, return just the value
        return graph._value;
      } else {
        return graph;
      }
    } else {
      return null;
    }
  }

  /**
   * Sets the values of the instances of this cell and all descendants that are bound to
   * outputs of the instantiated component.
   * @param graph The value graph that is used to set the values.
   */
  public setOutputValueGraph(
    graph: undefined | ValueGraphData | ValueGraphData[]
  ): void {
    if (!this.cellType.isConnectedToOutput) return;

    if (graph === null || graph === undefined) {
      graph = [];
    } else if (!Array.isArray(graph)) {
      graph = [graph];
    }

    graph = this.ResolveReferences(graph);

    this.setValue(
      graph.map((value) =>
        isValue(value) || value instanceof Instance
          ? value
          : value._value ?? null
      )
    );

    for (let i = 0; i < this.instances.length; i++) {
      const item = graph[i];
      if (item === undefined || isValue(item) || item instanceof Instance) {
        this.instances[i].setOutputValueGraph({});
      } else {
        this.instances[i].setOutputValueGraph(item);
      }
    }
  }

  /**
   * Resolves instance references in the value graph, i.e. replaces them with
   * the value or the object that is referenced. References to this cell (and
   * its instances) are not resolved.
   *
   * References where the value in the graph has children result in the value of
   * the reference only. References where the value in the graph does not have
   * children result in the value graph of the reference.
   *
   * @param graph The value graph in which to resolve the references.
   * @returns The value graph with te resolved references.
   */
  private ResolveReferences(graph: ValueGraphData[]): ValueGraphData[] {
    return graph.map((item) => {
      if (
        // item is just a value, not a graph (no children)
        item instanceof Instance &&
        // TODO: replace with item.parentCell !== this
        !this.instances.includes(item) // reference to this cell
      ) {
        return item.getValueGraph();
      } else if (
        typeof item === "object" && // item is an object
        !(item instanceof Date) &&
        !(item instanceof Instance) && // must be checked so that item._value is valid
        item?._value instanceof Instance &&
        Object.entries(item).length === 1 && // no other children than `_value`
        // TODO: replace with item._value.parentCell !== this
        !this.instances.includes(item?._value) // reference to this cell
      ) {
        return item._value.getValueGraph();
      } else {
        return item;
      }
    });
  }

  /**
   * Saves the value of the previous behavior frame of all the cell's instances
   * so that delta behavior functions can use this value.
   */
  public setPrevValue(): void {
    for (const inst of this.instances) {
      inst.setPrevValue();
    }
  }

  /**
   * Sets the previous values on all cells where the execution of their behavior
   * has been skipped.
   */
  public setSkippedPrevValues(): void {
    if (this.skipBehave()) {
      this.setPrevValue();
    }

    for (const inst of this.instances) {
      inst.setSkippedPrevValues();
    }
  }

  public getParentOrSiblingOrThisInstancesByCellName(
    name: string,
    fromRoot: boolean,
    breadcrumb?: InstanceBreadcrumb
  ): Instance[] {
    if (fromRoot && this.cellType.hasParentOrSibling(name)) {
      return this.parentInstance.getParentOrSiblingOrThisInstancesByCellName(
        name,
        fromRoot,
        breadcrumb
      );
    }

    if (this.cellType.name === name.toLowerCase()) {
      // this is the cell we are looking for
      return this.instances;
    }

    // otherwise continue walking up the hierarchy
    return this.parentInstance.getParentOrSiblingOrThisInstancesByCellName(
      name,
      fromRoot,
      breadcrumb
    );
  }

  public getChildInstancesByCellName(
    name: string,
    breadcrumb?: InstanceBreadcrumb
  ): Instance[] {
    if (this.cellType.getChildTowardsAttribute(name)) {
      // within the instances below this cell there are cells with this
      // cellTypeName so let's look them up!
      const result: Instance[] = [];

      const inst = breadcrumb?.get(this.cellType);
      if (inst) {
        // if there is an element of this celltype in the breadcrumb, just use
        // the instance in the breadcrumb, not the entire array
        result.push(...inst.getChildInstancesByCellName(name, breadcrumb));
      } else {
        // otherwise break the breadcrumb chain as using an instance from the
        // breacrumb below this point would be invalid in any case
        for (const instance of this.instances) {
          result.push(...instance.getChildInstancesByCellName(name));
        }
      }

      return result;
    }

    throw new Error(`Unknown name '${name}'`);
  }

  // getInstancesByCellName looks up the cell tree until it finds the requested cellType
  // with cellTypeName in its descendants, then returns an array of instances
  public getInstancesByCellName(name: string): Instance[] {
    // TODO: we should make this method case sensitive as for the behavior
    // execution all names are normalised to lowercase anyhow
    if (this.cellType.name == name.toLowerCase()) return this.instances;

    if (this.cellType.getChildTowardsAttribute(name)) {
      // within the instances below this cell there are cells with this
      // cellTypeName so let's look them up!
      const result: Instance[] = [];
      for (const instance of this.instances) {
        result.push(...instance.getInstancesByCellName(name));
      }
      return result;
    } else {
      // no cell type attribute with this name means we go up the hierarchy
      // or null in case the cell does not have a parent
      return this.parentInstance
        ? this.parentInstance.getInstancesByCellName(name)
        : [];
    }
  }
}
