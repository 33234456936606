import { backOff } from "exponential-backoff";
import firebase from "firebase/app";
import "firebase/functions";

import * as qbo from "@shared/src/api/quickbooks";
import { QuickBooksConnectionBase } from "@shared/src/components/quickbooks-connection-base";
import { QuickBooksQueryBase } from "@shared/src/components/quickbooks-query-base";
import { QuickBooksWriteBase } from "@shared/src/components/quickbooks-write-base";

import { getDefaultBackoffOptions } from "../callable";

/**
 * Opens a popup window to initiate the Hyperseeed QuickBooks app
 * authorization flow (where the user authorizes Hyperseed to access
 * their QuickBooks data).
 */
function openAuthPopup(orgId: string): Promise<void> {
  const width = 500;
  const height = width * 1.618;
  const left = window.screenX + (window.innerWidth - width) / 2;
  const top = window.screenY + (window.innerHeight - height) / 2;
  const proxy = window.open(
    `/oauth?org=${orgId}`,
    undefined,
    `popup,left=${left},top=${top},width=${width},height=${height}`
  );
  // Note: the beforeunload event handler does not seem to work on
  // Chrome, so we just poll the new window's `closed` property.
  // https://stackoverflow.com/a/48240128
  return new Promise<void>((resolve) => {
    const timer = setInterval(() => {
      if (proxy?.closed) {
        clearInterval(timer);
        resolve();
      }
    }, 500);
  });
}

/**
 * Executes the given QuickBooks API request. If the response
 * indicates that the user must authorize the Hyperseed QuickBooks
 * app, opens a popup to initiate the authorization flow and then
 * attempts the request once more.
 *
 * @param request the QuickBooks API request
 * @return the QuickBooks API resposne
 * @throws object with `message` property if second authorization
 *   attempt did not succeed
 */
async function execute(request: qbo.APIRequest): Promise<qbo.APIResponse> {
  const apiCallFn = firebase.functions().httpsCallable("quickbooks-apiCall");
  const backoffOptions = getDefaultBackoffOptions();
  let result = await backOff(() => apiCallFn(request), backoffOptions);
  let response = result.data as qbo.APIResponse;
  if (response.errorCode === qbo.ErrorCode.NEED_AUTH) {
    await openAuthPopup(request.orgId);
    result = await apiCallFn(request);
    response = result.data as qbo.APIResponse;
    if (response.errorCode === qbo.ErrorCode.NEED_AUTH) {
      throw new qbo.QuickBooksError(
        "QuickBooks authorization failed",
        qbo.ErrorCode.AUTH_FAILED
      );
    }
  }
  return response;
}

export class QuickBooksQueryComponent extends QuickBooksQueryBase {
  protected query(request: qbo.APIRequest): Promise<qbo.APIResponse> {
    return execute(request);
  }
}

export class QuickBooksWriteComponent extends QuickBooksWriteBase {
  protected write(request: qbo.APIRequest): Promise<qbo.APIResponse> {
    return execute(request);
  }
}

export class QuickBooksConnectionComponent extends QuickBooksConnectionBase {
  protected async execute(
    request: qbo.ConnectionRequest
  ): Promise<qbo.ConnectionResponse> {
    const connectionFn = firebase
      .functions()
      .httpsCallable("quickbooks-connection");
    const backoffOptions = getDefaultBackoffOptions();
    let result = await backOff(() => connectionFn(request), backoffOptions);
    let response = result.data as qbo.ConnectionResponse;
    if (response.errorCode === qbo.ErrorCode.NEED_AUTH) {
      // Connection op "connect" returned NEED_AUTH; prompt the user to
      // authorize the Hyperseed QuickBooks app, then fire the
      // "connect" request again to complete the connection.
      await openAuthPopup(request.orgId);
      result = await connectionFn(request);
      response = result.data as qbo.ConnectionResponse;
      if (response.errorCode === qbo.ErrorCode.NEED_AUTH) {
        throw new qbo.QuickBooksError(
          "QuickBooks authorization failed",
          qbo.ErrorCode.AUTH_FAILED
        );
      }
    }
    return response;
  }
}
