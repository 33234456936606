import { AppContext } from "../classes/app-context";
import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  Unsubscribable,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

export enum Status {
  PENDING = "pending",
  FAILED = "failed",
  SUCCEEDED = "succeeded",
}

enum ErrorCode {
  BAD_DATA_TYPE = "hyperseed/bad-data-type",
  APP_NAME_UNSET = "hyperseed/app-name-unset",
  ORG_ID_UNSET = "hyperseed/org-id-unset",
  COLLECTION_UNSET = "hyperseed/collection-unset",
  BAD_COLLECTION = "hyperseed/bad-collection",
  DATA_UNSET = "hyperseed/data-unset",
}

/**
 * Represents a Firebase document.
 */
export interface Document {
  id?: string;
  [key: string]: unknown;
}

/**
 * Represents a Firestore write error.
 */
export class FirestoreWriteError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Regular expression used to remove leading and trailing slashes from
 * a path.
 */
const slashesRE = /^\/+|\/+$/g;

/**
 * Writes a document to Firestore, replacing the existing document if
 * it exists.
 */
export abstract class FirestoreWriteBase implements Component {
  private _invokedBy: ComponentInvoker;

  /** App name, used in the Firestore document path */
  private _appName?: string;
  /** Org ID of data owner, used in the Firestore document path */
  private _orgId?: string;
  /** User ID of the user writing to Firestore */
  protected _userId?: string;
  /**
   * Collection path as a slash-separated Firestore path following
   * orgs/{orgId}/apps/{appName}.
   *
   * Path must be an odd number of path segments.
   */
  private _collection?: string;
  /**
   * The IDs of the documents written.
   */
  protected _writtenIds?: string[];
  /** The data to write */
  private _data: unknown = null;
  /** Flag for caller to request write synchronization */
  private _write = false;
  /** Flag to check write synchronization state transition */
  private _prevWrite = false;

  /** The number of documents written to Firestore */
  protected _docsWritten = 0;
  /** The status after a Firestore operation was triggered */
  private _status: Status | null = null;
  /** A description of the error if status is FAILED */
  private _error: string | null = null;
  /** A code identifying the error if status is FAILED */
  private _errorCode: ErrorCode | string | null = null;
  /**
   * Pulses true for a single frame after write operation completes
   * (whether it succeeded or failed)
   */
  private _finished = false;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   appName: { _input: null },
   *   collection: { _input: null },
   *   data: {
   *     _input: null,
   *     _iscollection: true,
   *     property: {
   *       _input: null,
   *     },
   *   },
   *   write: { _input: null },
   *   // Outputs
   *   finished: { _output: null },
   *   docsWritten: { _output: null },
   *   status: { _output: null },
   *   error: { _output: null },
   *   errorCode: { _output: null },
   *   writtenIds: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        if (context.orgId !== this._orgId) {
          this._orgId = context.orgId;
        }
        if (context.userId !== this._userId) {
          this._userId = context.userId;
        }
      },
    });
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  private reset() {
    this._finished = false;
    this.pushOutputs();
    this._invokedBy.setBusy(false);
  }

  setInputValueGraph(graph: ValueGraph): void {
    if (this._status === Status.PENDING) {
      Object.entries(graph).forEach(([key, value]) => {
        if (key.toLowerCase() == "write") this._write = Boolean(value);
      });

      if (this._write !== this._prevWrite) {
        // keep the previous state in sync
        this._prevWrite = this._write;
        // and log error just in case another write operation is triggered
        if (this._write)
          console.error(
            "firestore write operation ignored while component is pending"
          );
      }

      return;
    }

    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "appname":
          this._appName = value?.toString();
          break;
        case "collection":
          this._collection = value?.toString();
          this._collection = this._collection?.replace(slashesRE, "");
          break;
        case "data":
          this._data = value as unknown;
          break;
        case "write":
          this._write = Boolean(value);
          break;
      }
    });

    if (this._write !== this._prevWrite) {
      this._prevWrite = this._write;
      if (this._write) {
        this._invokedBy.setBusy(true);
        queueFrame(() => this.callWrite());
      }
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      finished: this._finished,
      docsWritten: this._docsWritten,
      status: this._status,
      error: this._error,
      errorCode: this._errorCode,
      writtenIds: this._writtenIds,
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (this._appName === undefined) {
        throw new FirestoreWriteError(
          "appName is missing",
          ErrorCode.APP_NAME_UNSET
        );
      }
      if (this._orgId === undefined) {
        throw new FirestoreWriteError(
          "orgId is missing",
          ErrorCode.ORG_ID_UNSET
        );
      }
      if (!this._collection) {
        throw new FirestoreWriteError(
          "collection is required",
          ErrorCode.COLLECTION_UNSET
        );
      }
      if (this._collection) {
        const segments = this._collection.split("/");
        if (segments.length % 2 !== 1) {
          throw new FirestoreWriteError(
            "collection needs odd number of path segments",
            ErrorCode.BAD_COLLECTION
          );
        }
      }
      if (this._data === null) {
        throw new FirestoreWriteError("data is missing", ErrorCode.DATA_UNSET);
      }
      if (typeof this._data !== "object") {
        throw new FirestoreWriteError(
          `Unsupported document type ${typeof this._data}`,
          ErrorCode.BAD_DATA_TYPE
        );
      }
    } catch (err) {
      this._error = (err as FirestoreWriteError).message;
      this._errorCode = (err as FirestoreWriteError).code;
      this._status = Status.FAILED;
      this.pushOutputs();
      this._invokedBy.setBusy(false);
      return false;
    }
    return true;
  }

  /**
   * Calls the asynchronuous write method and sets the output
   * properties of the component accordingly.
   */
  private callWrite() {
    if (!this.validateInputs()) return;
    this._status = Status.PENDING;
    this._error = null;
    this.pushOutputs();
    this.write(
      this._orgId as string,
      this._appName as string,
      this._collection as string,
      this._data
    )
      .then(() => {
        this._error = null;
        this._errorCode = null;
        this._status = Status.SUCCEEDED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((err) => {
        if (err instanceof FirestoreWriteError) {
          this._error = err.message;
          this._errorCode = err.code;
        } else {
          this._error = String(err);
          this._errorCode = (err as { code: string }).code ?? "unknown";
        }
        this._status = Status.FAILED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  /**
   * Writes documents to Firestore.
   *
   * @param orgId the org ID of the data owner, used in the Firestore
   *   document path
   * @param appName the app name, used in the Firestore document path
   * @param collection the relative collection path
   * @param data the document data to store
   * @returns an array of written document IDs
   * @throws Error if write failed
   */
  protected abstract write(
    orgId: string,
    appName: string,
    collection: string,
    data: unknown
  ): Promise<void>;

  /**
   * Adds metadata fields to a Firestore document object. Metadata
   * fields include timestamps and user IDs of document update events.
   */
  protected abstract addMetadata(data: Document): Document;

  protected pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("data.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["appname", "collection", "data", "write"]);
/** Names of component outputs. */
const outputs = new Set([
  "finished",
  "status",
  "docswritten",
  "error",
  "errorcode",
  "completepath",
  "writtenids",
]);
