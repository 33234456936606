import { AppContext } from "../classes/app-context";
import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import {
  DocumentData,
  FirestoreErrorCode,
  Timestamp,
  WhereFilterOp,
} from "../types/firebase/firestore";

export enum ErrorCode {
  BAD_COLLECTION = "hyperseed/bad-collection",
  BAD_QUERY = "hyperseed/bad-query",
  MAX_IDS = "hyperseed/max-ids-exceeded",
  BAD_PAGE = "hyperseed/bad-page-index",
}

/**
 * Represents a Firestore query error.
 */
export class FirestoreQueryError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Query where clause.
 */
export interface WhereClause {
  /** The path to compare */
  field: string;
  /** The operation string (e.g "<", "<=", "==", ">", ">=") */
  op: WhereFilterOp;
  /** The value for comparison */
  value: unknown;
}

/**
 * Query orderBy clause.
 */
export interface OrderByClause {
  /** The path of the field to order by */
  field: string;
  /** Specifies descending order for this field */
  descending?: boolean;
}

/**
 * Regular expression used to remove leading and trailing slashes from
 * a path.
 */
const slashesRE = /^\/+|\/+$/g;

interface QueueItem {
  context?: AppContext;
  graph?: ValueGraph;
}

/**
 * Queries Firestore.
 */
export abstract class FirestoreQueryBase implements InitializableComponent {
  private _invokedBy: ComponentInvoker;

  private _queue: QueueItem[] = [];

  /** App name, used in the Firestore document key path */
  private _appName?: string;
  /** Org ID of data owner, used in the Firestore document key path */
  private _orgId?: string;
  /**
   * Collection path between `"orgs/{orgId}"` and the value of the
   * `collection` input. Can be used as an alternative to setting the
   * `appName` input (which resolves to the `orgRoot`-equivalent of
   * `"apps/Billing"`).
   */
  private _orgRoot?: string;
  /**
   * Collection path as a slash-separated Firestore path following
   * orgs/{orgId}/apps/{appName}.
   *
   * Must have an odd number of segments.
   */
  private _collection?: string;
  /** The IDs of the documents to query */
  private _ids?: string[];
  /** Flag for caller to request listening for document changes */
  protected _listen = true;
  /** Query where clauses */
  private _where?: WhereClause[];
  /** Specifies the sort order for documents */
  private _orderBy?: OrderByClause[];
  /** Specifies the maximum number of documents (the size of one page) */
  private _limit?: number;
  /** Specifies the zero-based index of the page to return */
  private _page?: number;
  /** Function to unsubscribe listen observer */
  protected _unsubscribe?: () => void;

  /** The colllection data queried from Firestore */
  private _data?: ValueGraph[];
  /** true if the collection or query has more elements than limit */
  private _hasMore = false;
  /** A description of an error */
  private _error: string | null = null;
  /** A code identifying an error */
  private _errorCode: ErrorCode | FirestoreErrorCode | string | null = null;

  /** The busy state of this component */
  private _isBusy = false;

  /**
   * True when the component is processing an input.
   *
   * Note how it differs from `_isBusy`:
   * - `_isBusy` is used to signal busy state to the invoker;
   *   `_processing` is used internally.
   * -  While `_isBusy` remains true as long as `_queue` items are
   *    being processed, `_processing` is toggled for each item in the
   *    queue.
   */
  private _processing = false;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   appName: { _input: null },
   *   orgRoot: { _input: null },
   *   collection: { _input: null },
   *   ids: {
   *     _input: null,
   *     _iscollection: true,
   *   },
   *   where: {
   *     _iscollection: true,
   *     _input: null,
   *     field: { _input: null },
   *     op: { _input: null },
   *     value: { _input: null },
   *   },
   *   orderBy: {
   *     _iscollection: true,
   *     _input: null,
   *     field: { _input: null },
   *     descending: { _input: null },
   *   },
   *   limit: { _input: null },
   *   page: { _input: null },
   *   listen: { _input: null },
   *   // Outputs
   *   error: { _output: null },
   *   errorCode: { _output: null },
   *   data: { _output: null },
   *   hasMore:  { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        // Process changes to orgId.
        if (context.orgId !== this._orgId) {
          this._queue.push({ context });
          this.setBusy(true);
          if (this._queue.length === 1) this.processQueue();
        }
      },
    });
  }

  destroy(): void {
    this._unsubscribe?.();
    this._contextSubscription?.unsubscribe();
  }

  private reset() {
    // reset busy state only when input queue is dry
    this.setBusy(this._queue.length > 0 || this._processing);
  }

  setInputValueGraph(graph: ValueGraph): void {
    // First add the input graph to the input queue
    this._queue.push({ graph });
    // inform the invoker that the component is busy (asuming that setting any
    // input requires some processing)
    this.setBusy(true);
    // If this is the first and only item in the queue, start the processing
    // loop (as otherwise it is already started)
    if (this._queue.length === 1) this.processQueue();
  }

  private processQueue() {
    // pause processing the input queue when the request to Firestore is still
    // pending
    // TODO: should we add some timeout, e.g. 10ms, or should we use an observer?
    if (this._processing) {
      queueFrame(() => this.processQueue());
      return;
    }

    // retrieve the first item from the input queue and process it
    const { context, graph } = this._queue.shift() ?? {};
    if (context) {
      this.processContext(context);
    }
    if (graph) {
      this.processInput(graph);
    }

    // as long as items are left on the input queue, continue with
    // processing the queue
    if (this._queue.length > 0) queueFrame(() => this.processQueue());
  }

  private processContext(context: AppContext): void {
    if (this._processing) {
      return;
    }
    this._processing = true;
    this._orgId = context.orgId;
    queueFrame(() => this.callListenOrQuery());
  }

  private processInput(graph: ValueGraph): void {
    if (this._processing) {
      return;
    }
    this._processing = true;
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "appname":
          this._appName = value?.toString();
          break;
        case "orgroot":
          this._orgRoot = value?.toString();
          break;
        case "collection":
          this._collection = value?.toString();
          this._collection = this._collection?.replace(slashesRE, "");
          break;
        case "ids":
          this._ids = value as string[];
          if (!Array.isArray(this._ids)) this._ids = [this._ids];
          break;
        case "where":
          if (Array.isArray(value))
            this._where = value as unknown as WhereClause[];
          else this._where = [value as unknown as WhereClause];
          break;
        case "orderby":
          if (Array.isArray(value))
            this._orderBy = value as unknown as OrderByClause[];
          else this._orderBy = [value as unknown as OrderByClause];
          break;
        case "limit":
          this._limit = value === null ? undefined : Number(value);
          break;
        case "page":
          this._page = value === null ? undefined : Number(value);
          break;
        case "listen":
          this._listen = Boolean(value);
          break;
      }
    });
    if (!this._listen) this._unsubscribe?.();
    queueFrame(() => this.callListenOrQuery());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      error: this._error,
      errorCode: this._errorCode,
      data: this._data,
      hasMore: this._hasMore,
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (!this._orgId || !this._collection) {
        this.update(undefined, null, null);
        return false;
      }
      if (!this._orgRoot && !this._appName) {
        this.update(undefined, null, null);
        return false;
      }
      const segments = this._collection.split("/");
      if (segments.length % 2 !== 1) {
        throw new FirestoreQueryError(
          "collection needs odd number of collection segments",
          ErrorCode.BAD_COLLECTION
        );
      }
      if (this._where) {
        for (const clause of this._where) {
          if (!clause.field || !clause.op || clause.value === undefined) {
            throw new FirestoreQueryError(
              "incomplete where clause",
              ErrorCode.BAD_QUERY
            );
          }
        }
      }
      if (this._orderBy) {
        for (const clause of this._orderBy) {
          if (!clause.field) {
            throw new FirestoreQueryError(
              "incomplete orderBy clause",
              ErrorCode.BAD_QUERY
            );
          }
        }
      }
      if (this._ids && this._ids.length > 10) {
        throw new FirestoreQueryError("max 10 IDs exceeded", ErrorCode.MAX_IDS);
      }
    } catch (err) {
      this.update(
        undefined,
        (err as FirestoreQueryError).message,
        (err as FirestoreQueryError).code
      );
      return false;
    }
    return true;
  }

  /**
   * Calls either the listen method to register an observer (if the
   * "listen" input is true) or the asynchronuous query method and sets
   * the output properties of the component accordingly.
   */
  private callListenOrQuery() {
    if (!this.validateInputs()) return;
    this.setBusy(true);
    this._error = null;
    this._errorCode = null;
    if (this._listen) {
      try {
        this.listen(
          this._orgId as string,
          this._collection as string,
          this._ids as string[],
          this._appName,
          this._orgRoot,
          this._where,
          this._orderBy,
          // request one more to know if it has more than requested
          this._limit ? this._limit + 1 : undefined,
          this._page
        );
      } catch (err) {
        if (err instanceof FirestoreQueryError) {
          this.update(undefined, err.message, err.code);
        } else {
          this.update(
            undefined,
            String(err),
            (err as { code: string }).code ?? "unknown"
          );
        }
      }
      return;
    }
    this.query(
      this._orgId as string,
      this._collection as string,
      this._ids as string[],
      this._appName,
      this._orgRoot,
      this._where,
      this._orderBy,
      // request one more to know if it has more than requested
      this._limit ? this._limit + 1 : undefined,
      this._page
    )
      .then((data) => {
        this.update(data, null, null);
      })
      .catch((err) => {
        if (err instanceof FirestoreQueryError) {
          this.update(undefined, err.message, err.code);
        } else {
          this.update(
            undefined,
            String(err),
            (err as { code: string }).code ?? "unknown"
          );
        }
      });
  }

  /**
   * Recursively converts the given document object's
   * `firebase.firestore.Timestamp` properties to JavaScript `Date`
   * objects.
   */
  protected convertTimestamp(doc: DocumentData): DocumentData {
    Object.entries(doc).forEach(([key, value]) => {
      if (typeof (value as Timestamp)?.toDate === "function") {
        doc[key] = (value as Timestamp).toDate();
      } else if (value && typeof value === "object") {
        doc[key] = this.convertTimestamp(value as Timestamp);
      }
    });
    return doc;
  }

  /**
   * Installs an observer to listen for document updates from
   * Firestore.
   *
   * @param orgId the org ID of the data owner, used in the Firestore
   *   document key path
   * @param collection the relative collection path
   * @param ids the IDs of the documents to query
   * @param appName the app name, used in the Firestore document key
   *   path
   * @param orgRoot the collection orgRoot, required if `appName` not
   *   set
   * @param where query where clauses
   * @param orderBy the field and direction by which to sort
   * @param limit maximum number of items to return
   * @param page zero-based index of the page to return
   */
  protected abstract listen(
    orgId: string,
    collection: string,
    ids: string[],
    appName?: string,
    orgRoot?: string,
    where?: WhereClause[],
    orderBy?: OrderByClause[],
    limit?: number,
    page?: number
  ): void;

  /**
   * Queries Firestore.
   *
   * @param orgId the org ID of the data owner, used in the Firestore
   *   document key path
   * @param collection the relative collection path
   * @param ids the IDs of the documents to query
   * @param appName the app name, used in the Firestore document key
   *   path
   * @param orgRoot the collection orgRoot, required if `appName` not
   *   set
   * @param where query where clauses
   * @param orderBy the field and direction by which to sort
   * @param limit maximum number of items to return (one page)
   * @param page zero-based index of the page to return
   * @returns the document data
   * @throws Error if query failed
   */
  protected abstract query(
    orgId: string,
    collection: string,
    ids: string[],
    appName?: string,
    orgRoot?: string,
    where?: WhereClause[],
    orderBy?: OrderByClause[],
    limit?: number,
    page?: number
  ): Promise<ValueGraph[]>;

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  /**
   * Sets the busy state of the component and propagates it to the invoker if it
   * has changed
   */
  protected setBusy(busy: boolean) {
    if (busy !== this._isBusy) {
      this._isBusy = busy;
      this._invokedBy.setBusy(busy);
    }
  }

  /**
   * Sets the given outputs and triggers the `updated` edge output.
   */
  protected update(
    data: ValueGraph[] | undefined,
    error: string | null,
    errorCode: ErrorCode | FirestoreErrorCode | string | null
  ) {
    // in case of an update from the listener the component has not yet reported
    // itself as busy, so we make sure it is done in any case
    this.setBusy(true);

    this._processing = false;

    // limit number of returned documents to 9999, as firestore returns max
    // 10000
    this._data = data?.slice(0, this._limit ?? 9999);
    this._hasMore = data ? data.length > (this._limit ?? 9999) : false;
    this._error = error;
    this._errorCode = errorCode;
    this.pushOutputs();

    // reset() will inform the invoker that the component is no longer busy
    queueFrame(() => this.reset());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("where.")) return true;
    if (name.startsWith("orderby.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    if (name.startsWith("data.")) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set([
  "appname",
  "orgroot",
  "collection",
  "ids",
  "where",
  "orderby",
  "limit",
  "page",
  "listen",
]);
/** Names of component outputs. */
const outputs = new Set(["error", "errorcode", "data", "hasmore"]);
