export const environment = {
  firebase: {
    apiKey: "AIzaSyCegRkm44ymxxmnyjLXK30rHOMvgHsg5Nk",
    authDomain: "seed-alpha-1.firebaseapp.com",
    projectId: "seed-alpha-1",
    storageBucket: "seed-alpha-1.appspot.com",
    messagingSenderId: "469967980585",
    appId: "1:469967980585:web:0bdbf93490c33e67dbb438",
  },
  gcloud: {
    publicBucket: "public-seed-alpha-1",
  },
  sheetsApi: {
    clientId:
      "469967980585-1gcvgsm3tdoql4mlgsennh6njlhu4ti7.apps.googleusercontent.com",
  },
  // This is just a placeholder; we currently just have a hotglue
  // sandbox account that is used only on seed-dev-1361e.
  hotglue: { flows: {} },
};
