export type FrameFunction = () => void;

/** The queue of functions to be called in the given sequence */
const frameQueue: FrameFunction[] = [];
/**
 * isScheduled is `true` when a next processFrames call has already been
 * scheduled.
 */
let isScheduled = false;

/**
 * Adds a function to the frame queue and schedules processing the queue as
 * soon as possible.
 * @param frameFn The function to be added to the queue
 */
export default function queueFrame(frameFn: FrameFunction): void {
  frameQueue.push(frameFn);
  // if the frame processor has not yet been scheduled, then schedule its first
  // call
  if (!isScheduled) {
    setTimeout(() => processFrames());
    isScheduled = true;
  }
}

/**
 * Processes all pending frames. Queues remaining frames when execution took
 * more than 100ms or when aditional frames have been queued.
 */
function processFrames() {
  isScheduled = false;
  const start = Date.now();
  const frames = frameQueue.length;

  for (let f = 0; f < frames; f++) {
    // remove the first frame from the queue and process it
    const frameFn = frameQueue.shift();
    if (frameFn) frameFn();
    // interrupt if processing already took longer than 100ms
    if (Date.now() - start > 100) break;
  }

  // Schedule another call to the frame processor if there are more frames and
  // the next call to the processor wasn't yet scheduled
  if (frameQueue.length > 0 && !isScheduled) {
    setTimeout(() => processFrames());
    isScheduled = true;
  }
}
