import jsep from "jsep";

import Cell from "../classes/cell";
import {
  CellNameWalker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { makeArray, toValue, toNumber } from "./behavior-commons";

export function createStringFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "concat":
      return new concatFunctionBehaviorNode(token, mode);
    case "length":
      return new lengthFunctionBehaviorNode(token, mode);
    case "split":
      return new splitFunctionBehaviorNode(token, mode);
    case "indexof":
      return new indexOfFunctionBehaviorNode(token, mode);
    case "lastindexof":
      return new lastIndexOfFunctionBehaviorNode(token, mode);
    case "substring":
      return new substringFunctionBehaviorNode(token, mode);
    case "left":
      return new leftFunctionBehaviorNode(token, mode);
    case "right":
      return new rightFunctionBehaviorNode(token, mode);
    case "startswith":
      return new startsWithFunctionBehaviorNode(token, mode);
    case "endswith":
      return new endsWithFunctionBehaviorNode(token, mode);
    case "match":
      return new matchFunctionBehaviorNode(token, mode);
    case "test":
      return new testFunctionBehaviorNode(token, mode);
    case "replace":
      return new replaceFunctionBehaviorNode(token, mode);
    case "tolowercase":
    case "lower":
      return new toLowerCaseFunctionBehaviorNode(token, mode);
    case "touppercase":
    case "upper":
      return new toUpperCaseFunctionBehaviorNode(token, mode);
    case "trim":
      return new trimFunctionBehaviorNode(token, mode);
    case "trimstart":
      return new trimStartFunctionBehaviorNode(token, mode);
    case "trimend":
      return new trimEndFunctionBehaviorNode(token, mode);
  }
}

/**
 * Concatenates values into a single string without adding a separator.
 * The values are converted to strings before they get concatenated.
 * @name concat() function
 * @param ...value The value(s) that are concatenated into a string. If a value
 *    is an array, each element of the array is converted to a string before it
 *    is added to the resulting string.
 * @returns The concatenated string.
 */
class concatFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    let result = "";
    for (const argument of this.arguments) {
      const array = makeArray(argument.evaluate(scope, calledFrom));
      for (const element of array) {
        result += (element ?? "").toString();
      }
    }
    return result;
  }
}

/**
 * Returns the length of a string.
 * @name length() function
 * @param string The string for which to retrieve the length.
 * @returns The length of the string.
 */
// TODO: shall we return an array of string lengths?
class lengthFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    return string === null ? 0 : string.toString().length;
  }
}

/**
 * Splits a string by a separator into an ordered list of substrings.
 * @name split() function
 * @param string The string that will be split.
 * @param separator The separator by which to split the string.
 * @returns An array of substrings. If separator is not found, the entire
 *    string is returned as a sinlge item in the resulting array.
 */
class splitFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    const separator = toValue(this.arguments[1].evaluate(scope, calledFrom));
    return !string
      ? []
      : !separator
      ? [string]
      : string.toString().split(separator.toString());
  }
}

/**
 * Returns the index within a string of the first occurrence of a search value,
 * or -1 if not found. This function is case sensitive.
 * @name indexOf() function
 * @param string The string in which to search.
 * @param search The string to search for.
 * @param from (optional) The index from where to start the search.
 * @returns The 0-based index at which search has been found or -1 if not found.
 */
// TODO: in case of the first argment being an array we could give back an array of indexes?
class indexOfFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    // TODO: does returning mull make any sense here? As when `search` cannot be
    // found in null, the result should still be -1
    if (!string) return null;

    const search = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (!search) return -1;

    if (this.arguments.length < 3) {
      return string.toString().indexOf(search.toString());
    } else {
      const from = toNumber(this.arguments[2].evaluate(scope, calledFrom));
      return string.toString().indexOf(search.toString(), from);
    }
  }
}

/**
 * Returns the index within a string of the last occurrence of a search value,
 * or -1 if not found. This function is case sensitive.
 * @name lastIndexOf() function
 * @param string The string in which to search.
 * @param search The string to search for.
 * @param from (optional) The index of the last character in the string to be considered as the beginning of a match. The default value is `+Infinity`. If `from >= length(string)`, the whole string is searched. If `from < 0`, the behavior will be the same as if it would be 0.
 * @returns The 0-based index at which search has been found or -1 if not found. If `search` is an empty string, then `from` is returned.
 */
// TODO: in case of the first argment being an array we could give back an array of indexes?
class lastIndexOfFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    // TODO: does returning mull make any sense here? As when `search` cannot be
    // found in null, the result should still be -1
    if (!string) return null;

    const search = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (!search) return -1;

    if (this.arguments.length < 3) {
      return string.toString().lastIndexOf(search.toString());
    } else {
      const from = toNumber(this.arguments[2].evaluate(scope, calledFrom));
      return string.toString().lastIndexOf(search.toString(), from);
    }
  }
}

/**
 * Returns a new string containing characters of the string from (or between)
 * the specified index (or indeces).
 * @name substring() function
 * @param string The string from which to take the substring.
 * @param start The start index from where to take the substring.
 * @param end (optional) The end index until where to take the substring.
 * @returns The substring from start up to but not including end.
 */
class substringFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    const start = toNumber(this.arguments[1].evaluate(scope, calledFrom));

    if (this.arguments.length < 3) {
      return string.toString().substring(start);
    } else {
      const end = toNumber(this.arguments[2].evaluate(scope, calledFrom));
      return string.toString().substring(start, end);
    }
  }
}

/**
 * Returns the first character or characters in a text string, based on the
 * number of characters you specify.
 * @name left() function
 * @param string The string from which to extract the characters.
 * @param numChars (optional) The number of characters you want to extract. If
 *   less than 0, an empty string is returned. If omitted, 1 character is
 *   returned. If greater than
 *   the length of `string`, the entire string is returned.
 * @returns The extracted characters from `string`.
 */
class leftFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    if (this.arguments.length < 2) {
      return string.toString().substring(0, 1);
    } else {
      const numChars = toNumber(this.arguments[1].evaluate(scope, calledFrom));
      return string.toString().substring(0, numChars);
    }
  }
}

/**
 * Returns the last character or characters in a text string, based on the
 * number of characters you specify.
 * @name right() function
 * @param string The string from which to extract the characters.
 * @param numChars (optional) The number of characters you want to extract. If
 *   less than 0, an empty string is returned. If omitted, 1 character is
 *   returned. If greater than the length of `string`, the entire string is
 *   returned.
 * @returns The extracted characters from `string`.
 */
class rightFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    let string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    string = string.toString();

    if (this.arguments.length < 2) {
      return string.substring(string.length - 1);
    } else {
      const numChars = toNumber(this.arguments[1].evaluate(scope, calledFrom));
      return string.substring(string.length - numChars);
    }
  }
}

/**
 * Determines whether a string begins with the characters of a specified string,
 * returning `true` or `false` as appropriate.
 * @name startsWith() function
 * @param string The string in which to search.
 * @param search The characters to be searched for at the start of `string`.
 * @param position (optional) The position in this string at which to begin
 *   searching for `search`. Defaults to 0.
 * @returns `true` if the given characters are found at the beginning of the
 *   `string`; otherwise, `false`.
 */
class startsWithFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return false;

    let search = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (search === null) search = "";

    if (this.arguments.length < 3) {
      return string.toString().startsWith(search.toString());
    } else {
      const position = toNumber(this.arguments[2].evaluate(scope, calledFrom));
      return string.toString().startsWith(search.toString(), position);
    }
  }
}

/**
 * Determines whether a string ends with the characters of a specified string,
 * returning `true` or `false` as appropriate.
 * @name endsWith() function
 * @param string The string in which to search.
 * @param search The characters to be searched for at the end of `string`.
 * @param length (optional) If provided, it is used as the length of `string`.
 *   Defaults to the entire length of `string`.
 * @returns `true` if the given characters are found at the end of the `string`;
 *   otherwise, `false`.
 */
class endsWithFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return false;

    let search = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (search === null) search = "";

    if (this.arguments.length < 3) {
      return string.toString().endsWith(search.toString());
    } else {
      const length = toNumber(this.arguments[2].evaluate(scope, calledFrom));
      return string.toString().endsWith(search.toString(), length);
    }
  }
}

/**
 * Matches a regular expression against a string and returns the matching substrings or null if there is no match
 * @name match() function
 * @param string The string against which to match.
 * @param regex The regular expression.
 * @param flags (opional) Regex flags used for the match.
 * @returns An array of all matching substrings from string.
 */
class matchFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): string | string[] | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    const regexString = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (regexString === null) return null;

    const flags =
      this.arguments.length > 2
        ? (
            toValue(this.arguments[2].evaluate(scope, calledFrom)) ?? ""
          ).toString()
        : "";

    let regex;
    try {
      regex = new RegExp(regexString.toString(), flags);
    } catch (e) {
      return null;
    }

    if (flags.search("g") >= 0) {
      // the global flag is set, so we loop until no further entry is found
      const result = [];
      let match;
      while ((match = regex.exec(string.toString())) !== null) {
        result.push(match[0]);
      }
      return result;
    } else {
      // without global flag we run the regex just once to avoid infinite loop
      const match = regex.exec(string.toString());
      return match ? match[0] : null;
    }
  }
}

/**
 * Tests a string against a regular expression.
 * Returns `true` or `false`.
 * @name test() function
 * @param string The string against which to match.
 * @param regex The regular expression.
 * @param flags (opional) Regex flags used for the match.
 * @returns Returns `true` if `string` matched `regex`, otherwise `false`. If an
 *    error occured, `null` is returned.
 */
class testFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    const regexString = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (regexString === null) return null;

    // if flags are defined, ignore the global flag as otherwise it remembers
    // the position according to
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test#using_test_on_a_regex_with_the_global_flag
    const flags =
      this.arguments.length > 2
        ? (toValue(this.arguments[2].evaluate(scope, calledFrom)) ?? "")
            .toString()
            .replace(/g/gi, "")
        : "";

    let regex;
    try {
      regex = new RegExp(regexString.toString(), flags);
    } catch (e) {
      // TODO: we should return an error code
      return null;
    }

    return regex.test(string.toString());
  }
}

/**
 * Replaces occurrences of a substring with another substring.
 * If the search is an array, it replaces all substrings with replace.
 * If the search and replace are arrays, it replaces them pair by pair.
 * If the replace array is shorter than search array it uses empty strings.
 * @name replace() function
 * @param string The string in which to replace the substrings.
 * @param search A regex to find the substrings that will get replaced.
 * @param replace The substring that replaces the search string.
 * @returns The string where search has been replaced with replace.
 */
// TODO: 'If the search is an array, it replaces all substrings with replace' is not yet working correctly
// TODO: this function (as it mimics replaceAll) needs to use regular expressions for the search string
//       thus the special characters used for regex need to be escaped
class replaceFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    let string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    string = string.toString();

    const search = this.arguments[1].evaluate(scope, calledFrom);
    if (search === null) return string;

    let replace = this.arguments[2].evaluate(scope, calledFrom) ?? "";

    if (!Array.isArray(search)) {
      replace = (toValue(replace) ?? "").toString();
      try {
        return string.replace(RegExp(search.toString(), "g"), replace);
      } catch (e) {
        return string;
      }
    } else {
      if (!Array.isArray(replace)) {
        replace = [replace];
      }
      for (let i = 0; i < search.length; i++) {
        if (search[i]) {
          string = string.replace(
            RegExp((search[i] ?? "").toString(), "g"),
            i < replace.length ? (toValue(replace[i]) ?? "").toString() : ""
          );
        }
      }
    }

    return string;
  }
}

/**
 * Converts a string value to lowercase. Converts a non string argument to a
 * string before.
 * @name toLowerCase() function
 * @param string The string to be converted to lowercase.
 * @returns The string all in lowercase.
 */
class toLowerCaseFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    return string.toString().toLowerCase();
  }
}

/**
 * Converts a string value to uppercase. Converts a non string argument to a
 * string before.
 * @name toUpperCase() function
 * @param string The string to be converted to uppercase.
 * @returns The string all in uppercase.
 */
class toUpperCaseFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    return string.toString().toUpperCase();
  }
}

/**
 * Trims whitespace from the beginning and end of the string. Converts a non
 * string argument to a string before.
 * @name trim() function
 * @param string The string to be trimmed.
 * @returns The trimmed string.
 */
class trimFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    return string.toString().trim();
  }
}

/**
 * Trims whitespace from the beginning of the string. Converts a non
 * string argument to a string before.
 * @name trimStart() function
 * @param string The string to be trimmed.
 * @returns The trimmed string.
 */
class trimStartFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    return string.toString().trimStart();
  }
}

/**
 * Trims whitespace from the end of the string. Converts a non
 * string argument to a string before.
 * @name trimEnd() function
 * @param string The string to be trimmed.
 * @returns The trimmed string.
 */
class trimEndFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const string = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (string === null) return null;

    return string.toString().trimEnd();
  }
}
