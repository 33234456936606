import {
  ComponentInvoker,
  PerfCounter,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { SchemaLoader } from "../schema/schema-global";
import { AppContext } from "./app-context";
import { ComponentLibrary } from "./component-library";
import CustomComponent from "./custom-component";

export type BrainDependencies = {
  componentLibrary?: ComponentLibrary;
  schemaLoader?: SchemaLoader;
  perfCounter?: PerfCounter;
  appContext?: AppContext;
};

/** Represents a listener callback registered with `addOutputListener`. */
export type OutputListener = (graph: ValueGraph) => void;

export class Brain implements ComponentInvoker {
  private _main: CustomComponent | null = null;

  private _library?: ComponentLibrary;
  private _schemaLoader?: SchemaLoader;
  readonly perfCounter?: PerfCounter;
  private _context?: AppContext;

  private _outputListenerSequence = 0;
  /** Maps output listener sequence numbers to listener callbacks. */
  private _outputListeners: Map<number, OutputListener>;

  constructor(deps: BrainDependencies) {
    this._library = deps.componentLibrary;
    this._schemaLoader = deps.schemaLoader;
    this.perfCounter = deps.perfCounter;
    this._context = deps.appContext;
    this._outputListeners = new Map<number, OutputListener>();
  }

  /**
   * Initialises the brain by instantiating the CellType tree and loading itself
   * from the storage. Eventually starts the behavior loop.
   * When init is used to re-initialise this brain, the original entry point is re-used.
   *
   * @param entryPoint (optional) An entry point into the schema. "main" is the default.
   * @returns A promise to the brain object.
   * @throws Error when a defined entry point has not been found.
   */
  async init(entryPoint = "main"): Promise<Brain> {
    if (!this._library && this._schemaLoader) {
      const schema = await this._schemaLoader.getSchema();
      this._library = new ComponentLibrary(schema);
    }

    if (!this._library)
      throw new Error(
        "componentLibrary was not defined in BrainDependencies or schemaLoader could not load successfully"
      );

    this._main = new CustomComponent(this._library, this, this.perfCounter);
    this._main.init(entryPoint);

    // Subscribe to output updates from the main component. When the
    // provided callback is invoked, notify all registered listeners.
    // TODO: should this be set before this._main.init to have the first update
    // with constant values propagated?
    this._main.subscribeToOutputUpdates(() => {
      if (this._outputListeners.size === 0) return;
      const graph = this.getOutputValueGraph();
      for (const listener of this._outputListeners.values()) {
        listener(graph);
      }
    });

    return this;
  }

  public get main(): CustomComponent | null {
    return this._main;
  }

  /**
   * Sets input values on the component that has been initialised by
   * `init`. If the component has not yet been initialised, the function
   * returns without setting any inputs.
   *
   * @param graph A JavaScript object with the input values. The keys
   *     must correspond to the schema of the component. If the name of
   *     an input is not found in the schema, the value is ignored.
   */
  public setInputValueGraph(graph: ValueGraph): void {
    this._main?.setInputValueGraph(graph);
  }

  /**
   * Sets input values on the component that has been initialised by
   * `init` and returns a promise that resolves with output values. If
   * the component has not yet been initialised, the function returns
   * without setting any inputs.
   *
   * @param graph A JavaScript object with the input values. The keys
   *     must correspond to the schema of the component. If the name of
   *     an input is not found in the schema, the value is ignored.
   * @param timeout The number of milliseconds the Hyperseed runtime
   *     waits for the inputs to resolve before throwing an exception.
   *     Default: 5000 ms. A value of 0 disables the timeout.
   * @throws Error if the timeout was exceeded before the inputs were
   *     resolved.
   */
  public async setInputValueGraphAndWait(
    graph: ValueGraph,
    timeout = 5000
  ): Promise<ValueGraph> {
    this._main?.setInputValueGraph(graph);
    await this._main?.waitUntilSettled(timeout);
    return Promise.resolve(this.getOutputValueGraph());
  }

  /**
   * Returns the current output values of the component that has been initialised by `init`.
   * If the component has not yet been initialised, an empty object is returned.
   * @returns A JavaScript object with the output values. The keys correspond to the schema of the component.
   */
  public getOutputValueGraph(): ValueGraph {
    if (!this._main) return {};
    else return this._main.getOutputValueGraph();
  }

  /**
   * Attaches the UI components of this application to a DOM root.
   * @param rootDOMNode The root DOM node this application shall be mounted to.
   */
  public attachDOM(rootDOMNode: Element): void {
    if (!this._main) return;

    this._main.attachDOM(rootDOMNode);
  }

  /**
   * Registers a listener that is called whenever output values are
   * updated.
   *
   * @param listener a function to be executed when output values are
   *   updated
   * @returns a positive integer value that identifies the registered
   *    output listener. This value can be passed to
   *    `removeOutputListener` to cancel the listener.
   */
  public addOutputListener(listener: OutputListener): number {
    const id = this._outputListenerSequence++;
    this._outputListeners.set(id, listener);
    return id;
  }

  /**
   * Cancels an output update listener that was set with
   * `addOutputListener`.
   *
   * @param id a listener ID returned by `setUpdateListener`
   */
  public removeOutputListener(id: number): void {
    this._outputListeners.delete(id);
  }

  /**
   * Removes this brain from memory but does not delete any data from its
   * storage.
   */
  public destroy(): void {
    if (!this._main) return;

    this._main.destroy();
    this._main = null;
  }

  // TODO: implement the ComponentInvoker interface correctly
  setOutputValueGraph(): void {
    // do nothing (for now)
  }

  setBusy(): void {
    // do nothing (for now)
  }

  fixOrderOfDOMNodes(): void {
    // do nothing (and eventually remove it)
  }

  getAppContext(): AppContext | undefined {
    return this._context;
  }
}
