import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import {
  FirestoreReadBase,
  FirestoreReadError,
  ErrorCode,
} from "@shared/src/components/firestore-read-base";
import { ComponentInvoker } from "@shared/src/interfaces/global-interfaces";

export default class FirestoreReadComponent extends FirestoreReadBase {
  private _db: firebase.firestore.Firestore;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);
    this._db = firebase.firestore();
  }

  protected listen(
    orgId: string,
    collection: string,
    id: string,
    appName: string,
    orgRoot?: string
  ): void {
    const collectionPath = orgRoot
      ? `orgs/${orgId}/${orgRoot}/${collection}`
      : `orgs/${orgId}/apps/${appName}/${collection}`;
    const collectionRef = this._db.collection(collectionPath);
    const docRef = collectionRef.doc(id);
    this._unsubscribe?.();
    this._unsubscribe = docRef.onSnapshot({
      next: (doc) => {
        if (!doc.exists) {
          this.update(null, "document not found", ErrorCode.NOT_FOUND);
          return;
        }
        const data = [this.convertTimestamp({ id: doc.id, ...doc.data() })];
        this.update(data, null, null);
      },
      error: (error: firebase.firestore.FirestoreError) => {
        // Log the error unless currentUser is null, which can happen
        // when the user logs out while the component is listening.
        if (firebase.auth().currentUser !== null) {
          console.error("onSnapshot failed:", error);
        }
        this.update(null, error.message, error.code);
      },
    });
  }

  protected async read(
    orgId: string,
    collection: string,
    id: string,
    appName: string,
    orgRoot?: string
  ): Promise<unknown> {
    try {
      const collectionPath = orgRoot
        ? `orgs/${orgId}/${orgRoot}/${collection}`
        : `orgs/${orgId}/apps/${appName}/${collection}`;
      const collectionRef = this._db.collection(collectionPath);
      const doc = await collectionRef.doc(id).get();
      if (!doc.exists) {
        throw new FirestoreReadError(
          `document not found: ${doc.id}`,
          ErrorCode.NOT_FOUND
        );
      }
      return this.convertTimestamp({ id: doc.id, ...doc.data() });
    } catch (err) {
      if (err instanceof FirestoreReadError) throw err;
      console.error("read failed:", err);
      throw err;
    }
  }
}
