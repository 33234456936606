import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIViewComponent from "./ui-view-component";

/**
 * The UI link component represents an anchor element in the DOM tree.
 */
export default class UILabelComponent extends UIViewComponent {
  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy, "label");
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.for = { _input: null };
    return schema;
  }

  get DOMNode(): HTMLLabelElement {
    return this._DOMNode as HTMLLabelElement;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "for":
        // potentially make use of this.DOMNode.* property
        this.setOrRemoveAttribute("for", value);
        break;
    }
  }
}
