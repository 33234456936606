import { makeArray } from "../behavior/behavior-commons";
import queueFrame from "../classes/frame-queue";
import Instance from "../classes/instance";
import {
  isValue,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIPointerComponent from "./ui-pointer-component";

interface DataTransfer {
  type: string;
  data: string;
}

/**
 * UIDragComponent captures the dragging of DOM elements, thus listens to the
 * drag events.
 */
export default class UIDragComponent extends UIPointerComponent {
  private _dragging = false;
  private _dataTransfer: DataTransfer[] = [];
  private _imageSrc: string | null = null;

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.dataTransfer = {
      _iscollection: true,
      _input: null,
      type: { _input: null },
      data: { _input: null },
    };
    schema.imageSrc = { _input: null };

    schema.dragging = { _output: {} };

    return schema;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();
    result.dragging = this._dragging;
    return result;
  }

  attachDOM(parentDOMNode: Element): void {
    super.attachDOM(parentDOMNode);

    parentDOMNode.setAttribute("draggable", "true");

    this.addEventListenerToParent("dragstart", this.onDragStart);
    this.addEventListenerToParent("drag", this.onDrag);
    this.addEventListenerToParent("dragend", this.onDragEnd);
  }

  detachDOM(): void {
    this.removeEventListenerFromParent("dragstart", this.onDragStart);
    this.removeEventListenerFromParent("drag", this.onDrag);
    this.removeEventListenerFromParent("dragend", this.onDragEnd);

    super.detachDOM();

    this._dragging = false;
    queueFrame(() => this.pushOutputs());
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "datatransfer":
        this._dataTransfer = [];
        for (const data of makeArray(value)) {
          this.addTransferDataAttributes(data);
        }
        break;
      case "imagesrc":
        this._imageSrc = value?.toString() ?? null;
        break;
    }
  }

  private addTransferDataAttributes(data: ValueGraphData): void {
    if (data === null) {
      // no data means we do not add an element
    } else if (isValue(data) || data instanceof Instance) {
      // a primitive value or an instance reference is set as a text value
      // TODO: an instance reference should be resolved to a value or object first
      this._dataTransfer.push({ type: "text/plain", data: data.toString() });
    } else {
      // an object sets type and data accordingly
      let type, dataValue;

      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined) {
          switch (key.toLowerCase()) {
            case "type":
              type = value?.toString();
              break;
            case "data":
              dataValue = value?.toString();
              break;
          }
        }
      });

      if (type !== undefined && dataValue !== undefined) {
        this._dataTransfer.push({ type: type, data: dataValue });
      }
    }
  }

  // TODO: to avoid adding multiple instances of the same callback function, the
  // function at the time being must be static as by not having a DOM node on
  // the mouse, keyboard, drag & drop sensors, the runtime considers those not
  // yet being added. We should find a cleaner way of doing this. see also
  // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#memory_issues

  private onDragStart = (event: Event): void => {
    const dt = (event as DragEvent).dataTransfer;
    if (dt) {
      for (const data of this._dataTransfer) {
        dt.setData(data.type, data.data);
      }
      if (this._imageSrc) {
        const img = new Image();
        img.src = this._imageSrc;
        dt.setDragImage(img, 0, 0);
      }
    }

    this._dragging = true;

    // TODO: find someone who can help us understand why at least Chrome
    // does weird things sometimes and for whatever reason resets the mouse
    // coordinates during drag&drop
    if (
      (event as DragEvent).screenX != 0 ||
      (event as DragEvent).screenY != 0
    ) {
      this.setPointerAttributes(event as DragEvent);
      this.propagationAndDefault(event as DragEvent);
    }

    this.pushOutputs();
  };

  private onDrag = (event: Event): void => {
    this._dragging = true;

    if (
      (event as DragEvent).screenX != 0 ||
      (event as DragEvent).screenY != 0
    ) {
      this.setPointerAttributes(event as DragEvent);
      this.propagationAndDefault(event as DragEvent);
    }

    this.pushOutputs();
  };

  private onDragEnd = (event: Event): void => {
    this._dragging = false;

    if (
      (event as DragEvent).screenX != 0 ||
      (event as DragEvent).screenY != 0
    ) {
      this.setPointerAttributes(event as DragEvent);
      this.propagationAndDefault(event as DragEvent);
    }

    this.pushOutputs();
  };
}
