import { AppContext } from "../classes/app-context";
import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  InitializableComponent,
  Unsubscribable,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import {
  DocumentData,
  FirestoreErrorCode,
  Timestamp,
} from "../types/firebase/firestore";

export enum ErrorCode {
  BAD_COLLECTION = "hyperseed/bad-collection",
  NOT_FOUND = "hyperseed/not-found",
}

/**
 * Represents a Firestore read error.
 */
export class FirestoreReadError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Regular expression used to remove leading and trailing slashes from
 * a path.
 */
const slashesRE = /^\/+|\/+$/g;

interface QueueItem {
  context?: AppContext;
  graph?: ValueGraph;
}

/**
 * Reads a document from Firestore.
 */
export abstract class FirestoreReadBase implements InitializableComponent {
  private _invokedBy: ComponentInvoker;

  private _queue: QueueItem[] = [];

  /** App name, used in the Firestore document key path */
  private _appName?: string;
  /** Org ID of data owner, used in the Firestore document key path */
  private _orgId?: string;
  /**
   * Collection path between `"orgs/{orgId}"` and the value of the
   * `collection` input. Can be used as an alternative to setting the
   * `appName` input (which resolves to the `orgRoot`-equivalent of
   * `"apps/Billing"`).
   */
  private _orgRoot?: string;
  /**
   * Collection path as a slash-separated Firestore path following
   * orgs/{orgId}/apps/{appName}.
   *
   * Path must be an odd number of path segments.
   */
  private _collection?: string;
  /** The ID of the document to read */
  private _id?: string;
  /** Flag for caller to request listening for document changes */
  protected _listen = true;
  /** Function to unsubscribe listen observer */
  protected _unsubscribe?: () => void;

  /**
   * The document data read from Firestore.
   */
  private _data: unknown | null = null;
  /** A description of an error */
  private _error: string | null = null;
  /** A code identifying an error */
  private _errorCode: ErrorCode | FirestoreErrorCode | string | null = null;

  /** The busy state of this component */
  private _isBusy = false;

  /**
   * True when the component is processing an input.
   *
   * Note how it differs from `_isBusy`:
   * - `_isBusy` is used to signal busy state to the invoker;
   *   `_processing` is used internally.
   * -  While `_isBusy` remains true as long as `_queue` items are
   *    being processed, `_processing` is toggled for each item in the
   *    queue.
   */
  private _processing = false;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   appName: { _input: null },
   *   orgRoot: { _input: null },
   *   collection: { _input: null },
   *   id: { _input: null },
   *   listen: { _input: null },
   *   // Outputs
   *   error: { _output: null },
   *   errorCode: { _output: null },
   *   data: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  init(): void {
    this._contextSubscription = this._invokedBy.getAppContext()?.subscribe({
      next: (context: AppContext) => {
        // Process changes to orgId.
        if (context.orgId !== this._orgId) {
          this._queue.push({ context });
          this.setBusy(true);
          if (this._queue.length === 1) this.processQueue();
        }
      },
    });
  }

  destroy(): void {
    this._unsubscribe?.();
    this._contextSubscription?.unsubscribe();
  }

  private reset() {
    // reset busy state only when input queue is dry
    this.setBusy(this._queue.length > 0 || this._processing);
  }

  setInputValueGraph(graph: ValueGraph): void {
    // First add the input graph to the input queue
    this._queue.push({ graph });
    // inform the invoker that the component is busy (asuming that setting any
    // input requires some processing)
    this.setBusy(true);
    // If this is the first and only item in the queue, start the processing
    // loop (as otherwise it is already started)
    if (this._queue.length === 1) this.processQueue();
  }

  private processQueue() {
    // pause processing the input queue when the request to Firestore is still
    // pending
    // TODO: should we add some timeout, e.g. 10ms, or should we use an observer?
    if (this._processing) {
      queueFrame(() => this.processQueue());
      return;
    }

    // retrieve the first item from the input queue and process it
    const { context, graph } = this._queue.shift() ?? {};
    if (context) {
      this.processContext(context);
    }
    if (graph) {
      this.processInput(graph);
    }

    // as long as items are left on the input queue, continue with
    // processing the queue
    if (this._queue.length > 0) queueFrame(() => this.processQueue());
  }

  private processContext(context: AppContext): void {
    if (this._processing) {
      return;
    }
    this._processing = true;
    this._orgId = context.orgId;
    queueFrame(() => this.callListenOrRead());
  }

  private processInput(graph: ValueGraph): void {
    if (this._processing) {
      return;
    }
    this._processing = true;
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "appname":
          this._appName = value?.toString();
          break;
        case "orgroot":
          this._orgRoot = value?.toString();
          break;
        case "collection":
          this._collection = value?.toString();
          this._collection = this._collection?.replace(slashesRE, "");
          break;
        case "id":
          this._id = value?.toString();
          break;
        case "listen":
          this._listen = Boolean(value);
          break;
      }
    });
    if (!this._listen) this._unsubscribe?.();
    queueFrame(() => this.callListenOrRead());
  }

  getOutputValueGraph(): ValueGraph {
    return {
      error: this._error,
      errorCode: this._errorCode,
      data: this._data as ValueGraph[],
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (!this._orgId || !this._collection || !this._id) {
        this.update(null, null, null);
        return false;
      }
      if (!this._orgRoot && !this._appName) {
        this.update(null, null, null);
        return false;
      }
      const segments = this._collection.split("/");
      if (segments.length % 2 !== 1) {
        throw new FirestoreReadError(
          "collection needs odd number of path segments",
          ErrorCode.BAD_COLLECTION
        );
      }
    } catch (err) {
      this.update(
        null,
        (err as FirestoreReadError).message,
        (err as FirestoreReadError).code
      );
      return false;
    }
    return true;
  }

  /**
   * Calls either the listen method to register an observer (if the
   * "listen" input is true) or the asynchronuous read method and sets
   * the output properties of the component accordingly.
   */
  private callListenOrRead() {
    if (!this.validateInputs()) return;
    this.setBusy(true);
    this._error = null;
    this._errorCode = null;
    if (this._listen) {
      try {
        this.listen(
          this._orgId as string,
          this._collection as string,
          this._id as string,
          this._appName,
          this._orgRoot
        );
      } catch (err) {
        if (err instanceof FirestoreReadError) {
          this.update(null, err.message, err.code);
        } else {
          this.update(
            null,
            String(err),
            (err as { code: string }).code ?? "unknown"
          );
        }
      }
      return;
    }
    this.read(
      this._orgId as string,
      this._collection as string,
      this._id as string,
      this._appName,
      this._orgRoot
    )
      .then((data) => {
        this.update(data, null, null);
      })
      .catch((err) => {
        if (err instanceof FirestoreReadError) {
          this.update(null, err.message, err.code);
        } else {
          this.update(
            null,
            String(err),
            (err as { code: string }).code ?? "unknown"
          );
        }
      });
  }

  /**
   * Recursively converts the given document object's
   * `firebase.firestore.Timestamp` properties to JavaScript `Date`
   * objects.
   */
  protected convertTimestamp(doc: DocumentData): DocumentData {
    Object.entries(doc).forEach(([key, value]) => {
      if (typeof (value as Timestamp)?.toDate === "function") {
        doc[key] = (value as Timestamp).toDate();
      } else if (value && typeof value === "object") {
        doc[key] = this.convertTimestamp(value as Timestamp);
      }
    });
    return doc;
  }

  /**
   * Installs an observer to listen for document updates from
   * Firestore.
   *
   * @param orgId the org ID of the data owner, used in the Firestore
   *   document key path
   * @param collection the relative collection path
   * @param id the document ID to read
   * @param appName the app name, used in the Firestore document key
   *   path, required if `orgRoot` not set
   * @param orgRoot the collection orgRoot, required if `appName` not
   *   set
   */
  protected abstract listen(
    orgId: string,
    collection: string,
    id: string,
    appName?: string,
    orgRoot?: string
  ): void;

  /**
   * Reads documents from Firestore.
   *
   * @param orgId the org ID of the data owner, used in the Firestore
   *   document key path
   * @param collection the relative collection path
   * @param id ID of the document to read
   * @param appName the app name, used in the Firestore document key
   *   path, required if `orgRoot` not set
   * @param orgRoot the collection orgRoot, required if `appName` not
   *   set
   * @returns the document data
   * @throws Error if read failed
   */
  protected abstract read(
    orgId: string,
    collection: string,
    id: string,
    appName?: string,
    orgRoot?: string
  ): Promise<unknown>;

  private pushOutputs() {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  /**
   * Sets the busy state of the component and propagates it to the invoker if it
   * has changed
   */
  protected setBusy(busy: boolean) {
    if (busy !== this._isBusy) {
      this._isBusy = busy;
      this._invokedBy.setBusy(busy);
    }
  }

  /**
   * Sets the given outputs and triggers the `updated` edge output.
   */
  protected update(
    data: unknown | null,
    error: string | null,
    errorCode: ErrorCode | FirestoreErrorCode | string | null
  ) {
    // in case of an update from the listener the component has not yet reported
    // itself as busy, so we make sure it is done in any case
    this.setBusy(true);

    this._processing = false;

    this._data = data;
    this._error = error;
    this._errorCode = errorCode;
    this.pushOutputs();

    // reset() will inform the invoker that the component is no longer busy
    queueFrame(() => this.reset());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    if (name.startsWith("data.")) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["appname", "orgroot", "collection", "id", "listen"]);
/** Names of component outputs. */
const outputs = new Set(["error", "errorcode", "data"]);
