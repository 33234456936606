import * as contentType from "content-type";
import "cross-fetch/polyfill";
import { BackoffOptions, backOff } from "exponential-backoff";

import queueFrame from "../classes/frame-queue";
import { stripValueProps } from "../helpers/helpers";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

export interface ApiHeader {
  key: string;
  value: string;
}

interface Request {
  headers: ApiHeader[];
  method?: string;
  payload?: unknown;
}

export interface Response {
  headers: ApiHeader[];
  payload?: unknown;
  statuscode: number;
  statustext: string;
}

enum Status {
  IDLE = "request-idle",
  PENDING = "request-pending",
  SUCCEEDED = "request-succeeded",
  FAILED = "request-failed",
}

/**
 * The API component allows caller to trigger an outbound API request to
 * a third-party API.
 */
export default class ApiComponent implements Component {
  private _invokedBy: ComponentInvoker;
  private _url: string | null = null;
  private _request: Request | null = null;
  private _response: Response | null = null;
  private _triggered = false;

  /** The API request status */
  private _status: Status = Status.IDLE;
  /** The error message if an error occured during signin or signout */
  private _error: string | null = null;

  private _backoffStartingDelay = 100;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   url: { _input: null },
   *   request: {
   *     _input: null,
   *     headers: {
   *       _input: null,
   *       _iscollection: true,
   *       key: { _input: null },
   *       value: { _input: null },
   *     },
   *     method: { _input: null },
   *     payload: {
   *       _input: null,
   *       _iscollection: true,
   *       property: {
   *         _input: null,
   *       },
   *     },
   *   },
   *   trigger: { _input: null },
   *   // Outputs
   *   response: {
   *     _output: null,
   *     headers: {
   *       _output: null,
   *       _iscollection: true,
   *       key: { _output: null },
   *       value: { _output: null },
   *     },
   *     statusCode: { _output: null },
   *     statusText: { _output: null },
   *     payload: {
   *       _output: null,
   *       _iscollection: true,
   *       property: {
   *         _output: null,
   *       },
   *     },
   *   },
   *   status: { _output: null },
   *   error: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  destroy(): void {
    // nothing to destroy
  }

  private reset() {
    // TODO: reset function does not add value as there is no edge output like
    // `finished`. We can move resetting the trigger and _busy state directly to
    // the execute method.
    this._triggered = false;
    this.pushOutputs();
    this._invokedBy.setBusy(false);
  }

  private execute(): void {
    if (this._url === null) return;
    this._error = null;
    this._status = Status.PENDING;
    this.pushOutputs();

    let response: Response;
    const fetchInit = {
      headers: new Headers(),
      method: this._request?.method,
      body: this._request?.payload
        ? JSON.stringify(this._request?.payload)
        : undefined,
    };
    for (const { key, value } of this._request?.headers ?? []) {
      fetchInit.headers.set(key, value);
    }
    if (fetchInit.body) {
      const headerText = fetchInit.headers.get("Content-Type");
      if (!headerText) {
        this._error = `API request Content-Type is required with payload`;
        this._status = Status.FAILED;
        this._response = null;
        this.pushOutputs();
        queueFrame(() => this.reset());
        return;
      }
      const header = contentType.parse(headerText);
      if (header.type !== "application/json") {
        this._error = `API request Content-Type "${header.type}" is not supported`;
        this._status = Status.FAILED;
        this._response = null;
        this.pushOutputs();
        queueFrame(() => this.reset());
        return;
      }
    }
    const numOfAttempts = 3;
    let attempt = 0;
    /**
     * Wraps `fetch` and converts retryable status codes to thrown
     * errors so that `backOff` can retry the request.
     */
    const fetchRetry = async (url: string, init: RequestInit) => {
      const resp = await fetch(url, init);
      // If numOfAttempts is exhausted, return response to stop
      // retrying.
      if (++attempt === numOfAttempts) return resp;
      // If status dictates, throw to direct backOff to retry.
      if (resp.status === 429 || resp.status >= 500) {
        throw new Error(
          `${init.method ?? ""} ${url} returned status ${resp.status} ${
            resp.statusText
          }`
        );
      }
      // Return response to skip retry.
      return resp;
    };
    const backOffOptions: BackoffOptions = {
      numOfAttempts,
      startingDelay: this._backoffStartingDelay,
    };
    backOff(() => fetchRetry(this._url ?? "unknown", fetchInit), backOffOptions)
      .then((resp) => {
        response = {
          headers: [],
          statuscode: resp.status,
          statustext: resp.statusText,
        };
        resp.headers.forEach((value, key) => {
          response.headers.push({ key, value });
        });
        const headerText = resp.headers.get("Content-Type");
        let header;
        if (headerText) {
          header = contentType.parse(headerText);
          if (header.type === "application/json") {
            return resp.json();
          }
        }
        // Responses with no body (e.g., HEAD requests) are supported.
        if (resp.headers.get("Content-Length") === "0") return null;
        // Responses with a non-JSON body are not supported.
        if (!headerText)
          this._error = 'API response header "Content-Type" is missing';
        else
          this._error = `API response Content-Type "${
            header?.type ?? ""
          }" is not supported`;
        return null;
      })
      .then((data) => {
        response.payload = data;
        this._response = response;
        if (response.statuscode >= 400) {
          this._error = `API responded with ${response.statuscode} ${response.statustext}`;
        }
        this._status = this._error === null ? Status.SUCCEEDED : Status.FAILED;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((error) => {
        this._error =
          error instanceof Error ? error.message : (error as string);
        this._status = Status.FAILED;
        this._response = null;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  /**
   * Sets the backoff starting delay in milliseconds. Intended for use
   * by unit tests.
   * @see https://www.npmjs.com/package/exponential-backoff
   */
  setBackoffStartingDelay(delay: number) {
    this._backoffStartingDelay = delay;
  }

  setInputValueGraph(graph: ValueGraph): void {
    let url: string | null = null;
    let request: Request | null = null;
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "url":
          url = value?.toString() ?? null;
          break;
        case "request":
          request = value as unknown as Request;
          break;
        case "trigger":
          this._triggered = Boolean(value);
          break;
      }
    });
    if (url === null) return;
    // TODO: Consider aborting request if inputs are updated while
    // status is pending. Or implement input queue...
    if (this._status === Status.PENDING) return;
    // remove the _value properties (mainly within payload)
    stripValueProps(request);
    this._url = url;
    this._request = request;
    if (this._triggered) {
      this._invokedBy.setBusy(true);
      queueFrame(() => this.execute());
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      response: this._response as ValueGraphData,
      status: this._status,
      error: this._error,
    };
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("request.headers.")) return true;
    if (name.startsWith("request.payload.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    if (name.startsWith("response.headers.")) return true;
    if (name.startsWith("response.payload.")) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set([
  "url",
  "request",
  "request.headers",
  "request.method",
  "request.payload",
  "trigger",
]);
/** Names of component outputs. */
const outputs = new Set([
  "response",
  "response.headers",
  "response.statuscode",
  "response.statustext",
  "response.payload",
  "status",
  "error",
]);
