import { isValue } from "../interfaces/global-interfaces";

/**
 * Recursively removes `_value` properties from the given object.
 */
export function stripValueProps(o: unknown) {
  if (!o || typeof o !== "object") return;
  delete (o as { _value: unknown })._value;
  Object.values(o as Record<string, unknown>).forEach((value) => {
    if (typeof value === "object") stripValueProps(value);
  });
}

/**
 * Removes top level properties from an object independent of case.
 * @param obj The object from which to remove the properties.
 * @param props The property names to be removed.
 */
export function stripTopLevelProps(
  obj: Record<string, unknown>,
  props: string[]
) {
  props = props.map((v) => v.toLowerCase());
  Object.entries(obj).forEach(([key]) => {
    if (props.includes(key.toLowerCase())) delete obj[key];
  });
}

/**
 * Removes properties or replaces property names from an object by a case
 * insensitive path.
 * @param obj The object from which to remove the properties.
 * @param propPaths The paths to the properties to be removed or renamed.
 * @param rename The property names to be set. missing entries as well as `null`
 *    or empty string will remove the property.
 * @param parentPath The path of the parent for nested properties.
 */
export function stripProps(
  obj: object,
  propPaths: string[],
  rename: (string | null)[],
  parentPath?: string
) {
  if (Array.isArray(obj) || isValue(obj)) return;

  propPaths = propPaths.map((v) => v.toLowerCase());
  Object.entries(obj).forEach(([key, value]) => {
    const path = parentPath
      ? `${parentPath}.${key.toLowerCase()}`
      : key.toLowerCase();

    const index = propPaths.findIndex((element) => element === path);
    const setName = rename[index];
    if (index >= 0) {
      delete (obj as Record<string, unknown>)[key];
      if (setName) (obj as Record<string, unknown>)[setName] = value;
    }
    if (Array.isArray(value)) {
      value.forEach((item) =>
        stripProps(item as object, propPaths, rename, path)
      );
    } else if (typeof value === "object") {
      stripProps(value as object, propPaths, rename, path);
    }
  });
}

/**
 * Returns the value of a property from an object by a case insensitive path.
 * Does not return values of properties or elements of arrays.
 * @param obj The object from which to remove the properties.
 * @param propPath The path to the property from which to return the value.
 * @param parentPath The path of the parent for nested properties.
 * @returns The value of the property or `undefined` if property was not found.
 */
export function valueOfProp(
  obj: object,
  propPath: string,
  parentPath?: string
): unknown {
  propPath = propPath.toLowerCase();
  let result: unknown;
  Object.entries(obj).forEach(([key, value]) => {
    const path = parentPath
      ? `${parentPath}.${key.toLowerCase()}`
      : key.toLowerCase();

    if (path === propPath) {
      result = value as unknown;
      return;
    }

    if (result === undefined && value && typeof value === "object") {
      result = valueOfProp(value as object, propPath, path);
    }
  });

  return result;
}
