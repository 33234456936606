// Handles the QuickBooks API authorization flow on the /oauth page.
import { backOff } from "exponential-backoff";
import firebase from "firebase/app";
import "firebase/functions";

import * as qbo from "@shared/src/api/quickbooks";

import { getDefaultBackoffOptions } from "./callable";

/**
 * Parses the given URL query string and returns a map of parameter
 * keys to values.
 */
function parseQueryString(search?: string): Map<string, string> {
  const params = new Map<string, string>();
  if (!search) return params;
  const parts = search.split("?");
  if (parts.length !== 2) return params;
  const pairs = parts[1].split("&");
  pairs.forEach((p) => {
    const [key, value] = p.split("=");
    if (key && value) params.set(key, decodeURIComponent(value));
  });
  return params;
}

/**
 * Creates a QuickBooks access token if necessary after checking the
 * URL for an authorization code and realm ID. If the page was loaded
 * after a redirect from QuickBooks, where the user authorized our
 * QuickBooks app, query parameters `code` and `realmId` are present in
 * the URL. That URL is sent to the backend where it is used to extract
 * the query parameters and create the access token.
 *
 * @return true if redirect query parameters are present and an access
 *  token was created
 */
async function maybeCreateAccessToken(): Promise<boolean> {
  if (!window.location.search) {
    return false;
  }
  const params = parseQueryString(window.location.search);
  const authCode = params.get("code");
  const realmId = params.get("realmId");
  const stateParams = parseQueryString(
    "?" + window.decodeURIComponent(params.get("state") ?? "NONE")
  );
  const orgId = stateParams.get("org") ?? "UNKNOWN";
  const request: qbo.CreateAuthTokenRequest = {
    orgId,
    uri: window.location.href,
  };
  if (authCode && realmId) {
    const authFn = firebase
      .functions()
      .httpsCallable("quickbooks-createAuthToken");
    const backoffOptions = getDefaultBackoffOptions();
    await backOff(() => authFn(request), backoffOptions);
    return true;
  }
  return false;
}

/**
 * Retrieves the QuickBooks authorization URI from the backend and
 * redirects the user to start the auth flow, and creates an access
 * token if a redirect from the the QuickBooks auth server is detected
 * to complete the auth flow.
 */
export async function authorize() {
  try {
    if (await maybeCreateAccessToken()) {
      window.close();
      return;
    }
    const params = parseQueryString(window.location.search);
    const orgId = params.get("org") ?? "UNKNOWN";
    const authFn = firebase
      .functions()
      .httpsCallable("quickbooks-getAuthorizeUri");
    const request: qbo.GetAuthorizeUriRequest = {
      state: `org=${orgId}`,
    };
    const backoffOptions = getDefaultBackoffOptions();
    const result = await backOff(() => authFn(request), backoffOptions);
    const response = result.data as qbo.GetAuthorizeUriResponse;
    window.location.href = response.uri;
  } catch (error) {
    console.error("QuickBooks authorization failed:", error);
  }
}
