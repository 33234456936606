import { ValueGraph } from "./interfaces/global-interfaces";
import { OrgRole, WhereClause } from "./model";

/**
 * Represents an empty RPC request.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyRequest {}

/**
 * Represents an empty RPC response.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyResponse {}

/** Represents a request for the Hyperseed run Firebase Function. */
export interface HyperseedRunRequest {
  /**
   * The location of the Hyperseed application schema to execute.
   */
  schemaPath: string;
  /**
   * The name of the component in the Hyperseed application schema that
   * should be executed. The default value used if component is not
   * specified is "main".
   */
  component?: string;
  /**
   * An object whose properties represent the Hyperseed application's
   * input values.
   */
  input: ValueGraph;
  /**
   * The org ID affected by this request. The authenticated user's
   * access to the org will be checked using the `roles` auth token
   * claim.
   */
  orgId: string;
}

/** Represents a response for the Hyperseed run Firebase Function. */
export interface HyperseedRunResponse {
  /**
   * The output values after the inputs have been processed and the
   * application has settled (i.e., output values are stable).
   */
  output: ValueGraph;
}

/**
 * Represents a request for the `api-makeApiKey` Firebase Function.
 */
export interface MakeApiKeyRequest {
  orgId: string;
}

/**
 * Represents error codes for the `api-makeApiKey` Firebase Function.
 */
export enum MakeApiErrorCode {
  /**
   * An API key already exists for the user.
   */
  KEY_EXISTS = "hyperseed/api-key-exists",
  /**
   * An unknown error occurred.
   */
  UNKNOWN = "hyperseed/uknown-error",
}

/**
 * Represents a response for the `api-makeApiKey` Firebase Function.
 */
export interface MakeApiKeyResponse {
  apiKey?: string;
  errorCode?: MakeApiErrorCode;
}

/**
 * Represents a response for the `api-deleteApiKey` Firebase Function.
 */
export interface DeleteApiKeyResponse {
  errorCode?: MakeApiErrorCode;
}

/**
 * Allows filtering the document tree copy operation. Useful in the
 * case of large volume of data that cannot be copied within the
 * timeout limit of Firebase Functions (9 minutes) as it allows a
 * subsequent run to pick up where the previous run timed out.
 */
export interface CopyDocumentFilter {
  /**
   * Collection path to filter.
   */
  collectionPath: string;
  /**
   * Doc ID within `collectionPath` to start copying.
   */
  skipBeforeDocId: string;
}

/**
 * Represents a request for the `system-copyDocument` Firebase Function
 * to copy a Firestore document tree.
 */
export interface CopyDocumentRequest {
  /**
   * Source document path.
   */
  srcDocumentPath: string;
  /**
   * Destination document path.
   */
  dstDocumentPath: string;
  /**
   * If true, copies the document tree recursively.
   */
  recursive: boolean;
  filter?: CopyDocumentFilter;
}

/**
 * Represents a response for the `system-copyDocument` Firebase
 * Function.
 */
export interface CopyDocumentResponse {
  ok: boolean;
  docsCopied: number;
}

/**
 * Represents a request for the `org-create` Firebase Function
 * to create a new organization.
 */
export interface OrgCreateRequest {
  name: string;
}

/**
 * Represents a response for the `org-create` Firebase Function.
 */
export interface OrgCreateResponse {
  id: string;
}

/**
 * Represents a request for the `org-setUserRole` Firebase Function to
 * set a user's role in an organization.
 */
export interface OrgSetUserRoleRequest {
  orgId: string;
  userId: string;
  role: OrgRole;
}

/**
 * Represents a request for the `org-removeUser` Firebase Function to
 * remove a user from an organization.
 */
export interface OrgRemoveUserRequest {
  orgId: string;
  userId: string;
}

/**
 * Represents a request for the `hyperseed-startBatchJobFn` and
 * `hyperseed-runBatchJobPubSub` Firebase Functions to initiate a batch
 * job for an organization.
 */
export interface BatchRequest {
  /**
   * ID of the org for which to run batch job.
   */
  orgId: string;
  /**
   * Identifies the batch job name at `orgs/{orgId}/jobs/{jobId}`.
   */
  jobId: string;
  /**
   * Identifies the batch document at
   * `orgs/{orgId}/jobs/{jobId}/batches/{batchId}`.
   */
  batchId: string;
  /**
   * The name of the Firestore collection whose documemts to enumerate.
   * Relative to `apps/Billing`.
   *
   * @example Given `Invoices`, a task is dispatched for each matching
   *   document in the `orgs/{orgId}/apps/Billing/Invoices` collection.
   */
  collection: string;
  /**
   * Where clauses used to filter documemts in `collection` for batch
   * processing.
   */
  filters: WhereClause[];
  /**
   * Custom component entry point, relative to `"schema/billing"`,
   * passed to `Brain#init`.
   */
  entryPoint: string;
  /**
   * Custom component inputs as a JSON-serializable object. If unset,
   * an empty object will be sent to the custom component.
   */
  inputs?: ValueGraph;
  /**
   * Name of `input` property to inject matching document ID. Each
   * matching document in `collection` matching `filters` will have its
   * document ID injected into `inputs` as the value of `docIdInput`.
   * If unset, document IDs will not be injected.
   */
  docIdInput?: string;
  /**
   * Custom component execution timeout in milliseconds. Passed to
   * `Brain#setInputValueGraphAndWait`. Default: 5000ms
   */
  timeout?: number;
  /**
   * The desired interval between monitor job task runs in seconds. The
   * batch document will be updated at this frequency. Default: initial
   * run after 60s, then every 300s (5m).
   */
  intervalSeconds?: number;
  /**
   * NOTE: For batch job framework use only.
   *
   * Slack message timestamp, used as a message ID for a thread parent.
   * If set, batch job status messages for all orgs are grouped in a
   * thread. Otherwise, batch job status messages are posted directly
   * to the channel.
   */
  threadTs?: string;
}

/**
 * Represents a response for the `hyperseed-startBatchJobFn` and
 * `hyperseed-runBatchJobPubSub` Firebase Functions to initiate a batch
 * job for an organization.
 */
export interface BatchResponse {
  /**
   * Firestore path to the job document at
   * `orgs/{orgId}/jobs/{jobId}/batches/{batchId}`.
   */
  batchPath: string;
}
