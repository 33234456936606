import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIElementComponent from "./ui-element-component";

/**
 * Represents an SVG animate element in the DOM tree. Details about the circle
 * element you can find here:
 * https://developer.mozilla.org/en-US/docs/Web/SVG/Element/animate
 */
export default class SVGAnimateComponent extends UIElementComponent {
  protected _DOMNode: SVGAnimateElement;

  constructor(invokedBy: ComponentInvoker, tagName = "animate") {
    super(invokedBy);

    if (!["animate", "animateMotion"].includes(tagName))
      throw new Error(
        `Invalid tagName '${tagName}' for SVG animate component.`
      );

    this._DOMNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      tagName
    ) as SVGAnimateElement; // requires casting as TS otherwise assumes just an SVGElement
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.begin = { _input: null };
    schema.dur = { _input: null };
    schema.end = { _input: null };
    schema.min = { _input: null };
    schema.max = { _input: null };
    schema.restart = { _input: null };
    schema.fill = { _input: null };
    schema.values = { _input: null };
    schema.from = { _input: null };
    schema.to = { _input: null };
    schema.by = { _input: null };
    schema.additive = { _input: null };
    schema.accumulate = { _input: null };
    schema.repeatCount = { _input: null };
    schema.repeatDur = { _input: null };
    schema.calcMode = { _input: null };
    schema.keyTimes = { _input: null };
    schema.keySplines = { _input: null };
    schema.attributeName = { _input: null };
    return schema;
  }

  get DOMNode(): SVGAnimateElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "begin":
      case "dur":
      case "end":
      case "min":
      case "max":
      case "restart":
      case "fill":
      case "values":
      case "from":
      case "to":
      case "by":
      case "additive":
      case "accumulate":
        this.setOrRemoveAttribute(key.toLowerCase(), value);
        break;
      case "repeatcount":
        this.setOrRemoveAttribute("repeatCount", value);
        break;
      case "repeatdur":
        this.setOrRemoveAttribute("repeatDur", value);
        break;
      case "calcmode":
        this.setOrRemoveAttribute("calcMode", value);
        break;
      case "keytimes":
        this.setOrRemoveAttribute("keyTimes", value);
        break;
      case "keysplines":
        this.setOrRemoveAttribute("keySplines", value);
        break;
      case "attributename":
        this.setOrRemoveAttribute("attributeName", value);
        break;
    }
  }
}
