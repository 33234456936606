import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * Represents a checkbox input element in the DOM tree.
 */
export default class UIInputCheckboxComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLInputElement;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("input");
    this._DOMNode.type = "checkbox";
    this._DOMNode.addEventListener("change", () => this.onChange());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.disabled = { _input: null };
    schema.required = { _input: null };

    schema.setChecked = { _input: null };

    schema.checked = { _output: {} };
    return schema;
  }

  get DOMNode(): HTMLInputElement {
    return this._DOMNode;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.checked = this._DOMNode.checked;

    return result;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      // TODO: move disabled and required to an InputElementComponent?
      case "disabled":
        this._DOMNode.disabled = Boolean(value);
        break;
      case "required":
        this._DOMNode.required = Boolean(value);
        break;

      case "setchecked":
        if (value !== null) {
          this._DOMNode.checked = Boolean(value);
          // as setting the value does not trigger the event
          queueFrame(() => this.onChange());
        }
        break;
    }
  }

  private onChange() {
    this.pushOutputs();
  }
}
