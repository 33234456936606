import jsep from "jsep";

import Cell from "../classes/cell";
import { CellNameWalker } from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import { isEmpty, toNumber, toValue } from "./behavior-commons";

export function createTestFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "isempty":
    case "isblank":
      return new isEmptyFunctionBehaviorNode(token, mode);
    case "isnan":
      return new isNaNFunctionBehaviorNode(token, mode);
    case "isnumber":
      return new isNumberFunctionBehaviorNode(token, mode);
    case "isboolean":
    case "islogical":
      return new isBooleanFunctionBehaviorNode(token, mode);
    case "isstring":
    case "istext":
      return new isStringFunctionBehaviorNode(token, mode);
    case "isdatetime":
      return new isDatetimeFunctionBehaviorNode(token, mode);
  }
}

/**
 * Tests if the argument is empty (is null), an empty array, an empty object, or
 * an array of empty elements.
 * @name isEmpty() function
 * @param value A value or an array.
 * @returns True if value is empty.
 */
class isEmptyFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    const result = this.arguments[0].evaluate(scope, calledFrom);
    return isEmpty(result);
  }
}

/**
 * Tests if the argument is not a number (NaN).
 * @name isNaN() function
 * @param value A value or an array.
 * @returns True if value is not a number.
 */
class isNaNFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return isNaN(toNumber(this.arguments[0].evaluate(scope, calledFrom)));
  }
}

// TODO: shall we return `false` on all `is` functions when the argument is an
// array? I.e. not converting to a scalar value first?

/**
 * Tests if the argument is of type number.
 * @name isNumber() function
 * @param value A value or an array.
 * @returns True if value is of type number.
 */
class isNumberFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return (
      typeof toValue(this.arguments[0].evaluate(scope, calledFrom)) === "number"
    );
  }
}

/**
 * Tests if the argument is of type boolean.
 * @name isBoolean() function
 * @param value A value or an array.
 * @returns True if value is of type boolean.
 */
class isBooleanFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return (
      typeof toValue(this.arguments[0].evaluate(scope, calledFrom)) ===
      "boolean"
    );
  }
}

/**
 * Tests if the argument is of type string.
 * @name isString() function
 * @param value A value or an array.
 * @returns True if value is of type string.
 */
class isStringFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return (
      typeof toValue(this.arguments[0].evaluate(scope, calledFrom)) === "string"
    );
  }
}

/**
 * Tests if the argument is of type Datetime.
 * @name isDatetime() function
 * @param value A value or an array.
 * @returns True if value is of type Datetime.
 */
class isDatetimeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): Value {
    return (
      toValue(this.arguments[0].evaluate(scope, calledFrom)) instanceof Date
    );
  }
}
