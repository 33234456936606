import SystemComponentFactory from "../components/system-component-factory";
import {
  CellNameWalker,
  Component,
  ComponentInvoker,
  InstanceBreadcrumb,
  isDOMComponent,
  isInitializableComponent,
  isValue,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { AppContext } from "./app-context";
import Cell, { CellBehaviorType } from "./cell";
import CellType, { InitGraph, InitGraphData } from "./cell-type";
import CustomComponent from "./custom-component";

export default class Instance implements CellNameWalker, ComponentInvoker {
  readonly root: CustomComponent;
  readonly parentCell: Cell | null;
  readonly cellType: CellType;
  protected _value: Value = null;
  protected _prevValue: Value = null;
  protected _cells = new Map<CellType, Cell>();
  private _invokedComponent?: Component;
  private _componentInputUpdated?: boolean;
  private _invokedIsBusy?: boolean;

  public index = -1;
  public DOMNodeId?: string;

  constructor(
    root: CustomComponent,
    parentCell: Cell | null,
    cellType: CellType
  ) {
    this.root = root;
    this.parentCell = parentCell;
    this.cellType = cellType;
  }

  /**
   * The DOM node of the component this instance is invoking. It returns `null`
   * if there is no invoked component or the component is not bound to the UI.
   *
   * @returns The DOM node of the component the instance is invoking or null.
   */
  public get DOMNode(): Element | null {
    if (this._invokedComponent && isDOMComponent(this._invokedComponent)) {
      return this._invokedComponent.DOMNode;
    } else {
      return null;
    }
  }

  /**
   * Returns the component this instance is invoking or `undefined` if this
   * instance does not invoke a component.
   */
  public get invokedComponent(): Component | undefined {
    return this._invokedComponent;
  }

  /** Returns the busy state of the invoked component. If no component has been
   * invoked, it returns `false` */
  public get invokedIsBusy(): boolean {
    return this._invokedIsBusy ?? false;
  }

  /**
   * Returns the closest instance that upstream is invoking a component or
   * `null` if there is none.
   */
  public get invocationRoot(): Instance | null {
    if (this._invokedComponent) {
      return this;
    } else if (this.parentCell) {
      return this.parentCell.parentInstance.invocationRoot;
    } else {
      return null;
    }
  }

  /**
   * The primitive value of this instance.
   *
   * @returns The primitive value of this instance.
   */
  public valueOf(): Value {
    return this._value;
  }

  /**
   * Returns the value of this instance as a string.
   *
   * @returns The value of this instance converted to a string. If the value is
   *    `null`, an empty string is returned.
   */
  public toString(): string {
    return this._value !== null ? this._value.toString() : "";
  }

  /**
   * Returns the (primitive) value this instance had before its behavior most
   * recently has been invoked.
   *
   * @returns The previous primitive value of this instance.
   */
  public prevValueOf(): Value {
    return this._prevValue;
  }

  /**
   * Initialises this instance by setting its initial value, instantiating and
   * initializing its child cells (without triggering their behavior) and
   * invoking the component that is defined by its type (only in case a type is
   * defined).
   *
   * @param data The initial value or data graph for this instance.
   */
  public init(data: Value | InitGraph): void {
    // avoiding negative zeros as they do not exist in spreadsheets
    if (isValue(data)) this._value = data === 0 ? 0 : data;
    else if (isValue(data._value))
      this._value = data._value === 0 ? 0 : data._value;

    /* TODO: shall the initialization not be considered a value update? The
     * following line would achieve this.
     *
     * Underlying thoughts: currently setting default values on the inputs of a
     * component is considered an update of the value, as the component is
     * initialised with its own default values. We probably would like to change
     * that behavior, as this would reduce flickering of e.g. UI components.
     * Nonetheless for the sake of consistency EACH setting of a default value
     * is a first update of that value.
     */
    /* this._prevValue = this._value; */

    // loop through all children of cellType to create the cells of this instance
    for (const cellType of this.cellType.children) {
      // create child cell of respective cellType
      const cell = new Cell(cellType, this);
      // children are looked up by cellType object
      this._cells.set(cellType, cell);

      let cellData: InitGraphData = null;

      // check if there is some initialization data for the child cell
      if (!isValue(data))
        Object.entries(data).forEach(([key, value]) => {
          if (cellType.name === key.toLowerCase()) {
            cellData = value ?? null;
            return;
          }
        });

      // initialize the cell with the appropriate initialization value or behavior
      cell.init(cellData);
    }

    this.invokeComponent();
  }

  /**
   * Removes this instance and all its cells
   */
  public destroy(): void {
    this._cells.forEach((cell) => {
      cell.destroy();
    });
    this._cells.clear();

    this.detachDOM();

    if (this._invokedComponent) {
      this._invokedComponent.destroy();
      // remvove the invoked component from the busy components map in case it
      // is still there. Do not delegate to the involed component, as system components might not consider this case
      this.root.setBusy(this._invokedComponent, false);
      delete this._invokedComponent;
    }
  }

  /**
   * Builds a storage path by walking up the tree from the instance to root
   * using the original cell and component names and the instance index instead
   * of the instance ids.
   */
  public getSimplifiedOriginalPath(cellName?: string): string {
    let path, inst;
    if (cellName) {
      path = cellName;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      inst = this;
    } else {
      path = this.cellType.isCollection
        ? `${this.cellType.originalName}.${this.index}`
        : this.cellType.originalName;
      inst = this.parentCell?.parentInstance ?? null;
    }

    while (inst != null) {
      path = inst.cellType.isCollection
        ? `${inst.cellType.originalName}.${inst.index}.` + path
        : `${inst.cellType.originalName}.` + path;
      inst = inst.parentCell?.parentInstance ?? null;
    }
    return path;
  }

  /**
   * Executes one behavior frame for this instance and all its child cells.
   *
   * If this instance is invoking a system or custom component and one of
   * the input values for this component has been updated, it sets the input
   * values of this component at the end of the frame execution of this
   * instance.
   */
  public behaveAll(): void {
    this._cells.forEach((cell) => {
      cell.behaveAll();
    });

    if (this._invokedComponent) {
      if (this._componentInputUpdated)
        this._invokedComponent.setInputValueGraph(this.getInputValueGraph());

      // reset _componentInputUpdated only after inputs have been propagated to the
      // component as not all input updates happen directly in the
      // behavior frame (e.g. custom components only exposing system component
      // inputs).
      this._componentInputUpdated = false;

      // the busy attribute is set outside the cell behavior loop as it must
      // consider component inputs to have been updated and the component to
      // report or not report being busy
      this.getAttribute("_busy")?.setValue(this.invokedIsBusy);
    }
  }

  /**
   * Informs this instance that at least one of the input values of the
   * component it is implementing has been updated in the current behavior
   * frame. Intended to be called from instances of the Cell class.
   */
  public markComponentInputUpdated(): void {
    this._componentInputUpdated = true;
  }

  /**
   * Invokes the system or custom component, i.e. creates it, initialises it
   * incl. triggering its first behavior frame.
   */
  private invokeComponent(): void {
    if (!this.cellType.type) return;

    if (this._invokedComponent) {
      throw new Error(
        "invokeComponent() must not be called when the component was already invoked"
      );
    }

    this._invokedComponent = SystemComponentFactory.Create(
      this.cellType.type,
      this,
      this.root.library
    );

    if (!this._invokedComponent) {
      this._invokedComponent = new CustomComponent(
        this.root.library,
        this,
        this.root.perfCounter
      );
    }

    if (isInitializableComponent(this._invokedComponent)) {
      this._invokedComponent.init(this.cellType.type);
    }

    // set default input values on the invoked component
    this._invokedComponent?.setInputValueGraph(this.getInputValueGraph());
  }

  public setBusy(busy: boolean): void {
    // ignore setting busy when component has already been removed
    if (!this._invokedComponent) return;

    if (busy !== this._invokedIsBusy) {
      this._invokedIsBusy = busy;
      // queue a next behavior frame on the root component as the busy change
      // might affect a cell
      if (this.cellType.hasAttribute("_busy")) this.root.queueBehaveAll();
      this.root.setBusy(this._invokedComponent, busy);
    }
  }

  /**
   * Returns the value graph of this instance and all descendant instances as a
   * one-dimensional array of objects with the simplified original path and
   * value of each instance.
   * @param topBottomItems The maximum number of items being returned from the
   *  top and the end of the collection. More than these are skipped
   *  in-between.
   * @returns An array of path/value pairs of this instance and all cells.
   */
  public getValueGraphAsArray(
    topBottomItems = 5
  ): { path: string; value: Value }[] {
    const result = [];
    if (!this.cellType.isRoot)
      result.push({
        path: this.getSimplifiedOriginalPath(),
        value: this.valueOf(),
      });

    this._cells.forEach((cell) => {
      // collections with more than `topBottomItems * 2 + 1` items (11 by
      // default) that are not constants return only the first and last
      // maxItems/2 rows
      const compress =
        cell.instances.length > topBottomItems * 2 + 1 &&
        cell.cellBehavior !== CellBehaviorType.CONSTANT;

      for (let i = 0; i < cell.instances.length; i++) {
        const inst = cell.instances[i];

        if (!compress || i !== topBottomItems) {
          result.push(...inst.getValueGraphAsArray(topBottomItems));
        } else {
          // skip rows in the middle and add a dummy row
          i = cell.instances.length - topBottomItems - 1;
          result.push({
            path: this.getSimplifiedOriginalPath(
              cell.cellType.originalName + ".[...]"
            ),
            value: null,
          });
        }
      }
    });

    return result;
  }

  /**
   * Returns the value graph of the cells of this instance and all descendants.
   * @returns A graph with the value of this instance and all cells.
   */
  public getValueGraph(): ValueGraph {
    const result: ValueGraph = {};
    if (!this.cellType.isRoot) result._value = this._value;
    this._cells.forEach((cell, cellType) => {
      result[cellType.originalName] = cell.getValueGraph();
    });

    return result;
  }

  /**
   * Sets the value of the cells of this instance and all descendants.
   * @param graph The value graph that is used to set the values.
   */
  public setValueGraph(graph: ValueGraph): void {
    Object.entries(graph).forEach(([key, value]) => {
      if (key.toLowerCase() === "_index") return;
      const cellType = this.cellType.getAttribute(key);
      if (cellType && value !== undefined) {
        const cell = this._cells.get(cellType);
        cell?.setValueGraph(value);
      }
    });
  }

  /**
   * Returns the value graph of the cells of this instance and all descendants
   * that are bound to inputs of the invoked component.
   * @returns A graph with the value of this instance and all cells that map to
   *    inputs of the component.
   */
  public getInputValueGraph(): ValueGraph {
    const result: ValueGraph = {};
    if (!this.cellType.isRoot) result._value = this._value;
    this._cells.forEach((cell, cellType) => {
      if (cellType.isConnectedToInput)
        result[cellType.originalName] = cell.getInputValueGraph();
    });

    return result;
  }

  /**
   * Sets the value of the cells of this instance and all descendants that are
   * inputs of the component this instance belongs to.
   * @param graph The value graph that is used to set the values.
   */
  public setInputValueGraph(graph: ValueGraph): void {
    //if (!(this.cellType.isInput || this.cellType.isRoot)) return;

    Object.entries(graph).forEach(([key, value]) => {
      const cellType = this.cellType.getAttribute(key);
      if (cellType && value !== undefined) {
        const cell = this._cells.get(cellType);
        cell?.setInputValueGraph(value);
      }
    });
  }

  /**
   * Returns the value graph of the cells of this instance and all descendants
   * that are outputs of the component this instance belongs to.
   * @returns A graph with the value of this instance and all cells that are
   *    outputs of the component.
   */
  public getOutputValueGraph(): ValueGraph {
    const result: ValueGraph = {};
    if (!this.cellType.isRoot) result._value = this._value;

    if (
      !this.cellType.isRoot &&
      this._invokedComponent &&
      isDOMComponent(this._invokedComponent) &&
      this._invokedComponent.DOMNode?.id
    )
      result._DOMNodeId = this._invokedComponent.DOMNode.id;

    this._cells.forEach((cell, cellType) => {
      if (cellType.isOutput)
        result[cellType.originalName] = cell.getOutputValueGraph();
    });

    return result;
  }

  /**
   * Sets the values of the cells of this instance and all descendants that are
   * bound to outputs of the invoked component.
   * @param graph The value graph that is used to set the values.
   */
  public setOutputValueGraph(graph: ValueGraph): void {
    if (graph._DOMNodeId) {
      this.DOMNodeId = graph._DOMNodeId.toString();
    } else {
      delete this.DOMNodeId;
    }

    this._cells.forEach((cell, cellType) => {
      if (!cellType.isConnectedToOutput) return;

      // explicitly setting the type of newValue as TypeScript does not
      // recoginse that `value` in the following forEach can become `undefined`,
      // which could cause issues within `setOutputValueGraph`. To be on the
      // safe side we have extended the types that `setOutputValueGraph` can
      // receive to `undefined` as well.
      let newValue: ValueGraphData | ValueGraphData[] = null;

      Object.entries(graph).forEach(([key, value]) => {
        if (cellType.name === key.toLowerCase()) {
          newValue = value ?? null;
          return;
        }
      });

      cell?.setOutputValueGraph(newValue);
    });
  }

  // TODO: this method should not be public (only accessible to the parent cell)
  public setValue(value: Value): void {
    // to avoid negative zeros as they do not exist in spreadsheets
    this._value = value === 0 ? 0 : value;
  }

  /** Returns the an attribbute (cell) with the requested name.
   * @param name The name of the attribute (cell).
   * @returns The cell with the requested name.
   */
  public getAttribute(name: string): Cell | undefined {
    const celltype = this.cellType.getAttribute(name);
    if (!celltype) return undefined;
    return this._cells.get(celltype);
  }

  /** Returns the value(s) of the instances within a cell with the requested
   * name.
   * @param name The name of the attribute (cell).
   * @returns The value of the attribute or an array of values if the cell is a
   *    collection.
   */
  public getAttributeValue(name: string): Value | Value[] {
    const attr = this.getAttribute(name);
    if (attr === undefined) {
      return null;
    } else if (!attr.cellType.isCollection) {
      return attr.instances[0] === undefined
        ? null
        : attr.instances[0].valueOf();
    } else {
      return attr.instances.map((inst) => {
        return inst.valueOf();
      });
    }
  }

  /**
   * Saves the value of the previous behavior of this instance so that delta
   * behavior functions can use this value.
   */
  public setPrevValue(): void {
    this._prevValue = this._value;
  }

  /**
   * Sets the previous values on all instances where the execution of their
   * cell's behavior has been skipped.
   */
  public setSkippedPrevValues(): void {
    this._cells.forEach((cell) => {
      cell.setSkippedPrevValues();
    });
  }

  public getParentOrSiblingOrThisInstancesByCellName(
    name: string,
    fromRoot: boolean,
    breadcrumb?: InstanceBreadcrumb
  ): Instance[] {
    breadcrumb?.set(this.cellType, this);

    if (fromRoot && this.parentCell && this.cellType.hasParentOrSibling(name)) {
      return this.parentCell.getParentOrSiblingOrThisInstancesByCellName(
        name,
        fromRoot,
        breadcrumb
      );
    }

    if (this.cellType.name === name.toLowerCase()) {
      // this is an instance of the cell we are looking for

      // in case we are coming from a child, just this parent and not the entire
      // instances array of the parent cell is returned
      return [this];
    }

    const sibling = this.getAttribute(name);
    // if there is a sibling to the cell with the name we are looking for,
    // return the instances of that cell. In case the instances are requested
    // directly from an instance, it also returns instances of a child cell of
    // this instance.
    if (sibling) return sibling.instances;

    if (!this.parentCell) throw new Error(`Unknown name '${name}'`);

    return this.parentCell.getParentOrSiblingOrThisInstancesByCellName(
      name,
      fromRoot,
      breadcrumb
    );
  }

  public getChildInstancesByCellName(
    name: string,
    breadcrumb?: InstanceBreadcrumb
  ): Instance[] {
    const celltype = this.cellType.getChildTowardsAttribute(name);
    if (!celltype) throw new Error(`Unknown name '${name}'`);

    const cell = this._cells.get(celltype);
    if (!cell) return []; // the cell has not been instantiated yet

    if (celltype.name === name.toLowerCase()) {
      const inst = breadcrumb?.get(celltype);
      // if there is an element of this celltype in the breadcrumb, only return
      // the instance in the breadcrumb, not the entire array
      return inst ? [inst] : cell.instances;
    }

    return cell.getChildInstancesByCellName(name, breadcrumb);
  }

  public getInstancesByCellName(name: string): Array<Instance> {
    // TODO: we should make this method case sensitive as for the behavior
    // execution all names are normalised to lowercase anyhow
    if (this.cellType.name == name.toLowerCase()) {
      // this is an instance of the cell we are looking for
      return [this];
    }

    const celltype = this.cellType.getChildTowardsAttribute(name);
    if (!celltype) {
      if (this.parentCell) {
        return this.parentCell.getInstancesByCellName(name);
      } else {
        throw new Error(`Unknown name '${name}'`);
      }
    }

    const cell = this._cells.get(celltype);
    if (!cell) return []; // the cell has not been instantiated yet

    return cell.getInstancesByCellName(name);
  }

  /**
   * Looks into the cell/instance graph following a path of cellType names and
   * returns the instance at the end of the path. Stops at an instance
   * collection or missing instance.
   * @param path Sequence of cell type names separated with dots (`.`). Must
   *    start with a child name of this instance.
   * @returns The instance at the end of the path. If on the way down there is a
   *    collection or an instance is not existing, it returns null.
   */
  public getInstanceByPath(path: string): Instance | null {
    const d = path.indexOf(".");

    if (d < 0) {
      // '.' not found, thus we have reached the last element in the path
      const celltype = this.cellType.getAttribute(path.trim());
      if (!celltype) return null;

      const cell = this._cells.get(celltype);
      return cell && cell.instances.length !== 0 && !cell.cellType.isCollection
        ? cell.instances[0]
        : null;
    }

    const remainder = path.substring(d + 1);
    const attributename = path.substring(0, d).trim();

    const celltype = this.cellType.getAttribute(attributename);
    if (!celltype) return null;

    const cell = this._cells.get(celltype);
    return cell && cell.instances.length !== 0 && !cell.cellType.isCollection
      ? cell.instances[0].getInstanceByPath(remainder)
      : null;
  }

  /**
   * If this instance invokes a visible component (a `DOMComponent`), it
   * attaches the component and all children to the DOM tree.
   */
  public attachDOM(parentDOMNode: Element): void {
    if (!this._invokedComponent || !isDOMComponent(this._invokedComponent))
      return;

    this._invokedComponent.attachDOM(parentDOMNode);

    this._cells.forEach((cell) => {
      for (const inst of cell.instances) {
        if (this.DOMNode) inst.attachDOM(this.DOMNode);
      }
    });
  }

  /**
   * Detaches an invoked DOM component and all its children from the DOM tree.
   */
  public detachDOM(): void {
    if (!this._invokedComponent || !isDOMComponent(this._invokedComponent))
      return;

    this._invokedComponent.detachDOM();

    this._cells.forEach((cell) => {
      for (const inst of cell.instances) {
        inst.detachDOM();
      }
    });
  }

  /**
   * Reorders the DOM nodes of the instances within cells of this instance
   * according to their order in the schema definition (thus the cellType order).
   *
   * Considers that some cells might never or currently not be shown in the view.
   */
  public fixOrderOfDOMNodes(): void {
    if (!this.parentCell) this.root.invokedBy?.fixOrderOfDOMNodes();

    if (!this.DOMNode) return;

    // first record the sequence in which the DOM nodes should be ordered
    const domnodes: Element[] = [];

    this._cells.forEach((cell) => {
      for (const inst of cell.instances) {
        if (inst.DOMNode && inst.DOMNode.parentElement) {
          domnodes.push(inst.DOMNode);
        }
      }
    });

    // finally check if the siblings of each node are correct and fix them if
    // neccessary reverse sequence is required as DOM nodes only offer
    // insertBefore.
    // it also supports the text nodes in view components being the first one
    // while not captured in here.
    for (let i = domnodes.length - 1; i >= 0; i--) {
      const node = domnodes[i];
      const followingnode = domnodes[i + 1];
      if (i < domnodes.length - 1) {
        if (node.nextSibling !== followingnode) {
          this.DOMNode.removeChild(node);
          this.DOMNode.insertBefore(node, followingnode);
        }
      } else {
        if (node.nextSibling !== null) {
          this.DOMNode.removeChild(node);
          this.DOMNode.appendChild(node);
        }
      }
    }
  }

  getAppContext(): AppContext | undefined {
    return this.root.invokedBy?.getAppContext();
  }
}
