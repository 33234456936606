import { AppContext } from "../classes/app-context";
import CellType from "../classes/cell-type";
import Instance from "../classes/instance";
import { OrgRoles } from "../model";
import { Schema, Value } from "../schema/schema-global";

/**
 * Type guard to check whether a value is of type `Value` (i.e. a primitive,
 * `null`, or `Date`).
 * @param value The value to check.
 * @returns `true` if `value` is of type `Value`
 */
export function isValue(value: unknown): value is Value {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    value instanceof Date ||
    value === null
  );
}

/**
 * Type guard to check whether a value is of type `Schema`.
 * @param value The value to check.
 * @returns `true` if `value` is of type `Schema`
 */
export function isSchema(value: unknown): value is Schema {
  return (
    value !== null &&
    typeof value === "object" &&
    !(value instanceof Date) &&
    !(value instanceof Instance) &&
    !Array.isArray(value)
  );
}

export type InstanceBreadcrumb = Map<CellType, Instance>;

/**
 * The interface used by behavior tokens to request an instance array by a cell
 * name.
 */
export interface CellNameWalker {
  /**
   * Looks up parent and sibling instances by their cellType name starting from
   * this, then parent before its direct children (no grandchildren), then
   * grandparent and its direct children (and so on).
   *
   * When evaluated on an instance, it also returns an attribute of the instance
   * with the given name.
   * @param name The cellType name.
   * @param fromRoot Skips the current cell if there is a parent or sibling of a
   *    parent with `name`.
   * @param breadcrumb When a CellType/Instance map is provided, each instance
   *    which was traversed when walking up the instance tree to find `name`
   *    together with its cellType is added.
   * @returns An array of instances or an empty array if the cells being found
   *    do not have any instances.
   * @throws An error when `name` cannot be found.
   */
  getParentOrSiblingOrThisInstancesByCellName(
    name: string,
    fromRoot: boolean,
    breadcrumb?: InstanceBreadcrumb
  ): Instance[];

  /**
   * Looks up child instances by their cellType name starting from its children
   * before grandchildren (and so on). Never returns instances of itself (this).
   * @param name The cellType name.
   * @param breadcrumb If a CellType/Instance map is provided, the method on its
   *    way down the instance tree uses the mapped Instance of a given CellType
   *    of a cell instead of the entire Instance array.
   * @returns An array of instances or an empty array if the cells being found
   *    do not have any instances.
   * @throws An error when `name` cannot be found.
   */
  getChildInstancesByCellName(
    name: string,
    breadcrumb?: InstanceBreadcrumb
  ): Instance[];

  /**
   * The legacy way to look up instances by their cellType name. Looks up
   * instances starting from this, then children before grandchildren (and so
   * on) before parent before children and grandchildren of parent before
   * grandparent before children and grandchildren of grandparent. Thus looking
   * for the most proximate cell with `name`.
   * @param name The cellType name.
   * @returns An array of instances or an empty array if the cells being found
   *    do not have any instances.
   * @throws An error when `name` cannot be found.
   */
  getInstancesByCellName(name: string): Instance[];
  cellType: CellType;
}

/**
 * The interface of each value in value graphs that are exchanged via inputs
 * and outputs of components.
 */
export type ValueGraphData = Value | Instance | ValueGraph;

/**
 * The interface of the entire value graph that is exchanged via inputs
 * and outputs of components.
 */
export interface ValueGraph {
  _value?: Value | Instance;
  [key: string]: undefined | ValueGraphData | ValueGraphData[];
}

/**
 * The interface for any object that invokes a component and wants to receive
 * updates from the outputs of the component.
 */
export interface ComponentInvoker {
  /**
   * Sets the values on the component invoker that are bound to the outputs of
   * the invoked component. Values in the invoker that are not included in the
   * value graph are set to `null`.
   * @param graph A JavaScript object with the output values. If the name of an
   *    output is not existing on the component invoker, the value is ignored.
   */
  setOutputValueGraph(graph: ValueGraph): void;

  /**
   * Reports the busy state of the invoked conponent to the component invoker.
   * Should instantly be called with `true` by the invoked component as soon as
   * it is aware of data to be processed. Should be called with `false` as soon
   * as the processing has finished.
   * @param busy Set to `true` when the component is busy, `false` if it is not.
   */
  setBusy(busy: boolean): void;

  /** reorders visual elements in the DOM according to their order in the memory
   * model. */
  fixOrderOfDOMNodes(): void;

  /**
   * Returns the observable application context.
   */
  getAppContext(): AppContext | undefined;
}

/**
 * Component constructor interface used for dynamic instantiation.
 *
 * @see https://stackoverflow.com/a/43578377
 */
export interface ComponentConstructor {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (invokedBy: ComponentInvoker, tagName?: string): Component;
}

/**
 * The base interface for all Components (system components as well as custom
 * components).
 */
export interface Component {
  /**
   * Destroys the the component by removing itself and all references from
   * memory without deleting its data from storage. Always call this method when
   * the component is no longer in use, as otherwise memory and the behavior
   * loop will not be reset.
   */
  destroy(): void;

  /**
   * Sets all input values included in the value graph and skips all other
   * inputs.
   * @param graph A JavaScript object with the input values. If the name of an
   *    input is not existing on the component, the value is ignored.
   */
  setInputValueGraph(graph: ValueGraph): void;

  /**
   * Return the current output values of the component.
   * @returns A JavaScript object with the output values.
   */
  getOutputValueGraph(): ValueGraph;
}

/**
 * The base interface for all visual Components that bind to the WebAPI (DOM).
 */
export interface DOMComponent extends Component {
  /** The root DOM node of this component. */
  readonly DOMNode: Element | null;

  /**
   * Attaches (mounts) the component to the parent DOM element, usually the
   * invoked DOMComponent of the parent object of the component invoker. Latest
   * in this step the DOM element needs to be created.
   *
   * @param parentDOMNode The parent's DOM node. Must not be null.
   * */
  attachDOM(parentDOMNode: Element): void;

  /** Detaches (unmounts) the component from the DOM element of the parent
   * component. The component may delete the DOM element (and return `null` in
   * the `DOMNode` property then). */
  detachDOM(): void;
}

/** The type guard to identify whether a component is a DOM component. */
export function isDOMComponent(
  component: Component
): component is DOMComponent {
  return (
    "DOMNode" in component &&
    "attachDOM" in component &&
    "detachDOM" in component
  );
}

/**
 * Interface for components with an `init` method.
 */
export interface InitializableComponent extends Component {
  init: (cellType: string) => void;
}

/**
 * Type guard to identify whether a component is initializable.
 */
export function isInitializableComponent(
  component: Component
): component is InitializableComponent {
  return (component as InitializableComponent).init !== undefined;
}

/**
 * The frame observer interface is an additional interface on component
 * invokers by which they will be informed about a behavior frame being finished
 */
export interface FrameObserver {
  behaviorFrameFinished(): void;
}

/** The type guard to identify whether an object implements the frame observer
 * interface. */
export function isFrameObserver(
  component: unknown
): component is FrameObserver {
  return (
    component !== null &&
    typeof component === "object" &&
    "behaviorFrameFinished" in component
  );
}

/** The interface any performance counter should implement. */
export interface PerfCounter {
  /** Called when an expression has been evaluated */
  expressionEvaluated(): void;

  /** Called when a behavior frame was finished */
  behaviorFrameFinished(component?: string, graph?: ValueGraph): void;

  /**
   * Called when a value has been set.
   * @param path The path of the value.
   * @param value The (scalar) value.
   * @param before The previous (scalar) value.
   */
  valueSet(
    path: string,
    value: Value | Value[],
    before: Value | Value[],
    after: Value | Value[]
  ): void;

  /**
   * If set to a non empty string, values shall be traced where their path
   * matches the search pattern of this string, thus valueSet should be called.
   * The character '*' can be used as a wildcard, otherwise use only letters,
   * numbers, '_', and '.' for the search pattern.
   */
  trace: string;

  /** If set then the component's state at the end of frame should be sent to
   * behaviorFrameFinished */
  traceFrames: boolean;

  /** Tests whether `path` matches the pattern set in `trace`*/
  traceThis(path: string): boolean;
}

/** Represents a user in a Seed application. */
export interface User {
  id: string;
  username: string | null;
  /**
   * User roles independent of any organizations.
   */
  roles: string[];
  /**
   * User roles within organizations.
   */
  orgRoles: OrgRoles;
  authToken: string;
}

export interface SchemaPublisher {
  /**
   * Publishes a schema file by uploading it to the default Cloud
   * Storage bucket of the target Firebase project.
   *
   * @param appName the Seed application name
   * @param schema the schema contents as a YAML string
   * @param makePublic whether to make the published schema publicly
   *   accessible
   * @returns the Cloud Storage URL of the published schema file
   */
  publish(
    appName: string,
    schema: string,
    makePublic: boolean
  ): Promise<string>;
}

/**
 * Represents unsubscribable objects such as observable subscriptions.
 */
export interface Unsubscribable {
  unsubscribe(): void;
}

/**
 * An observer is provided to an observable's `subscribe` method and
 * receives observable updates.
 */
export interface Observer<T> {
  next: (value: T) => void;
  error: (err: unknown) => void;
  complete: () => void;
}
