import Decimal from "decimal.js";
import jsep from "jsep";

import Cell from "../classes/cell";
import Instance from "../classes/instance";
import {
  CellNameWalker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Value } from "../schema/schema-global";
import { BehaviorMode, BehaviorNode, getArgumentNodes } from "./behavior";
import {
  makeArray,
  toNumber,
  compareValues,
  toBoolean,
  toValue,
  addValues,
} from "./behavior-commons";

export function createArrayFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "at":
      return new atFunctionBehaviorNode(token, mode);
    case "first":
      return new firstFunctionBehaviorNode(token, mode);
    case "last":
      return new lastFunctionBehaviorNode(token, mode);
    case "find":
      return new findFunctionBehaviorNode(token, mode);
    case "findindex":
      return new findIndexFunctionBehaviorNode(token, mode);
    case "includes":
      return new includesFunctionBehaviorNode(token, mode);
    case "lookup":
      return new lookupFunctionBehaviorNode(token, mode);
    case "lookupcondition":
      return new lookupConditionFunctionBehaviorNode(token, mode);
    case "fill":
      return new fillFunctionBehaviorNode(token, mode);
    case "filter":
      return new filterFunctionBehaviorNode(token, mode);
    case "exclude":
      return new excludeFunctionBehaviorNode(token, mode);
    case "distinct":
      return new distinctFunctionBehaviorNode(token, mode);
    case "sort":
      return new sortFunctionBehaviorNode(token, mode);
    case "reverse":
      return new reverseFunctionBehaviorNode(token, mode);
    case "slice":
      return new sliceFunctionBehaviorNode(token, mode);
    case "count":
      return new countFunctionBehaviorNode(token, mode);
    case "countif":
      return new countIfFunctionBehaviorNode(token, mode);
    case "min":
      return new minFunctionBehaviorNode(token, mode);
    case "max":
      return new maxFunctionBehaviorNode(token, mode);
    case "sum":
      return new sumFunctionBehaviorNode(token, mode);
    case "sumif":
      return new sumIfFunctionBehaviorNode(token, mode);
    case "join":
      return new joinFunctionBehaviorNode(token, mode);
    case "map":
      return new mapFunctionBehaviorNode(token, mode);
  }
}

/**
 * Returns the array item at the given index or `null` if out of bounds. Accepts
 * negative integers, which count back from the last item.
 * @name at() function
 * @param array The array from which to pick the item.
 * @param index The position of the element in the array starting at position 0.
 *    If index is negative, the index is counted from the back where -1 is the
 *    last item. If index is `null` or `NaN`, `null` is returned.
 * @returns The item at the given index or null if out of bounds.
 */
class atFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));
    let index = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (index === null) return null;

    index = toNumber(index);

    if (index >= 0 && index < array.length) {
      return array[index] ?? null;
    } else if (index < 0 && -index <= array.length) {
      return array[array.length + index] ?? null;
    } else {
      return null;
    }
  }
}

/**
 * Returns the first array item or null if the array is empty.
 * @name first() function
 * @param array The array from which to pick the item.
 * @returns The first item of the array.
 */
class firstFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    if (array.length > 0) {
      return array[0];
    } else {
      return null;
    }
  }
}

/**
 * Returns the last array item or null if the array is empty.
 * @name last() function
 * @param array The array from which to pick the item.
 * @returns The last item of the array.
 */
class lastFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    if (array.length > 0) {
      return array[array.length - 1];
    } else {
      return null;
    }
  }
}

/**
 * Returns the first item in the array for which the condition is truthy.
 * @name find() function
 * @param array The array in which to look up the item.
 * @param condition The condition against which to test.
 * @returns The found item or null if none was found.
 */
class findFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    for (const item of array) {
      const value = this.arguments[1].evaluate(
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (toBoolean(value)) return item;
    }

    return null;
  }
}

// TODO: document the class rather than the Seed function being implemented using jsDoc
/**
 * Returns the index of the the first item in the array for which the condition
 * is truthy.
 * @name findIndex() function
 * @param array The array in which to look up the item.
 * @param condition The condition against which to test.
 * @returns The index of the found item or null if none was found.
 */
class findIndexFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number | null {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const value = this.arguments[1].evaluate(
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (toBoolean(value)) return i;
    }

    return null;
  }
}

/**
 * Returns true if the array includes a given value.
 * @name includes() function
 * @param array The array in which to look for `value`.
 * @param value The value the array is supposed to include.
 * @returns `true` if `array` contains `value`, otherwise false.
 */
class includesFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));
    const value = toValue(this.arguments[1].evaluate(scope, calledFrom));

    for (const item of array) {
      if (compareValues(item, value) === 0) return true;
    }

    return false;
  }
}

/**
 * Returns the value in an array at the position where in another array it
 * has found the first index of an element where a certain condition is truthy.
 * @name lookupCondition() function
 * @param resultArray The array from which to take the result.
 * @param searchArray The array in which to find the element that meets `condition`.
 * @param condition The condition each element in `searchArray` is tested against.
 * @returns The value in `resultArray` at the positiom where `condition` is true in `searchArray`.
 */
class lookupConditionFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const resultArray = makeArray(
      this.arguments[0].evaluate(scope, calledFrom)
    );

    const searchArray = makeArray(
      this.arguments[1].evaluate(scope, calledFrom)
    );

    let index = -1;

    for (let i = 0; i < searchArray.length; i++) {
      const item = searchArray[i];
      const value = this.arguments[2].evaluate(
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (toBoolean(value)) {
        index = i;
        break;
      }
    }

    if (index >= 0 && index < resultArray.length) {
      return resultArray[index];
    } else {
      return null;
    }
  }
}

/**
 * Returns the value in an array at the position where a search value has been
 * looked up in another array.
 * @name lookup() function
 * @param value The value to be found in `searchArray`
 * @param searchArray The array in which to search `value`.
 * @param resultArray The array from which to take the result.
 * @param isSorted (optional) If true (the default), the nearest match (less
 *    than or equal to the search key) is returned. If all values in
 *    `searchArray` column are greater than the search key, `null` is returned.
 *    Otherwise an exact match is returned.
 * @returns The value in `resultArray` at the positiom where `value` is found in
 *    `searchArray` or `null` if not found.
 */
class lookupFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 3, 4);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const value = toValue(this.arguments[0].evaluate(scope, calledFrom));

    const searchArray = makeArray(
      this.arguments[1].evaluate(scope, calledFrom)
    );

    const resultArray = makeArray(
      this.arguments[2].evaluate(scope, calledFrom)
    );

    const isSorted =
      this.arguments.length < 4 ||
      toBoolean(this.arguments[3].evaluate(scope, calledFrom));

    let index = -1;

    // a result can only be found if the second argument results in an instance
    for (let i = 0; i < searchArray.length; i++) {
      const compare = compareValues(toValue(searchArray[i]), value);
      if (compare == 0) {
        index = i;
        break;
      } else if (isSorted && compare !== undefined && compare < 0) {
        index = i;
      } else if (isSorted && compare !== undefined && compare > 0) {
        break;
      }
    }

    if (index >= 0 && index < resultArray.length) {
      return resultArray[index];
    } else {
      return null;
    }
  }
}

/**
 * Returns an array filled with a static value or a sequence of values.
 * @name fill() function
 * @param count The number of times value is added to the array.
 * @param value The value that the array is getting filled with.
 * @param increment (optional) Is added to `value` for each element by the operation `previousElement + increment`.
 * @returns The array filled `count` times with `value` incremented by `increment`.
 */
class fillFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const count = toNumber(this.arguments[0].evaluate(scope, calledFrom));
    let value = this.arguments[1].evaluate(scope, calledFrom);
    const increment =
      this.arguments.length > 2
        ? toValue(this.arguments[2].evaluate(scope, calledFrom))
        : null;

    const result: ValueGraphData[] = [];

    for (let i = 0; i < count; i++) {
      if (Array.isArray(value)) {
        for (let j = 0; j < value.length; j++) {
          result.push(value[j]);
          if (increment) value[j] = addValues(toValue(value[j]), increment);
        }
      } else {
        result.push(value);
        if (increment) value = addValues(toValue(value), increment);
      }
    }

    return result;
  }
}

/**
 * Returns an array containing all items of an array
 * for which the condition is truthy.
 * @name filter() function
 * @param array The array from which to filter the items.
 * @param condition The condition against which to test.
 * @returns The resulting array.
 */
class filterFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    const result: ValueGraphData[] = [];
    for (const item of array) {
      const value = this.arguments[1].evaluate(
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (toBoolean(value)) result.push(item);
    }

    return result;
  }
}

/**
 * Returns items from one array excluding items from a second array or value.
 * @name exclude() function
 * @param array The array from which to exclude items.
 * @param exclude The array of items or a single value to exclude.
 * @returns The resulting array.
 */
class excludeFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const source = makeArray(this.arguments[0].evaluate(scope, calledFrom));
    const exclude = makeArray(this.arguments[1].evaluate(scope, calledFrom));

    const result: ValueGraphData[] = [];
    for (const item of source) {
      let found = false;

      for (let i = 0; i < exclude.length; i++) {
        if (compareValues([exclude[i]], [item]) == 0) {
          found = true;
          exclude.splice(i, 1);
          break;
        }
      }

      if (!found) {
        result.push(item);
      }
    }

    return result;
  }
}

/**
 * Returns each array element exactly once in the order of their first presence.
 * @name distinct() function
 * @param array The array from which to return items.
 * @param distinct (optional) The value in the scope of `array` which shall be
 *    distinct.
 * @returns The resulting array.
 */
class distinctFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    const result: ValueGraphData[] = [];
    const added: Value[] = [];
    for (const item of array) {
      let value: Value;
      if (this.arguments.length < 2) {
        value = toValue(item);
      } else {
        value = toValue(
          this.arguments[1].evaluate(
            item instanceof Instance ? [item] : undefined,
            calledFrom
          )
        );
      }

      if (added.findIndex((item) => compareValues(item, value) == 0) < 0) {
        result.push(item);
        added.push(value);
      }
    }

    return result;
  }
}

/**
 * Sorts the items of an array in ascending or descending order.
 * @name sort() function
 * @param array The array to be sorted.
 * @param criteria (optional) The value in the scope of the array item by which
 * to sort. If not defined the array is sorted by itself.
 * @param direction (optional) Sorting direction as 'asc' or 'desc' where asc
 * orders ascending and desc orders descending, default is ascending.
 * @returns The resulting array.
 */
class sortFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 3);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    const temp = [];
    for (const item of array) {
      let value;
      if (this.arguments.length < 2) {
        value = toValue(item);
      } else {
        value = toValue(
          this.arguments[1].evaluate(
            item instanceof Instance ? [item] : undefined,
            calledFrom
          )
        );
      }
      temp.push({
        source: item,
        criteria: value,
      });
    }

    const ascending =
      this.arguments.length < 3 ||
      toValue(this.arguments[2].evaluate(scope, calledFrom))
        ?.toString()
        .toLowerCase() !== "desc";

    if (ascending) {
      temp.sort((a, b) => compareValues(a.criteria, b.criteria) ?? 0);
    } else {
      temp.sort((a, b) => compareValues(b.criteria, a.criteria) ?? 0);
    }

    return temp.map((item) => item.source);
  }
}

/**
 * Reverses the order of the items in an array.
 * @name reverse() function
 * @param array The array to be reversed.
 * @returns The reversed array.
 */
class reverseFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    const result = array.slice();
    return result.reverse();
  }
}

/**
 * Extracts a section of an array.
 * @name slice() function
 * @param array The array from which to extract the items.
 * @param start Zero-based index at which to start extraction.
 * @param end (optional) Zero-based index before which to end extraction.
 *    `slice` extracts up to but not including `end`. If `end` is omitted, all
 *    items from `start` up to the end of the `array` are returned.
 * @returns The resulting array.
 */
class sliceFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 3);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));
    const start = toNumber(this.arguments[1].evaluate(scope, calledFrom));
    const end =
      this.arguments.length < 3
        ? array.length
        : toNumber(this.arguments[2].evaluate(scope, calledFrom));

    return array.slice(start, end);
  }
}

/**
 * Returns the number of items in an array.
 * @name count() function
 * @param array The array for which to retrieve the number of items.
 * @returns The number of items in the array.
 */
class countFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    const array = this.arguments[0].evaluate(scope, calledFrom);
    // TODO: not sure if returning a 0 length for a null is correct
    // e.g. count(reverse(null)) gives a different result than count(null)
    return Array.isArray(array) ? array.length : array === null ? 0 : 1;
  }
}

/**
 * Returns the number of items in an array for which the condition is truthy.
 * @name countIf() function
 * @param array The array for which to retrieve the number of items.
 * @param condition The condition against which to test.
 * @returns The number of items where the condition is truthy.
 */
class countIfFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    let result = 0;
    for (const item of array) {
      const condition = this.arguments[1].evaluate(
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (toBoolean(condition)) result++;
    }

    return result;
  }
}

/**
 * Returns the element with the lowest numerical value of all elements in the
 * array(s) or value(s).
 * @name min() function
 * @param ...array|value The array(s) or value(s) to look into their numbers.
 * @returns The element with the lowest numerical value or null if no element
 *    with a numerical value was included.
 */
class minFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    let result = null;
    let compare = null;
    for (const argument of this.arguments) {
      const array = makeArray(argument.evaluate(scope, calledFrom));
      for (const element of array) {
        if (element === null) continue;
        const value = toNumber(element);
        if (!isNaN(value) && (compare === null || value < compare)) {
          result = element;
          compare = toNumber(element);
        }
      }
    }
    return result;
  }
}

/**
 * Returns the element with the highest numerical value of all elements in the
 * array(s) or value(s).
 * @name max() function
 * @param ...array|value The array(s) or value(s) to look into their numbers.
 * @returns The element with the highest numerical value or null if no element
 *    with a numerical value was included.
 */
class maxFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    let result = null;
    let compare = null;
    for (const argument of this.arguments) {
      const array = makeArray(argument.evaluate(scope, calledFrom));
      for (const element of array) {
        if (element === null) continue;
        const value = toNumber(element);
        if (!isNaN(value) && (compare === null || value > compare)) {
          result = element;
          compare = toNumber(element);
        }
      }
    }
    return result;
  }
}

/**
 * Returns the numerical total of all elements in the array(s) or value(s) by
 * summing up their values. Returns NaN if one of the values can not be
 * converted to a number.
 * @name sum() function
 * @param ...array|value The array(s) or value(s) to sum up.
 * @returns The sum of all numerical elements or NaN if some value is not a
 *    number.
 */
class sumFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    let result = new Decimal(0);
    for (const argument of this.arguments) {
      const array = makeArray(argument.evaluate(scope, calledFrom));
      for (const element of array) {
        result = Decimal.add(result, toNumber(element));
      }
    }
    return Number(result);
  }
}

/**
 * Returns the numerical total of all items of an array
 * for which the condition is truthy.
 * @name sumIf() function
 * @param array The array to sum up.
 * @param condition The condition against which to test.
 * @returns The sum of all numerical elements or NaN if some value is not a
 *    number.
 */
class sumIfFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    let result = new Decimal(0);
    for (const item of array) {
      const condition = this.arguments[1].evaluate(
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (toBoolean(condition)) result = Decimal.add(result, toNumber(item));
    }
    return Number(result);
  }
}

/**
 * Combines an array of values into a string separated by a specified separator
 * string. If a value is empty (empty string or null), no separator is added by
 * default. If `considerEmpty` is set to `true` a separator is added also for
 * empty items. If only one element is combined, then that item will be returned
 * without using the separator.
 * @name join() function
 * @param array The array to be combined.
 * @param separator (optional) The separator inserted between the strings. The
 *    default is the comma `,`.
 * @param considerEmpty (optional) When set to `true` a separator is added for
 *    empty items. `false` by default.
 * @returns The combined string
 */
class joinFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const separator =
      this.arguments.length > 1
        ? toValue(this.arguments[1].evaluate(scope, calledFrom))?.toString() ??
          ""
        : ",";
    const considerEmpty =
      this.arguments.length > 2
        ? Boolean(toValue(this.arguments[2].evaluate(scope, calledFrom)))
        : false;

    let result = "";

    const param = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    for (const item of param) {
      const value = toValue(item);
      const str = value?.toString() ?? "";
      result +=
        (result.length && (str.length || considerEmpty) ? separator : "") + str;
    }

    return result;
  }
}

/**
 * Creates a new array by looping through the elements of the first argument and
 * evaluating the second argument in the context of each element. If the second argument results in an array, the elements of this array are
 * @name map() function
 * @param array The array to loop through.
 * @param item The resulting item that is added to the new array.
 * @returns The resulting array.
 */
class mapFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    const result: ValueGraphData[] = [];
    for (const item of array) {
      const value = this.arguments[1].evaluate(
        // TODO: IMPORTANT! if item is not an instance, an exception can be
        // thrown during runtime and the behavior execution gets out of sync!
        item instanceof Instance ? [item] : undefined,
        calledFrom
      );
      if (Array.isArray(value)) {
        Array.prototype.push.apply(result, value);
      } else {
        result.push(value);
      }
    }

    return result;
  }
}
