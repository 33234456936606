import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

// TODO: implement a separate video component with the additional properties
// that the HTML video element is implementing.

/**
 * Represents a video or audio element in the DOM tree.
 */
export default class UIMediaComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLMediaElement;

  constructor(invokedBy: ComponentInvoker, tagName = "video") {
    super(invokedBy);

    if (!["audio", "video"].includes(tagName))
      throw new Error(`Invalid tagName '${tagName}' for media component.`);

    this._DOMNode = document.createElement(tagName) as HTMLMediaElement;
  }

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.src = { _input: null };
    schema.autoplay = { _input: null };
    schema.controls = { _input: null };
    schema.loop = { _input: null };
    schema.muted = { _input: null };
    schema.volume = { _input: null };
    schema.load = { _input: null };
    schema.pause = { _input: null };
    schema.play = { _input: null };

    return schema;
  }

  get DOMNode(): HTMLMediaElement {
    return this._DOMNode;
  }

  setInputValueGraph(graph: ValueGraph): void {
    super.setInputValueGraph(graph);

    // the play/pause actions are executed at the end making sure hidden, src,
    // etc. is set before
    Object.entries(graph).forEach(([key, value]) => {
      if (value === undefined) return;
      switch (key.toLowerCase()) {
        // TODO: the commands should react to changes rather than to the absolute
        // value. Otherwise in case e.g. `volume` is set and play is still true it
        // would trigger the play command although not intended.
        case "load":
          if (value) this._DOMNode.load();
          break;
        case "pause":
          if (value) this._DOMNode.pause();
          break;
        case "play":
          // TODO: IMPORTANT! does not work!
          if (value) void this._DOMNode.play();
          break;
      }
    });
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "src":
        // potentially make use of this.DOMNode.src property
        this.setOrRemoveAttribute("src", value);
        break;
      case "autoplay":
        // TODO: IMPORTANT! does not work!
        if (value !== null) {
          this._DOMNode.autoplay = Boolean(value);
        } else {
          this._DOMNode.removeAttribute("autoplay");
        }
        break;
      case "controls":
        if (value !== null) {
          this._DOMNode.controls = Boolean(value);
        } else {
          this._DOMNode.removeAttribute("controls");
        }
        break;
      case "loop":
        if (value !== null) {
          this._DOMNode.loop = Boolean(value);
        } else {
          this._DOMNode.removeAttribute("loop");
        }
        break;
      case "muted":
        if (value !== null) this._DOMNode.muted = Boolean(value);
        break;
      case "volume":
        // set the volume only if a value has been given
        if (value !== null) this._DOMNode.volume = Number(value);
        break;
    }
  }
}
