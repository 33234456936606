import { backOff } from "exponential-backoff";
import firebase from "firebase/app";
import "firebase/functions";

import queueFrame from "@shared/src/classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  Unsubscribable,
  ValueGraph,
} from "@shared/src/interfaces/global-interfaces";
import { Schema } from "@shared/src/schema/schema-global";

import { getDefaultBackoffOptions, translateErrorCode } from "../callable";

enum Status {
  IDLE = "request-idle",
  PENDING = "request-pending",
  SUCCEEDED = "request-succeeded",
  FAILED = "request-failed",
}

/**
 * The callable component allows caller to invoke a Firebase HTTPS
 * callable function on the backend.
 */
export default class CallableComponent implements Component {
  private _invokedBy: ComponentInvoker;
  private _function: string | null = null;
  private _request: ValueGraph | null = null;
  private _response: ValueGraph | null = null;
  private _triggered = false;

  /** The callable component request status */
  private _status: Status = Status.IDLE;
  /** The error message if an error occured during execution */
  private _error: string | null = null;

  private _statusCode: number | null = null;
  private _statusText: string | null = null;

  private _contextSubscription?: Unsubscribable;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   function: { _input: null },
   *   request: {
   *     _input: null,
   *     _iscollection: true,
   *     property: {
   *       _input: null,
   *     },
   *   },
   *   trigger: { _input: null },
   *   // Outputs
   *   response: {
   *     _input: null,
   *     _iscollection: true,
   *     property: {
   *       _input: null,
   *     },
   *   },
   *   status: { _output: null },
   *   statusCode: { _output: null },
   *   statusText: { _output: null },
   *   error: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  destroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  private execute(): void {
    if (this._function === null) {
      this._response = null;
      this._error = `Callable function failed: function name must be set when triggered`;
      this._status = Status.FAILED;
      [this._statusCode, this._statusText] =
        translateErrorCode("invalid-argument");
      this.pushOutputs();
      this._invokedBy.setBusy(false);
      return;
    }
    this._error = null;
    this._status = Status.PENDING;
    this._statusCode = null;
    this._statusText = null;
    this.pushOutputs();

    const runFn = firebase.functions().httpsCallable(this._function);
    const backoffOptions = getDefaultBackoffOptions();
    backOff(() => runFn(this._request), backoffOptions)
      .then((result) => {
        this._response = result.data as ValueGraph;
        this._status = Status.SUCCEEDED;
        this._statusCode = 200;
        this._statusText = "OK";
        this.pushOutputs();
        this._invokedBy.setBusy(false);
      })
      .catch((error: firebase.functions.HttpsError) => {
        this._response = null;
        this._error = "Callable function failed: " + error.message;
        this._status = Status.FAILED;
        [this._statusCode, this._statusText] = translateErrorCode(error.code);
        this.pushOutputs();
        this._invokedBy.setBusy(false);
      });
  }

  setInputValueGraph(graph: ValueGraph): void {
    let trigger = false;
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "function":
          this._function = value?.toString() ?? null;
          break;
        case "request":
          this._request = value as unknown as ValueGraph;
          break;
        case "trigger":
          trigger = Boolean(value);
          break;
      }
    });
    // TODO: Consider aborting request if inputs are updated while
    // status is pending. Or implement input queue...
    if (this._status === Status.PENDING) {
      // Log error just in case execution is triggered while pending.
      if (trigger && !this._triggered)
        console.error(
          "Callable component trigger ignored while component is pending"
        );

      this._triggered = trigger;
      return;
    }

    this._triggered = trigger;
    if (trigger) {
      this._invokedBy.setBusy(true);
      queueFrame(() => this.execute());
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      response: this._response,
      status: this._status,
      statusCode: this._statusCode,
      statusText: this._statusText,
      error: this._error,
    };
  }

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("request.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    if (name.startsWith("response.")) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["function", "request", "trigger"]);
/** Names of component outputs. */
const outputs = new Set([
  "response",
  "status",
  "statuscode",
  "statustext",
  "error",
]);
