import * as qbo from "../api/quickbooks";
import queueFrame from "../classes/frame-queue";
import {
  Component,
  ComponentInvoker,
  ValueGraph,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";

enum Status {
  PENDING = "pending",
  FAILED = "failed",
  SUCCEEDED = "succeeded",
  STALE = "stale",
}

enum ErrorCode {
  BAD_DATA_TYPE = "hyperseed/bad-data-type",
  REALM_ID_UNSET = "hyperseed/realm-id-unset",
  RESOURCE_UNSET = "hyperseed/resource-unset",
  DATA_UNSET = "hyperseed/data-unset",
  UNKNOWN_RESOURCE = "hyperseed/unknown-resource",
}

/**
 * Represents a QuickBooks write error.
 */
class QuickBooksWriteError extends Error {
  code: ErrorCode;
  constructor(message: string, code: ErrorCode) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

type QBOResource = "invoice" | "customer" | "item";

/**
 * Abstract base class of the QuickBooks write component that allows
 * caller to write resources via the QuickBooks Online API.
 */
export abstract class QuickBooksWriteBase implements Component {
  private _invokedBy: ComponentInvoker;

  /** ID of the relevant Hyperseed organization */
  private _orgId?: string;
  /** Realm ID identifying QuickBooks data owner */
  private _realmId?: string;
  /**
   * Identifies the QuickBooks resource to write.
   */
  private _resource?: QBOResource;
  /** The data to write */
  private _data: unknown = null;
  /** Flag for caller to request write synchronization */
  private _write = false;
  /** Flag to check write synchronization state transition */
  private _prevWrite = false;

  /**
   * The IDs of the resources written.
   */
  private _writtenIds?: string[];
  /** The number of resources written to QuickBooks */
  private _resourcesWritten = 0;
  /** The status after a QuickBooks operation was triggered */
  private _status: Status | null = null;
  /** A description of the error if status is FAILED */
  private _error: string | null = null;
  /** A code identifying the error if status is FAILED */
  private _errorCode: ErrorCode | string | null = null;
  /**
   * Pulses true for a single frame after write operation completes
   * (whether it succeeded or failed)
   */
  private _finished = false;

  constructor(invokedBy: ComponentInvoker) {
    this._invokedBy = invokedBy;
  }

  /**
   * Component implements {@link isInput} and {@link isOutput}.
   *
   * Sample schema:
   * ```
   * {
   *   // Inputs
   *   orgId: { _input: null },
   *   realmId: { _input: null },
   *   resource: { _input: null },
   *   data: {
   *     _input: null,
   *     _iscollection: true,
   *   },
   *   write: { _input: null },
   *   // Outputs
   *   finished: { _output: null },
   *   resourcesWritten: { _output: null },
   *   status: { _output: null },
   *   error: { _output: null },
   *   errorCode: { _output: null },
   *   writtenIds: { _output: null },
   * }
   * ```
   */
  static getSchema(): Schema {
    return {};
  }

  destroy(): void {
    // nothing to destroy
  }

  private reset() {
    this._finished = false;
    this.pushOutputs();
  }

  setInputValueGraph(graph: ValueGraph): void {
    if (this._status === Status.PENDING) {
      Object.entries(graph).forEach(([key, value]) => {
        if (key.toLowerCase() == "write") this._write = Boolean(value);
      });
      if (this._write !== this._prevWrite) {
        // Keep the previous state in sync.
        this._prevWrite = this._write;
        // Log the error just in case another write operation is
        // triggered.
        if (this._write) {
          console.error(
            "QuickBooks write operation ignored while component is pending"
          );
        }
      }
      return;
    }
    Object.entries(graph).forEach(([key, value]) => {
      switch (key.toLowerCase()) {
        case "orgid":
          this._orgId = value?.toString();
          break;
        case "realmid":
          this._realmId = value?.toString();
          break;
        case "resource":
          this._resource = value?.toString() as QBOResource;
          break;
        case "data":
          this._data = value as unknown;
          break;
        case "write":
          this._write = Boolean(value);
          break;
      }
    });
    if (this._write !== this._prevWrite) {
      this._prevWrite = this._write;
      if (this._write) queueFrame(() => this.callWrite());
    }
  }

  getOutputValueGraph(): ValueGraph {
    return {
      finished: this._finished,
      resourcesWritten: this._resourcesWritten,
      status: this._status,
      error: this._error,
      errorCode: this._errorCode,
      writtenIds: this._writtenIds,
    };
  }

  /**
   * Validates input values.
   * @returns true if inputs are valid, otherwise false
   */
  private validateInputs(): boolean {
    try {
      if (this._realmId === undefined) {
        throw new QuickBooksWriteError(
          "realmId is missing",
          ErrorCode.REALM_ID_UNSET
        );
      }
      if (!this._resource) {
        throw new QuickBooksWriteError(
          "resource is required",
          ErrorCode.RESOURCE_UNSET
        );
      }
      if (this._data === null) {
        throw new QuickBooksWriteError("data is missing", ErrorCode.DATA_UNSET);
      }
      if (typeof this._data !== "object") {
        throw new QuickBooksWriteError(
          `Unsupported resource type ${typeof this._data}`,
          ErrorCode.BAD_DATA_TYPE
        );
      }
      if (!this._orgId) {
        this._error = null;
        this._errorCode = null;
        this._status = Status.STALE;
        this.pushOutputs();
        return false;
      }
    } catch (err) {
      this._error = (err as QuickBooksWriteError).message;
      this._errorCode = (err as QuickBooksWriteError).code;
      this._status = Status.FAILED;
      this.pushOutputs();
      return false;
    }
    return true;
  }

  /**
   * Calls the asynchronuous write method and sets the output
   * properties of the component accordingly.
   */
  private callWrite() {
    if (!this.validateInputs()) return;
    const realmId = this._realmId ?? "NONE";
    let path: string;
    switch (this._resource) {
      case "invoice":
        path = `/v3/company/${realmId}/invoice?minorversion=65`;
        break;
      default:
        throw new QuickBooksWriteError(
          `unknown resource ${this._resource ?? "NONE"}`,
          ErrorCode.UNKNOWN_RESOURCE
        );
    }
    const request: qbo.APIRequest = {
      orgId: this._orgId ?? "UNKNOWN",
      method: "POST",
      path,
      body: this._data,
    };
    this._status = Status.PENDING;
    this._error = null;
    this.pushOutputs();
    this.write(request)
      .then((response) => {
        switch (this._resource) {
          case "invoice":
            // eslint-disable-next-line no-case-declarations
            const invoice = (response.body as qbo.CreateInvoiceResponse)
              .Invoice;
            this._writtenIds = [invoice.Id];
            this._resourcesWritten++;
            break;
        }
        this._error = null;
        this._errorCode = null;
        this._status = Status.SUCCEEDED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      })
      .catch((err) => {
        if (err instanceof QuickBooksWriteError) {
          this._error = err.message;
          this._errorCode = err.code;
        } else {
          this._error = String(err);
          this._errorCode = (err as { code: string }).code ?? "unknown";
        }
        this._status = Status.FAILED;
        this._finished = true;
        this.pushOutputs();
        queueFrame(() => this.reset());
      });
  }

  /**
   * Writes the resource to QuickBooks. Implemented by the
   * platform-specific concrete class.
   *
   * @param request the API request to write the QuickBooks resource
   * @return the QuickBooks API response
   */
  protected abstract write(request: qbo.APIRequest): Promise<qbo.APIResponse>;

  protected pushOutputs(): void {
    this._invokedBy.setOutputValueGraph(this.getOutputValueGraph());
  }

  static isInput(name: string): boolean {
    if (inputs.has(name)) return true;
    if (name.startsWith("data.")) return true;
    return false;
  }

  static isOutput(name: string): boolean {
    if (outputs.has(name)) return true;
    return false;
  }
}

/** Names of component inputs. */
const inputs = new Set(["orgid", "realmid", "resource", "data", "write"]);
/** Names of component outputs. */
const outputs = new Set([
  "finished",
  "status",
  "resourceswritten",
  "error",
  "errorcode",
  "completepath",
  "writtenids",
]);
