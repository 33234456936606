import {
  ComponentInvoker,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * The UI view component represents a div element in the DOM tree.
 */
export default class UIViewComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLElement;

  constructor(invokedBy: ComponentInvoker, tagName = "div") {
    super(invokedBy);

    // create the DOM node even before it gets attached to the parent DOM node
    // so that we have a place to store the input values
    // TODO: should we store the inputs in optional properties that get deleted
    // when the DOM node is created and attached?
    this._DOMNode = document.createElement(tagName);
  }

  static getSchema(): Schema {
    const schema = super.getSchema();
    schema.text = { _input: null };
    return schema;
  }

  get DOMNode(): HTMLElement {
    return this._DOMNode;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      case "text":
        this.setOrRemoveTextNode(value);
        break;
    }
  }
}
