import { toValue } from "../behavior/behavior-commons";
import queueFrame from "../classes/frame-queue";
import {
  ComponentInvoker,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema, Value } from "../schema/schema-global";
import UIHTMLElementComponent from "./ui-html-element-component";

/**
 * Represents an input element in the DOM tree.
 */
export default class UIInputComponent extends UIHTMLElementComponent {
  protected _DOMNode: HTMLInputElement;
  private _changed = false;

  constructor(invokedBy: ComponentInvoker) {
    super(invokedBy);

    this._DOMNode = document.createElement("input");
    this._DOMNode.addEventListener("input", () => this.onInput());
    this._DOMNode.addEventListener("change", () => this.onChange());
    this._DOMNode.addEventListener("select", () => this.onSelect());
  }

  static getSchema(): Schema {
    const schema = super.getSchema();

    schema.disabled = { _input: null };
    schema.required = { _input: null };

    schema.autocomplete = { _input: null };
    schema.max = { _input: null };
    schema.maxLength = { _input: null };
    schema.min = { _input: null };
    schema.minLength = { _input: null };
    schema.pattern = { _input: null };
    schema.placeholder = { _input: null };
    schema.readonly = { _input: null };
    schema.setSelectionStart = { _input: null };
    schema.setSelectionEnd = { _input: null };
    schema.setSelectionDirection = { _input: null };
    schema.step = { _input: null };
    schema.setSelectAll = { _input: null };
    schema.setValue = { _input: null };
    schema.type = { _input: null };

    schema.value = { _output: {} };
    schema.selectionStart = { _output: {} };
    schema.selectionEnd = { _output: {} };
    schema.selectionDirection = { _output: {} };
    schema.changed = { _output: {} };

    return schema;
  }

  get DOMNode(): HTMLInputElement {
    return this._DOMNode;
  }

  getOutputValueGraph(): ValueGraph {
    const result = super.getOutputValueGraph();

    result.value = this.getValue();
    result.selectionStart = this._DOMNode.selectionStart;
    result.selectionEnd = this._DOMNode.selectionEnd;
    result.selectionDirection = this._DOMNode.selectionDirection;
    result.changed = this._changed;

    return result;
  }

  protected setDOMElementProperty(
    key: string,
    value: ValueGraphData | ValueGraphData[]
  ): void {
    super.setDOMElementProperty(key, value);

    switch (key.toLowerCase()) {
      // TODO: move disabled and required to an InputElementComponent?
      case "disabled":
        this._DOMNode.disabled = Boolean(value);
        break;
      case "required":
        this._DOMNode.required = Boolean(value);
        break;

      case "autocomplete":
        // TODO: potentially use this.DOMNode.autocomplete
        this.setOrRemoveAttribute("autocomplete", value);
        break;
      case "max":
        // TODO: potentially use this.DOMNode.max
        // TODO: make sure setting min/max happens before setValue
        this.setOrRemoveAttribute("max", value);
        break;
      case "maxlength":
        // TODO: potentially use this.DOMNode.maxLength
        this.setOrRemoveAttribute("maxLength", value);
        break;
      case "min":
        // TODO: potentially use this.DOMNode.min
        // TODO: make sure setting min/max happens before setValue
        this.setOrRemoveAttribute("min", value);
        break;
      case "minlength":
        // TODO: potentially use this.DOMNode.minLength
        this.setOrRemoveAttribute("minLength", value);
        break;
      case "pattern":
        // TODO: potentially use this.DOMNode.pattern
        this.setOrRemoveAttribute("pattern", value);
        break;
      case "placeholder":
        // TODO: potentially use this.DOMNode.placeholder
        this.setOrRemoveAttribute("placeholder", value);
        break;
      case "readonly":
        // TODO: potentially use this.DOMNode.readonly
        this._DOMNode.readOnly = Boolean(value);
        break;
      case "setselectionstart":
        // TODO: Note that according to the WHATWG forms spec selectionStart,
        // selectionEnd properties and setSelectionRange method apply only to
        // inputs of types text, search, URL, tel and password. See also
        // https://html.spec.whatwg.org/multipage/input.html#concept-input-apply
        if (value !== null && this._DOMNode.type === "text") {
          this._DOMNode.selectionStart = Number(value);
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setselectionend":
        if (value !== null && this._DOMNode.type === "text") {
          this._DOMNode.selectionEnd = Number(value);
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "setselectiondirection":
        if (value !== null && this._DOMNode.type === "text") {
          this._DOMNode.selectionDirection =
            value?.toString() === "forward"
              ? "forward"
              : value?.toString() === "backward"
              ? "backward"
              : "none";
          queueFrame(() => this.pushOutputs());
        }
        break;
      case "step":
        // TODO: potentially use this.DOMNode.step
        this.setOrRemoveAttribute("step", value);
        break;
      case "setselectall":
        if (value) this._DOMNode.select();
        break;
      case "setvalue":
        this.setValue(toValue(value));
        break;
      case "type":
        // TODO: potentially use this.DOMNode.type
        this.setOrRemoveAttribute("type", value);
        break;
    }
  }

  private setValue(value: Value): void {
    // value == null means we do not update the value
    if (value == null) return;

    switch (this._DOMNode.type) {
      case "date":
        this._DOMNode.value =
          value instanceof Date
            ? value.toISOString().substring(0, 10)
            : value.toString();
        break;
      case "datetime-local":
        this._DOMNode.value =
          value instanceof Date
            ? value.toISOString().substring(0, 19)
            : value.toString();
        break;
      case "month":
        this._DOMNode.value =
          value instanceof Date
            ? value.toISOString().substring(0, 7)
            : value.toString();
        break;
      case "time":
        this._DOMNode.value =
          value instanceof Date
            ? value.toISOString().substring(11, 8)
            : value.toString();
        break;
      default:
        this._DOMNode.value = value.toString();
    }

    queueFrame(() => this.pushOutputs());
  }

  private getValue(): Value {
    switch (this._DOMNode.type) {
      case "date":
      case "datetime-local":
      case "month":
        // dates are returned as UTC datetimes, datetimes are returned in the
        // local timezone
        return this._DOMNode.value ? new Date(this._DOMNode.value) : null;
      case "time":
        return this._DOMNode.value
          ? new Date("1970-01-01T" + this._DOMNode.value + "Z")
          : null;
      default:
        return this._DOMNode.value;
    }
  }

  private onChange() {
    this._changed = true;
    this.pushOutputs();
    queueFrame(() => {
      this._changed = false;
      this.pushOutputs();
    });
  }

  private onInput() {
    this.pushOutputs();
  }

  private onSelect() {
    if (this._DOMNode.type === "text") this.pushOutputs();
  }
}
