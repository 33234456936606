import { isSchema, isValue } from "../interfaces/global-interfaces";
import { Schema, Value } from "./schema-global";

/**
 * Checks whether a particular name is a reserved schema property name
 * like _value.
 * @param name The name of the schema property to be checked.
 * @returns True if this is a reserved property name.
 */
export function isReservedName(name: string): boolean {
  return [
    "_value",
    "_input",
    "_output",
    "_type",
    "_iscollection",
    "_id",
    "_comment",
    "_read",
    "_write",
    "_recursiveaccess",
    "_designprops",
    "_behaviormode",
  ].includes(name);
}

/**
 * Does a case insensititve lookup of elements on a schema root
 * @param schema The schema in which to look up the element.
 * @param name The name of the element being looked for.
 * @returns A value or schema if the element has been found, otherwise `undefined`.
 */
export function findRootElement(
  schema: Schema,
  name: string
): Value | undefined | Schema {
  name = name.toLowerCase();
  for (const key in schema) {
    if (key.toLowerCase() == name) {
      const result = schema[key];
      if (isValue(result) || isSchema(result)) return result;
      // otherwise we return undefined at the end of the function, e.g. when the
      // value found is an array
    }
  }

  return undefined;
}

/**
 * Finds the main entry point into a schema. If `main` is not defined or main
 * is not a schema (object), the schema itself is the entry point.
 * @param schema The schema in which to find the main entry point.
 * @returns The main entry point.
 */
export function getMain(schema: Schema): Schema {
  const main = getEntryPoint(schema, "main");
  return main ? main : schema;
}

/**
 * Finds an entry point into a schema by its name. If the entry point is not
 * defined or it is not a schema (object), it returns `undefined`.
 * @param schema The schema in which to find the entry point.
 * @param name The name of the entry point to look for.
 * @returns The entry point or undefined if not found.
 */
export function getEntryPoint(
  schema: Schema,
  name: string
): Schema | undefined {
  const entry = findRootElement(schema, name);
  return isSchema(entry) ? entry : undefined;
}

export function getElementByPath(
  schema: Schema,
  path: string
): Schema | Value | undefined {
  path = path.toLowerCase();

  do {
    const d = path.indexOf(".");

    if (d < 0) {
      // '.' not found, thus we have reached the last element in the path
      return findRootElement(schema, path.trim());
    }

    const element = findRootElement(schema, path.substring(0, d).trim());
    if (!isSchema(element)) return undefined;

    schema = element;

    path = path.substring(d + 1);
  } while (path.length > 0);
}
