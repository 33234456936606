import {
  BehaviorMode,
  BehaviorNode,
  createBehaviorTree,
  isBehaviorNode,
} from "../behavior/behavior";
import { isExpression } from "../behavior/behavior-commons";
import SystemComponentFactory from "../components/system-component-factory";
import { isSchema, isValue } from "../interfaces/global-interfaces";
import { Schema, Value } from "../schema/schema-global";
import * as schemaUtils from "../schema/schema-utils";

class SchemaError extends Error {
  constructor(message: string, location: CellType) {
    super(
      message +
        ` (found in ${
          location.root.originalName + "." + location.originalPathFromRoot
        })`
    );
  }
}

/** Data type for properties of an init value graph of a cellType  */
export type InitGraphData =
  | BehaviorNode
  | Value
  | InitGraph
  | (Value | InitGraph)[];

/** Data type for the init value graph of a cellType  */
export interface InitGraph {
  _value?: Value | BehaviorNode;
  [key: string]: undefined | InitGraphData;
}

/**
 * Defines the behavior of cells and their instances. Is instantiated from a
 * schema.
 * @param schema A JS object containing the application schema.
 * @param name The name of this cell type.
 * @param parent The parent cellType, may be null if this is the rootCellType.
 * @param library A library schema to be used to resolve component references.
 */
export default class CellType {
  /**
   * The parent cellType of this cell type. It is `null` for the root of a
   * component.
   */
  readonly parent: CellType | null;

  /**
   * The optimised name of this cell type. Cell type names are in particular
   * converted to lowercase for case insensitive searches.
   */
  readonly name: string;

  /**
   * The id of this cell type. Reserved for later use to identify cell types
   * even across renaming the cell type.
   */
  readonly id: string | null = null;

  /**
   * The root cellType of this component (or `this` if this is the root itself)
   */
  readonly root: CellType;

  /**
   * Type of the component to be invoked, e.g. "seed.ui.view"; default is
   * `null`, which is an invisible value cell.
   */
  readonly type: string | null = null;

  /**
   * If isCollection is `true`, cells of this type can have multiple instances,
   * otherwise they have exactly one instance. `false` is the default.
   */
  readonly isCollection: boolean = false;

  /**
   * Returs the original name of the cell type (before conversion to lower case
   * etc.).
   */
  readonly originalName: string;

  /**
   * Array of the subordinated cell types (children). Using an array keeps the
   * children cell types in their original order.
   */
  readonly children: CellType[] = [];

  /**
   * Map to lookup the properties of this cellType by name. The cell type names
   * are stored in lowercase as the keys in this map.
   */
  protected attributes = new Map<string, CellType>();

  /**
   * The map to direct a request for a certain cellType name which can be found
   * lower in the hierarchy. The value in the map points to the particular child
   * of this cellType by which to dig deeper to find the requested cell type
   * name.
   */
  protected pathToAttributes = new Map<string, CellType>();

  /**
   * The way how behavior expressions (incl. the resolution of identifiers) are
   * evaluated
   */
  readonly behaviorMode?: BehaviorMode;

  /**
   * The constant init value or the root node of the tree of tokens defining the
   * bahavior of cells of this type.
   */
  readonly behavior: InitGraphData = null;

  // various performance optimisation constants

  /** The path starting from the parent that owns an id not including that
   * parent's name all in lowercase */
  readonly pathFromId: string;

  /** The path starting from the root of the component not including the
   * component name all in lowercase */
  readonly pathFromRoot: string;

  /** The path starting from the root of the component not including the
   * component name in its original writing (lower/uppercase) */
  readonly originalPathFromRoot: string;

  /** This cellType declares an input of the component */
  readonly isInput: boolean = false;
  /** This cellType declares an output of the component */
  readonly isOutput: boolean = false;
  /** This cellType is connected to an input of an invoked component */
  readonly isConnectedToInput: boolean = false;
  /** This cellType is connected to an output of an invoked component */
  readonly isConnectedToOutput: boolean = false;

  constructor(
    schema: unknown,
    name: string,
    parent: CellType | null,
    library: Schema | null
  ) {
    this.parent = parent;
    this.name = parent ? name.toLowerCase() : "root"; // the name of the root is always "root"
    this.originalName = name;

    this.root = this.parent ? this.parent.root : this;

    this.pathFromRoot = this.getPathFromRoot();
    this.originalPathFromRoot = this.getOriginalPathFromRoot();

    this.checkForSiblingWithSameName();

    if (isValue(schema) || Array.isArray(schema)) {
      this.behaviorMode = parent?.behaviorMode;
      this.behavior = this.getBehavior(schema);
    } else if (isSchema(schema)) {
      this.type = this.getType(schema._type, library);

      this.isCollection = this.getIsCollection(schema._iscollection);

      this.id = this.getId(schema._id);

      this.behaviorMode = this.getBehaviorMode(schema._behaviormode);

      if (schema._value !== undefined) {
        this.checkValueProperty();
        this.behavior = this.getBehavior(schema._value);
      } else if (schema._input !== undefined) {
        this.checkInputProperty();
        this.behavior = this.getBehavior(schema._input);
        this.checkNoInputBehavior(this.behavior);
        this.isInput = true;
      } else if (schema._output !== undefined) {
        this.checkOutputProperty();
        this.behavior = this.getBehavior(schema._output);
        this.isOutput = true;
      }
    } else {
      throw new SchemaError(
        `Invalid schema fragment ${schema as string}`,
        this
      );
    }

    // find the element in the invoked component in case this is an input
    // or output of this component

    // TODO: not quite clean when this CellType is the root of the component. At
    // the time being this is caught as `getPathFromInvokedComponent` returns an
    // empty string which makes `getElementByPath` to return `undefined`.
    const closestInvokingType = this.getClosestInvokingCellType()?.type;
    if (closestInvokingType) {
      if (
        closestInvokingType === "seed.api.consumer" ||
        closestInvokingType === "seed.callable" ||
        closestInvokingType === "seed.remote" ||
        closestInvokingType === "seed.local.schema" ||
        closestInvokingType === "seed.google.charts" ||
        closestInvokingType === "seed.quickbooks.query" ||
        closestInvokingType === "seed.quickbooks.write" ||
        closestInvokingType === "seed.task.http" ||
        closestInvokingType.startsWith("seed.firestore.")
      ) {
        // TODO: Make this apply to all components, not just listed types.
        this.isConnectedToInput = SystemComponentFactory.isInput(
          closestInvokingType,
          this.getPathFromInvokedComponent()
        );
        this.isConnectedToOutput = SystemComponentFactory.isOutput(
          closestInvokingType,
          this.getPathFromInvokedComponent()
        );
      } else {
        const compSchema = this.getComponentSchema(
          closestInvokingType,
          library
        );
        const compCellType =
          compSchema &&
          schemaUtils.getElementByPath(
            compSchema,
            this.getPathFromInvokedComponent()
          );

        if (isSchema(compCellType)) {
          // depending on whether this cell connects to an input or output of an
          // invoked component, remember it is connected
          this.isConnectedToInput = compCellType?._input !== undefined;
          this.isConnectedToOutput = compCellType?._output !== undefined;

          // copy the collection type of the connected input or output
          if (this.isConnectedToInput)
            this.isCollection = compCellType._iscollection === true;
          if (this.isConnectedToOutput)
            this.isCollection = compCellType._iscollection === true;
        }
      }
    }

    this.pathFromId = this.getPathFromId(); // requires this.isCollection to be set before

    if (this.isSystemOutput() && this.behavior !== null) {
      throw new SchemaError(
        `${this.originalName} must not have a value or behavior being declared`,
        this
      );
    } else if (this.isConnectedToOutput && this.behavior !== null) {
      throw new SchemaError(
        "A property connecting to an output must not declare a value or behavior",
        this
      );
    }

    if (isSchema(schema)) {
      for (const key in schema) {
        if (
          Object.prototype.hasOwnProperty.call(schema, key) &&
          !schemaUtils.isReservedName(key.toLowerCase())
        ) {
          const value = schema[key];
          if (value === undefined) continue;

          // create child cellType
          const ct = new CellType(value, key, this, library);
          this.children.push(ct);
          if (ct.name) {
            // attributes can be looked up by name
            this.attributes.set(ct.name, ct);
            // pathToAttributes will include direct children as well as children of children
            this.pathToAttributes.set(ct.name, ct);
          }

          // add all cellTypes of the children's pathToAttributes to this pathToAttributes
          // the value is the direct child of this, not the target cell itself
          ct.addAllAttributeKeysToMap(this.pathToAttributes);
        }
      }
    }
  }

  public get isRoot(): boolean {
    return this.parent === null;
  }

  /**
   * Adds all subordinated cellType names to map pointing to this cellType.
   * Purpose is to add all subordinated cellType names with itself to the
   * parent cellType path map. It allows the parent to know that it can
   * expect a cellType with this name somwhere underneath itself.
   * If there is a cellType lower in the hierarchy with the same
   * name, only the top most will be mapped.
   *
   * If there are two cellTypes on the same hierarchy, the one more up
   * in the schema will be mapped.
   * @param map The map to which to add the subordinated cell type names.
   *            Usually the attribute path map of the parent cellType
   */
  addAllAttributeKeysToMap(map: Map<string, CellType>): void {
    this.pathToAttributes.forEach((value: CellType, key: string) => {
      // only add it to the map if not yet there (higher hierarchies
      // and more up in the schema are winning)
      !map.has(key) && map.set(key, this);
    });
  }

  getChildTowardsAttribute(name: string): CellType | undefined {
    return this.pathToAttributes.get(name.toLowerCase());
  }

  getAttribute(name: string): CellType | undefined {
    return this.attributes.get(name.toLowerCase());
  }

  hasAttribute(name: string): boolean {
    return this.attributes.has(name.toLowerCase());
  }

  /**
   * Looks into the cellType graph following a path of cellType names and
   * returns the cellType at the end of the path.
   * @param path Sequence of cellType names separated with dots ('.').
   *             Must start with a child name of this cellType.
   * @returns The cellType at the end of the path or null if not found.
   */
  getAttributeByPath(path: string): CellType | undefined {
    path = path.toLowerCase();
    const d = path.indexOf(".");

    if (d < 0) {
      // '.' not found, thus we have reached the last element in the path
      return this.getAttribute(path.trim());
    }

    const remainder = path.substring(d + 1);
    const attributename = path.substring(0, d).trim();

    const celltype = this.getAttribute(attributename);
    return celltype?.getAttributeByPath(remainder);
  }

  /**
   * Checks whether a CellType name is either a parent, grandparent, sibling,
   * sibling of a parent, or this.
   * @param name The CellType name to check for.
   * @returns `true` if `name` is a parent or sibling or this.
   */
  public hasParentOrSiblingOrIsThis(name: string): boolean {
    return (
      (this.parent?.name === name.toLowerCase() ||
        this.parent?.hasAttribute(name) ||
        this.parent?.hasParentOrSiblingOrIsThis(name)) ??
      false
    );
  }

  /**
   * Checks whether a CellType name is either a parent, grandparent, sibling, or
   * sibling of a parent.
   * @param name The CellType name to check for.
   * @returns `true` if `name` is a parent or sibling.
   */
  public hasParentOrSibling(name: string): boolean {
    const lowercase = name.toLowerCase();
    return (
      (this.parent?.name === lowercase ||
        (this.parent?.hasAttribute(name) && this.name !== lowercase) ||
        this.parent?.hasParentOrSiblingOrIsThis(name)) ??
      false
    );
  }

  /**
   * Checks whether a CellType name is a parent or grandparent.
   * @param name The CellType name to check for.
   * @returns `true` if `name` is a parent.
   */
  public hasParent(name: string): boolean {
    return (
      (this.parent?.name === name.toLowerCase() ||
        this.parent?.hasParent(name)) ??
      false
    );
  }

  /**
   * Returns the entire, normalised path of the schema starting from the first
   * child of the root. I.e. root is not included and the the root's path itself
   * is empty.
   * @returns The normalised path from the root of the schema.
   */
  private getPathFromRoot(): string {
    if (!this.parent) {
      return "";
    } else if (this.parent.pathFromRoot.length === 0) {
      return this.name;
    } else {
      return this.parent.pathFromRoot + "." + this.name;
    }
  }

  /**
   * Returns the entire path of the schema with the original names of the
   * CellTypes (not normalised) starting from the first child of the root. I.e.
   * root is not included and the the root's path itself is empty.
   * @returns The path from the root of the schema.
   */
  private getOriginalPathFromRoot(): string {
    if (!this.parent) {
      return "";
    } else if (this.parent.originalPathFromRoot.length === 0) {
      return this.originalName;
    } else {
      return this.parent.originalPathFromRoot + "." + this.originalName;
    }
  }

  /**
   * Returns the normalised path starting upstream from the CellType that owns
   * an id. The name of the CellType owning the id is excluded.
   *
   * If upstream there is no CellType with an id, it returns the path from the
   * root (excluding root).
   *
   * Make sure the `isCollection` property has been set before this function is
   * called.
   * @returns The normalised path from the CellType implementing a component.
   */
  private getPathFromId(): string {
    if (this.isCollection || !this.parent) {
      return "";
    } else if (this.parent.pathFromId.length === 0) {
      return this.name;
    } else {
      return this.parent.pathFromId + "." + this.name;
    }
  }

  /**
   * Returns the normalised path starting upstream from the CellType that
   * implements a component. The CellType at the root of where the component is
   * invoked is not included, so it corresponds to the path within the
   * component (`getPathFromRoot`).
   *
   * If there is no component implemented upstream, it returns the path from the
   * root (excluding root).
   *
   * Make sure the `type` property has been set before this function is called.
   * @returns The normalised path from the CellType invoking a component.
   */
  private getPathFromInvokedComponent(): string {
    if (this.type || !this.parent) {
      return "";
    } else if (this.parent.getPathFromInvokedComponent().length === 0) {
      return this.name;
    } else {
      return this.parent.getPathFromInvokedComponent() + "." + this.name;
    }
  }

  private checkForSiblingWithSameName() {
    if (this.parent && this.parent.hasAttribute(this.name))
      throw new SchemaError(
        "A cell type must not have the same name as a sibling",
        this
      );
  }

  private getType(type: unknown, library: Schema | null): string | null {
    if (type === undefined) return null;

    if (typeof type !== "string")
      throw new SchemaError("Invalid _type declaration", this);

    // TODO: replace call to getComponentSchema with checking just for the type
    // name (preferably from a type map)
    if (this.getComponentSchema(type, library)) return type.toLowerCase();

    throw new SchemaError(`Unknown type '${type}' in _type declaration`, this);
  }

  private getComponentSchema(
    type: string,
    library: Schema | null
  ): Schema | undefined {
    let schema = undefined;

    schema = SystemComponentFactory.getSchema(type);
    if (schema) return schema;

    if (library) schema = schemaUtils.findRootElement(library, type);
    if (schema === undefined || isSchema(schema)) return schema;

    throw new SchemaError(`Invalid component declaration for '${type}'`, this);
  }

  private getIsCollection(isCollection: unknown): boolean {
    if (isCollection === undefined) return false;

    if (this.parent === null) {
      throw new SchemaError(
        "_iscollection must not be defined at the root of a schema",
        this
      );
    }

    if (typeof isCollection !== "boolean")
      throw new SchemaError(
        "_iscollection must be declared as a boolean",
        this
      );

    return isCollection === true;
  }

  private getId(id: unknown): string | null {
    if (id === undefined) return null;

    if (typeof id !== "string")
      throw new SchemaError("Invalid _id declaration", this);

    return id.toLowerCase();
  }

  private checkValueProperty() {
    if (this.parent === null)
      throw new SchemaError(
        "_value must not be defined at the root of a schema",
        this
      );
  }

  /**
   * Recursively checks some init data graph whether it does not have any
   * expression either on top level or at one of the graph's nodes.
   * @param data The data graph to be checked for not having an expression.
   * @throws If one of graph's nodes has an expression.
   */
  private checkNoInputBehavior(data: InitGraphData): void {
    if (isBehaviorNode(data))
      throw new SchemaError("_input must not have an expression", this);

    if (isValue(data)) return;

    if (Array.isArray(data)) {
      for (const item of data) this.checkNoInputBehavior(item);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(data).forEach(([key, value]) => {
      this.checkNoInputBehavior(value ?? null);
    });
  }

  private checkInputProperty() {
    if (this.parent === null)
      throw new SchemaError(
        "_input must not be defined at the root of a schema",
        this
      );

    if (!(this.parent.isRoot || this.parent.isInput))
      throw new SchemaError(
        "_input must be defined directly below the root of a schema or below an input",
        this
      );
  }

  private checkOutputProperty() {
    if (this.parent === null)
      throw new SchemaError(
        "_output must not be defined at the root of a schema",
        this
      );

    if (!(this.parent.isRoot || this.parent.isOutput))
      throw new SchemaError(
        "_output must be defined directly below the root of a schema or below an output",
        this
      );
  }

  private getBehaviorMode(mode: unknown): BehaviorMode | undefined {
    // return the behavior mode only if a valid value (string) is provided
    // otherwise it inherits it from the parent
    if (typeof mode === "undefined") return this.parent?.behaviorMode;

    if (Object.values(BehaviorMode).includes(mode as BehaviorMode))
      return mode as BehaviorMode;

    throw new SchemaError("Unknown behavior mode", this);
  }

  /**
   * Checks whether this cell type is referencing a system output, like
   * `_index`.
   * @returns `true` if this cell type is referencing a system output.
   */
  private isSystemOutput() {
    return ["_index", "_busy"].includes(this.name);
  }

  /**
   * Converts a value that defines the behavior or initialization value of an
   * element within a schema into an initialization graph.
   * @param value A value that is assigned to an element in the schema.
   * @returns An initialization graph consisting of constants and behavior
   *    trees.
   * @throws If `value` includes elements that are not a constant value, array
   *    of constants, an object, or if the object contains arrays of arrays or
   *    arrays of expressions (which are not supported).
   */
  private getBehavior(value: unknown): InitGraphData {
    // simple values incl. null are returned as a behavior tree if they are an
    // expression, otherwise as they are
    if (isValue(value)) {
      if (typeof value === "string" && isExpression(value)) {
        try {
          return createBehaviorTree(value, this.behaviorMode);
        } catch (err) {
          throw new SchemaError(String(err), this);
        }
      } else return value;
    }

    // arrays are returned as arrays, but do not support arrays of expressions
    // or arrays of arrays
    if (Array.isArray(value)) {
      return value.map((item) => {
        const result = this.getBehavior(item);
        if (isBehaviorNode(result) || Array.isArray(result))
          throw new SchemaError(
            "Array constants must not be an array of expressions or an array of arrays.",
            this
          );
        return result;
      });
    }

    if (typeof value === "object" && value !== null) {
      const result: InitGraph = {};
      Object.entries(value).forEach(([key, value]) => {
        result[key] = this.getBehavior(value);
      });

      // return null for an empty object
      if (Object.keys(result).length === 0) return null;

      // flatten the object if just _value is defined
      if (Object.keys(result).length === 1 && result._value !== undefined)
        return result._value;

      return result;
    }

    throw new SchemaError(
      "_value, _input, and _output must be declared as a primitive, an object, an array, or an expression.",
      this
    );
  }

  /**
   * Returns the closest CellType found upstream that is invoking a component. Used
   * to compare paths from within a component to their invoking context.
   */
  private getClosestInvokingCellType(): CellType | null {
    if (this.type) {
      return this;
    } else {
      return this.parent?.getClosestInvokingCellType() ?? null;
    }
  }
}
