import jsep from "jsep";
import * as uuid from "uuid";
import * as yaml from "yaml";

import Cell from "../classes/cell";
import Instance from "../classes/instance";
import {
  CellNameWalker,
  isSchema,
  isValue,
  ValueGraph,
  ValueGraphData,
} from "../interfaces/global-interfaces";
import { Schema, Value } from "../schema/schema-global";
import * as schemaUtils from "../schema/schema-utils";
import {
  getIdentifiersHelper,
  getRegionsHelper,
} from "../schema/schema-utils-helpers";
import {
  BehaviorMode,
  BehaviorNode,
  createBehaviorTree,
  getArgumentNodes,
  isJsepError,
} from "./behavior";
import {
  toValue,
  toNumber,
  makeArray,
  isExpression,
  uid,
} from "./behavior-commons";

type PathValuePair = {
  path: string;
  value: Value;
};

export function createVariousFunctionBehaviorNode(
  token: jsep.CallExpression,
  mode: BehaviorMode
): BehaviorNode | undefined {
  switch ((token.callee as jsep.Identifier).name.toLowerCase()) {
    case "parseschema":
      return new parseSchemaFunctionBehaviorNode(token, mode);
    case "stringifyschema":
      return new stringifySchemaFunctionBehaviorNode(token, mode);
    case "prettifyschema":
      return new prettifySchemaFunctionBehaviorNode(token, mode);
    case "extractinterface":
      return new extractInterfaceFunctionBehaviorNode(token, mode);
    case "extractinputs":
      return new extractInputsFunctionBehaviorNode(token, mode);
    case "extractoutputs":
      return new extractOutputsFunctionBehaviorNode(token, mode);
    case "pathvaluearraytoobject":
      return new pathValueArrayToObjectFunctionBehaviorNode(token, mode);
    case "objecttopathvaluearray":
      return new objectToPathValueArrayFunctionBehaviorNode(token, mode);
    case "extractidentifiers":
      return new extractIdentifiersFunctionBehaviorNode(token, mode);
    case "splitexpressiontoregions":
      return new splitExpressionToRegionsFunctionBehaviorNode(token, mode);
    case "isinvalidexpression":
      return new isInvalidExpressionFunctionBehaviorNode(token, mode);
    case "animateto":
      return new animateToFunctionBehaviorNode(token, mode);
    case "uuid1":
      return new uuid1FunctionBehaviorNode(token, mode);
    case "uuid4":
      return new uuid4FunctionBehaviorNode(token, mode);
    case "uuidvalidate":
      return new uuidValidateFunctionBehaviorNode(token, mode);
    case "uuidversion":
      return new uuidVersionFunctionBehaviorNode(token, mode);
    case "uid":
      return new uidFunctionBehaviorNode(token, mode);
    case "encodeuri":
      return new encodeURIFunctionBehaviorNode(token, mode);
    case "encodeuricomponent":
      return new encodeURIComponentFunctionBehaviorNode(token, mode);
    case "decodeuri":
      return new decodeURIFunctionBehaviorNode(token, mode);
    case "decodeuricomponent":
      return new decodeURIComponentFunctionBehaviorNode(token, mode);
    case "tabletoobject":
    case "tabletoobjects":
      return new tableToObjectsFunctionBehaviorNode(token, mode);
    case "objectstotable":
      return new objectsToTableFunctionBehaviorNode(token, mode);
  }
}

/**
 * Parses a Hyperseed schema string and returns a structured object.
 * @name parseSchema() function
 * @param schema The schema string to be parsed.
 * @returns An object with the properties of the top level seed and an array of
 *    strings for the child seeds.
 */
class parseSchemaFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraph | Value {
    let schemaSource = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (!schemaSource) return null;

    if (
      // identify whether schemaSource is not an object with a name value pair
      // we test whether there is a key with a colon at the beginning
      (typeof schemaSource === "string" &&
        !/^\s*{?\s*(\w+|"\w+"):/.test(schemaSource)) ||
      typeof schemaSource === "number" ||
      typeof schemaSource === "boolean"
    )
      schemaSource = `_value: ${schemaSource as string}`;

    let schema: Schema;

    try {
      schema = yaml.parse(schemaSource.toString(), {
        prettyErrors: true,
      }) as Schema;

      const result: ValueGraph = {
        type: schema._type !== undefined ? (schema._type as string) : null,
        behaviorMode:
          schema._behaviormode !== undefined
            ? (schema._behaviormode as string)
            : null,
        isCollection: Boolean(schema._iscollection),
        values:
          isValue(schema._value) || Array.isArray(schema._value)
            ? valueToPathValueArray(schema._value)
            : isValue(schema._input) || Array.isArray(schema._input)
            ? valueToPathValueArray(schema._input)
            : isValue(schema._output) || Array.isArray(schema._output)
            ? valueToPathValueArray(schema._output)
            : [],
        comment:
          schema._comment !== undefined ? (schema._comment as string) : null,
        isInput: schema._input !== undefined,
        isOutput: schema._output !== undefined,
        designProps: [],
        children: [],
        error: null,
      };

      if (schema._designprops) {
        Object.entries(schema._designprops as Record<string, unknown>).forEach(
          ([key, value]) => {
            if (value === undefined) return;
            (result.designProps as ValueGraphData[]).push({
              // TODO: rename `name` to `path` for compatibility with
              // pathValueArray functions
              name: key,
              value: value as ValueGraphData,
            });
          }
        );
      }

      Object.entries(schema).forEach(([key, value]) => {
        if (schemaUtils.isReservedName(key.toLowerCase())) return;
        if (value === undefined) return;
        (result.children as ValueGraphData[]).push({
          name: key,
          schema: isValue(value)
            ? stringifyValue(value)
            : stringifyObject(value as ValueGraph),
        });
      });

      return result;
    } catch (err) {
      return {
        error: err instanceof Error ? err.message : String(err),
      };
    }
  }
}

/**
 * Converts a structured Hyperseed schema object into a formatted schema string.
 * @name stringifySchema() function
 * @param schema The schema object to be stringified.
 * @param children An array of schemata of children elements.
 * @param indentation The line indentation as a string.
 * @returns A formatted string with the entire schema.
 */
class stringifySchemaFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 3);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const schema = this.arguments[0].evaluate(scope, calledFrom);
    if (!schema) return null;
    if (!isSchema(schema)) return null;

    let children = "";
    if (this.arguments.length > 1) {
      children =
        toValue(this.arguments[1].evaluate(scope, calledFrom))?.toString() ??
        "";
    }

    const indentation =
      this.arguments.length > 2
        ? toValue(this.arguments[2].evaluate(scope, calledFrom))?.toString() ??
          "  "
        : "  "; // default indentation is 2 spaces

    return stringifyObject(schema, children, indentation);
  }
}

// TODO: Add tests for the prettifySchema function
/**
 * Prettifies a Hyperseed schema string.
 * @name prettifySchema() function
 * @param schema The schema string to be stringified.
 * @param indentation The line indentation as a string.
 * @returns The prettified schema.
 */
class prettifySchemaFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const schemaSource = this.arguments[0].evaluate(scope, calledFrom);
    if (!schemaSource) return null;

    const indentation =
      this.arguments.length > 1
        ? toValue(this.arguments[1].evaluate(scope, calledFrom))?.toString() ??
          "  "
        : "  "; // default indentation is 2 spaces

    let schema: Schema;

    try {
      schema = yaml.parse(schemaSource.toString(), {
        prettyErrors: true,
      }) as Schema;
    } catch (err) {
      return err instanceof Error ? err.message : String(err);
    }

    return stringifyObject(schema as ValueGraph, undefined, indentation);
  }
}

/**
 * Parses a Hyperseed component schema string and extracts the interface schema
 * that can be used to invoke the component.
 * @name extractInterface() function
 * @param name The name of the component.
 * @param schema The schema to be parsed.
 * @returns The interface schema or null if either `name` or `schema` are
 * `null`.
 */
class extractInterfaceFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraph | null {
    const component = toValue(
      this.arguments[0].evaluate(scope, calledFrom)
    )?.toString();
    const yamlSource = toValue(this.arguments[1].evaluate(scope, calledFrom));
    if (!yamlSource) return null;

    let schema: unknown;

    try {
      schema = yaml.parse(yamlSource.toString(), {
        prettyErrors: true,
      });
    } catch (err) {
      return {
        error: String(err) + "\n----------\n" + yamlSource.toString(),
      };
    }

    // TODO: _type should be added as the first element
    const result = this.parseInterface(schema);
    if (component) result._type = component;

    return result as ValueGraph;
  }

  private parseInterface(schema: unknown): Schema {
    const result: Schema = {};

    if (!isSchema(schema)) return result;

    if (schema._iscollection) result._iscollection = true;
    // TODO: for an input with a value and not an expression return the default
    // value
    if (schema._input !== undefined) result._value = null;
    // for outputs we anyhow return an empty object as the correct placeholder

    Object.entries(schema).forEach(([key, value]) => {
      if (schemaUtils.isReservedName(key.toLowerCase())) return;
      if (value === undefined) return;

      if (
        // just consider values that are not empty objects as otherwise neither
        // an input nor output is defined
        isSchema(value) &&
        Object.keys(value as Record<string, unknown>).length > 0
      ) {
        // stop parsing if value is still not an input or output
        if (value._input === undefined && value._output === undefined) return;

        const branch = this.parseInterface(value);
        if (Object.keys(branch).length === 1 && branch._value !== undefined) {
          // in case the child branch returns just a value (for e.g. an input)
          // just assign the value, not the entire branch
          result[key] = branch._value;
        } else {
          result[key] = branch;
        }
      }
    });

    return result;
  }
}

/**
 * Parses a Hyperseed component schema string and extracts all inputs as
 * path/value pairs.
 * @name extractInputs() function
 * @param schema The schema to be parsed.
 * @returns An array of path/value pairs.
 */
class extractInputsFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const yamlSource = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (!yamlSource) return [];

    let schema: unknown;

    try {
      schema = yaml.parse(yamlSource.toString(), {
        prettyErrors: true,
      });
    } catch {
      return [];
    }

    return parseInputs("", schema).map((item) => item.path);
  }
}

/**
 * Parses a Hyperseed component schema string and extracts all inputs as
 * path/value pairs.
 * @name extractOutputs() function
 * @param schema The schema to be parsed.
 * @returns An array of path/value pairs.
 */
class extractOutputsFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    const yamlSource = toValue(this.arguments[0].evaluate(scope, calledFrom));
    if (!yamlSource) return [];

    let schema: unknown;

    try {
      schema = yaml.parse(yamlSource.toString(), {
        prettyErrors: true,
      });
    } catch {
      return [];
    }

    return parseOutputs("", schema).map((item) => item.path);
  }
}

/**
 * Converts an array of path/value pairs to an object. Names in the path are
 * converted to object properties, numbers are converted to array elements.
 * @name pathValueArrayToObject() function
 * @param array An array of path/value pairs.
 * @returns An object with the values according to their paths.
 */
class pathValueArrayToObjectFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] {
    const array = makeArray(this.arguments[0].evaluate(scope, calledFrom));

    let result = null;

    for (const item of array) {
      let path: string;
      let value: Value;
      if (isValue(item)) {
        continue;
      } else if (item instanceof Instance) {
        path = item.getAttributeValue("path")?.toString() ?? "";
        value = toValue(item.getAttributeValue("value"));
      } else {
        path = item["path"]?.toString() ?? "";
        value = toValue(item["value"] ?? null);
      }

      result = this.walkThePath(path.split("."), value, result);
    }

    return result;
  }

  /** Walks along the path and mixes value into object */
  private walkThePath(
    path: string[],
    value: Value,
    object: ValueGraphData | ValueGraphData[]
  ): ValueGraphData | ValueGraphData[] {
    if (path.length === 0 || path[0] === "") return value;

    let result = object;

    // a `+` at the end of a path element means adding another item to the array
    const addBefore = path[0].endsWith("+");
    // a `-` at the end of a path element means deleting the array item at this position
    const deleteThis = path[0].endsWith("-");

    // be aware that after adding or deleting an item the array indices have
    // changed, thus use the add/delete elements always just at the end of the
    // array

    if (addBefore || deleteThis) {
      // for further processing we consider the path element w/o the `+` and `-` operator
      const index = Number(path[0].substring(0, path[0].length - 1));
      // operator on a path element that is not a number (incl. empty string,
      // thus only the operator is found), is negative, or is not the last
      // element in the path is invalid, do nothing
      // TODO: return error code?
      if (isNaN(index) || path[0].length === 1 || index < 0 || path.length > 1)
        return result;

      if (addBefore) {
        // at the time being arrays of arrays are not supported
        if (Array.isArray(value)) value = null;

        if (!Array.isArray(result)) {
          // overwrite the result if it is not yet an array
          result = [value];
        } else {
          // inserts the value (or adds it at the end when index is larger than
          // the length of the array)
          result.splice(index, 0, value);
        }
      } else {
        // do not delete anything if there is not yet an array
        if (!Array.isArray(result)) return result;
        result.splice(index, 1);
      }

      return result;
    }

    const index = Number(path[0]);
    if (!isNaN(index)) {
      if (!Array.isArray(result)) {
        // overwrite the result if it is not yet an array
        result = [];
      }
      if (index < 0) return result;
      // TODO: do not allow `undefined` array entries when setting elements on a
      // particular index (or catch this issue in the stringifySchema function)
      // set the value of the element to the result of the remaining path/value
      const item = this.walkThePath(
        path.slice(1),
        value,
        result[index] ?? null
      );
      // at the time being arrays of arrays are not supported
      result[index] = Array.isArray(item) ? null : item;
      return result;
    } else {
      if (
        isValue(result) ||
        result instanceof Instance ||
        Array.isArray(result)
      ) {
        // overwrite the result if it is not yet an object
        result = {};
      }

      // set the value of the property to the result of the remaining path/value
      const item = this.walkThePath(
        path.slice(1),
        value,
        result[path[0]] ?? null
      );
      if (item === null && !(path[0] === "_input" || path[0] === "_output")) {
        // TODO: write a test for this special case (was required when setting a
        // property to null)

        // if value is null, do not add the property or if already existing then
        // remove the property from the object except when it declares an input
        // or output
        delete result[path[0]];
      } else {
        result[path[0]] = item;
      }

      if (typeof result === "object" && Object.entries(result).length === 0) {
        // empty objects are returned as null values
        return null;
      } else {
        return result;
      }
    }
  }
}

/**
 * Converts an object to an array of path/value pairs. Object properties are
 * concatenated with their names into the path, indices of array elements are
 * concatenated as numbers into the path.
 * @name objectToPathValueArray() function
 * @param object An object to be converted.
 * @returns An array of path/value pairs.
 */
class objectToPathValueArrayFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData[] {
    return valueToPathValueArray(this.arguments[0].evaluate(scope, calledFrom));
  }
}

/**
 * Extracts all identifiers from an expression excluding function names and
 * string literals. The expression must start with the equal `=` operator,
 * otherwise `null` is returned.
 * @name extractIdentifiers() function
 * @param expression The expression being parsed.
 * @returns An array of identifier names.
 */
class extractIdentifiersFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] {
    const expression = this.arguments[0]
      .evaluate(scope, calledFrom)
      ?.toString();

    if (!expression || !isExpression(expression)) return null;

    return getIdentifiersHelper(expression);
  }
}

/**
 * Splits an expression in its regions for identifiers, quoted, and others.
 * @name splitExpressionToRegions() function
 * @param expression The expression being parsed.
 * @returns An array of objects with type, content, begin, and end of the
 *    regions.
 */
class splitExpressionToRegionsFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] {
    const expression = this.arguments[0]
      .evaluate(scope, calledFrom)
      ?.toString();

    if (!expression) return null;

    if (!isExpression(expression))
      return [
        {
          type: "quoted",
          content: expression,
          begin: 0,
          end: expression.length,
        },
      ];

    return getRegionsHelper(expression);
  }
}

/**
 * Validates an expression returning the first error it finds within the
 * expression.
 * @name isInvalidExpression() function
 * @param expression The expression being validated.
 * @returns `null` if the expression is valid, otherwise the first error it
 *    can find.
 */
class isInvalidExpressionFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const expression = this.arguments[0]
      .evaluate(scope, calledFrom)
      ?.toString();

    if (!expression) return null;

    try {
      createBehaviorTree(expression);
      return {
        _value: false,
        message: null,
        description: null,
        index: null,
      };
    } catch (err) {
      if (isJsepError(err)) {
        return {
          _value: true,
          message: err.message,
          description: err.description,
          index: err.index,
        };
      } else if (err instanceof Error) {
        return {
          _value: true,
          message: err.message,
          description: err.message,
          index: 0,
        };
      } else {
        return {
          _value: true,
          message: String(err),
          description: String(err),
          index: 0,
        };
      }
    }
  }
}

class animateToFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 2, 2);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number {
    if (!calledFrom)
      // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
      // otherwise the behavior execution gets out of sync
      throw new Error(
        "animateTo function cannot be called outside of a cell graph"
      );

    let now = toNumber(calledFrom.instances);
    const target = toNumber(this.arguments[0].evaluate(scope, calledFrom));

    if (now == target) return target;

    const speed = Math.abs(
      toNumber(this.arguments[1].evaluate(scope, calledFrom))
    );
    if (target > now) {
      now += speed;
      return now > target ? target : now;
    } else {
      now -= speed;
      return now < target ? target : now;
    }
  }
}
/**
 * Creates a version 1 (timestamp) UUID.
 *
 * Be aware that this function returns a new value on every call so that a cell
 * value will continuously be updated.
 * @name uuid1() function
 * @returns A version 1 UUID.
 */
class uuid1FunctionBehaviorNode implements BehaviorNode {
  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    getArgumentNodes(token, mode, 0, 0);
  }

  public evaluate(): string {
    return uuid.v1();
  }
}

/**
 * Creates a version 4 (random) UUID.
 *
 * Be aware that this function returns a new value on every call so that a cell
 * value will continuously be updated.
 * @name uuid4() function
 * @returns A version 4 UUID.
 */
class uuid4FunctionBehaviorNode implements BehaviorNode {
  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    getArgumentNodes(token, mode, 0, 0);
  }

  public evaluate(): string {
    return uuid.v4();
  }
}

/**
 * Tests a string to see if it is a valid UUID.
 * @name uuidValidate() function
 * @param uuid The uuid to be validated.
 * @returns True if `uuid` is valid.
 */
class uuidValidateFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): boolean {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (!value) return false;
    return uuid.validate(value.toString());
  }
}

/**
 * Detects the RFC version of a UUID.
 * @name uuidVersion() function
 * @param uuid The uuid of which the version should be detected.
 * @returns The version of `uuid` or null if `uuid` is not a valid uuid.
 */
class uuidVersionFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): number | null {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (!value) return null;
    try {
      return uuid.version(value.toString());
    } catch (err) {
      if (err instanceof TypeError) {
        return null;
      } else {
        // TODO: IMPORTANT! do not throw an excpetion, but return an error
        // value, as otherwise the behavior execution gets out of sync
        throw err;
      }
    }
  }
}

/**
 * Generates a random alphanumeric string with a default length of 20
 * characters. The default corresponds to a Firestore document id.
 * @name uid() function
 * @param length The length of the uid.
 * @returns The uid.
 */
class uidFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 0, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string {
    const length =
      this.arguments.length > 0
        ? toNumber(this.arguments[0].evaluate(scope, calledFrom))
        : 20;
    return uid(length);
  }
}
/**
 * Encodes a URI by replacing each instance of certain characters by one, two,
 * three, or four escape sequences representing the UTF-8 encoding of the
 * character.
 * @name encodeURI() function
 * @param URI A complete URI.
 * @returns A string representing the provided string encoded as a URI or null
 *    if `URI` is null.
 */
class encodeURIFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (value === null) return null;

    return encodeURI(value.toString());
  }
}

/**
 * Encodes a URI by replacing each instance of certain characters by one, two,
 * three, or four escape sequences representing the UTF-8 encoding of the
 * character.
 * @name encodeURIComponent() function
 * @param URIComponent A string, number, boolean, null, undefined, or any
 *    object. Before encoding, the `URIComponent` gets converted to a string.
 * @returns A string representing the provided `URIComponent` encoded as a URI
 *    component or null if `URIComponent` is null.
 */
class encodeURIComponentFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (value === null) return null;

    return encodeURIComponent(value.toString());
  }
}

/**
 * Decodes a Uniform Resource Identifier (URI) previously created by
 * `encodeURI` or by a similar routine.
 * @name decodeURI() function
 * @param encodedURI A complete, encoded Uniform Resource Identifier.
 * @returns A string representing the unencoded version of the given encoded
 *    Uniform Resource Identifier (URI) or null if `encodedURI` is null.
 */
class decodeURIFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (value === null) return null;

    // TODO: return error code in case decodeURI throws an exception
    return decodeURI(value.toString());
  }
}

/**
 * Decodes a Uniform Resource Identifier (URI) component previously created by
 * `encodeURIComponent` or by a similar routine.
 * @name decodeURIComponent() function
 * @param encodedURI An encoded component of a Uniform Resource Identifier.
 * @returns A string representing the decoded version of the given encoded
 *    Uniform Resource Identifier (URI) component or null if `URIComponent` is
 *    null.
 */
class decodeURIComponentFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): string | null {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (value === null) return null;

    // TODO: return error code in case decodeURIComponent throws an exception
    return decodeURIComponent(value.toString());
  }
}

/**
 * Converts a table defined by columns and rows into an array of objects.
 * @name tableToObjects() function
 * @param table A reference to a table object.
 * @returns An array of row objects, where each row has properties corresponding
 *    to the column names.
 */
class tableToObjectsFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(
    scope?: CellNameWalker[],
    calledFrom?: Cell
  ): ValueGraphData | ValueGraphData[] | null {
    const value = this.arguments[0].evaluate(scope, calledFrom);
    if (isValue(value)) return null;

    if (value instanceof Instance)
      return tableToObjects(value.getValueGraph() as unknown as Table);

    if (!Array.isArray(value)) return tableToObjects(value as unknown as Table);

    return {
      error: "Argument of tableToObject function must not be an array",
    };
  }
}

/**
 * Converts an array of objetcs into a table where the property names become
 * column names and the property values become the cells.
 * @name objectsToTable() function
 * @param objects A reference to a table object.
 * @returns A table defined by columns and rows where each row has cells.
 */
class objectsToTableFunctionBehaviorNode implements BehaviorNode {
  protected arguments: BehaviorNode[];

  constructor(token: jsep.CallExpression, mode: BehaviorMode) {
    this.arguments = getArgumentNodes(token, mode, 1, 1);
  }

  public evaluate(scope?: CellNameWalker[], calledFrom?: Cell): ValueGraphData {
    const value = makeArray(this.arguments[0].evaluate(scope, calledFrom));
    return objectsToTable(value) as unknown as ValueGraphData;
  }
}

/**
 * Helper functions
 */

/**
 * Converts a value including objects and arrays to a path/value array where
 * object properties are represented by concatenation of their names and arrays
 * are represented by concatenation of their index.
 * @param value The value that shall be converted.
 * @returns An array with path/value pairs.
 * @example 12 -> [{ path: "", value: 12 }]
 * @example { property: "string" } -> [{ path: "property", value: "string" }]
 * @example ["one", "two"] -> [{ path: "0", value: "one" }, { path: "1", value: "two" }]
 * @example { arraay: [1, 2] -> [{ path: "array.0", value: 1 }, { path: "array.1", value: 2 }]
 */
function valueToPathValueArray(value: unknown): PathValuePair[] {
  if (isValue(value)) {
    return [{ path: "", value: value }];
  } else if (Array.isArray(value)) {
    const result = [];
    for (let i = 0; i < value.length; i++) {
      // for each array item prepend the index to each path
      result.push(
        ...valueToPathValueArray(value[i]).map((item) => {
          return {
            path: item.path ? `${i}.${item.path}` : `${i}`,
            value: item.value,
          };
        })
      );
    }
    return result;
  } else if (value instanceof Instance) {
    return valueToPathValueArray(value.getValueGraph());
  } else if (typeof value === "object" && value !== null) {
    const result: PathValuePair[] = [];
    // add a "root" item for objects so that they identify an item in an array
    // in case the object is empty
    if (Object.entries(value).length === 0)
      result.push({ path: "", value: null });

    Object.entries(value).forEach(([propKey, propValue]) => {
      // for each property of the object prepend the index to each path
      result.push(
        ...valueToPathValueArray(propValue).map((item) => {
          return {
            path: item.path ? `${propKey}.${item.path}` : propKey,
            value: item.value,
          };
        })
      );
    });
    return result;
  } else {
    // TODO: IMPORTANT! do not throw an excpetion, but return an error value, as
    // otherwise the behavior execution gets out of sync
    throw new Error("Invalid constant value");
  }
}

/**
 * Stringifies a schema object.
 * @param schema The schema object to be stringified.
 * @param children (optional) Will be added to the stringified schema before the
 *    closing `}` using the indentation `ind`.
 * @param ind (optional) The indentation used for properties and
 *    children as a string. The default is two space characters.
 * @returns The stringified schema.
 */
function stringifyObject(
  schema: ValueGraph,
  children?: string,
  ind = "  "
): string {
  let result = "";
  let countprops = 0;

  Object.entries(schema).forEach(([key, value]) => {
    if (value === undefined) return;

    if (value instanceof Instance) value = toValue(value);

    if (
      (schemaUtils.isReservedName(key) &&
        value === null &&
        key !== "_value" &&
        key !== "_input" &&
        key !== "_output") ||
      (key === "_iscollection" && !value)
    )
      return;

    if (isValue(value)) {
      result += ind + key + ": " + stringifyValue(value) + ",\n";
    } else if (Array.isArray(value)) {
      const array = stringifyArray(value, ind);
      result += ind + key + ": " + array.replace(/\n/g, "\n" + ind) + ",\n";
    } else {
      let child = stringifyObject(value, "", ind).trim();
      if (child.endsWith("\n")) child = child.substring(0, child.length - 1);
      result += ind + key + ": " + child.replace(/\n/g, "\n" + ind) + ",\n";
    }
    countprops += 1;
  });

  if (children) {
    if (children.endsWith("\n"))
      children = children.substring(0, children.length - 1);
    result += ind + children.replace(/\n/g, "\n" + ind) + ",\n";
    countprops += 2; // making sure not just the value will be returned
  }

  if (!result) {
    return stringifyValue(null);
  } else if (countprops === 1 && result.startsWith(ind + "_value: ")) {
    // if result would be just `{ _value: xyz }` then return only `xyz`
    // TODO: do not use toValue() when the value is an array
    return stringifyValue(toValue(schema._value ?? null));
  } else {
    return "{\n" + result + "}";
  }
}

/**
 * Stringifies a primitive value.
 * @param value A primitive value (number, string, boolean) or null.
 * @returns The stringified value and escapes double quotation marks and
 *    backslashes within strings.
 */
function stringifyValue(value: Value): string {
  // escape double quotation marks and all backslashes
  // TODO: do not do this when this string works fine for YAML
  if (typeof value === "string") {
    return '"' + value.replace(/\\/g, "\\\\").replace(/"/g, '\\"') + '"';
  } else {
    return value === null ? "null" : value.toString();
  }
}

/**
 * Stringifies an array of values or objects using the given identation.
 * @param array The array to be stringified.
 * @param ind The indentation used for properties and children as a string.
 * @returns The stringified array.
 */
function stringifyArray(array: ValueGraphData[], ind: string): string {
  let result = [];
  let addNewlines = false;

  for (let value of array) {
    if (value instanceof Instance) value = toValue(value);
    if (isValue(value)) {
      result.push(stringifyValue(value));
    } else if (Array.isArray(value)) {
      result.push(stringifyArray(value, ind));
      addNewlines = true;
    } else {
      result.push(stringifyObject(value, "", ind));
      addNewlines = true;
    }
  }
  if (addNewlines) {
    result = result.map((value) => {
      return value.replace(/\n/g, "\n" + ind);
    });
    return "[\n" + ind + result.join(",\n" + ind) + "\n]";
  } else {
    return "[" + result.join(", ") + "]";
  }
}

/**
 * Returns a path/value array of all inputs of a schema.
 * @param path The path of this schema branch.
 * @param schema The schema to be parsed.
 * @returns A path/value array.
 */
function parseInputs(path: string, schema: unknown): PathValuePair[] {
  const result: PathValuePair[] = [];

  // TODO: add whether the input/output is a collection

  if (!isSchema(schema)) return result;

  if (schema._input !== undefined)
    result.push({
      path: path,
      value: isValue(schema._input) ? schema._input : null,
    });

  Object.entries(schema).forEach(([key, value]) => {
    if (schemaUtils.isReservedName(key.toLowerCase())) return;
    // stop parsing if value is still not an input or output
    if (!isSchema(value) || value._input === undefined) return;

    result.push(...parseInputs(path ? path + "." + key : key, value));
  });

  return result;
}

/**
 * Returns a path/value array of all outputs of a schema.
 * @param path The path of this schema branch.
 * @param schema The schema to be parsed.
 * @returns A path/value array.
 */
function parseOutputs(path: string, schema: unknown): PathValuePair[] {
  const result: PathValuePair[] = [];

  // TODO: add whether the input/output is a collection

  if (!isSchema(schema)) return result;

  if (schema._output !== undefined)
    result.push({
      path: path,
      value: isValue(schema._output) ? schema._output : null,
    });

  Object.entries(schema).forEach(([key, value]) => {
    if (schemaUtils.isReservedName(key.toLowerCase())) return;
    // stop parsing if value is still not an input or output
    if (!isSchema(value) || value._output === undefined) return;

    result.push(...parseOutputs(path ? path + "." + key : key, value));
  });

  return result;
}

interface Row {
  cells: Value[];
}

interface Table {
  columns: string[];
  rows: Row[];
}

// regular expression to replace all non word characters (and numeric characters
// at the beginning of the string)
const replacePropNameRE = /^\d|\W/g;

function tableToObjects(table: Table): ValueGraphData[] {
  const result: ValueGraphData[] = [];
  // TODO: make `columns`, `rows`, and `cells` case insensitive
  table.rows.map((row) => {
    const item: ValueGraphData = {};
    row.cells.forEach((cell, index) => {
      // do not add the cell if it is beyond the number of columns or if the
      // column name is not defined
      if (index >= table.columns.length) return;
      const colName = table.columns[index];
      if (!colName) return;
      // set the object properties
      item[colName.replace(replacePropNameRE, "")] = cell;
    });
    result.push(item);
  });
  return result;
}

function objectsToTable(objects: ValueGraphData[]): Table {
  const table: Table = { columns: [], rows: [] };

  for (let item of objects) {
    // a table row requires at least an object. `null` does not create a row
    if (isValue(item)) continue;

    if (item instanceof Instance) item = item.getValueGraph();

    const row: Row = { cells: [] };

    Object.entries(item).forEach(([key, value]) => {
      const keyLower = key.toLowerCase();
      if (keyLower === "_value") return; // the root value is not considered
      let colIndex = table.columns.findIndex(
        (name) => name.toLowerCase() == keyLower
      );
      // key is not yet added to the column names
      if (colIndex < 0) colIndex = table.columns.push(key) - 1;
      row.cells[colIndex] = toValue(value ?? null);
    });

    for (let i = 0; i < row.cells.length; i++) {
      if (row.cells[i] === undefined) row.cells[i] = null;
    }

    table.rows.push(row);
  }

  return table;
}
